import { Fragment, useMemo } from "react"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { Table as TanstackTable, flexRender } from "@tanstack/react-table"

import { ReactComponent as TestTubesLarge } from "app/assets/icons/test-tubes-medium.svg"
import BodyText from "app/components/design-system/BodyText"
import useSetQueryParam from "app/hooks/use-set-query-param"

import StartOrderButton from "../order-history/StartOrderButton"
import { useIOKInventory } from "./InventoryDataProvider"
import { InventoryItem } from "./inventory-types"

interface Props {
  table: TanstackTable<any>
}

const InventoryTableRenderer: React.FC<Props> = ({ table }) => {
  const { count } = useIOKInventory()

  return (
    <div className="border border-slate-200 rounded-lg overflow-auto bg-white shadow">
      <Table className="table-auto px-6">
        {Boolean(count) && (
          <TableHead className="bg-slate-50">
            <TableHeaderRow table={table} />
          </TableHead>
        )}
        <TableBody>
          {table.options.meta?.isLoading ? (
            <TablePlaceholderRows table={table} />
          ) : table.getRowModel().rows.length > 0 ? (
            <TableDataRows table={table} />
          ) : (
            <TableEmptyRow table={table} />
          )}
        </TableBody>
      </Table>
    </div>
  )
}

const TableDataRows = ({ table }: { table: TanstackTable<InventoryItem> }) => {
  return (
    <>
      {table.getRowModel().rows.map((row) => (
        <Fragment key={row.id}>
          <TableRow
            className="bg-white h-[80px]"
            onClick={
              row.getCanExpand() ? row.getToggleExpandedHandler() : undefined
            }
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell
                key={cell.id}
                className="break-words"
                style={{
                  ...cell.column.columnDef.meta?.cellStyle,
                  ...cell.column.columnDef.meta?.dataCellStyle,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        </Fragment>
      ))}
    </>
  )
}

const TableHeaderRow = ({ table }: { table: TanstackTable<InventoryItem> }) => {
  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableCell
              key={header.id}
              className="break-words"
              colSpan={header.colSpan}
              style={{
                ...header.column.columnDef.meta?.cellStyle,
                ...header.column.columnDef.meta?.headerCellStyle,
                maxWidth: header.column.columnDef.maxSize,
                minWidth: header.column.columnDef.minSize,
                width: header.getSize(),
              }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const TableEmptyRow = ({ table }: { table: TanstackTable<InventoryItem> }) => {
  const { count } = useIOKInventory()
  const setQueryParam = useSetQueryParam()
  const { title, subtitle, children } = useMemo(() => {
    if (count && count > 0) {
      return {
        title: "No Kits match the selected filters.",
        subtitle: "",
        children: "",
      }
    } else {
      return {
        title: "Order kits through Rupa to track your inventory.",
        subtitle:
          "Kit tracking is available for specialty kits from Diagnostic Solutions, DUTCH, US Biotek, Alletess, Doctor's Data, and Mosaic Diagnostics.",
        children: (
          <div className="pt-3">
            <StartOrderButton
              onSuccess={() => setQueryParam("tab", "order-history")}
            />
          </div>
        ),
      }
    }
  }, [count])

  return (
    <TableRow>
      <TableCell
        align="center"
        className="px-2 py-8 break-words"
        colSpan={table.getAllColumns().length}
      >
        <div className="flex flex-col content-center items-center bg-white gap-[5px] mx-auto md:w-2/3 lg:w-1/2">
          <TestTubesLarge className="fill-slate-500" />
          <BodyText className="font-semibold text-base15">{title}</BodyText>
          <BodyText className="text-sm">{subtitle}</BodyText>
          {children}
        </div>
      </TableCell>
    </TableRow>
  )
}

const TablePlaceholderRows = ({
  table,
}: {
  table: TanstackTable<InventoryItem>
}) => {
  return (
    <>
      {[0, 1, 2, 3, 4].map((index) => (
        <TableRow key={index} className="bg-white h-[80px]">
          {table.getAllColumns().map((column, colIdx) => (
            <TableCell
              key={column.id}
              style={{
                ...column.columnDef.meta?.cellStyle,
                ...column.columnDef.meta?.dataCellStyle,
              }}
            >
              {CellSkeleton(colIdx)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const CellSkeleton = (colIdx: number) => {
  switch (colIdx) {
    case 0:
      return (
        <>
          <Skeleton
            animation="wave"
            component="div"
            height={24}
            width="75%"
            variant="text"
          />
          <Skeleton
            animation="wave"
            component="div"
            height={24}
            width="50%"
            variant="text"
          />
        </>
      )
    case 1:
      return (
        <Skeleton
          animation="wave"
          component="div"
          height={24}
          width="75%"
          variant="text"
        />
      )
    case 2:
      return (
        <Skeleton
          animation="wave"
          component="div"
          className="rounded-2xl"
          height={32}
          width={88}
          variant="text"
        />
      )
    case 3:
      return (
        <Skeleton
          animation="wave"
          component="div"
          className="ml-auto rounded-lg"
          height={45}
          width={144}
          variant="text"
        />
      )
  }
}

export default InventoryTableRenderer

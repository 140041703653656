import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Helmet } from "react-helmet"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useMount } from "react-use"

import { CircularProgress, Link, makeStyles, Theme } from "@material-ui/core"

import { API } from "app/api"
import BodyText from "app/components/design-system/BodyText"
import {
  OrderIntent,
  StorefrontType,
  useResource,
} from "app/hooks/use-resource"
import { trackLabShopEvent, trackPage } from "app/services/segment"
import { showMessage } from "app/store/actions"
import { handleApiError } from "app/utils"

import { LabShopEvent } from "../constants"
import PublicStorefront404 from "./PublicStorefront404"
import PublicStorefrontSingleProduct from "./PublicStorefrontSingleProduct"
import Footer from "./components/Footer"
import Navbar from "./components/Navbar"
import PublicStorefrontListProducts from "./components/PublicStorefrontListProducts"

enum SegmentAddToCartActions {
  ADD = "Add",
  REMOVE = "Remove",
}

export interface SelectedCatalogProduct {
  type: string
  id: string
}

export enum CardOptions {
  PRODUCT = "storefront_product",
  LAB_TEST = "lab_test",
  BUNDLE = "lab_test_bundle",
}

const styles = (theme: Theme) => ({
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorMessageTextContainer: {
    display: "flex",
    "flex-direction": "column",
    gap: 3,
  },
  errorMessageText: {
    display: "block",
    color: "white",
    fontSize: 15,
  },
})

const useStyles = makeStyles(styles)

const PublicStorefront = () => {
  const classes = useStyles()

  const { storefrontId } = useParams<{
    storefrontId: string
  }>()

  const { search } = useLocation()
  const history = useHistory()

  const dispatch = useDispatch()

  const [showSingleCatalogProduct, setShowSingleCatalogProduct] =
    useState<SelectedCatalogProduct | null>(null)

  const storefrontPublishedInclude = [
    "storefront_sections.storefront_products.lab_test.lab_company",
    "storefront_sections.storefront_products.lab_test.lab_test_types",
    "storefront_sections.storefront_products.lab_test.biomarkers",
    "storefront_sections.storefront_products.lab_test.health_categories",
    "storefront_sections.storefront_products.bundle.lab_tests.lab_test_types",
    "storefront_sections.storefront_products.bundle.lab_tests.lab_company",
    "storefront_sections.storefront_products.bundle.lab_tests.biomarkers",
    "storefront_sections.storefront_products.custom_fee",
    "ordering_practitioner",
    "clinic",
  ]
  const {
    ready,
    data: storefront,
    errors,
  } = useResource<StorefrontType>(`storefronts/${storefrontId}/published/`, {
    include: storefrontPublishedInclude,
  })

  const storefrontCartInclude = [
    "bundles.lab_tests.lab_company",
    "lab_tests.lab_company",
    "line_items",
    "storefront_products.bundle.lab_tests.lab_company",
    "storefront_products.lab_test.lab_company",
  ]
  const {
    data: cart,
    updateCache: updateCart,
    errors: cartErrors,
  } = useResource<OrderIntent>(`storefronts/${storefrontId}/cart/`, {
    include: storefrontCartInclude,
  })

  const handleScrollToFaq = async () => {
    if (showSingleCatalogProduct) {
      history.push({ search: "" })
      // Need to hesitate very shortly so that scroll to dom element works
      await new Promise((r) => setTimeout(r, 200))
    }

    const section = document.querySelector("#faq")

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  /**
   * Sends request to API to add or remove the product from the cart
   *
   * @param id ID of the LabTest or Bundle
   * @param type Either LabTest or Bundle
   * @param requestBody Request to send to API
   * @param alreadyAdded Whether or not test was added or removed (for sentry)
   */
  const handleAddRemoveCart = async (
    id: string,
    type: CardOptions,
    requestBody: any,
    alreadyAdded: boolean
  ) => {
    const noCartFound =
      cartErrors?.find(({ code }) => code === "no_cart_found") || !cart

    try {
      const response = noCartFound
        ? await API.Storefront.createCart(
            storefront?.id,
            requestBody,
            storefrontCartInclude
          )
        : await API.Storefront.updateCart(
            storefront?.id,
            requestBody,
            storefrontCartInclude
          )
      updateCart(response.data)
    } catch (error: any) {
      try {
        const errorDetail = error.response.data.errors.detail

        if (errorDetail && "storefront_products" in errorDetail) {
          const errorMessage = (
            <div className={classes.errorMessageTextContainer}>
              <BodyText className={classes.errorMessageText}>
                {errorDetail.storefront_products[0]}
              </BodyText>
              <BodyText className={classes.errorMessageText}>
                Reach out to{" "}
                <Link
                  href="mailto:support@rupahealth.com"
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  support@rupahealth.com
                </Link>{" "}
                if you need further assistance.
              </BodyText>
            </div>
          )
          dispatch(
            showMessage({
              message: errorMessage,
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              autoHideDuration: null,
            })
          )
        } else {
          dispatch(handleApiError(error))
        }
      } catch {
        dispatch(handleApiError(error))
      }
    }

    if (storefront && cart) {
      trackLabShopEvent(LabShopEvent.ADD_TO_CART, {
        storefrontId: storefront?.id,
        storefrontCartId: cart?.id,
        labTestId: type === CardOptions.LAB_TEST ? id : "",
        bundleId: type === CardOptions.BUNDLE ? id : "",
        type: alreadyAdded
          ? SegmentAddToCartActions.ADD
          : SegmentAddToCartActions.REMOVE,
      })
    }
  }

  useEffect(() => {
    if (search) {
      if (search.includes("?labTest=")) {
        const labTestId = search.split("?labTest=")[1]
        setShowSingleCatalogProduct({ type: "labTest", id: labTestId })
      } else if (search.includes("?bundle=")) {
        const bundleId = search.split("?bundle=")[1]
        setShowSingleCatalogProduct({ type: "bundle", id: bundleId })
      } else if (search.includes("?storefrontProduct=")) {
        setShowSingleCatalogProduct({
          type: "product",
          id: search.split("?storefrontProduct=")[1],
        })
      } else {
        setShowSingleCatalogProduct(null)
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      }
    } else {
      setShowSingleCatalogProduct(null)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [search])

  useMount(() => {
    trackPage(window.location.pathname)
  })

  if (errors) {
    return <PublicStorefront404 />
  }

  if (!ready) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress size={60} />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{storefront.attributes.name}</title>
        <meta name="description" content={storefront.attributes.description} />
        <meta property="og:title" content={storefront.attributes.name} />
        <meta
          property="og:description"
          content={storefront.attributes.description}
        />
      </Helmet>
      <Navbar scrollToFaq={handleScrollToFaq} storefront={storefront} />
      {!showSingleCatalogProduct ? (
        <PublicStorefrontListProducts
          storefront={storefront}
          cart={cart}
          handleAddRemoveCart={handleAddRemoveCart}
        />
      ) : (
        <PublicStorefrontSingleProduct
          storefront={storefront}
          cart={cart}
          handleAddRemoveCart={handleAddRemoveCart}
          selectedProduct={showSingleCatalogProduct}
        />
      )}
      <Footer />
    </>
  )
}

export default PublicStorefront

import * as React from "react"

import { InputLabel, Paper } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg"
import { ReactComponent as SelectionIcon } from "app/assets/icons/selection-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import TextField from "app/components/forms/TextField"
import { primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles(() => ({
  autocompleteEndAdornment: {
    position: "absolute",
    right: 9,
    pointerEvents: "none",
  },
  textField: {
    backgroundColor: "white",
    borderRadius: "6px",
    "& fieldset": {
      borderRadius: "6px",
      border: "1px solid #D3DCE3",
    },
  },
  dropdownOptionText: {
    padding: "14px 10px",
  },
  dropdownOption: {
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  dropdownContent: {
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    marginTop: 5,
    overflowY: "auto",
    "& .MuiAutocomplete-listbox": {
      padding: 0,
    },
    "& .MuiAutocomplete-option": {
      padding: 0,
    },
  },
  popupIconSpacer: {
    width: 16,
    height: 16,
  },
}))

interface FilterProps<T> {
  value: T | null
  options: T[]
  placeholder: string
  onChange: (event: React.ChangeEvent<{}>, newValue: T | null) => void
  getOptionLabel: (option: T) => string
  selectIcon?: boolean
  style?: React.CSSProperties
  textFieldStyle?: React.CSSProperties
  getOptionSelected?: (option: T, value: T) => boolean
  noOptionsText?: string
  label?: string
}

export const Filter = <T,>({
  value,
  options,
  placeholder,
  onChange,
  getOptionLabel,
  selectIcon,
  style,
  textFieldStyle,
  getOptionSelected,
  noOptionsText,
  label,
}: FilterProps<T>) => {
  const classes = useStyles()
  if (!style) {
    style = { width: 300, fontSize: 14 }
  }

  return (
    <>
      {label && (
        <InputLabel className="font-semibold text-sm">{label}</InputLabel>
      )}
      <Autocomplete
        id="autocomplete-filter"
        popupIcon={<div className={classes.popupIconSpacer}></div>}
        value={value as any}
        style={{ ...style }}
        options={options}
        freeSolo={false}
        autoHighlight={true}
        PaperComponent={({ children }) => (
          <Paper className={classes.dropdownContent}>{children}</Paper>
        )}
        getOptionLabel={getOptionLabel}
        getOptionSelected={
          getOptionSelected || ((option, value) => option === value)
        }
        onChange={onChange}
        renderOption={(option) => (
          <div className={classes.dropdownOption}>
            <BodyText className={classes.dropdownOptionText} weight="semibold">
              {getOptionLabel(option)}
            </BodyText>
          </div>
        )}
        noOptionsText={noOptionsText}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            multiline
            placeholder={placeholder}
            variant="outlined"
            size="small"
            style={{ ...textFieldStyle }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {selectIcon ? (
                    <SelectionIcon
                      width={16}
                      height={16}
                      fill={primaryColor}
                      className={classes.autocompleteEndAdornment}
                    />
                  ) : (
                    <SearchIcon
                      width={16}
                      height={16}
                      fill={primaryColor}
                      className={classes.autocompleteEndAdornment}
                    />
                  )}
                </>
              ),
            }}
            className={classes.textField}
            // If value is set make the input focused
            // Otherwise don't pass prop to avoid overriding default behavior
            {...(value ? { focused: true } : {})}
          />
        )}
      />
    </>
  )
}

import { useLocation } from "react-use"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { OptimalRangeSource } from "app/main/settings/RupaBloodDashboards/constants"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

export default function usePatientPortalOptimalRanges(
  labCompanyId?: string,
  practitionerId?: string
) {
  const [isPatientPortalEnabled] = useFeatureFlag(FeatureFlag.PatientPortal)
  const location = useLocation()
  const isNewPatientPortal = location.href?.includes("patients")
  const useNewPatientPortalApi = isPatientPortalEnabled && isNewPatientPortal

  let optimalRangeUrl = "/patient_portal_lab_company_biomarker_range_legacy/"

  if (useNewPatientPortalApi) {
    optimalRangeUrl = "/patient_portal_lab_company_biomarker_range/"
  }

  let params = {}
  if (Boolean(labCompanyId)) {
    params["filter[lab_company.id]"] = labCompanyId
  }

  if (Boolean(practitionerId)) {
    params["practitioner_id"] = practitionerId
  }

  const { data, isLoading } = useCollectionSWR<
    ResourceCollection<LabCompanyBiomarkerRange>
  >(
    optimalRangeUrl,
    {
      ...{},
      include: ["biomarker", "lab_company"],
      params,
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  )

  const optimalRanges = useCachedCollection<LabCompanyBiomarkerRange>(data)

  const odxOptimalRanges = optimalRanges.filter(
    // TODO: Remove ODX once we have migrated all ODX ranges to Rupa Health - https://app.asana.com/0/1205298136487588/1205900165584568/f
    (optimalRange) =>
      optimalRange.attributes.source === OptimalRangeSource.ODX ||
      optimalRange.attributes.source === OptimalRangeSource.RUPA_HEALTH
  )
  const clinicOptimalRanges = optimalRanges.filter(
    (optimalRange) =>
      optimalRange.attributes.source === OptimalRangeSource.CLINIC
  )

  const mergedOptimalRanges: LabCompanyBiomarkerRange[] = []
  odxOptimalRanges.forEach((optimalRange) => {
    const clinicOptimalRange = clinicOptimalRanges.find(
      (or) =>
        or.relationships.biomarker.data?.id ===
        optimalRange.relationships.biomarker.data?.id
    )
    if (clinicOptimalRange) {
      mergedOptimalRanges.push(clinicOptimalRange)
    } else {
      mergedOptimalRanges.push(optimalRange)
    }
  })

  return {
    optimalRanges: mergedOptimalRanges,
    isLoading,
  }
}

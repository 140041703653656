import { useDispatch } from "react-redux"

import axios from "axios"

import { showMessage } from "app/store/actions"
import { getApiBaseUrl, handleApiError } from "app/utils"

const useLabResult = () => {
  const dispatch = useDispatch()

  const uploadLabResult = async (
    file: File,
    patientId: string
  ): Promise<string | undefined> => {
    const formData = new FormData()

    const fileType = getFileType(file)

    formData.append("original_file_name", file.name)
    formData.append("file_type", fileType)
    formData.append("uploaded_from", "user_upload")
    formData.append("file", file)
    formData.append("uploaded_for_patient_id", patientId)

    try {
      const response = await axios.post(
        getApiBaseUrl() + `/api/normalized/lab_results/`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )

      const labResultId = response?.data?.data?.id

      if (!labResultId) {
        dispatch(
          showMessage({
            message: "There was an error uploading the lab result",
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          })
        )

        return
      }

      return labResultId
    } catch (error) {
      dispatch(handleApiError(error))
      return
    }
  }

  const createLabResultFromOrderedResult = async (
    orderedResultId: string,
    patientId: string
  ): Promise<string | undefined> => {
    try {
      const response = await axios.post(
        getApiBaseUrl() +
          `/api/normalized/lab_results/create_from_ordered_result/`,
        {
          uploaded_for_patient_id: patientId,
          source_ordered_result_id: orderedResultId,
        }
      )

      const labResultId = response?.data?.data?.id

      if (!labResultId) {
        dispatch(
          showMessage({
            message: "There was an error uploading the lab result",
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          })
        )

        return
      }

      return labResultId
    } catch (error) {
      dispatch(handleApiError(error))
      return
    }
  }

  const invokeLabResultPipeline = async (labResultId: string) => {
    try {
      const response: any = await axios.post(
        getApiBaseUrl() + `/api/normalized/lab_results/${labResultId}/invoke/`
      )

      const invocationId = response?.data?.data?.job_id

      if (!invocationId) {
        dispatch(
          showMessage({
            message: "There was an error processing the PDF",
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          })
        )

        return
      }

      return invocationId
    } catch (error) {
      dispatch(handleApiError(error))
      return
    }
  }

  return {
    uploadLabResult,
    createLabResultFromOrderedResult,
    invokeLabResultPipeline,
  }
}

const getFileType = (file: File) => {
  const splitFileName = file.name.split(".")
  return splitFileName[splitFileName.length - 1]
}

export default useLabResult

import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { isEmpty } from "lodash"
import { useHistory, useLocation } from "react-router-dom"

import { CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { API } from "app/api"
import { StorefrontType } from "app/hooks/use-resource"
import { OrderStatusesState } from "app/main/dashboard/constants"
import OrderListTabs, {
  TabOptions,
} from "app/main/ecommerce/OrderList/OrderListTabs"
import { OrderTable } from "app/main/ecommerce/OrderList/OrderTable"
import {
  PAGE_SIZE,
  UserAppliedFilters,
} from "app/main/ecommerce/OrderList/types"
import { showMessage } from "app/store/actions"
import { colors, shadows } from "app/theme"
import { RootState } from "app/types"
import { handleApiError, handleApiSuccess } from "app/utils"

import { StorefrontOrderApprovalStatus } from "../constants"
import * as Actions from "../store/actions"

const useOrderListStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${colors.coolGray[200]}`,
    borderRadius: 8,
    overflow: "hidden",
    background: "white",
    margin: 16,
    boxShadow: shadows.default,

    [theme.breakpoints.up("sm")]: {
      margin: 24,
    },
  },
}))

type EcommerceOrderListProps = {
  storefronts: StorefrontType[]
}

export default function EcommerceOrderList({
  storefronts,
}: EcommerceOrderListProps) {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)

  const [appliedFilters, setAppliedFilters] = useState<UserAppliedFilters>({
    storefronts: "show",
  })
  const [activeTab, setActiveTab] = useState<TabOptions | undefined>(
    TabOptions.IN_PROGRESS
  )

  const orders = useSelector(({ ecommerce }: RootState) => ecommerce.orders)

  const ordersPending = useSelector(
    ({ ecommerce }: RootState) => ecommerce.ordersPending
  )

  const unreadResultNotifications = useSelector(
    ({ ecommerce }: RootState) => ecommerce.unreadResultNotifications
  )

  const history = useHistory()
  const { search } = useLocation()

  const handleChangePage = (page) => {
    setPage(page)
  }

  const ordersLoading = useMemo(
    () => ordersPending || !storefronts.length,
    [ordersPending, storefronts]
  )

  const offset = useMemo(() => page * PAGE_SIZE, [page])

  const handleChangeFilters = (attr, newValue) => {
    const filters = {
      ...appliedFilters,
      [attr]: newValue,
    }
    setAppliedFilters(filters)
    setPage(0)

    // If we are searching all orders, clear out query params
    if (attr === "tab_status" && !newValue) {
      history.push({
        search: "",
      })
      setActiveTab(undefined)
    }
  }

  /**
   * We want to default to the tabs in the following order if they have orders. If they don't, move on to the next priority tab:
   *
   * 1. Results In
   * 2. In Progress
   * 3. Draft
   * 4. Reviewed
   */
  const processDefaultTab = (
    orderStatuses: OrderStatusesState
  ): TabOptions | undefined => {
    if (orderStatuses.has_results_in_orders) {
      return TabOptions.RESULTS_IN
    } else if (orderStatuses.has_in_progress_orders) {
      return TabOptions.IN_PROGRESS
    } else if (orderStatuses.has_reviewed_orders) {
      return TabOptions.REVIEWED
    }
    return TabOptions.ALL
  }

  const fetchOrders = () => {
    dispatch(Actions.getUnreadResultNotifications(appliedFilters))
    dispatch(Actions.getOrders(offset, appliedFilters))
  }

  useEffect(() => {
    const getDefaultOrderStatuses = async () => {
      // Don't override default tab if a user is navigating directly to a dashboard with a tab query param already set
      const params = new URLSearchParams(search)
      const tab = params.get("tab")
      if (!tab) {
        await API.Order.getDefaultOrderStatuses({
          show_storefront_orders: true,
        })
          .then((response) => {
            const defaultTab = processDefaultTab(response.data)

            if (defaultTab) {
              history.push({
                search: `?tab=${defaultTab}`,
              })
              setActiveTab(defaultTab)
            }

            setAppliedFilters({
              storefronts: "show",
              applied: true,
              tab_status: defaultTab,
            })
          })
          .catch((error) => dispatch(handleApiError(error)))
      } else if ((Object.values(TabOptions) as string[]).includes(tab)) {
        setActiveTab(tab as TabOptions)
        setAppliedFilters({
          storefronts: "show",
          applied: true,
          tab_status: tab as TabOptions,
        })
      }
    }

    if (storefronts.length) {
      getDefaultOrderStatuses()
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(appliedFilters)) {
      fetchOrders()
    }

    dispatch(Actions.clearOrders())
  }, [offset, appliedFilters])

  const handleTabChange = (tab: TabOptions) => {
    history.push({
      search: `?tab=${tab}`,
    })
    handleChangeFilters("tab_status", tab)
    setActiveTab(tab)
  }

  const approveDenyOrder = async (
    approvalId: string,
    status: StorefrontOrderApprovalStatus
  ) => {
    try {
      const response =
        await API.StorefrontOrderApproval.approveOrDenyStorefrontOrder(
          approvalId,
          { status }
        )

      if (response.data) {
        fetchOrders()

        dispatch(
          handleApiSuccess(
            `Order successfully ${
              status === StorefrontOrderApprovalStatus.APPROVED
                ? "Approved"
                : "Denied"
            }`
          )
        )
      }
    } catch (error) {
      dispatch(
        showMessage({
          message: `Unable to ${
            status === StorefrontOrderApprovalStatus.APPROVED
              ? "approve"
              : "deny"
          } Order. Please try again later or reach out to support@rupahealth.com`,
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          autoHideDuration: 5000,
        })
      )
    }

    dispatch(Actions.getUnreadResultNotifications(appliedFilters))
    dispatch(Actions.getOrders(offset, appliedFilters))
  }

  const { container } = useOrderListStyles()

  const OrderTabs = (
    <OrderListTabs
      onTabChange={handleTabChange}
      appliedTabFilter={activeTab}
      useStorefrontTabs={true}
      unreadNotificationCount={unreadResultNotifications.count}
    />
  )

  if (ordersLoading) {
    return (
      <div className={container}>
        {OrderTabs}
        <div className="flex w-24 justify-center p-10">
          <CircularProgress aria-label="Loading orders" />
        </div>
      </div>
    )
  }

  return (
    <div className={container}>
      {OrderTabs}
      <OrderTable
        appliedFilters={appliedFilters}
        onChangeFilters={handleChangeFilters}
        onChangePage={handleChangePage}
        orders={orders}
        page={page}
        storefronts={storefronts.map((storefront) => ({
          id: storefront.id,
          name: storefront.attributes.name,
        }))}
        handleApproveDenyOrder={approveDenyOrder}
      />
    </div>
  )
}

import React, { useMemo, useEffect, useState } from "react"

import clsx from "clsx"
import { Link } from "react-router-dom"

import NiceModal from "@ebay/nice-modal-react"

import { UserPaths } from "app/Routes"
import Loading from "app/components/Loading"
import BodyText from "app/components/design-system/BodyText"
import { Dialog } from "app/components/modals"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useBundleResultsPdfPayload from "app/main/blood-lab-dashboards/BloodLabDashboardsPdfExport/use-bundle-results-pdf-payload"
import { InRangeOption } from "app/main/patient-orders/results-over-time/types/types"
import { ClinicSettings } from "app/main/settings/RupaBloodDashboards/constants"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { Clinic } from "types/clinic"
import { Patient, PatientSettings } from "types/patient"
import { Practitioner } from "types/practitioner"

import BloodLabDashboardsBiomarkerCountOverview from "../BloodLabDashboardsModal/BloodLabDashboardsBiomarkerCountOverview"
import BloodLabDashboardsBiomarkerMissingWarning from "../BloodLabDashboardsModal/BloodLabDashboardsBiomarkerMissingWarning"
import BloodLabDashboardsDisclaimerText from "../BloodLabDashboardsModal/BloodLabDashboardsDisclaimerText"
import { BloodLabDashboardsGroupByGroupings } from "../BloodLabDashboardsModal/BloodLabDashboardsGroupByToggle"
import BloodLabDashboardsModalHeader from "../BloodLabDashboardsModal/BloodLabDashboardsModalHeader"
import BloodLabDashboardsResultsNotSharedWarning from "../BloodLabDashboardsModal/BloodLabDashboardsResultsNotSharedWarning"
import BloodLabDashboardsSearchAndFilter from "../BloodLabDashboardsModal/BloodLabDashboardsSearchAndFilter"
import { DEFAULT_BODY_SYSTEM_DROPDOWN_ID } from "../BloodLabDashboardsModal/BodySystemDropdownFilter"
import useBiomarkerCustomDescriptions from "../hooks/use-biomarker-custom-descriptions"
import useBloodLabDashboardsSnapshot from "../hooks/use-blood-lab-dashboards-snapshot"
import BloodLabDashboardsSnapshotAbnormalSection from "./BloodLabDashboardsSnapshotAbnormalSection"
import BloodLabDashboardsSnapshotBiomarkerSection from "./BloodLabDashboardsSnapshotBiomarkerSection"

const useStyles = makeAppStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  dialog: {
    borderRadius: 12,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      margin: 0,
      width: "100vw",
    },
  },
  body: {
    backgroundColor: colors.coolGray[100],
    padding: 22.5,
    display: "flex",
    flexDirection: "column",
    gap: 24,
    maxWidth: 970,
    borderTop: `1px solid ${colors.blueGray[300]}`,
  },
  noResultsText: {
    color: colors.blueGray[400],
    textAlign: "center",
  },
  disabledNotificationContainer: {
    background: colors.blueGray[200],
    padding: "8px 22.5px",
    maxWidth: 970,
    borderTop: `1px solid ${colors.blueGray[300]}`,
  },
  settingsLink: {
    color: primaryColor,
    fontWeight: 600,
  },
  divider: {
    marginTop: 9,
    background: colors.blueGray[200],
  },
  notSharedWarning: {
    maxHeight: 300,
    opacity: 1,
    marginTop: 0,
    transition: "max-height 0.3s ease-in, opacity 0.3s ease-in",
  },
  hideNotSharedWarning: {
    maxHeight: 0,
    opacity: 0,
    marginTop: -24,
    zIndex: -1,
    transition:
      "max-height 0.3s ease-out, opacity 0.3s ease-out, margin-top 0.4s",
  },
}))

interface BloodLabDashboardsIntroductionModalProps {
  onClose: () => void
  snapshotId: string
  kitSentToPatient: boolean
}

const BloodLabDashboardsSnapshotModal =
  NiceModal.create<BloodLabDashboardsIntroductionModalProps>((props) => {
    const handleApiError = useHandleApiError()

    const [searchText, setSearchText] = React.useState("")
    const [inRangeValueFilter, setInRangeValueFilter] =
      React.useState<InRangeOption>(InRangeOption.ALL)
    const [groupBy, setGroupBy] =
      React.useState<BloodLabDashboardsGroupByGroupings>(
        BloodLabDashboardsGroupByGroupings.LAB_TEST
      )
    const [hideNotSharedWarning, setHideNotSharedWarning] = React.useState(
      props.kitSentToPatient
    )

    const classes = useStyles()

    const [filterBodySystemId, setFilterBodySystemId] = useState<string>(
      DEFAULT_BODY_SYSTEM_DROPDOWN_ID
    )

    const {
      bloodReportResults,
      allBiomarkers,
      isBloodReportResultsLoading,
      isBloodReportResultsValidating,
      bloodReportResultsError,
      mutateBloodReportResultsAndSnapshot,
      getBloodReportResultsByLabTest,
      getBloodReportResultsByBodySystem,
      snapshot,
      isSnapshotLoading,
      snapshotError,
      orderedResult,
      labTests,
      sortedBodySystems,
      missingBiomarkers,
      usesClinicOptimalRange,
      labCompany,
      userResults,
    } = useBloodLabDashboardsSnapshot(props.snapshotId, searchText)

    const {
      biomarkerCustomDescriptionsMapping,
      isLoading: isBiomarkerCustomDescriptionsLoading,
    } = useBiomarkerCustomDescriptions(
      allBiomarkers.map((biomarker) => biomarker.id)
    )

    const patient = useCachedResource<Patient>(
      snapshot?.relationships.patient.data
    )

    const totalBiomarkers = useCachedCollection<Biomarker>(
      labTests.flatMap((labTest) => {
        return labTest.relationships.biomarkers.data
      })
    )

    const practitioner = useCachedResource<Practitioner>(
      patient?.relationships.practitioner.data
    )

    const clinic = useCachedResource<Clinic>(
      practitioner?.relationships.clinic.data
    )

    const showHighLowDescriptions = useMemo(() => {
      return patient?.attributes.patient_settings.includes(
        PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS
      )
    }, [patient])

    const abnormalBloodReportResults = useMemo(() => {
      return bloodReportResults.filter(
        (bloodReportResult) =>
          bloodReportResult.attributes.out_of_standard_range
      )
    }, [bloodReportResults])

    const showAllMarkersTitle = useMemo(() => {
      if (
        searchText !== "" ||
        filterBodySystemId !== DEFAULT_BODY_SYSTEM_DROPDOWN_ID
      ) {
        return false
      }

      if (
        groupBy === BloodLabDashboardsGroupByGroupings.BODY_SYSTEM &&
        sortedBodySystems.length === 0
      ) {
        return false
      }

      if (
        groupBy === BloodLabDashboardsGroupByGroupings.LAB_TEST &&
        labTests.length === 0
      ) {
        return false
      }

      return true
    }, [searchText, groupBy, filterBodySystemId])

    const handleOriginalLabReportClick = () => {
      if (orderedResult?.attributes.result_file) {
        window.open(orderedResult.attributes.result_file)
      }
    }

    const optimalRangesSettingTurnedOn = useMemo(() => {
      return clinic?.attributes?.clinic_settings.includes(
        ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
      )
    }, [clinic])

    const numberOfActiveDiscreteResults = useMemo(() => {
      return orderedResult?.attributes.active_discrete_results_count || 0
    }, [orderedResult])

    useEffect(() => {
      if (bloodReportResultsError !== undefined) {
        props.onClose()
        handleApiError(bloodReportResultsError)
      } else if (snapshotError !== undefined) {
        props.onClose()
        handleApiError(snapshotError)
      }
    }, [bloodReportResultsError, snapshotError])

    const closeNotSharedWarning = useEventCallback(() => {
      setHideNotSharedWarning(true)
    })

    const bloodReportResultsByLabTest = useMemo(() => {
      return labTests.map((labTest) => ({
        labTestName: labTest.attributes.name,
        bloodReportResults: getBloodReportResultsByLabTest(labTest),
      }))
    }, [labTests])

    const pdfPayload = useBundleResultsPdfPayload({
      outOfRangeBloodReportResults: abnormalBloodReportResults,
      bloodReportResultsByLabTest: bloodReportResultsByLabTest,
      userResults: userResults,
      orderedResult: orderedResult,
      labCompany: labCompany,
      patient: patient,
      practitioner: practitioner,
      clinic: clinic,
      usesClinicOptimalRange,
      biomarkerCustomDescriptionsMapping,
      snapshotId: snapshot?.id,
      isLoading:
        isSnapshotLoading ||
        isBloodReportResultsLoading ||
        isBiomarkerCustomDescriptionsLoading,
    })

    if (
      isSnapshotLoading ||
      isBloodReportResultsLoading ||
      isBiomarkerCustomDescriptionsLoading
    ) {
      return (
        <Dialog
          open
          onClose={props.onClose}
          aria-labelledby="blood-reports-modal"
          maxWidth={false}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              overflow: "hidden",
            },
          }}
        >
          <Loading ariaLabel="Loading Report" />
        </Dialog>
      )
    }

    return (
      <Dialog
        onClose={props.onClose}
        open
        aria-labelledby="blood-reports-modal"
        className={classes.dialog}
        classes={{ paper: classes.dialog }}
        maxWidth={false}
      >
        <div className={classes.container}>
          <BloodLabDashboardsModalHeader
            onClose={props.onClose}
            downloadableResultUrls={
              orderedResult?.attributes.result_file
                ? [orderedResult?.attributes.result_file]
                : []
            }
            orderId={
              orderedResult?.relationships.order?.data.id
                ? orderedResult?.relationships.order?.data.id
                : ""
            }
            patient={patient}
            orderedResult={orderedResult}
            onUpdatePatient={mutateBloodReportResultsAndSnapshot}
            showCustomizePatientDashboard={Boolean(
              clinic?.attributes?.clinic_settings.includes(
                ClinicSettings.USE_RUPA_BLOOD_REPORTS
              )
            )}
            pdfPayload={pdfPayload}
          />
          {!clinic?.attributes?.clinic_settings.includes(
            ClinicSettings.USE_RUPA_BLOOD_REPORTS
          ) && (
            <div className={classes.disabledNotificationContainer}>
              Your client will receive the original lab report from the lab
              company. To share Blood Lab Dashboards with your patients, turn it
              on in{" "}
              <Link
                onClick={() => props.onClose()}
                to={UserPaths.RUPA_BLOOD_DASHBOARDS}
                className={classes.settingsLink}
              >
                Settings
              </Link>
              .
            </div>
          )}
          <div className={classes.body}>
            <div
              className={clsx(
                classes.notSharedWarning,
                hideNotSharedWarning ? classes.hideNotSharedWarning : ""
              )}
            >
              <BloodLabDashboardsResultsNotSharedWarning
                patientFirstName={patient?.attributes.first_name || "Patient"}
                kitId={orderedResult?.relationships.kit.data.id ?? ""}
                orderId={orderedResult?.relationships.order?.data.id ?? ""}
                practitionerId={practitioner?.id || ""}
                isBloodDashboardsOn={Boolean(
                  clinic?.attributes?.clinic_settings.includes(
                    ClinicSettings.USE_RUPA_BLOOD_REPORTS
                  )
                )}
                onSendResultsToPatient={closeNotSharedWarning}
              />
            </div>
            {/* If the number of discrete results does not match the total number of biomarkers, it means
            we were unable to extract values for all biomarkers (and in this case it is irrelevant if
            we were able to match them with Biomarkers or not). In this case, we need to let the user
            know that not all of their results are in from the lab AND they will not be in the PDF. */}
            {searchText === "" &&
              totalBiomarkers.length - numberOfActiveDiscreteResults > 0 && (
                <BloodLabDashboardsBiomarkerCountOverview
                  biomarkerCount={numberOfActiveDiscreteResults}
                  biomarkerTotal={totalBiomarkers.length}
                />
              )}
            {/* If we have the same amount of discrete results as we do total biomarkers, it means we
              were able to extract discrete values for everything, but we were unable to match some
              with Biomarkers. In this case, we need to let the user know that these biomarkers 
              will not be visualized. */}
            {searchText === "" &&
              totalBiomarkers.length - numberOfActiveDiscreteResults === 0 &&
              missingBiomarkers.length > 0 && (
                <BloodLabDashboardsBiomarkerMissingWarning
                  biomarkers={missingBiomarkers}
                  downloadableResultUrls={
                    orderedResult?.attributes.result_file
                      ? [orderedResult?.attributes.result_file]
                      : []
                  }
                  orderedResultId={orderedResult?.id ? orderedResult?.id : ""}
                />
              )}
            <BloodLabDashboardsSearchAndFilter
              onSearch={setSearchText}
              searchText={searchText}
              handleInRangeValueChange={setInRangeValueFilter}
              inRangeValue={inRangeValueFilter}
              filterBodySystemId={filterBodySystemId}
              setFilterBodySystemId={setFilterBodySystemId}
              onGroupBy={setGroupBy}
              groupBy={groupBy}
              availableBodySystems={sortedBodySystems}
              isValidating={isBloodReportResultsValidating}
            />
            {searchText && sortedBodySystems.length === 0 && (
              <BodyText weight={"semibold"} className={classes.noResultsText}>
                We couldn't find any markers that match your search. 🤔
              </BodyText>
            )}

            {searchText === "" &&
              filterBodySystemId === DEFAULT_BODY_SYSTEM_DROPDOWN_ID &&
              abnormalBloodReportResults.length > 0 &&
              inRangeValueFilter === InRangeOption.ALL && (
                <BloodLabDashboardsSnapshotAbnormalSection
                  bloodReportResults={abnormalBloodReportResults}
                  showHighLowDescriptions={showHighLowDescriptions}
                  biomarkerCustomDescriptionsMapping={
                    biomarkerCustomDescriptionsMapping
                  }
                />
              )}

            <div>
              {showAllMarkersTitle && (
                <BodyText weight="semibold" size="sm" className={"mb-2"}>
                  All Markers
                </BodyText>
              )}
              {groupBy === BloodLabDashboardsGroupByGroupings.BODY_SYSTEM && (
                <div
                  className={"bg-white rounded-xl overflow-hidden shadow-sm"}
                >
                  {sortedBodySystems.map((bodySystem) =>
                    filterBodySystemId === DEFAULT_BODY_SYSTEM_DROPDOWN_ID ||
                    filterBodySystemId === bodySystem.id ? (
                      <BloodLabDashboardsSnapshotBiomarkerSection
                        bloodReportResults={getBloodReportResultsByBodySystem(
                          bodySystem,
                          inRangeValueFilter
                        )}
                        sectionTitle={bodySystem.attributes.name}
                        showHighLowDescriptions={showHighLowDescriptions}
                        biomarkerCustomDescriptionsMapping={
                          biomarkerCustomDescriptionsMapping
                        }
                        showBiomarkersWithoutResults={
                          inRangeValueFilter === InRangeOption.ALL
                        }
                        biomarkersIdsWithoutResults={bodySystem.relationships.biomarkers.data
                          .filter((biomarker) =>
                            missingBiomarkers.find(
                              (discreteResultBiomarker) =>
                                discreteResultBiomarker.id === biomarker.id
                            )
                          )
                          .map((biomarker) => biomarker.id)}
                      />
                    ) : null
                  )}
                </div>
              )}

              {groupBy === BloodLabDashboardsGroupByGroupings.LAB_TEST && (
                <div
                  className={"bg-white rounded-xl overflow-hidden shadow-sm"}
                >
                  {labTests.map((labTest) => (
                    <BloodLabDashboardsSnapshotBiomarkerSection
                      bloodReportResults={getBloodReportResultsByLabTest(
                        labTest,
                        filterBodySystemId,
                        inRangeValueFilter
                      )}
                      sectionTitle={labTest.attributes.name}
                      showHighLowDescriptions={showHighLowDescriptions}
                      biomarkerCustomDescriptionsMapping={
                        biomarkerCustomDescriptionsMapping
                      }
                      showBiomarkersWithoutResults={
                        inRangeValueFilter === InRangeOption.ALL
                      }
                      biomarkersIdsWithoutResults={labTest.relationships.biomarkers.data
                        .filter((biomarker) =>
                          missingBiomarkers.find(
                            (discreteResultBiomarker) =>
                              discreteResultBiomarker.id === biomarker.id
                          )
                        )
                        .map((biomarker) => biomarker.id)}
                    />
                  ))}
                </div>
              )}
            </div>

            <BloodLabDashboardsDisclaimerText
              optimalRangesSettingTurnedOn={optimalRangesSettingTurnedOn}
              handleOriginalLabReportClick={handleOriginalLabReportClick}
            />
          </div>
        </div>
      </Dialog>
    )
  })

export default BloodLabDashboardsSnapshotModal

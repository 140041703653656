import { useEffect } from "react"

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@rupahealth/design"

import { ReactComponent as AllDocumentsOptionIcon } from "app/assets/images/document-select-option-icon.svg"
import { ReactComponent as FoodPlanOptionIcon } from "app/assets/images/food-plan-option-icon.svg"
import { ReactComponent as OrderedResultOptionIcon } from "app/assets/images/ordered-result-option-icon.svg"
import { ReactComponent as ResultsSummaryOptionIcon } from "app/assets/images/results-summary-option-icon.svg"
import useQueryState from "app/hooks/use-query-state"
import { PatientDocumentType } from "types/patient-with-documents"

export const DOCUMENT_TYPE_QUERY_PARAM_KEY = "documentType"

export enum DocumentTypeQueryParamValue {
  ALL = "all",
  FOOD_PLAN = "food_plan",
  ORDERED_RESULT = "lab_report",
  RESULTS_SUMMARY = "results_summary",
  PATIENT_RESULT = "patient_result",
}

export const DOCUMENT_TYPE_OPTIONS = [
  {
    type: DocumentTypeQueryParamValue.ALL,
    paramKey: DocumentTypeQueryParamValue.ALL,
    label: (
      <>
        Document Type <strong>All</strong>
      </>
    ),
    icon: AllDocumentsOptionIcon,
  },
  {
    type: PatientDocumentType.ORDERED_RESULT,
    paramKey: DocumentTypeQueryParamValue.ORDERED_RESULT,
    label: "Lab Report",
    icon: OrderedResultOptionIcon,
  },
  {
    type: PatientDocumentType.FOOD_PLAN,
    paramKey: DocumentTypeQueryParamValue.FOOD_PLAN,
    label: "Food Plan",
    icon: FoodPlanOptionIcon,
  },
  {
    type: PatientDocumentType.RESULTS_SUMMARY,
    paramKey: DocumentTypeQueryParamValue.RESULTS_SUMMARY,
    label: "Results Summary",
    icon: ResultsSummaryOptionIcon,
  },
  {
    type: PatientDocumentType.PATIENT_RESULT,
    paramKey: DocumentTypeQueryParamValue.PATIENT_RESULT,
    label: "Imported Result",
    icon: ResultsSummaryOptionIcon,
  },
]
export type DocumentTypeOption = typeof DOCUMENT_TYPE_OPTIONS[0]
export function getDocumentTypeOption(
  type?: PatientDocumentType | DocumentTypeQueryParamValue.ALL
) {
  return DOCUMENT_TYPE_OPTIONS.find((option) => option.type === type)
}

export function getDocumentParamValueOption(key?: string | null) {
  return DOCUMENT_TYPE_OPTIONS.find((option) => option.paramKey === key)
}

interface Props {
  disabled?: boolean
}
const DocumentTypeSelect: React.FC<Props> = ({ disabled }) => {
  const [{ documentType }, setQueryState] = useQueryState(
    (query) => ({
      ...query,
      [DOCUMENT_TYPE_QUERY_PARAM_KEY]: query[
        DOCUMENT_TYPE_QUERY_PARAM_KEY
      ] as DocumentTypeQueryParamValue,
    }),
    (state) => state
  )
  const handleDocumentTypeChange = (value: DocumentTypeQueryParamValue) => {
    setQueryState((state) => ({
      ...state,
      [DOCUMENT_TYPE_QUERY_PARAM_KEY]: value,
    }))
  }

  useEffect(() => {
    if (documentType && !getDocumentParamValueOption(documentType)) {
      handleDocumentTypeChange(DocumentTypeQueryParamValue.ALL)
    }
  }, [documentType])

  return (
    <Select
      variant="default"
      disabled={disabled}
      defaultValue={DocumentTypeQueryParamValue.ALL}
      value={documentType}
      onValueChange={handleDocumentTypeChange}
    >
      <SelectTrigger className="w-auto min-w-[200px] h-[34px]">
        <SelectValue
          placeholder={
            <DocumentOptionLabel
              option={getDocumentParamValueOption(
                DocumentTypeQueryParamValue.ALL
              )}
            />
          }
        />
      </SelectTrigger>
      <SelectContent>
        {DOCUMENT_TYPE_OPTIONS.map((option) => (
          <DocumentOptionLabel key={option.paramKey} option={option} />
        ))}
      </SelectContent>
    </Select>
  )
}

const DocumentOptionLabel: React.FC<{ option?: DocumentTypeOption }> = ({
  option,
}) => {
  if (!option) return null

  return (
    <SelectItem value={option.paramKey}>
      <div className="flex items-center text-body">
        <option.icon
          className="text-slate-500 text-[14px]"
          fill="currentColor"
        />
        <span className="mx-[10px]">{option.label}</span>
      </div>
    </SelectItem>
  )
}
export default DocumentTypeSelect

import { Paper, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { ReactComponent as SelectionIcon } from "app/assets/icons/selection-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import TextField from "app/components/forms/TextField"
import { colors, primaryColor, shadows } from "app/theme"

import Tooltip from "../Tooltip"

const useStyles = makeStyles<Theme, { filterApplied?: boolean }>((theme) => ({
  autocomplete: {
    "& .MuiButtonBase-root": {
      // So autocomplete icon does not rotate when clicked
      transform: "rotate(0deg)",
    },
    "& button.MuiButtonBase-root": {
      visibility: (props) => (props.filterApplied ? "visible" : "hidden"),
    },
    minWidth: 180,
  },
  autocompleteEndAdornment: {
    position: "absolute",
    right: 9,
    pointerEvents: "none",
    maxWidth: 360,
  },
  inputRoot: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textField: {
    backgroundColor: "white",
    borderRadius: "6px",
    "& fieldset": {
      borderRadius: "6px",
      border: "1px solid #D3DCE3",
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  placeholder: {
    "&::placeholder": {
      color: colors.blueGray[500],
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    color: (props) => (props.filterApplied ? primaryColor : "inherit"),
  },
  dropdownOptionText: {
    padding: "14px 10px",
  },
  dropdownOption: {
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  dropdownContent: {
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    marginTop: 5,
    overflowY: "auto",
    "& .MuiAutocomplete-listbox": {
      padding: 0,
    },
    "& .MuiAutocomplete-option": {
      padding: 0,
    },
    // So we can have a tooltip on a disabled option
    "& .MuiAutocomplete-listbox li[aria-disabled='true']": {
      pointerEvents: "inherit",
    },
    "& .MuiAutocomplete-listbox li[aria-disabled='true']:hover, .MuiAutocomplete-listbox li[aria-disabled='true']:focus":
      {
        background: "white",
      },
    "& .MuiAutocomplete-listbox li[aria-disabled='true']:active": {
      background: "white",
      pointerEvents: "none",
    },
  },
  separator: {
    background: colors.blueGray[200],
    height: 1,
    width: "100%",
  },
  popupIconSpacer: {
    width: 16,
    height: 16,
  },
}))

interface DropdownSelectFieldProps {
  options: string[]
  getOptionLabel?: (option: string) => string
  onChange: (event: any, newValue: any) => void
  value: string | undefined | null
  placeholder: string
  disabled?: boolean
  disabledOptions?: string[]
  disabledMessage?: string
  noOptionsText?: string | React.ReactNode
  id?: string
}

export function DropdownSelectField({
  options,
  getOptionLabel,
  onChange,
  value,
  placeholder,
  disabledOptions,
  disabledMessage,
  noOptionsText,
  id,
}: DropdownSelectFieldProps) {
  const classes = useStyles({ filterApplied: Boolean(value) })
  const getOptionDisabled = (option) =>
    Boolean(disabledOptions?.includes(option))
  const getOptionLabelDefault = getOptionLabel ?? ((option) => option)
  return (
    <Autocomplete
      id={id}
      getOptionLabel={getOptionLabelDefault}
      getOptionDisabled={getOptionDisabled}
      popupIcon={<div className={classes.popupIconSpacer}></div>}
      className={classes.autocomplete}
      style={{ fontSize: 14 }}
      value={value}
      onChange={onChange}
      options={options}
      noOptionsText={noOptionsText || "No options"}
      renderOption={(option) => {
        const disableTooltip = !getOptionDisabled(option) || !disabledMessage
        return (
          <Tooltip
            arrow
            interactive
            placement="bottom"
            title={disabledMessage ?? ""}
            disableHoverListener={disableTooltip}
            disableFocusListener={disableTooltip}
            disableTouchListener={disableTooltip}
          >
            <div className={classes.dropdownOption}>
              <BodyText
                className={classes.dropdownOptionText}
                weight="semibold"
              >
                {getOptionLabelDefault(option)}
              </BodyText>
              {option !== options.slice(-1)[0] && (
                <div className={classes.separator}></div>
              )}
            </div>
          </Tooltip>
        )
      }}
      freeSolo={false}
      autoHighlight={true}
      openOnFocus={true}
      PaperComponent={({ children }) => (
        <Paper className={classes.dropdownContent}>{children}</Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          multiline
          placeholder={placeholder}
          variant="outlined"
          size="small"
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            classes: { input: classes.placeholder, root: classes.inputRoot },
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                <SelectionIcon
                  width={16}
                  height={16}
                  fill={primaryColor}
                  className={classes.autocompleteEndAdornment}
                />
              </>
            ),
          }}
          className={classes.textField}
          // If value is set make the input focused
          // Otherwise don't pass prop to avoid overriding default behavior
          {...(value ? { focused: Boolean(value) } : {})}
        />
      )}
    />
  )
}

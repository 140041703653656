/**
 * Analytics integrations
 *
 * Segment is used for collecting analytics data.
 *
 * The Segment Object-Action naming framework must be used for all events:
 * https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
 *
 * When a new event is created, it must also be added to the Analytics Events Google Sheet:
 * https://docs.google.com/spreadsheets/d/17xP3a1IYJY2Oz6ZpEetK-Oyd999xjaU_shuN-ucdBSo/edit#gid=639423297
 */
import history from "@history"
import { isEmpty } from "lodash"

import { ORDER_LINE_ITEM_TYPE } from "app/constants"
import { InOfficeKitTabId } from "app/main/in-office-kits/InOfficeKitsTabs"
import { IOKInventoryEvents } from "app/main/in-office-kits/inventory/inventory-types"
import { segmentKey } from "app/settings"
import { JobStatus } from "types/job-status"

import { trackEventWithProperties } from "./segment.typed"

/**
 * Runs the segment setup script copied directly from the Segment website.
 */
export function loadSegment() {
  var analytics = (window.analytics = window.analytics || [])
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error("Segment snippet included twice.")
    else {
      analytics.invoked = !0
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
      ]
      analytics.factory = function (t) {
        return function () {
          var e = Array.prototype.slice.call(arguments)
          e.unshift(t)
          analytics.push(e)
          return analytics
        }
      }
      for (var t = 0; t < analytics.methods.length; t++) {
        var e = analytics.methods[t]
        analytics[e] = analytics.factory(e)
      }
      analytics.load = function (t, e) {
        var n = document.createElement("script")
        n.type = "text/javascript"
        n.async = !0
        n.src =
          "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js"
        var a = document.getElementsByTagName("script")[0]
        a.parentNode.insertBefore(n, a)
        analytics._loadOptions = e
      }
      analytics.SNIPPET_VERSION = "4.1.0"
      analytics.load(segmentKey)
      trackPage(window.location.pathname)
    }

  history.listen((location) => {
    trackPage(location.pathname)
  })
}

export function analyticsIdentify(userId) {
  window.analytics.identify(userId)
}

export function analyticsReset() {
  window.analytics.reset()
}

/**
 * Track a page view.
 * We explicitly do not send url parameters to ensure no sensitive information is ever sent to Segment.
 * @param pathname
 */
export function trackPage(pathname) {
  window.analytics.page(history.location.pathname, {
    title: document.title,
    path: pathname,
    url: window.location.host + pathname,
    search: window.location.search ? "REDACTED" : "",
    referrer: window.location.search ? "REDACTED" : "",
  })
}

// Analytics Events

export function trackPractitionerOnboardingSurveySkipped() {
  trackEvent("Practitioner Onboarding Survey Skipped")
}

export function trackResendEmailVerification() {
  trackEventWithProperties("Resend Email Verification Clicked", {
    onboarding: "In Product",
  })
}

export function trackUploadLicense() {
  trackEventWithProperties("Upload License Clicked", {
    onboarding: "In Product",
  })
}

export function trackPOCAdded() {
  trackEventWithProperties("Point of Contact Added", {
    onboarding: "In Product",
  })
}

export function trackPhysicianServicesApplicationClicked() {
  trackEventWithProperties("Physician Services Application Clicked", {
    onboarding: "In Product",
  })
}

export function trackClickedMessageUs() {
  trackEventWithProperties("Clicked Message Us", {
    onboarding: "In Product",
  })
}

export function trackPractitionerOnboardingSurveyCompleted() {
  trackEvent("Practitioner Onboarding Survey Completed")
}

export function trackCatalogNavGettingStartedLinkClosed() {
  trackEvent("Nav Getting Started Banner Closed")
}

export function trackMarkedTaskComplete(taskKey) {
  trackEventWithProperties("Marked Task Complete", { task_key: taskKey })
}

export function trackClickedOMBookingLink(omId, omName, omBookingLink) {
  trackEventWithProperties("Clicked Onboarding Manager Booking Link", {
    onboarding: "In Product",
    omId,
    omName,
    omBookingLink,
  })
}

export function trackIOKOnboardingLearnMoreClicked(practitionerId) {
  trackEventWithProperties("Clicked Learn More in IOK Onboarding", {
    practitioner_id: practitionerId,
  })
}

export function trackIOKDetailsClicked(practitionerId) {
  trackEventWithProperties("Clicked Details in Order History", {
    practitioner_id: practitionerId,
  })
}

export function trackIOKOrderSuppliesClicked(practitionerId, tab) {
  if (tab === InOfficeKitTabId.INVENTORY) {
    trackEventWithProperties(IOKInventoryEvents.REORDER_SUPPLIES, {
      practitioner_id: practitionerId,
    })
  } else {
    trackEventWithProperties("Clicked IOK Order Supplies", {
      practitioner_id: practitionerId,
    })
  }
}

export function trackStartOrderFromOrderSupplies(practitionerId) {
  trackEventWithProperties("Clicked Start Order From Order Supplies Page", {
    practitioner_id: practitionerId,
  })
}

export function trackLabDropdownFromOrderSupplies(practitionerId, labId) {
  trackEventWithProperties(
    "Clicked Lab Dropdown From Order Supplies Order Form",
    {
      practitioner_id: practitionerId,
      lab_id: labId,
    }
  )
}

export function trackIOKSuppliesOrderFormConfirming(
  practitionerId,
  labId,
  largeOrder,
  quantities = {}
) {
  trackEventWithProperties("Clicked IOK Supplies Order Continue", {
    practitioner_id: practitionerId,
    lab_id: labId,
    large_order: largeOrder,
    quantities,
  })
}

export function trackIOKSuppliesOrderFormSubmit(
  practitionerId,
  labId,
  largeOrder,
  quantities = {}
) {
  trackEventWithProperties("Clicked IOK Supplies Order Submit", {
    practitioner_id: practitionerId,
    lab_id: labId,
    large_order: largeOrder,
    quantities,
  })
}

/**
 * Track the bundle link share click event in Segment/Mixpanel.
 * @param {string} bundleId The bundle ID to be shared.
 * @param {string} shareId The bundle share ID.
 */
export function trackBundleSharedViaLink(bundleId, shareId) {
  trackBundleShare(bundleId, shareId, "link")
}

/**
 * Track the bundle email share click event in Segment/Mixpanel.
 * @param {string} bundleId The bundle ID to be shared.
 * @param {string} shareId The bundle share ID.
 */
export function trackBundleSharedViaEmail(bundleId, shareId) {
  trackBundleShare(bundleId, shareId, "email")
}

/**
 * Track the bundle Facebook share click event in Segment/Mixpanel.
 * @param {string} bundleId The bundle ID to be shared.
 * @param {string} shareId The bundle share ID.
 */
export function trackBundleSharedViaFacebook(bundleId, shareId) {
  trackBundleShare(bundleId, shareId, "facebook")
}

/**
 *
 * @param {string} bundleId The bundle ID that was shared
 * @param {string} shareId The ID of the lab_test_share_bundle
 * @param {("link"|"email"|"facebook")} shareMethod The method used to share
 */
function trackBundleShare(bundleId, shareId, shareMethod) {
  trackEventWithProperties("Lab Test Bundle Shared", {
    share_method: shareMethod,
    bundle_id: bundleId,
    share_id: shareId,
  })
}

/**
 * Triggered when a user visits the bundles settings page
 * @param {string} pracId The Prac ID
 * @param {number} clinicId The Clinic ID
 */
export function trackBundleVisitCustomSettings(pracId, clinicId) {
  trackEventWithProperties("bundles_visit_custom_bundles_settings_page", {
    prac_id: pracId,
    clinic_id: clinicId,
  })
}

/**
 * Triggered when a user drags and drops a bundle card
 * @param {string} pracId The Prac ID
 * @param {number} clinicId The Clinic ID
 * @param {string} fromSection The From Section (Shared, Personal)
 * @param {string} toSection The To Section (Shared, Personal)
 */
export function trackBundleDrag(pracId, clinicId, fromSection, toSection) {
  trackEventWithProperties("bundles_drag", {
    prac_id: pracId,
    clinic_id: clinicId,
    from_section: fromSection,
    to_section: toSection,
  })
}

/**
 * Triggered when a user clicks on the accordion icon to show optional actions on bundle
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} bundleId The Bundle ID
 */
export function trackBundleAccordionClick(pracId, clinicId, bundleId) {
  trackEventWithProperties("bundles_accordian_click", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
  })
}

/**
 * Triggered when a user clicks on the share with clinic or unshare with clinic action button
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} bundleId The Bundle ID
 * @param {string} type The Type (Add or Remove)
 */
export function trackBundleShareWithClinicClick(
  pracId,
  clinicId,
  bundleId,
  type
) {
  trackEventWithProperties("bundles_share_with_clinic_click", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
    type: type,
  })
}

/**
 * Triggered when a user confirms that they want to unshare the bundle with the clinic
 * @param {string} pracId The Prac ID
 * @param {number} clinicId The Clinic ID
 * @param {string | undefined} bundleId The Bundle ID
 */
export function trackBundleRemoveFromClinicConfirmationClick(
  pracId,
  clinicId,
  bundleId
) {
  trackEventWithProperties("bundles_remove_from_clinic_confirmation_click", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
  })
}

/**
 * Triggered when a user clicks on the copy link action
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} bundleId The Bundle ID
 */
export function trackBundleCopyLinkClick(pracId, clinicId, bundleId) {
  trackEventWithProperties("bundles_copy_link_click", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
  })
}

/**
 * Triggered when a user clicks on the view and edit action
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} bundleId The Bundle ID
 */
export function trackBundleViewEditClick(pracId, clinicId, bundleId) {
  trackEventWithProperties("bundles_view_edit_click", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
  })
}

/**
 * Triggered when a user clicks on make a copy action
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} bundleId The Bundle ID
 */
export function trackBundleMakeCopyClick(pracId, clinicId, bundleId) {
  trackEventWithProperties("bundles_make_copy_click", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
  })
}

/**
 * Triggered when a user clicks on the delete bundle action
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} bundleId The Bundle ID
 * @param {boolean} shared Whether the bundle is shared or not
 */
export function trackBundleDeleteClick(pracId, clinicId, bundleId, shared) {
  trackEventWithProperties("bundles_delete_click", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
    shared: shared,
  })
}

/**
 * Triggered when a user confirms that they want to remove a bundle (personal and shared)
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} bundleId The Bundle ID
 * @param {boolean} shared Whether the bundle is shared or not
 */
export function trackBundleDeleteConfirmationClick(
  pracId,
  clinicId,
  bundleId,
  shared
) {
  trackEventWithProperties("bundles_delete_confirmation_click", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
    shared: shared,
  })
}

/**
 * Triggered when a user confirms that they just want to hide (unfavorite) a bundle rather than removing it from the clinic
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} bundleId The Bundle ID
 * @param {boolean} shared Whether the bundle is shared or not
 */
export function trackBundleHideForMeClick(pracId, clinicId, bundleId, shared) {
  trackEventWithProperties("bundles_hide_for_me_click", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
    shared: shared,
  })
}

/**
 * Triggered when a user clicks on the bundle option from the create panel or bundle button
 * @param {string} pracId The Prac ID
 * @param {number} clinicId The Clinic ID
 */
export function trackBundleCreateClick(pracId, clinicId) {
  trackEventWithProperties("bundles_create_bundle_click", {
    prac_id: pracId,
    clinic_id: clinicId,
  })
}

/**
 * Triggered when a user clicks on create bundle from the bundle modal
 * @param {string} pracId The Prac ID
 * @param {number} clinicId The Clinic ID
 * @param {shared} boolean Whether the bundle is shared or not
 */
export function trackBundleCreateFromModalClick(pracId, clinicId, shared) {
  trackEventWithProperties("bundles_create_bundle_click_modal", {
    prac_id: pracId,
    clinic_id: clinicId,
    shared,
  })
}

/**
 * Triggered when a user clicks on the heart of a bundle. Triggered from settings or cart, and also whether it was favorited or unfavorited.
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} bundleId The Bundle ID
 * @param {boolean} shared Whether the bundle is shared or not
 * @param {boolean} favorited Whether the bundle was favorited or unfavorited
 * @param {string} location The Location (cart or settings)
 */
export function trackBundleFavoriteClick(
  pracId,
  clinicId,
  bundleId,
  shared,
  favorited,
  location
) {
  trackEventWithProperties("bundles_favorite_click", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
    shared: shared,
    favorited: favorited,
    location: location,
  })
}

/**
 * Triggered when a user adds a bundle to their cart
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} bundleId The Bundle ID
 * @param {boolean} shared Whether the bundle is shared or not
 */
export function trackAddBundleToCart(pracId, clinicId, bundleId, shared) {
  trackEventWithProperties("bundles_add_bundle_to_cart", {
    prac_id: pracId,
    clinic_id: clinicId,
    bundle_id: bundleId,
    shared: shared,
  })
}

/**
 * Triggered when a user navigates to an order creation cart (either new or existing order)
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} orderId The Order ID
 */
export function trackCartVisit(pracId, clinicId, orderId) {
  trackEventWithProperties("cart_visit", {
    prac_id: pracId,
    clinic_id: clinicId,
    order_id: orderId,
  })
}

/**
 * Triggered when a user sends the order to a patient
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} orderId The Order ID
 * @param {boolean} isPractitionerPaying Whether the practitioner is paying for the order
 */
export function trackSendToPatient(
  pracId,
  clinicId,
  orderId,
  isPractitionerPaying
) {
  trackEventWithProperties("cart_send_to_patient", {
    prac_id: pracId,
    clinic_id: clinicId,
    order_id: orderId,
    is_practitioner_paying: isPractitionerPaying,
  })
}

/**
 * Triggered when a user starts a new order
 * @param {string} pracId The Prac ID
 * @param {string | number} clinicId The Clinic ID
 * @param {string} orderId The Order ID
 * @param {boolean} isPractitionerPaying Whether the practitioner is paying for the order
 */
export function trackStartNewOrder(
  pracId,
  clinicId,
  orderId,
  isPractitionerPaying
) {
  trackEventWithProperties("start_new_order", {
    prac_id: pracId,
    clinic_id: clinicId,
    order_id: orderId,
    is_practitioner_paying: isPractitionerPaying,
  })
}

/**
 * Triggered when a user saves the order as a draft
 * @param {string} pracId The Prac ID
 * @param {string} clinicId The Clinic ID
 * @param {string} orderId The Order ID
 */
export function trackSaveAsDraft(pracId, clinicId, orderId) {
  trackEventWithProperties("cart_save_as_draft", {
    prac_id: pracId,
    clinic_id: clinicId,
    order_id: orderId,
  })
}

/**
 * Track any comparisons that happen using the Lab Comparison Modal
 * @param {string[]} testIds comparison test ids
 */
export function trackLabTestComparison(testIds) {
  if (!isEmpty(testIds)) {
    trackEventWithProperties("Lab Test Comparison", {
      test_ids: testIds,
    })
  }
}

/**
 * Track any searches that happen using the Lab Test Search
 * @param {URLSearchParams} searchParams search params for the query
 */
export function trackLabTestSearch(searchParams) {
  const properties = Object.fromEntries(searchParams)

  if (!isEmpty(properties)) {
    trackEventWithProperties("Lab Test Search", properties)
  }
}

/**
 * Track any click throughs from a lab test search.
 *
 * @param {URLSearchParams} searchParams properties to send to segment concerning search + actions
 */
export function trackLabTestSearchCTR(searchParams) {
  const properties = Object.fromEntries(searchParams)

  if (!isEmpty(properties)) {
    trackEventWithProperties("Lab Test Search CTR", properties)
  }
}

/**
 * Track the team members invited during the clinic invite onboarding flow.
 * @param {array} invitedTeamMembers list of objects representing the team members invited during onboarding.
 * @param {string} practitionerId string representing the ID of the practitioner who invited the team members.
 */
export function trackTeamMemberInvitedViaClinicInviteOnboarding(
  invitedTeamMembers,
  practitionerId
) {
  trackEventWithProperties(
    "Team Member(s) Invited Via Clinic Invite Onboarding",
    {
      invited_team_members: invitedTeamMembers,
      practitioner_id: practitionerId,
    }
  )
}

/**
 * Tracks different labshop events
 *
 * @param {string} eventName Name of event to trigger
 * @param {any} storefrontCartId Properties to attach to the event
 */
export function trackLabShopEvent(eventName, properties) {
  trackEventWithProperties(eventName, properties)
}

export const physicianAuthorizationApplicationPageEvents = {
  VISIT_SETTINGS: "pa_application_visit_settings",
  APPLY_CLICKED: "pa_application_apply_clicked",
  LEARN_MORE_CLICKED: "pa_application_learn_more_clicked",
  APPLICATION_CONFIRMATION: "pa_application_confirmation",
  UNDERSTAND_CLICKED: "pa_application_understand_clicked",
  APPLICATION_AGREEMENT: "pa_application_agreement",
  APPLICATION_SUBMIT: "pa_application_submit",
  UPLOADED_LICENSE: "pa_application_uploaded_license",
  UPLOADED_CERTIFICATE: "pa_application_uploaded_certificate",
}

/**
 * Tracks a patient checkout event.
 * @param {string} event The checkout event that has occurred. Should be one of PatientCheckoutTrackingEvents
 * @param {PatientPortalOrder} order The order associated with the event
 * @param {{}} metadata additional properties to send with the event
 */
export function trackPatientCheckoutEvent(event, order, metadata = {}) {
  const properties = {
    patient_email: order.patient.user.email,
    order_id: order.id,
    use_physician_services: order.requires_vendor_physician_authorization,
    has_custom_fee: order.line_items.some(
      (li) => li.type === ORDER_LINE_ITEM_TYPE.CUSTOM_FEE
    ),
    is_practitioner_paying: order.is_practitioner_paying,
    is_labshop_order: Boolean(order.storefront_id),
    order_phlebotomy_providers: order.phlebotomy_providers.map(
      (provider) => provider.provider_name
    ),
    insurance_available: Boolean(order.insurance_enabled_for),
    ...metadata,
  }
  trackEventWithProperties(event, properties)
}

export const PatientCheckoutTrackingEvents = {
  CHECKOUT_PAGE_LOADED: "checkout_page_loaded",
  EXPAND_PROCESSING_ORDERING_FEES: "expand_processing_ordering_fees",
  VIEW_PHYSICIAN_SERVICES_TOOLTIP: "view_physician_services_tooltip",
  VIEW_CUSTOM_FEE_TOOLTIP: "view_custom_fee_tooltip",
  PATIENT_CHECKOUT_REVIEW: "patient_checkout_review",
  PATIENT_CHECKOUT_SUBMIT: "patient_checkout_submit",
}

/**
 * Tracks a panel builder event.
 * @param {string} event The panel builder event that has occurred. Should be one of PANEL_BUILDER_TRACKING_EVENTS
 * @param {string} user_id The user id of the user who triggered the event
 * @param {{biomarker_id?: string, biomarker_grouping_id?: string, bundle_id?: string}} metadata Metadata associated with the event
 */
export function trackPanelBuilderEvent(event, metadata) {
  trackEventWithProperties(event, {
    ...metadata,
  })
}

export const PANEL_BUILDER_TRACKING_EVENTS = {
  CREATE_BUNDLE_OR_PANEL: "create_bundle_or_panel",
  CREATE_BASIC_BLOOD_PANEL: "create_basic_blood_panel",
  CREATE_CUSTOM_BUNDLE: "create_custom_bundle",
  PANEL_BUILDER_BIOMARKER_SELECTED: "panel_builder_biomarker_selected",
  PANEL_BUILDER_BIOMARKER_GROUPING_SELECTED:
    "panel_builder_biomarker_grouping_selected",
  PANEL_BUILDER_OPEN_DETAILS: "panel_builder_open_details",
  PANEL_BUILDER_CREATE_PANEL: "panel_builder_create_panel",
  PANEL_BUILDER_INPUT_NAME: "panel_builder_input_name",
  PANEL_BUILDER_FINALIZE_CREATE_PANEL: "panel_builder_finalize_create_panel",
  PANEL_BUILDER_EDIT: "panel_builder_edit",
}

/**
 * Tracks patient portal events.
 * @param {string} event The patient portal event. Should be one of PATIENT_PORTAL_TRACKING_EVENTS
 * @param {{source?: string,
 *          user_id?: string,
 *          patient_id?: string,
 *          order_id?: string}} metadata Metadata associated with the event
 */
export function trackPatientPortalEvent(event, metadata) {
  trackEventWithProperties(event, {
    ...metadata,
  })
}

export const PATIENT_PORTAL_TRACKING_EVENTS = {
  SIGN_UP_PAGE_VIEWED: "patient_portal_sign_up_page_viewed",
  SIGN_UP_PAGE_SUCCESS: "patient_portal_sign_up_success",
  LOGIN_PAGE_VIEWED: "patient_portal_login_page_viewed",
  PATIENTS_PAGE_VIEWED: "patient_portal_patients_page_viewed",
  ACCOUNTS_PAGE_VIEWED: "patient_portal_accounts_page_viewed",
  ORDERS_PAGE_VIEWED: "patient_portal_orders_page_viewed",
  ORDER_PAGE_VIEWED: "patient_portal_order_page_viewed",
  ORDER_CLICKED: "patient_portal_order_clicked",
  USER_ORDERS_PAGE_VIEWED: "patient_portal_user_orders_page_viewed",
  USER_ORDER_PAGE_VIEWED: "patient_portal_user_order_page_viewed",
  USER_ORDER_CLICKED: "patient_portal_user_order_clicked",
}

export const PATIENT_PORTAL_SOURCE = {
  WEBSITE: "website",
  EMAIL: "email",
  POST_PATIENT_CHECKOUT: "post_patient_checkout",
}

function trackEvent(name) {
  window.analytics.track(name)
}

/**
 * Tracks phlebotomy booking events.
 * @param {string} event The phlebotomy booking event. Should be one of PHLEBOTOMY_BOOKING_EVENTS
 * @param {{options?: string[],
 *          selected_option?: string,
 *          zip_code?: string,
 *          order_id?: string,
 *          from_location?: string,
 *          provider?: string}} metadata Metadata associated with the event
 */
export function trackPhlebotomyBookingEvent(event, metadata) {
  trackEventWithProperties(event, {
    ...metadata,
  })
}

/**
 * Tracks when the physician services opt-in modal is closed.
 * @param {{step: string,
 *          location: string,
 *          showing_updated_terms_copy: boolean,
 *          practitioner_has_physician_services_labshop: boolean}} metadata Metadata associated with the event
 */
export function trackPhysicianServicesOptInClose(metadata) {
  trackEventWithProperties("physician_services_opt_in_modal_close", {
    ...metadata,
  })
}

/* Defined here: https://www.notion.so/rupahealth/Axle-Phlebotomy-Tech-Spec-aa4d0e80b70d4995b6f4d96fca4a481e?pvs=4#57408dc126ab442293779a7ba9189ae6 */
export const PHLEBOTOMY_BOOKING_EVENTS = {
  PHLEBOTOMY_OPTIONS_SHOWN: "phlebotomy_options_shown",
  PHLEBOTOMY_OPTIONS_PICKED: "phlebotomy_options_picked",
  PHLEBOTOMY_SCHEDULE_APPOINTMENT_CLICKED:
    "phlebotomy_schedule_appointment_clicked",
  PHLEBOTOMY_BOOKING_PAGE_VISIT: "phlebotomy_booking_page_visit",
  PHLEBOTOMY_BOOKING_CONFIRMATION_PAGE_VISIT:
    "phlebotomy_booking_confirmation_page_visit",
  PHLEBOTOMY_RESCHEDULE_APPOINTMENT: "phlebotomy_reschedule_appointment",
}

/**
 * Tracks when a pdf parsing timeout occurs.
 * @param {{job_status: JobStatus}} metadata Metadata associated with the event
 */
export function trackPdfParsingTimeout(metadata) {
  trackEventWithProperties("pdf_parsing_timeout", {
    ...metadata,
  })
}

/**
 * Tracks pdf generation events.
 * @param {string} event The pdf generation event. Should be one of PDF_GENERATION_EVENTS
 * @param {{generation_id: string,
 *          margins?: [number, number, number, number],
 *          scale?: number,
 *          is_ready?: boolean,
 *          generation_duration?: Number,
 *          error_message?: string,
 *          metadata?: Record<string, any>}} metadata Metadata associated with the event
 */
export function trackPdfGenerationEvent(event, metadata) {
  trackEventWithProperties(event, {
    ...metadata,
  })
}

export const PDF_GENERATION_EVENTS = {
  PDF_GENERATION_STARTED: "pdf_generation_started",
  PDF_GENERATION_COMPLETED: "pdf_generation_completed",
  PDF_GENERATION_FAILED: "pdf_generation_failed",
}

/**
 * Tracks pdf generation events.
 * @param {{snapshot_id: string,
 *          scale?: number,
 *          descriptions_by?: string,
 *          ranges_by?: string}} metadata Metadata associated with the event
 */
export function trackBloodLabDashboardPdfGeneration(metadata) {
  trackEventWithProperties("blood_lab_pdf_generate_clicked", {
    ...metadata,
  })
}

import { useCallback, useState } from "react"

import { useAsync } from "react-use"

import * as Sentry from "@sentry/react"
import { loadStripe, Stripe, StripeError } from "@stripe/stripe-js"

import { API } from "app/api"
import { stripePublishableAPIKey } from "app/settings"
import { PostCheckoutIdentityVerificationSession } from "app/types"

const stripePromise = loadStripe(stripePublishableAPIKey)

export interface UsePostCheckoutIdentityVerificationHook {
  /**
   * Error from Stripe if the session is cancelled or fails.
   */
  error?: StripeError
  /**
   * If true, the SDK and session secret are loading.
   */
  loading: boolean
  /**
   * Error that occured while loading the SDK or session.
   */
  loadingError?: Error
  /**
   * On-click event listener for the IdentityVerificationButton.
   */
  onClick: () => Promise<void>
  /**
   * The {@link PostCheckoutIdentityVerificationSession} object.
   */
  session?: PostCheckoutIdentityVerificationSession
  /**
   * If true, the ID verification session has been submitted and is complete.
   */
  submitted: boolean
  /**
   * If true, the ID verification session is in progress.
   */
  verifying: boolean
}

export default function usePostCheckoutIdentityVerification(
  checkoutToken: string | null
): UsePostCheckoutIdentityVerificationHook {
  const [verifying, setVerifying] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState<StripeError>()
  const stripeAsync = useAsync<() => Promise<Stripe | null>>(
    () => stripePromise
  )
  const sessionAsync = useAsync<
    () => Promise<PostCheckoutIdentityVerificationSession>
  >(async () => {
    const response =
      await API.PatientCheckout.createIdentityVerificationSession(checkoutToken)
    return response.data
  }, [checkoutToken])

  const onClick = useCallback(async () => {
    if (!stripeAsync.value || !sessionAsync.value) {
      return
    }

    setVerifying(true)

    const { error } = await stripeAsync.value.verifyIdentity(
      sessionAsync.value.client_secret
    )

    if (error) {
      setVerifying(false)
      setError(error)
    } else {
      try {
        await API.PatientCheckout.markIdentityVerificationProcessing(
          checkoutToken,
          sessionAsync.value.verification_id,
          sessionAsync.value.version
        )
      } catch (error) {
        // This should generally not occur unless due to network error. Report to Sentry, and proceed as if successful.
        Sentry.captureException(error)
      }

      setVerifying(false)
      setSubmitted(true)
    }
  }, [stripeAsync, sessionAsync])

  return {
    error,
    loading: stripeAsync.loading || sessionAsync.loading,
    loadingError: stripeAsync.error || sessionAsync.error,
    onClick,
    session: sessionAsync.value,
    submitted,
    verifying,
  }
}

import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import _ from "lodash"
import { Link } from "react-router-dom"

import { Link as MaterialLink } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"

import RightCaretIcon from "app/assets/icons/carets/right-caret-dark-blue.svg"
import { resendRegistrationEmail } from "app/auth/store/actions/user.actions"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import {
  PATIENT_EXPERIENCE_GUIDE_URL,
  PHYSICIAN_SERVICES_CLIENT_EXPERIENCE_GUIDE_URL,
} from "app/constants"
import {
  LAB_COMPANIES_REQUIRING_IOK_ACTIVATION_ID,
  LAB_COMPANY_KEY,
  ORDER_NOTIFICATION_METHOD,
  ORDER_STATUS,
  PHYSICIAN_SERVICES_ENABLE_METHODS,
  STATES_WITH_ORDERING_RIGHTS,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import { ORDERING_ISSUE_TYPES } from "app/constants"
import {
  hasAMLAndLabcorpByAMLTests,
  hasAMLDropshipAndIOKTests,
} from "app/dataServices/labTestDataService"
import {
  hasInstantRequisitionTests,
  patientNotificationRequired,
} from "app/dataServices/orderDataService"
import {
  COUNTRY_ORDERING_ISSUE_KEYS,
  STATE_ORDERING_ISSUE_KEYS,
  getUnavailableReason,
  orderedTestHasOrderingIssues,
} from "app/dataServices/orderingRights"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import ErrorLabelFormsy from "app/main/checkout/ErrorLabelFormsy"
import { colors, primaryColor } from "app/theme"
import { RootState } from "app/types"
import { getAge, isPractitionerPayingOrder } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"
import {
  getLineItemWithMaxAgeRestriction,
  getMinimumAgeAndWarning,
  getOrderPatientLabel,
  getOrderTypeLabel,
} from "app/utils/order-utils"
import { getSigningPractitioner } from "app/utils/practitioner-utils"

import useCheckoutDraft from "../hooks/use-checkout-draft"

const useStyles = makeAppStyles((theme) => ({
  styledLink: {
    fontWeight: 600,
    display: "inline-block",
    color: primaryColor,
    cursor: "pointer",
  },
  checkoutTooltipContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  checkoutTooltipBodyText: {
    textAlign: "center",
  },
  checkoutTooltipCaret: {
    marginLeft: 5,
  },
  checkoutTooltipCTA: {
    display: "flex",
    alignItems: "center",
    marginTop: 5,
    cursor: "pointer",
  },
  deleteOrderButton: {
    marginTop: 12,
    color: colors.red[700],
    paddingTop: 12,
    paddingBottom: 12,
    "&:hover": {
      backgroundColor: colors.red[50],
    },
  },
  saveDraftButton: {
    marginTop: 12,
  },
}))

const CheckoutOrderTooltip = ({ classes, children, enabled, cta, title }) => {
  const container = (
    <div className={classes.checkoutTooltipContainer}>
      <BodyText size="sm" className={classes.checkoutTooltipBodyText}>
        {title}
      </BodyText>
      {Boolean(cta) && cta}
    </div>
  )

  return (
    <Tooltip
      title={container}
      disableHoverListener={!enabled}
      placement="top"
      arrow
      interactive
    >
      {children}
    </Tooltip>
  )
}

const CheckoutButton = ({
  order,
  orderIsSubmitting,
  submitDisabled,
  isEditingOrder,
  orderTypeLabel,
  isUserUnverifiedAndNonDemoOrder,
  resendEmailVerification,
  tooltipMessage,
  values,
}) => {
  const classes = useStyles()

  const patientName = order?.patient?.first_name
  const orderContainsKitsRequiringActivation = useMemo(() => {
    return order.ordered_tests.some((ot) => {
      return (
        LAB_COMPANIES_REQUIRING_IOK_ACTIVATION_ID.includes(
          ot?.lab_test?.lab_company?.key
        ) && ot.instant_requisition
      )
    })
  }, [order, values])
  const useCardChecked = useSelector(
    ({ orders }: RootState) => orders.paymentCards.useCardChecked
  )
  const orderPatientLabel = getOrderPatientLabel(
    values.requires_vendor_physician_authorization
  )

  let submitText

  if (order.status === ORDER_STATUS.SCHEDULED) {
    // Scheduled confirmed order
    submitText = `Finish Editing ${orderTypeLabel}`
  } else if (isEditingOrder) {
    // Edit order
    submitText = `Notify ${patientName}`
  } else if (order.date_scheduled) {
    // Schedule order
    submitText = `Schedule ${orderTypeLabel}`
  } else if (orderContainsKitsRequiringActivation) {
    // New order with practitioner pay
    submitText = `Pay & Submit`
  } else if (useCardChecked) {
    // New order with practitioner pay
    submitText = `Submit ${orderTypeLabel}`
  } else {
    // New order with patient pay
    submitText = `Send to ${orderPatientLabel}`
  }

  const submitButton = (
    <DesignSystemButton
      color="primary"
      loading={orderIsSubmitting}
      className="tour-onboarding-submit-order-button"
      type="submit"
      disabled={Boolean(submitDisabled)}
      fullWidth
      size="large"
    >
      {submitText}
    </DesignSystemButton>
  )

  return (
    <CheckoutOrderTooltip
      classes={classes}
      enabled={submitDisabled && tooltipMessage}
      title={tooltipMessage}
      cta={
        isUserUnverifiedAndNonDemoOrder ? (
          <div
            className={classes.checkoutTooltipCTA}
            onClick={resendEmailVerification}
          >
            Resend verification email
            <img
              className={classes.checkoutTooltipCaret}
              src={RightCaretIcon}
              alt="right arrow"
            />
          </div>
        ) : undefined
      }
    >
      <div>{submitButton}</div>
    </CheckoutOrderTooltip>
  )
}

const OrderActions = ({
  order,
  areAllKitActivationIdsSelected,
  orderIsSubmitting,
  patient,
  onDelete,
  onSave,
  edit,
}) => {
  const classes = useStyles()
  const { dirty, pending, values } = useCheckoutDraft()

  // use the draft "notification_method" to optimistically show message updates
  const orderNotificationMethod =
    values.notification_method || order.notification_method

  const [inOfficeKitsV2] = useFeatureFlag(FeatureFlag.InOfficeKitsV2)
  const [physicianServicesBackfillPhase2Enabled] = useFeatureFlag(
    FeatureFlag.PhysicianServicesBackfillPhase2
  )
  const [isBeastCoastEnabled] = useFeatureFlag(FeatureFlag.BeastCoast)
  const [isInternationalClinicsEnabled] = useFeatureFlag(
    FeatureFlag.InternationalClinics
  )

  const useCardChecked = useSelector(
    ({ orders }: RootState) => orders.paymentCards.useCardChecked
  )
  const user = useSelector(({ profile }: RootState) => profile.user)

  const isDemoOrder = order.status === ORDER_STATUS.DEMO

  const { blocked, blockedReason } = useMemo(() => {
    const foundItem = order.line_items.find(({ ordered_test }) => {
      if (!ordered_test || !order.practitioner) {
        return false // Skip this item since it doesn't meet the criteria
      }

      // Return true if the item meets the conditions to end the find loop
      return true
    })

    if (!foundItem) {
      // No item met the conditions, return default blocked values
      return { blocked: false, blockedReason: "" }
    }

    // Compute unavailable reason for the found item
    const unavailableReason = getUnavailableReason({
      labTest: foundItem.ordered_test.lab_test,
      signingPractitioner: order.signing_practitioner,
      orderingPractitioner: order.practitioner,
    })

    const isUnavailable = !(
      typeof unavailableReason === "string" && !unavailableReason.length
    )

    // Handle special cases for setting messaging elsewhere
    // Messaging for the blocked tooltip for these items is handled elsewhere
    // to keep the tooltip message consistent with the legacy copy
    if (
      foundItem?.ordered_test?.lab_test?.ordering_rights_status?.error_ordering_issues?.some(
        (issue) =>
          issue?.type === ORDERING_ISSUE_TYPES.PHYSICIAN_SERVICES ||
          issue?.type === ORDERING_ISSUE_TYPES.PRIMARY_PRACTITIONER_TYPE
      )
    ) {
      return { blocked: true, blockedReason: "" }
    }

    return { blocked: isUnavailable, blockedReason: unavailableReason }
  }, [order])

  const cannotOrderInState = useMemo(
    () =>
      order.line_items.find(({ ordered_test }) => {
        if (
          isBeastCoastEnabled &&
          ordered_test &&
          orderedTestHasOrderingIssues(ordered_test, STATE_ORDERING_ISSUE_KEYS)
        ) {
          return true
        }
        return false
      }),
    [order, values]
  )

  const cannotOrderInCountry = useMemo(
    () =>
      order.line_items.find(({ ordered_test }) => {
        return (
          isBeastCoastEnabled &&
          isInternationalClinicsEnabled &&
          ordered_test &&
          orderedTestHasOrderingIssues(
            ordered_test,
            COUNTRY_ORDERING_ISSUE_KEYS
          )
        )
      }),
    [order, values]
  )

  const cannotUsePracPayInState =
    isPractitionerPayingOrder(order) &&
    STATES_WITH_ORDERING_RIGHTS.includes(
      order.patient.default_shipping_address?.state
    )

  const containsUnavailableTest = useMemo(
    () =>
      order.ordered_tests.find(({ lab_test }) => {
        if (!lab_test.is_available) {
          return true
        }

        return false
      }),
    [order]
  )

  const hasMixedAMLTests = useMemo(
    () =>
      hasAMLAndLabcorpByAMLTests(
        order.ordered_tests.map((orderedTest) => orderedTest.lab_test)
      ),
    [order]
  )

  const hasMixedDeliveryMethodAMLTests = useMemo(
    () => hasAMLDropshipAndIOKTests(order.ordered_tests),
    [order]
  )

  // If the box is checked to use a card but one isn't actually set on the order yet then don't allow the order to
  // be submitted. The user could have entered their card details but not yet hit the save button on the card form.
  // We also disable order submission for non-verified users on non-demo orders
  // Disable the button if it's a scheduled order and the user hasn't selected any date scheduled
  const isUserUnverifiedAndNonDemoOrder = !user.is_verified && !isDemoOrder

  const orderHasInstantRequisitionsButPractitionerHasDisabledInstantRequisitions =
    inOfficeKitsV2
      ? !order?.practitioner?.clinic?.clinic_features?.includes(
          "allow_in_office_kits"
        ) && hasInstantRequisitionTests(order)
      : !order?.practitioner?.allow_in_office_kits &&
        hasInstantRequisitionTests(order)

  const lineItemWithHighestAgeRestriction = getLineItemWithMaxAgeRestriction(
    order.line_items
  )
  const { minAge: highestMinAgeRequired } = getMinimumAgeAndWarning(
    order,
    lineItemWithHighestAgeRestriction?.ordered_test?.lab_test
  )

  const pracPayAndMinAgeRestrictionIsNotMet =
    highestMinAgeRequired &&
    order?.is_practitioner_paying &&
    patient?.birthday &&
    getAge(new Date(patient.birthday)) < highestMinAgeRequired

  // Enforce IgeneX tests cannot be ordered for patients 65 or older for phys service orders.
  // TODO https://app.asana.com/0/1160663284050137/1204605541469993/f: enforce max age requirement in less hacky way.
  const pracPayPhysServiceAndMaxAgeRestrictionIsNotMet =
    _.some(
      order?.ordered_tests.map(
        (orderedTest) => orderedTest.lab_test.lab_company.key
      ),
      (key) => key === LAB_COMPANY_KEY.IGENEX
    ) &&
    order?.is_practitioner_paying &&
    order?.requires_vendor_physician_authorization &&
    patient?.birthday &&
    getAge(new Date(patient.birthday)) >= 65

  const invalidComboGroups = order?.ordered_tests.filter(
    (orderedTest) =>
      orderedTest.lab_test.combo_group &&
      orderedTest.combo_group_options &&
      orderedTest.combo_group_options.length !==
        orderedTest.lab_test.combo_num_selections
  )

  const orderHasHGInsuranceTests =
    order.insurance_enabled_for?.includes(
      LAB_COMPANY_KEY.HEALTH_GORILLA_LABCORP
    ) ||
    order.insurance_enabled_for?.includes(LAB_COMPANY_KEY.HEALTH_GORILLA_QUEST)
  const orderHasHGAndMedicareInsurance =
    orderHasHGInsuranceTests && order.use_insurance

  const orderRequiresICD10Code =
    _.isEmpty(order.icd10_codes_discrete) &&
    _.isEmpty(order.mapped_icd10_codes) &&
    order.insurance_enabled_for?.some((insurance_enabled_company_key) =>
      order.ordered_tests.find(
        (orderedTest) =>
          orderedTest.lab_test.lab_company.key === insurance_enabled_company_key
      )
    )

  const disabledBecauseInternational =
    order.practitioner?.clinic?.is_international_clinic &&
    !order.requires_vendor_physician_authorization

  const disabledBecausePhysicianServicesTermsMustBeReviewed =
    physicianServicesBackfillPhase2Enabled &&
    order?.requires_vendor_physician_authorization &&
    order.practitioner.physician_services_enable_method !==
      PHYSICIAN_SERVICES_ENABLE_METHODS.OPT_IN

  const isPilotPhysAuthOrder = order?.requires_physician_authorization
  const submitDisabled =
    // Prevent existing pilot phys auth orders from being submitted
    isPilotPhysAuthOrder ||
    disabledBecauseInternational ||
    hasMixedAMLTests ||
    hasMixedDeliveryMethodAMLTests ||
    orderHasHGAndMedicareInsurance ||
    dirty ||
    pending ||
    !order.practitioner ||
    pracPayAndMinAgeRestrictionIsNotMet ||
    pracPayPhysServiceAndMaxAgeRestrictionIsNotMet ||
    orderHasInstantRequisitionsButPractitionerHasDisabledInstantRequisitions ||
    containsUnavailableTest ||
    cannotOrderInState ||
    cannotOrderInCountry ||
    cannotUsePracPayInState ||
    (!isDemoOrder &&
      ((useCardChecked && !order.payment_card && !order.bank_account) ||
        !user.is_verified ||
        blocked)) ||
    Boolean(invalidComboGroups.length) ||
    orderRequiresICD10Code ||
    !areAllKitActivationIdsSelected ||
    disabledBecausePhysicianServicesTermsMustBeReviewed

  const orderTypeLabel = getOrderTypeLabel(
    values.requires_vendor_physician_authorization
  )
  const orderPatientLabel = getOrderPatientLabel(
    values.requires_vendor_physician_authorization
  )

  const patientName = order?.patient?.first_name

  const dispatch = useDispatch()

  const resendEmailVerification = () => {
    dispatch(resendRegistrationEmail({ email: user.email }))
  }

  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const physicianServicesText = "You must add "

  const tooltipMessage = (function () {
    const signingPractitioner = getSigningPractitioner(order.practitioner)
    if (disabledBecauseInternational) {
      return (
        <span>
          {physicianServicesText}
          <span
            onClick={() =>
              physicianServicesOptInModal.show({
                onClose: () => {
                  physicianServicesOptInModal.remove()
                },
              })
            }
            className={classes.styledLink}
          >
            {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
          </span>{" "}
          to order as a practitioner outside of the US.
        </span>
      )
    }
    if (isPilotPhysAuthOrder) {
      return `This order cannot be placed since it was created as part of the Physician Authorization beta program. Please create a new ${orderTypeLabel.toLowerCase()} and use ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}.`
    } else if (isUserUnverifiedAndNonDemoOrder) {
      return `You'll need to verify your email address before sending ${orderTypeLabel.toLowerCase()}s to your ${orderPatientLabel.toLowerCase()}s.`
    } else if (containsUnavailableTest) {
      return "One or more of the provided tests are not available. Please remove highlighted tests and try again."
    } else if (
      orderHasInstantRequisitionsButPractitionerHasDisabledInstantRequisitions
    ) {
      return `The selected practitioner has not allowed in-office kits. Please change the practitioner or select a different delivery method before placing the order.`
    } else if (cannotOrderInState) {
      return `Unable to order selected tests in the selected state.`
    } else if (cannotOrderInCountry) {
      return `Unable to order selected tests from clinic country.`
    } else if (cannotUsePracPayInState) {
      return `Unable to use Practitioner Pay in the patient's shipping state.`
    } else if (blocked && blockedReason) {
      return blockedReason
    } else if (blocked) {
      return values.requires_vendor_physician_authorization
        ? `This order includes tests which are not available for ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}.`
        : `${signingPractitioner?.user?.full_name} is not licensed to order some tests in this order. Please change the tests or the Ordering Practitioner to continue.`
    } else if (pracPayAndMinAgeRestrictionIsNotMet) {
      return (
        <BodyText>
          {order?.patient?.first_name} does not meet the minimum age requirement
          for all testing. Please remove the ineligible tests to complete this{" "}
          {orderTypeLabel.toLowerCase()}.{" "}
          <Link
            onClick={() => {
              window.Intercom("showNewMessage")
            }}
            className={classes.styledLink}
            to="#"
          >
            Message us
          </Link>{" "}
          if you have questions.
        </BodyText>
      )
    } else if (pracPayPhysServiceAndMaxAgeRestrictionIsNotMet) {
      return (
        <BodyText>
          {orderPatientLabel}s 65 and older are currently ineligible for some of
          the tests in this {orderTypeLabel.toLowerCase()}.{" "}
          <Link
            onClick={() => {
              window.Intercom("showNewMessage")
            }}
            className={classes.styledLink}
            to="#"
          >
            Message us
          </Link>{" "}
          if you have questions.
        </BodyText>
      )
    } else if (invalidComboGroups.length) {
      return `Please select the correct number of options for ${invalidComboGroups
        .map((ot) => ot.lab_test.name)
        .join(", ")}.`
    } else if (orderRequiresICD10Code) {
      return `Please select ICD-10 Codes. These are needed for patient insurance.`
    } else if (!areAllKitActivationIdsSelected) {
      return `Please select a kit activation ID for each kit that requires one.`
    } else if (disabledBecausePhysicianServicesTermsMustBeReviewed) {
      return `${
        order.practitioner.titled_full_name
      } needs to log in and review updated ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} Terms to send ${getOrderTypeLabel(
        true
      )}s.`
    }
    return ""
  })()

  return (
    <div className="flex flex-col">
      <ErrorLabelFormsy
        name="submitError"
        align="center"
        className="my-4 px-3"
      />
      {order.ordered_tests.length > 0 && (
        <>
          <CheckoutButton
            order={order}
            isEditingOrder={edit}
            orderIsSubmitting={orderIsSubmitting}
            submitDisabled={submitDisabled}
            orderTypeLabel={orderTypeLabel}
            isUserUnverifiedAndNonDemoOrder={isUserUnverifiedAndNonDemoOrder}
            resendEmailVerification={resendEmailVerification}
            tooltipMessage={tooltipMessage}
            values={values}
          />
          {patientNotificationRequired(order, patient) && (
            <div className="flex justify-center mb-4">
              <Typography
                align="center"
                color="textSecondary"
                className="text-sm13 font-semibold mt-2 px-8"
              >
                {edit && order.status !== ORDER_STATUS.SCHEDULED ? (
                  <span>
                    Sends {patientName} an email{" "}
                    {orderNotificationMethod ===
                      ORDER_NOTIFICATION_METHOD.EMAIL_AND_TEXT &&
                      "and text message "}{" "}
                    with the updated {orderTypeLabel.toLowerCase()} details.
                  </span>
                ) : null}

                {!edit ? (
                  <>
                    <span className="fs-exclude">
                      {patientName} will receive an email{" "}
                      {orderNotificationMethod ===
                        ORDER_NOTIFICATION_METHOD.EMAIL_AND_TEXT &&
                        "and text message "}{" "}
                      about their {orderTypeLabel.toLowerCase()}
                      {values.requires_vendor_physician_authorization &&
                        " and it will be sent to the signing physician"}
                      .
                      {values.requires_vendor_physician_authorization &&
                        ` Results will be released automatically to ${patientName} once they are available.`}
                      <br />
                    </span>
                    <MaterialLink
                      color="primary"
                      target="_blank"
                      href={
                        values.requires_vendor_physician_authorization
                          ? PHYSICIAN_SERVICES_CLIENT_EXPERIENCE_GUIDE_URL
                          : PATIENT_EXPERIENCE_GUIDE_URL
                      }
                    >
                      {" "}
                      See what {orderPatientLabel.toLowerCase()}s receive here.
                    </MaterialLink>
                  </>
                ) : null}
              </Typography>
            </div>
          )}
          {!edit && (
            <DesignSystemButton
              color="secondary"
              disabled={dirty || pending}
              onClick={onSave}
              size="large"
              className={classes.saveDraftButton}
            >
              Save Draft
            </DesignSystemButton>
          )}
        </>
      )}
      {[ORDER_STATUS.DRAFT, ORDER_STATUS.SCHEDULED].includes(order.status) && (
        <DesignSystemButton
          color="text"
          disabled={dirty || pending}
          onClick={onDelete}
          className={classes.deleteOrderButton}
        >
          Delete {orderTypeLabel}
        </DesignSystemButton>
      )}
    </div>
  )
}

export default OrderActions

import { forwardRef } from "react"

import { CircularProgress } from "@material-ui/core"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { Button, ButtonProps } from "@rupahealth/design"

import { ReactComponent as DotDotDotBlueCircleIcon } from "app/assets/icons/dot-dot-dot-blue-circle.svg"
import { ReactComponent as DownloadIcon } from "app/assets/icons/download-icon.svg"
import { ReactComponent as FoodPlanEditIcon } from "app/assets/icons/food-plan-edit.svg"
import { ReactComponent as TrashCanIcon } from "app/assets/icons/trash-can.svg"
import { colors, primaryColor, red, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  arrow: {
    fill: "white",
    boxShadow: shadows.lg,
  },
  dropdownContent: {
    width: 214,
    display: "flex",
    flexDirection: "column",
    background: "white",
    borderRadius: 8,
    boxShadow: shadows.lg,
    overflowY: "auto",
    zIndex: 1300,
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5),
    gap: theme.spacing(1.25),
    cursor: "pointer",
    fontSize: 15,
    fontWeight: 600,

    "&:hover, &:focus, &:active": {
      background: colors.blue[50],
    },

    "& > svg": {
      fill: "currentColor",
      minWidth: 15,
      width: 15,
      height: 15,
    },
    "& > $MuiCircularProgress-root": {
      minWidth: 15,
      width: 15,
      height: 15,
    },
  },
  separator: {
    margin: theme.spacing(0, "auto"),
    background: colors.blueGray[200],
    height: 1,
    width: `calc(100% - ${theme.spacing(3.0)}px)`,
    padding: theme.spacing(0.0, 1.5),
  },
}))

const DropdownMenuItem = ({ children, color, icon, onSelect }) => {
  const classes = useStyles()
  return (
    <DropdownMenu.Item
      className={classes.item}
      onSelect={onSelect}
      style={{ color }}
    >
      {icon}
      {children}
    </DropdownMenu.Item>
  )
}

const DropdownMenuTriggerButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    return (
      <Button
        {...props}
        ref={ref}
        size="icon"
        variant="ghost"
        className="group hover:bg-transparent"
      >
        <DotDotDotBlueCircleIcon
          viewBox="0 0 15 15"
          className="h-5 w-5 rounded-full group-hover:fill-primary-600 group-aria-expanded:fill-primary-600"
        />
      </Button>
    )
  }
)

export interface DocumentListItemDropdownMenuProps {
  isDownloading?: boolean
  deleteIcon?: React.ReactNode
  editIcon?: React.ReactNode
  downloadIcon?: React.ReactNode
  editLabel?: string
  downloadLabel?: string
  deleteLabel?: string
  onDelete?: () => void
  onDownload?: () => void
  onEdit?: () => void
}

export default function DocumentListItemDropdownMenu({
  isDownloading,
  deleteIcon,
  editIcon,
  downloadIcon,
  editLabel = "Edit",
  downloadLabel = "Download",
  deleteLabel = "Delete",
  onDelete,
  onDownload,
  onEdit,
}: DocumentListItemDropdownMenuProps) {
  const classes = useStyles()
  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <DropdownMenuTriggerButton />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="center"
          className={classes.dropdownContent}
          sideOffset={5}
          onCloseAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          <DropdownMenu.Arrow className={classes.arrow} />

          {onDownload && (
            <DropdownMenuItem
              color={primaryColor}
              icon={
                isDownloading ? (
                  <CircularProgress size={15} />
                ) : (
                  downloadIcon ?? <DownloadIcon viewBox="0 0 13 16" />
                )
              }
              onSelect={(e) => {
                e.preventDefault()

                onDownload()
              }}
            >
              {downloadLabel}
            </DropdownMenuItem>
          )}

          {onDownload && onEdit && (
            <DropdownMenu.Separator className={classes.separator} />
          )}

          {onEdit && (
            <DropdownMenuItem
              color={primaryColor}
              icon={editIcon ?? <FoodPlanEditIcon viewBox="0 0 16 13" />}
              onSelect={onEdit}
            >
              {editLabel}
            </DropdownMenuItem>
          )}

          {onEdit && onDelete && (
            <DropdownMenu.Separator className={classes.separator} />
          )}

          {onDelete && (
            <DropdownMenuItem
              color={red}
              icon={deleteIcon ?? <TrashCanIcon viewBox="0 0 15 17" />}
              onSelect={onDelete}
            >
              {deleteLabel}
            </DropdownMenuItem>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

import { ReactComponent as CarrotRightIcon } from "app/assets/icons/patient-portal/chevron-right.svg"
import OrderStatus from "app/components/OrderStatus"
import BodyText from "app/components/design-system/BodyText"
import { ORDER_STATUS } from "app/constants.typed"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, primaryColor, shadows } from "app/theme"
import formatDateForHuman from "app/utils/format-date-for-human"
import makeAppStyles from "app/utils/makeAppStyles"
import { getOrderTypeLabel } from "app/utils/order-utils"

import {
  PatientPortalKit,
  PatientPortalLabCompany,
  PatientPortalOrder,
  PatientPortalPractitioner,
  PatientPortalStorefront,
  PatientPortalClinic,
} from "../types"
import PatientPortalPatientOrderCardKit from "./PatientPortalPatientOrderCardKit"

const useStyles = makeAppStyles((theme) => ({
  container: {
    background: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 12,
    alignSelf: "stretch",
    borderRadius: 12,
    border: "1px solid white",
    boxShadow: shadows.sm,
    width: "100%",
    transition: "border-color 0.2s",

    "&:hover": {
      cursor: "pointer",
      borderColor: primaryColor,
    },
  },
  orderInfoContainer: {
    padding: "20px 24px 10px 24px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      justifyContent: "flex-start",
      gap: 12,
    },
  },
  orderInfoTitles: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  pendingPaymentTitle: {
    color: colors.blueGray[400],
  },
  orderInfoStatus: {
    display: "flex",
    alignItems: "center",
    gap: 27,
    height: "fit-content",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  orderedKitsContainer: {
    padding: "14px 24px 16px 24px",
    background: colors.blueGray[200],
    width: "100%",
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: 16,
    gridRowGap: 26,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
}))

interface Props {
  order: PatientPortalOrder
  onClick: (order: PatientPortalOrder) => void
}

const OrderStatusWrapper = ({
  order,
  kits,
}: {
  order: PatientPortalOrder
  kits: PatientPortalKit[]
}) => {
  const order_company_refs = kits.map(
    (kit) => kit.relationships.lab_company.data
  )
  const kitLabCompany =
    useCachedCollection<PatientPortalLabCompany>(order_company_refs)
  const orderHasNoShippedKits = kitLabCompany.every(
    (company) => company.attributes.only_in_house_phlebotomy
  )
  const backendOrderStatus = order.attributes.status
  const displayStatus =
    orderHasNoShippedKits &&
    backendOrderStatus === ORDER_STATUS.AWAITING_SHIPMENT
      ? ORDER_STATUS.IN_PROGRESS
      : backendOrderStatus

  return (
    <OrderStatus
      status={displayStatus}
      total_ordered_tests={order.attributes.total_ordered_tests}
      total_completed_ordered_tests={
        order.attributes.total_completed_ordered_tests
      }
    />
  )
}

const PatientPortalPatientOrderCard = ({ order, onClick }: Props) => {
  const classes = useStyles()

  const practitioner = useCachedResource<PatientPortalPractitioner>(
    order.relationships.practitioner.data
  )

  const clinic = useCachedResource<PatientPortalClinic>(
    practitioner?.relationships.clinic.data
  )

  const kits = useCachedCollection<PatientPortalKit>(
    order.relationships.kits.data
  )

  const storefront = useCachedResource<PatientPortalStorefront>(
    order.relationships.storefront.data
  )

  let subtext = `Ordered by ${practitioner?.attributes.titled_full_name}`

  if (storefront) {
    subtext = `Ordered on ${storefront.attributes.name}`
  } else if (order.attributes.requires_vendor_physician_authorization) {
    subtext = `${getOrderTypeLabel(
      order.attributes.requires_vendor_physician_authorization
    )} by ${practitioner?.attributes.titled_full_name}`
  }

  if (clinic) {
    subtext += ` at ${clinic.attributes.name}`
  }

  return (
    <div
      className={classes.container}
      onClick={() => onClick(order)}
      data-testid="patient-portal-order-card"
    >
      <div className={classes.orderInfoContainer}>
        <div className={classes.orderInfoTitles}>
          <BodyText
            size="lg"
            weight="semibold"
            className={
              order.attributes.status === ORDER_STATUS.PENDING_PAYMENT ||
              order.attributes.status === ORDER_STATUS.PENDING_SHIPPING_INFO
                ? classes.pendingPaymentTitle
                : ""
            }
          >
            {order.attributes.status !== ORDER_STATUS.PENDING_PAYMENT &&
            order.attributes.status !== ORDER_STATUS.PENDING_SHIPPING_INFO
              ? formatDateForHuman(order.attributes.date_patient_checkout)
              : order.attributes.status}
          </BodyText>
          <BodyText size="base" weight="regular">
            {subtext}
          </BodyText>
        </div>
        <div className={classes.orderInfoStatus}>
          <OrderStatusWrapper order={order} kits={kits} />
          <CarrotRightIcon fill={primaryColor} />
        </div>
      </div>
      <div className={classes.orderedKitsContainer}>
        {kits.map((kit) => (
          <PatientPortalPatientOrderCardKit kit={kit} key={kit.id} />
        ))}
      </div>
    </div>
  )
}

export default PatientPortalPatientOrderCard

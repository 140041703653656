import KitShipmentTrackingDescription from "app/components/StatusComponents/KitShipmentTrackingDescription"
import { KIT_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { KitStatuses } from "../../types/types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({ kit }: StatusConfigurationProps) => {
  if (
    kit.attributes.is_requisition_provided ||
    !kit.attributes.is_shipped_kit
  ) {
    if (kit.attributes.is_requisition_ready) {
      return <>{"Requisition is Ready"}</>
    }

    return <>{"Requisition is Processing"}</>
  }

  return <>{"Kit Shipped to Patient"}</>
}

const SubTitle = ({ kit, labCompany }: StatusConfigurationProps) => {
  if (
    kit.attributes.is_requisition_provided ||
    !kit.attributes.is_shipped_kit
  ) {
    return null
  }

  return (
    <KitShipmentTrackingDescription
      estimated_arrival_date={kit.attributes.estimated_arrival_date}
      shipment_tracking_link={kit.attributes.shipment_tracking_link}
      lab_company_notifies_sample_at_lab={
        labCompany?.attributes.notifies_sample_at_lab
      }
      lab_company_short_name={labCompany?.attributes.short_name}
    />
  )
}

const orderedWithLabStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: ({ kitStatus, labCompanyNotifiesSampleAtLab }: KitStatuses) =>
      kitStatus === KIT_STATUS.ORDERED_WITH_LAB ||
      (kitStatus === KIT_STATUS.AT_LAB && !labCompanyNotifiesSampleAtLab),
    progressPercentage: PROGRESS_PERCENTAGES.ORDERED_WITH_LAB,
    title: Title,
    subTitle: SubTitle,
  }

export default orderedWithLabStatusConfiguration

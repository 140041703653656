import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { saveAs } from "file-saver"
import { isEmpty } from "lodash"
import { useParams } from "react-router-dom"
import { useSearchParam } from "react-use"

import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import Error404Page from "app/main/errors/404/Error404Page"
import PatientInstruction from "app/main/patient-portal/PatientInstruction"
import Card from "app/patient-portal/Card"
import { PatientPortalPayment } from "app/patient-portal/types"
import {
  PATIENT_PORTAL_TRACKING_EVENTS,
  trackPatientPortalEvent,
} from "app/services/segment"
import { showMessage } from "app/store/actions"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import makeAppStyles from "app/utils/makeAppStyles"

import { usePatientPortalOrderResource } from "../hooks/use-patient-portal-order-resource"
import PatientPortalOrderKitList from "./PatientPortalOrderKitList"
import PatientPortalOrderLoadingState from "./PatientPortalOrderLoadingState"

const useStyles = makeAppStyles((theme) => ({
  notesToPatient: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  patientInstructions: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  breadcrumbsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 8,
    marginBottom: -20,
  },
  breadcrumbs: {
    maxWidth: theme.breakpoints.values.lg,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  breadcrumbDivider: {
    position: "relative",
    top: 2,
  },
}))

const PatientPortalOrder = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { orderId, patientId } = useParams<{
    orderId: string
    patientId: string
  }>()
  const expandedIndexParam = useSearchParam("expanded-index")
  const [expandedIndex, setExpandedIndex] = useState(
    parseInt(expandedIndexParam || "0", 10) || 0
  )

  // these params indicate that we should automatically download the receipt or superbill
  const downloadReceipt = useSearchParam("download-receipt")
  const downloadSuperbill = useSearchParam("download-superbill")
  const orderPaymentId = useSearchParam("order-payment")

  const payment = useCachedResource<PatientPortalPayment>(
    orderPaymentId
      ? {
          type: "patient_portal_payment",
          id: orderPaymentId,
        }
      : undefined
  )

  useEffect(() => {
    trackPatientPortalEvent(PATIENT_PORTAL_TRACKING_EVENTS.ORDER_PAGE_VIEWED, {
      order_id: orderId,
    })
  }, [])

  useEffect(() => {
    if (downloadSuperbill === "true" && payment?.attributes.superbill_url) {
      downloadReceiptOrSuperbill("Superbill", payment.attributes.superbill_url)
    }
  }, [downloadSuperbill, payment])

  useEffect(() => {
    if (downloadReceipt === "true" && payment?.attributes.receipt_url) {
      downloadReceiptOrSuperbill("Receipt", payment.attributes.receipt_url)
    }
  }, [downloadReceipt, payment])

  const downloadReceiptOrSuperbill = (
    documentType: "Receipt" | "Superbill",
    url: string
  ) => {
    dispatch(
      showMessage({
        message: `${documentType} downloading...`,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    )
    // we have to fetch the file data and then save it because
    // there is a bug with filesaver and AWS HEAD requests if we try
    // to directly feed the url to saveAs
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        saveAs(blob, `rupa-${documentType.toLowerCase()}.pdf`)
        dispatch(
          showMessage({
            message: `${documentType} downloaded! Check your downloads folder.`,
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          })
        )
      })
    // remove only the download param so that we don't download it again
    // if the user refreshes the page
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname +
        window.location.search
          .replace(/&?download-receipt=true/, "")
          .replace(/&?download-superbill=true/, "")
    )
  }

  const { error, ready, order, practitioner, patient } =
    usePatientPortalOrderResource(orderId, patientId)

  useEffect(() => {
    if (ready && order?.attributes.patient_checkout_url) {
      window.location.href = order.attributes.patient_checkout_url
    }
  }, [ready, order?.attributes.patient_checkout_url])

  if (error) {
    // If we have errors fetching the order, we show a 404 page.
    // This could be due to a server error, or someone trying to access an incorrect order
    return <Error404Page />
  }

  if (!ready || !order?.attributes?.date_patient_checkout) {
    return (
      <div className="flex flex-col gap-3 sm:gap-6">
        <PatientPortalOrderLoadingState />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3 sm:gap-6">
      <PatientPortalOrderKitList order={order} />

      {!isEmpty(order.attributes.patient_instructions) && (
        <Card className={classes.patientInstructions}>
          <DisplayText size="lg" weight="semibold">
            Prepare for Your Sample Collection
          </DisplayText>

          {Boolean(order.attributes.notes_to_patient) && (
            <div className={classes.notesToPatient}>
              <BodyText
                weight="semibold"
                className={classes.notesToPatientTitle}
              >
                Note from {practitioner.attributes.titled_full_name}:
              </BodyText>
              <BodyText italic className={classes.notesToPatientDescription}>
                {order.attributes.notes_to_patient}
              </BodyText>
            </div>
          )}
          {order.attributes.patient_instructions.map(
            (patientInstruction, index) => (
              <PatientInstruction
                key={patientInstruction.type}
                patientInstruction={patientInstruction}
                expanded={expandedIndex === index}
                onExpand={() =>
                  setExpandedIndex(expandedIndex === index ? -1 : index)
                }
                patient={patient}
              />
            )
          )}
        </Card>
      )}
    </div>
  )
}

export default PatientPortalOrder

import { useCallback, useMemo, useState } from "react"

import { faTrashCan } from "@fortawesome/free-regular-svg-icons"
import {
  faArrowDown,
  faArrowUp,
  faMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress, InputAdornment } from "@material-ui/core"
import { Button, cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"
import TextField from "app/components/forms/TextField"
import {
  ControlledAutoCompleteField,
  ControlledSelectField,
  ControlledTextField,
  FieldLabel,
} from "app/main/patient-checkout/fields"
import { BIOMARKER_RESULT_UNITS } from "app/main/results-upload/constants"
import useInputFormStyles from "app/main/results-upload/hooks/use-input-form-styles"
import {
  BiomarkerResultFormData,
  ResultsUploadManualEntryFormReturn,
} from "app/main/results-upload/hooks/use-results-upload-manual-entry-form"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { LabTestType } from "app/types"
import { Biomarker } from "types/biomarker"

import BiomarkerSearchPopover from "./BiomarkerSearchPopover"

interface Props extends Pick<ResultsUploadManualEntryFormReturn, "methods"> {
  field: BiomarkerResultFormData
  index: number
  onDelete: () => void
  isDeleteLoading: boolean
  isPatchLoading: boolean
  existingBiomarkerIds: string[]
  availableLabTestTypes: LabTestType[]
  currentUnit?: string
}

const ResultsUploadManualEntryBiomarkerResultForm = ({
  field,
  index,
  onDelete,
  existingBiomarkerIds,
  isDeleteLoading,
  isPatchLoading: _,
  availableLabTestTypes,
  currentUnit,
  methods,
}: Props) => {
  const classes = useInputFormStyles()

  const [showBiomarkerSearch, setShowBiomarkerSearch] = useState(false)

  const biomarker = useCachedResource<Biomarker>(
    field?.relationships?.biomarker?.data
  )

  const watchValue = methods.watch(`results.${index}.attributes.value`)
  const watchLowRange = methods.watch(`results.${index}.attributes.normal_min`)
  const watchHighRange = methods.watch(`results.${index}.attributes.normal_max`)

  const handleBiomarkerSelect = async (biomarker: Biomarker) => {
    methods.setValue(
      `results.${index}.relationships.biomarker.data.id`,
      biomarker.id
    )
  }

  const errors = useMemo(
    () => ({
      ...(methods.formState.errors.results?.[index]?.attributes ?? {}),
      ...(methods.formState.errors.results?.[index]?.relationships ?? {}),
    }),
    [methods.formState.errors.results?.[index]]
  )

  const highOrLowText = useMemo(() => {
    if (watchLowRange && watchValue) {
      const min = parseFloat(watchLowRange)
      const value = parseFloat(watchValue)

      if (!isNaN(min) && !isNaN(value) && min > value) {
        return "LOW"
      }
    }

    if (watchValue && watchHighRange) {
      const max = parseFloat(watchHighRange)
      const value = parseFloat(watchValue)

      if (!isNaN(max) && !isNaN(value) && value > max) {
        return "HIGH"
      }
    }

    return null
  }, [watchValue, watchLowRange, watchHighRange])

  // Custom filter so that µ shows when user types "u"
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option
        ?.toLowerCase()
        .replace(/µ/g, "u")
        .includes(inputValue.toLowerCase().replace(/µ/g, "u"))
    )
  }

  const biomarkerName =
    biomarker?.attributes.long_name === biomarker?.attributes.short_name
      ? biomarker?.attributes.long_name
      : `${biomarker?.attributes.long_name} (${biomarker?.attributes.short_name})`

  // Temporary solution disables enter key on form fields because
  // enter key triggers the delete button
  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === "Enter") e.preventDefault()
  }, [])

  return (
    <div className="w-full flex items-center rounded-md border border-slate-200">
      <div className="w-full border-r border-slate-200 py-3 px-4 flex flex-col gap-[2px]">
        <div className="flex gap-[14px] items-start flex-col lg:flex-row">
          <div className="flex flex-col w-full lg:min-w-[300px] lg:w-[300px]">
            <FieldLabel
              className="text-sm font-normal"
              label="Biomarker"
              required
            />
            {showBiomarkerSearch ? (
              <BiomarkerSearchPopover
                existingBiomarkerIds={existingBiomarkerIds}
                onBiomarkerSelect={handleBiomarkerSelect}
                widthOfToggleContent={300}
                isFromBiomarkerForm
                actionText="Update"
                toggleSearch={setShowBiomarkerSearch}
              />
            ) : (
              <TextField
                value={biomarkerName}
                className={cn(
                  classes.customTextInput,
                  classes.customTextInputBold
                )}
                required
                onClick={(e) => {
                  e.preventDefault()
                  setShowBiomarkerSearch(true)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon
                        className="text-primary text-sm"
                        icon={faMagnifyingGlass}
                      />
                    </InputAdornment>
                  ),
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault()
                      setShowBiomarkerSearch(true)
                    }
                  },
                }}
              />
            )}

            {errors.biomarker?.data?.id?.message ? (
              <BodyText size="xs" className="text-destructive">
                {errors.biomarker.data.id.message}
              </BodyText>
            ) : (
              <BodyText size="xs" className="mt-1 opacity-0">
                filler
              </BodyText>
            )}
          </div>

          <div className="flex flex-col gap-1 items-start w-full lg:max-w-[120px]">
            <ControlledAutoCompleteField
              name={`results.${index}.attributes.units`}
              className="w-full"
              label={<BodyText size="sm">Units</BodyText>}
              labelClassName="text-sm font-normal"
              getOptionLabel={(option) => option}
              customFilterOptions={filterOptions}
              options={[
                ...BIOMARKER_RESULT_UNITS,
                currentUnit || field.attributes.units,
              ]}
              inputClassName={classes.customInput}
              showErrors={false}
              freeSolo
              includeInputTextInOptions
              dropdownOnlyWhileTyping
              InputProps={{ onKeyDown }}
            />
            {errors.units?.message ? (
              <BodyText size="xs" className="text-destructive">
                {errors.units.message}
              </BodyText>
            ) : (
              <BodyText size="xs" className="opacity-0">
                filler
              </BodyText>
            )}
          </div>

          <div className="flex flex-col gap-1 items-start w-full">
            <ControlledSelectField
              name={`results.${index}.relationships.sample_type.data.id`}
              label="Sample Type"
              className="w-full"
              labelClassName="text-sm font-normal"
              required
              onKeyDown={onKeyDown}
            >
              {availableLabTestTypes.map((labTestType) => (
                <option key={labTestType.id} value={labTestType.id}>
                  {labTestType.name}
                </option>
              ))}
            </ControlledSelectField>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-[14px] items-start">
          <ControlledTextField
            name={`results.${index}.attributes.value`}
            label="Value"
            className="w-full"
            labelClassName="text-sm font-normal"
            required
            InputProps={{
              onKeyDown,
              endAdornment: Boolean(highOrLowText) && (
                <InputAdornment position="end">
                  <div className="py-[1px] px-2 bg-red-100 rounded-full">
                    <BodyText size="xxs" weight="bold" className="text-red-500">
                      {highOrLowText}
                    </BodyText>
                  </div>
                </InputAdornment>
              ),
            }}
          />

          <ControlledTextField
            name={`results.${index}.attributes.normal_min`}
            label="Standard Range Low"
            beforeLabelIcon={<FontAwesomeIcon icon={faArrowDown} />}
            className="w-full"
            labelClassName="text-sm font-normal"
            placeholder="None"
            InputProps={{ onKeyDown }}
          />

          <ControlledTextField
            name={`results.${index}.attributes.normal_max`}
            label="Standard Range High"
            beforeLabelIcon={<FontAwesomeIcon icon={faArrowUp} />}
            className="w-full"
            labelClassName="text-sm font-normal"
            placeholder="None"
            InputProps={{ onKeyDown }}
          />
        </div>
      </div>
      <div className="rounded-r-md bg-slate-50 px-[15px] self-stretch flex items-center flex-shrink">
        <Button
          variant="ghost"
          className="p-0"
          onClick={onDelete}
          disabled={isDeleteLoading}
        >
          {isDeleteLoading ? (
            <CircularProgress size={14} />
          ) : (
            <FontAwesomeIcon
              className="text-destructive text-base"
              icon={faTrashCan}
            />
          )}
        </Button>
      </div>
    </div>
  )
}

export default ResultsUploadManualEntryBiomarkerResultForm

import { Provider } from "react-redux"

import history from "@history"
import ReactDOM from "react-dom"
import { Router } from "react-router-dom"
import smoothscroll from "smoothscroll-polyfill"
import { SWRConfig } from "swr"

import "@fontsource/josefin-sans/400.css"
import "@fontsource/josefin-sans/500.css"
import "@fontsource/josefin-sans/600.css"
import "@fontsource/josefin-sans/700.css"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/500.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@rupahealth/design/dist/tailwind.css"
import * as Sentry from "@sentry/react"

import App from "app/App"
import * as refiner from "app/services/refiner"
import { loadSegment } from "app/services/segment"
import {
  environment,
  isDebug,
  isPR,
  PR_NUMBER,
  sentryDsn,
  sentryRelease,
} from "app/settings"
import configureStore from "app/store"
import { createCache } from "app/swr/helpers/cache"
import { getApiBaseUrl } from "app/utils"
import "react-app-polyfill/stable"

import * as serviceWorker from "./serviceWorker"
import "./styles/index.css"

// Polyfill for smooth scrolling. Currently used for the dashboard cards scrolling.
smoothscroll.polyfill()

if (!isDebug) {
  Sentry.init({
    dsn: sentryDsn,
    environment: environment,
    release: sentryRelease,
    tunnel: `${getApiBaseUrl()}/api/sentry-tunnel/`,
    beforeSend: (event, hint) => {
      // Used to better group generic exceptions for frontend Sentry errors
      const exception = hint?.originalException

      if (exception && exception.name && exception.message) {
        event.fingerprint = [exception.name, exception.message]
      }

      // Temporary fix to silence error
      if (exception?.message?.includes("Cannot get the leaf node at path")) {
        event.fingerprint = ["Cannot get the leaf node at path"]
      }

      return event
    },
  })
}

if (isPR && PR_NUMBER) {
  Sentry.setTag("pr.number", PR_NUMBER)
  Sentry.setTag(
    "pr.url",
    `https://github.com/rupahealth/rupalabs/pull/${PR_NUMBER}`
  )
}

window["_fs_ready"] = function () {
  Sentry.setTag("full_story.url", window.FS.getCurrentSessionURL())
}

console.log("Environment:", environment)

refiner.initialize()

loadSegment()

const store = configureStore()

ReactDOM.render(
  <SWRConfig
    value={{
      provider: createCache,
      errorRetryCount: 0,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }}
  >
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </SWRConfig>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

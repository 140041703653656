import clsx from "clsx"

import { Grid } from "@material-ui/core"

import ArrowTopRight from "app/assets/icons/arrow-top-right.svg"
import { Dialog, ModalContent, ModalHeader } from "app/components/modals"
import { navy, primaryColor, white } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  faqRow: {
    display: "flex",
    flexDirection: "row",
  },
  faqBlock: {
    display: "flex",
    backgroundColor: white,
    borderRadius: "4px",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07)",
    rowGap: "20px",
    marginBottom: "22px",
    padding: "24px",
    flexDirection: "column",
    color: navy,
    fontWeight: 400,
  },
  listBlock: {
    margin: "0 0 22px",
  },
  listNumber: {
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    lineHeight: "24px",
    color: white,
    backgroundColor: primaryColor,
    marginRight: "12px",
    flexShrink: 0,
    textAlign: "center",
  },
  bottomBlockItem: {
    width: "100%",
  },
  link: {
    color: primaryColor,
    display: "inline-block",
  },
  linkIcon: {
    marginLeft: 6,
  },
}))

const CallInsuranceInfoModal = ({ open, onClose }) => {
  const classes = useStyles()

  const labcorpLocationFinderUrl =
    "https://www.labcorp.com/labs-and-appointments"
  const faq = [
    <>
      <span>
        Before making the call, gather necessary information including:
      </span>
      <ul>
        <li>Your insurance ID card</li>
        <li>Personal information (name, date of birth, address)</li>
        <li>
          Specific Labcorp tests recommended by your practitioner and their CPT
          codes (listed next to your tests)
        </li>
        <li>
          Address of the Labcorp draw site that you wish to visit.
          <a href={labcorpLocationFinderUrl} target="_blank" rel="noreferrer">
            <span className={classes.link}>
              Find a Labcorp draw site near you
            </span>
            <img src={ArrowTopRight} className={classes.linkIcon} alt="" />
          </a>
        </li>
      </ul>
    </>,
    "Use the phone number provided on your insurance ID card to reach your insurance company’s customer service department.",
    "Once connected to a representative, introduce yourself and mention that you would like to verify coverage for specific blood lab tests from Labcorp.",
    "The representative will likely ask for your insurance ID number, personal details, and the details of the Labcorp tests. If the Labcorp location is in network, your insurance may also be able to get you an estimate.",
  ]

  return (
    <Dialog open={open}>
      <ModalHeader
        title="Calling Your Insurance Company"
        subtitle="Instructions for verifying your insurance coverage for Labcorp tests."
        onClose={onClose}
      ></ModalHeader>
      <ModalContent>
        <Grid
          container
          spacing={2}
          className={clsx(classes.faqBlock, classes.listBlock)}
        >
          {faq.map((item, index) => (
            <Grid item xs={12} key={index}>
              <div className={classes.faqRow}>
                <div className={classes.listNumber}>{index + 1}</div>
                <div>{item}</div>
              </div>
            </Grid>
          ))}
        </Grid>
        <div className={classes.faqBlock}>
          <div className={classes.bottomBlockItem}>
            If you confirm coverage and are comfortable with your out of pocket
            estimate, you can place the order and schedule an appointment at a
            Labcorp draw site.{" "}
            <i>
              <b>Be sure to bring your insurance ID card with you!</b>
            </i>
          </div>
          <div className={classes.bottomBlockItem}>
            If you are unable to confirm coverage, you can choose to switch to
            pay the discounted cash prices out of pocket. If you go through
            insurance and the test is not covered, you may end up with a bill
            more expensive than the discounted cash prices.
          </div>
        </div>
      </ModalContent>
    </Dialog>
  )
}
export default CallInsuranceInfoModal

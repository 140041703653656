import { useMediaQuery, Theme, makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import { UserPaths } from "app/Routes"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import { colors, navy } from "app/theme"

import SideNav from "./SideNav"
import NotificationsTable from "./components/NotificationsTable"

const styles = () => ({
  notificationWrapper: {
    background: colors.coolGray[50],
    border: `1px solid ${colors.coolGray[200]}`,
    minHeight: "100vh",
    padding: 24,
  },
  explanation: {
    color: navy,
  },
})
const useStyles = makeStyles(styles)

export default function Notifications() {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },
            {
              label: "Notifications",
            },
          ]}
          title="Notifications"
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title={"Notifications"}
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <div className={classes.notificationWrapper}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12}>
                <BodyText className={classes.explanation}>
                  Assign a team member to be the point of contact for all order
                  updates, emails, and support messages. Please note:{" "}
                  <b>
                    only the point of contact will receive updates, results
                    emails, and notifications from Rupa.
                  </b>
                </BodyText>
              </Grid>
              <Grid item xs={12}>
                <NotificationsTable />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

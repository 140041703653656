import axios from "axios"
import { differenceInYears, parse } from "date-fns"
import useSWR from "swr"

import { PHLEBOTOMY_PROVIDERS } from "app/types"
import { getApiBaseUrl } from "app/utils"

export interface ZipCodeCheckResponse {
  zip_code: string
  providers: string[]
}

let notificationsIfAxleUnavailable = {
  NOT_IN_AREA:
    "Mobile phlebotomy is not available in your area. Removing from order.",
  PATIENT_TOO_YOUNG:
    "Mobile phlebotomy is not available for patients less than three years old. Removing from order.",
}

export default function usePhlebotomyProviderAvailability(
  zipCode: string | null,
  checkoutToken: string,
  patient_birthday: string | null
) {
  const { data: zipCodeAvailability, isLoading } = useSWR(
    zipCode && checkoutToken
      ? `/phlebotomy-provider-availability/?token=${checkoutToken}&zip_code=${zipCode}`
      : null,
    {
      async fetcher(url) {
        const response = await axios.request<ZipCodeCheckResponse>({
          baseURL: getApiBaseUrl() + "/api/",
          url,
          method: "get",
        })

        return response.data
      },
      revalidateIfStale: true,
    }
  )

  let isPatientOverThreeYearsOld: boolean | undefined

  if (patient_birthday) {
    const dateToCompare = parse(patient_birthday, "yyyy-MM-dd", new Date())
    isPatientOverThreeYearsOld =
      Math.abs(differenceInYears(new Date(), dateToCompare)) >= 3
  }

  const axleInUsersArea = zipCodeAvailability?.providers.includes(
    PHLEBOTOMY_PROVIDERS.AXLE
  )

  let notificationMessage: string | null = null
  if (!axleInUsersArea) {
    notificationMessage = notificationsIfAxleUnavailable.NOT_IN_AREA
  } else if (!isPatientOverThreeYearsOld) {
    notificationMessage = notificationsIfAxleUnavailable.PATIENT_TOO_YOUNG
  }

  return {
    isLoading,
    notificationMessage,
    availableProviders: zipCodeAvailability?.providers ?? [],
    axleAvailable: axleInUsersArea && isPatientOverThreeYearsOld,
    zipCode,
  }
}

import { Link } from "@material-ui/core"
import { Button } from "@rupahealth/design"

import { ReactComponent as FlaskIcon } from "app/assets/icons/flask.svg"
import EmptyTrendsIcon from "app/assets/images/results-over-time/trends-empty.png"
import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useResultsUploadPdfModal from "app/main/results-upload/modals/ResultsUploadPdfModal"
import { primaryColor } from "app/theme"
import { Patient } from "app/types"

import {
  SUPPORTED_LABS_INTERCOM_ARTICLE_ID,
  SUPPORTED_LABS_INTERCOM_ARTICLE_URL,
} from "./constants/constants"

interface Props {
  patient: Patient
}

const ResultsOverTimeEmptyState = ({ patient }: Props) => {
  const [isResultsOverTimeManualUploadEnabled] = useFeatureFlag(
    FeatureFlag.ResultsOverTimeManualResultsUpload
  )

  const resultsUploadPdfModal = useResultsUploadPdfModal()

  return (
    <div className="py-7 px-4 bg-slate-50 flex flex-col justify-center items-center rounded-md mt-7">
      <div className="w-[175px]">
        <img src={EmptyTrendsIcon} alt="no trends" />
      </div>
      <div className="flex flex-col justify-center items-center gap-y-1">
        <BodyText weight="semibold" className="text-center">
          There aren't any results to display yet!
        </BodyText>
        <BodyText size="sm" className="text-center">
          Trends displays lab results over time.
        </BodyText>
        <BodyText size="sm" className="text-center">
          <Link
            className="font-semibold cursor-pointer"
            onClick={() => {
              window.Intercom
                ? window.Intercom(
                    "showArticle",
                    SUPPORTED_LABS_INTERCOM_ARTICLE_ID
                  )
                : window.open(SUPPORTED_LABS_INTERCOM_ARTICLE_URL, "_blank")
            }}
          >
            See how it works.
          </Link>
        </BodyText>
      </div>
      {isResultsOverTimeManualUploadEnabled && (
        <Button
          variant="outline"
          className="text-sm text-primary mt-[29px] w-full sm:w-[400px]"
          onClick={() => {
            resultsUploadPdfModal.show({
              onClose: () => resultsUploadPdfModal.remove(),
              patient,
            })
          }}
        >
          <FlaskIcon className="mr-2" fill={primaryColor} /> Import Results
        </Button>
      )}
    </div>
  )
}

export default ResultsOverTimeEmptyState

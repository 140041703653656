import { Fragment, useMemo, useRef, useState } from "react"

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  Theme,
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import * as Popover from "@radix-ui/react-popover"
import { Row, Table as TanstackTable, flexRender } from "@tanstack/react-table"

import AddBlueIcon from "app/assets/icons/add-blue.svg"
import AddGreyIcon from "app/assets/icons/add-grey.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import useDebounceState from "app/hooks/use-debounce-state"
import useAppSelector from "app/hooks/useAppSelector"
import AddBiomarkerSearchInput from "app/results-summary/components/ResultsSummaryEditor/components/AddBiomarker/AddBiomarkerSearchInput"
import AddBiomarkerSearchLoading from "app/results-summary/components/ResultsSummaryEditor/components/AddBiomarker/AddBiomarkerSearchLoading"
import AddBiomarkerSearchPopoverContent from "app/results-summary/components/ResultsSummaryEditor/components/AddBiomarker/AddBiomarkerSearchPopoverContent"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"

import useModifyBiomarkerCustomDescriptionModal from "../modals/hooks/use-modify-biomarker-custom-description"
import AddBiomarkerSearchBiomarkerOptions from "./AddBiomarkerSearchBiomarkerOptions"
import useAvailableBiomarkers from "./hooks/use-available-biomarkers"
import useBiomarkerCustomDescriptions from "./hooks/use-biomarker-custom-descriptions"
import useBiomarkerSearchCursorTracking from "./hooks/use-biomarker-search-cursor-tracking"
import useTableStyles from "./hooks/use-table-styles"

export interface TableRowDetailContext {
  row: Row<any>
}

export interface TableRendererProps {
  table: TanstackTable<any>
}

export default function TableRenderer({ table }: TableRendererProps) {
  const classes = useTableStyles()
  return (
    <div className={classes.container}>
      <Table className={classes.table}>
        <TableHead>
          <TableHeaderRow table={table} />
        </TableHead>

        <TableBody>
          {table.options.meta?.isLoading ? (
            <TablePlaceholderRows table={table} />
          ) : table.getRowModel().rows.length > 0 ? (
            <TableDataRows table={table} />
          ) : (
            <TableEmptyRow table={table} />
          )}
          <TableAddBiomarkerRow table={table} />
        </TableBody>
      </Table>
    </div>
  )
}

const TableHeaderRow = ({
  table,
}: {
  table: TanstackTable<BiomarkerCustomDescription>
}) => {
  const classes = useTableStyles()
  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id} className={classes.tableHeaderRow}>
          {headerGroup.headers.map((header) => (
            <TableCell
              key={header.id}
              className={classes.tableCell}
              colSpan={header.colSpan}
              style={{
                ...header.column.columnDef.meta?.cellStyle,
                ...header.column.columnDef.meta?.headerCellStyle,
                maxWidth: header.column.columnDef.maxSize,
                minWidth: header.column.columnDef.minSize,
                width: header.getSize(),
              }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const TablePlaceholderRows = ({
  table,
}: {
  table: TanstackTable<BiomarkerCustomDescription>
}) => {
  const classes = useTableStyles()
  return (
    <>
      {[0, 1, 2, 3, 4].map((index) => (
        <TableRow key={index} className={classes.tableDataRow}>
          {table.getAllColumns().map((column) => (
            <TableCell
              key={column.id}
              className={classes.tableCell}
              style={{
                ...column.columnDef.meta?.cellStyle,
                ...column.columnDef.meta?.dataCellStyle,
              }}
            >
              {Boolean(column.accessorFn) ? (
                <Skeleton
                  animation="wave"
                  component="div"
                  height={24}
                  width="75%"
                  variant="text"
                />
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const TableDataRows = ({
  table,
}: {
  table: TanstackTable<BiomarkerCustomDescription>
}) => {
  const classes = useTableStyles()
  return (
    <>
      {table.getRowModel().rows.map((row) => (
        <Fragment key={row.id}>
          <TableRow
            className={classes.tableDataRow}
            onClick={
              row.getCanExpand() ? row.getToggleExpandedHandler() : undefined
            }
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell
                key={cell.id}
                className={classes.tableCell}
                style={{
                  ...cell.column.columnDef.meta?.cellStyle,
                  ...cell.column.columnDef.meta?.dataCellStyle,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        </Fragment>
      ))}
    </>
  )
}

const TableAddBiomarkerRow = ({
  table,
}: {
  table: TanstackTable<BiomarkerCustomDescription>
}) => {
  const classes = useTableStyles()

  const triggerRef = useRef<any>()
  const contentRef = useRef<any>()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const clinic = practitioner?.clinic

  /**
   * We debounce the search input to prevent the user from triggering a new request on every key stroke.
   */
  const [debounceSearch, search, setSearch] = useDebounceState("", 300)
  const [isCreating, setIsCreating] = useState(false)
  const [searchIsOpen, setSearchIsOpen] = useState(false)

  const { biomarkers, isLoading, isValidating } = useAvailableBiomarkers(
    debounceSearch,
    true
  )

  const { onCreateBiomarkerCustomDescription, biomarkerIds } =
    useBiomarkerCustomDescriptions()

  const modifyBiomarkerCustomDescriptionsModal =
    useModifyBiomarkerCustomDescriptionModal()

  const {
    biomarkerCursor,
    moveBiomarkerCursorDown,
    moveBiomarkerCursorTo,
    moveBiomarkerCursorUp,
  } = useBiomarkerSearchCursorTracking(biomarkers)

  // Only show biomarkers that have not been added yet
  const filteredBiomarkers = useMemo(() => {
    return biomarkers?.filter(
      (biomarker) => !biomarkerIds.includes(biomarker.id)
    )
  }, [biomarkers, biomarkerIds])

  const handleCreate = async (biomarker) => {
    setIsCreating(true)

    const biomarkerDescription = await onCreateBiomarkerCustomDescription({
      type: "biomarker_custom_description",
      relationships: {
        biomarker: {
          data: {
            type: "biomarker",
            id: biomarker.id,
          },
        },
      },
    })

    setSearchIsOpen(false)
    setIsCreating(false)

    if (biomarkerDescription) {
      modifyBiomarkerCustomDescriptionsModal.show({
        biomarkerCustomDescription: biomarkerDescription,
      })
    }
  }

  const handleToggleAdding = () => {
    setSearch("")
    setSearchIsOpen(!searchIsOpen)
  }

  return (
    <TableRow className={classes.tableDataRow}>
      <TableCell
        align="left"
        className={classes.tableCell}
        colSpan={table.getAllColumns().length}
      >
        {!searchIsOpen && (
          <Tooltip
            title={
              !clinic?.clinic_has_practitioner_with_uploaded_license
                ? "Clinics must have a licensed medical practitioner on their team to customize descriptions."
                : ""
            }
            placement="bottom"
            arrow
          >
            <span>
              <DesignSystemButton
                color="text"
                onClick={() => setSearchIsOpen(true)}
                startIcon={
                  <img
                    src={
                      clinic?.clinic_has_practitioner_with_uploaded_license
                        ? AddBlueIcon
                        : AddGreyIcon
                    }
                    alt="add icon"
                    style={{ position: "relative", top: "-1px" }}
                  />
                }
                loading={false}
                disabled={
                  !clinic?.clinic_has_practitioner_with_uploaded_license
                }
              >
                Customize Biomarker Description
              </DesignSystemButton>
            </span>
          </Tooltip>
        )}

        {searchIsOpen && (
          <div className={classes.popover}>
            <Popover.Root open onOpenChange={handleToggleAdding}>
              <AddBiomarkerSearchInput
                moveBiomarkerCursorDown={moveBiomarkerCursorDown}
                moveBiomarkerCursorUp={moveBiomarkerCursorUp}
                onEnterSelect={() =>
                  biomarkerCursor && handleCreate(biomarkerCursor)
                }
                search={search}
                setSearch={setSearch}
                showLoading={isValidating}
                triggerRef={triggerRef}
                onToggleAdding={() => handleToggleAdding()}
              />

              <AddBiomarkerSearchPopoverContent
                contentRef={contentRef}
                width={isMobile ? "auto" : 400}
              >
                {filteredBiomarkers?.length ? (
                  <AddBiomarkerSearchBiomarkerOptions
                    cursor={biomarkerCursor}
                    identifiers={filteredBiomarkers}
                    isCreating={isCreating}
                    moveCursorTo={moveBiomarkerCursorTo}
                    onSelect={handleCreate}
                  />
                ) : isLoading || isValidating ? (
                  <AddBiomarkerSearchLoading />
                ) : (
                  <BodyText variant="body1">
                    {`No biomarkers found${
                      search ? ` matching search "${search}"` : ""
                    }`}
                  </BodyText>
                )}
              </AddBiomarkerSearchPopoverContent>
            </Popover.Root>
          </div>
        )}
      </TableCell>
    </TableRow>
  )
}

const TableEmptyRow = ({
  table,
}: {
  table: TanstackTable<BiomarkerCustomDescription>
}) => {
  const classes = useTableStyles()

  return (
    <TableRow className={classes.tableEmptyRow}>
      <TableCell
        align="center"
        className={classes.tableCell}
        colSpan={table.getAllColumns().length}
      >
        <div className={classes.emptyTableCellContainer}>
          <div className={classes.emptyTableCell}>
            <BodyText size="base" weight="semibold">
              You're using Rupa's preset descriptions!
            </BodyText>
            <BodyText size="base">
              Rupa already provides default descriptions for everything! You can
              customize if you'd like, or simply continue using our defaults.
            </BodyText>
          </div>
        </div>
      </TableCell>
    </TableRow>
  )
}

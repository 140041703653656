import { useMemo, useState } from "react"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Skeleton } from "@material-ui/lab"
import { Dialog, DialogContent } from "@rupahealth/design"

import { ReactComponent as ActivationCodeLarge } from "app/assets/icons/activation-code-large.svg"
import { ReactComponent as ActivationCodeSmall } from "app/assets/icons/activation-code-small.svg"
import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg"
import TextField from "app/components/forms/TextField"
import { IN_OFFICE_KIT_STATUS } from "app/constants.typed"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"

import IOKActivationCodeInfoTooltip from "../components/IOKActivationCodeInfoTooltip"
import { InOfficeKitType } from "../types"
import { InventoryItem } from "./inventory-types"

interface Props {
  inventoryItem: InventoryItem
}

const ActivationCodesModal: React.FC<Props> = ({ inventoryItem }) => {
  const modal = useModal()
  const [searchTerm, setSearchTerm] = useState("")

  const { isLoading, data: identifiers } = useCollectionSWR("/in_office_kit/", {
    params: {
      in_office_kit_product_id: inventoryItem.in_office_kit_product_id,
      status: [IN_OFFICE_KIT_STATUS.IN_STOCK],
    },
  })
  const inOfficeKits = useCachedCollection<InOfficeKitType>(identifiers)

  const activationCodes = useMemo(() => {
    return inOfficeKits.reduce(
      (acc, k) =>
        k.attributes.activation_id ? [...acc, k.attributes.activation_id] : acc,
      [] as string[]
    )
  }, [inOfficeKits])

  const filteredCodes = useMemo(() => {
    if (!searchTerm) return activationCodes
    return activationCodes.filter((code) => code && code.includes(searchTerm))
  }, [activationCodes, searchTerm])

  return (
    <Dialog
      open={modal.visible}
      onOpenChange={(open) => {
        if (!open) {
          setSearchTerm("")
          modal.hide()
        }
      }}
    >
      <DialogContent className="bg-slate-100 p-0 h-[90vh] overflow-scroll">
        <div className="py-6 px-7 md:py-12 md:px-14 space-y-4">
          <div className="flex flex-col">
            <div className="text-center text-body-500 space-y-[3px]">
              <span className="inline-block bg-white p-5 rounded-xl mb-[13px]">
                <ActivationCodeLarge className="fill-sky-600 block m-auto" />
              </span>
              <p className="text-xl19 font-semibold">
                {inventoryItem.in_office_kit_product_name} Activation Codes
              </p>
              <p className="text-base15">
                Activation Codes are unique numbers written on the lab kit. When
                you’re ready to use a kit, please make sure the Activation Code
                on your kit matches the one you enter.
              </p>
              <IOKActivationCodeInfoTooltip
                labCompanyKey={inventoryItem.lab_company_key}
              />
              <p className="text-sm13 italic">
                Activation codes are only needed for Mosaic and Genova kits.
              </p>
            </div>
          </div>

          <div className="flex flex-col border-t border-t-slate-200">
            <TextField
              fullWidth
              className="bg-white rounded-lg shadow-sm my-4 border border-slate-200"
              disabled={isLoading}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Filter Activation Codes"
              variant="outlined"
              size="small"
              InputProps={{
                className: "gap-2 rounded-lg",
                startAdornment: (
                  <SearchIcon
                    width={16}
                    height={16}
                    className="pointer-events-none"
                  />
                ),
              }}
            />
            <div className="flex flex-col gap-[10px]">
              {isLoading
                ? Array.from(
                    Array(inventoryItem.activation_id_count).keys()
                  ).map((idx) => (
                    <ActivationCodeListItem key={`loading-${idx}`} />
                  ))
                : filteredCodes.map((code) => (
                    <ActivationCodeListItem key={`code-${code}`} value={code} />
                  ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const ActivationCodeListItem: React.FC<{ value?: string }> = ({ value }) => {
  return (
    <div className="flex flex-nowrap items-center gap-2 p-2 rounded-lg bg-white shadow-sm border border-slate-200">
      <span className="bg-slate-100 p-[10px] rounded-md">
        <ActivationCodeSmall className="fill-slate-500 block m-auto" />
      </span>
      {value ? (
        <p className="text-body-500 text-base">{value}</p>
      ) : (
        <Skeleton
          animation="wave"
          component="div"
          height={24}
          width="75%"
          variant="text"
        />
      )}
    </div>
  )
}

const ActivationCodesNiceModal = NiceModal.create<Props>((props) => (
  <ActivationCodesModal {...props} />
))

export function useActivationCodesModal() {
  return useModal(ActivationCodesNiceModal)
}

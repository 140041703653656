import { useState } from "react"

import clsx from "clsx"

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  makeStyles,
} from "@material-ui/core"

import expandPanelIcon from "app/assets/images/expand-panel-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import { CHECKOUT_WARNINGS } from "app/constants"
import { maroon, colors, shadows, primaryColor, navy } from "app/theme"
import { CheckoutWarningTypes, CheckoutWarningSeverities } from "app/types"

interface WarningProps {
  type: CheckoutWarningTypes
  bulletedInfo?: string[] | React.ReactNode[]
  postTitleText?: string
  collapsible?: boolean
}

const WarningAccordion = withStyles({
  root: {
    "&::before": {
      display: "none",
    },
  },
})(Accordion)

const WarningAccordionSummary = withStyles({
  root: {
    height: "37px",
    minHeight: "37px",
  },
  expanded: {
    minHeight: "0px !important",
    margin: "6px 0",
  },
})(AccordionSummary)

const WarningAccordionDetails = withStyles({
  root: {
    padding: "0px 16px 9px",
  },
})(AccordionDetails)

const CheckoutWarning = ({
  type,
  bulletedInfo,
  postTitleText,
  collapsible = true,
}: WarningProps) => {
  const [expanded, setExpanded] = useState(!Boolean(collapsible))
  const handleChange = (event, isExpanded) => {
    if (collapsible) {
      setExpanded(isExpanded)
    }
  }
  const styles = useStyles()

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.wrapperWarning]:
          CHECKOUT_WARNINGS[type]?.severity ===
          CheckoutWarningSeverities.WARNING,
        [styles.wrapperHighlight]:
          CHECKOUT_WARNINGS[type]?.severity ===
          CheckoutWarningSeverities.HIGHLIGHT,
      })}
    >
      <WarningAccordion expanded={expanded} onChange={handleChange}>
        <WarningAccordionSummary
          expandIcon={
            !collapsible ? (
              <></>
            ) : (
              <img
                src={expandPanelIcon}
                alt="Expand Panel Icon"
                width={19}
                height={19}
              />
            )
          }
        >
          <BodyText
            className={clsx(styles.bodyText, {
              [styles.bodyTextWarning]:
                CHECKOUT_WARNINGS[type]?.severity ===
                CheckoutWarningSeverities.WARNING,
              [styles.bodyTextHighlight]:
                CHECKOUT_WARNINGS[type]?.severity ===
                CheckoutWarningSeverities.HIGHLIGHT,
            })}
            size="sm"
            weight="semibold"
          >
            {CHECKOUT_WARNINGS[type].title}
            {postTitleText && ` ${postTitleText}`}
          </BodyText>
        </WarningAccordionSummary>
        <WarningAccordionDetails>
          <BodyText
            className={clsx(styles.bodyText, {
              [styles.bodyTextWarning]:
                CHECKOUT_WARNINGS[type]?.severity ===
                CheckoutWarningSeverities.WARNING,
              [styles.bodyTextHighlight]:
                CHECKOUT_WARNINGS[type]?.severity ===
                CheckoutWarningSeverities.HIGHLIGHT,
            })}
            size="sm"
          >
            {CHECKOUT_WARNINGS[type].warning}
            {bulletedInfo?.length && (
              <ul>
                {bulletedInfo.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
          </BodyText>
        </WarningAccordionDetails>
      </WarningAccordion>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    border: `2px solid ${maroon}`,
    borderRadius: "6px",
    borderWidth: "2px 2px 2px 9px",
    boxSizing: "border-box",
    boxShadow: `${shadows.default}`,
    margin: "18px 0px",
  },
  wrapperWarning: {
    border: `2px solid ${colors.yellow[400]}`,
    borderRadius: "6px",
    borderWidth: "2px 2px 2px 9px",
  },
  wrapperHighlight: {
    border: `2px solid ${primaryColor}`,
    borderRadius: "6px",
    borderWidth: "2px 2px 2px 9px",
    margin: 0,
  },
  bodyText: {
    color: colors.rose[800],
  },
  bodyTextWarning: {
    color: colors.yellow[900],
  },
  bodyTextHighlight: {
    color: navy,
  },
}))

export default CheckoutWarning

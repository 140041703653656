import * as React from "react"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"
import moment from "moment"
import sanitizeHtml from "sanitize-html"

import { Box, Grid, Tab, Tabs, makeStyles, styled } from "@material-ui/core"
import ExpansionPanel from "@material-ui/core/Accordion"
import ExpansionPanelDetails from "@material-ui/core/AccordionDetails"
import ExpansionPanelSummary from "@material-ui/core/AccordionSummary"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import calendarIcon from "app/assets/images/calendar-icon.svg"
import expandPanelIcon from "app/assets/images/expand-panel-icon.svg"
import { NavyTooltip } from "app/components/NavyTooltip"
import BodyText from "app/components/design-system/BodyText"
import ResendConfirmationModal from "app/components/modals/ResendConfirmationModal"
import SendNewKitModal from "app/components/modals/SendNewKitModal/SendNewKitModal"
import { ORDER_EVENT_CATEGORIES, ORDER_STATUS } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { isDebug } from "app/settings"
import { showMessage } from "app/store/actions"
import { colors, navy, primaryColor, shadows } from "app/theme"
import { OrderEventType } from "app/types"
import { getApiBaseUrl, handleApiError, handleApiSuccess } from "app/utils"
import { resendOrderEvent } from "app/utils/order-utils"

import OrderEventAction, { downloadReceipt } from "./OrderEventAction"
import OrderEventNew from "./OrderEventNew"

const OrderEventsExpansionPanel = withStyles({
  root: {
    "&::before": {
      display: "none",
    },
  },
})(ExpansionPanel)

const OrderEventsExpansionPanelSummary = withStyles({
  root: {
    paddingLeft: "0",
  },
  expanded: {
    minHeight: "0 !important",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  content: {
    alignItems: "center",
    paddingBottom: 15,
    paddingTop: 10,
  },
})(ExpansionPanelSummary)

const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)({
  display: "block",
  padding: "8px 0 0 0",
})

const StyledGridContainer = styled(Grid)({
  paddingBottom: 20,
  paddingTop: 20,
  borderTopWidth: 1,
  borderColor: colors.blueGray[300],
})

const StyledBodyText = styled(BodyText)({
  color: navy,
  marginRight: 8,
})

function ExpandButton({ expanded }) {
  const buttonText = expanded ? "Hide" : "Show"
  return (
    <Button className="flex items-center justify-end" style={{ minWidth: 60 }}>
      <img
        src={expandPanelIcon}
        alt=""
        width={16}
        height={16}
        style={{ transform: `rotate(${expanded ? "180" : "0"}deg)` }}
      />
      <Typography
        className="font-semibold ml-1"
        style={{ color: primaryColor }}
      >
        {buttonText}
      </Typography>
    </Button>
  )
}

function OrderEvent({ event }) {
  return (
    <StyledGridContainer container spacing={2}>
      <Grid item sm={12} md={2}>
        <div className="flex items-center">
          <img
            src={calendarIcon}
            alt={"Calendar"}
            width={16}
            height={16}
            className="mr-1"
          />
          <StyledBodyText weight="semibold">
            {moment(event.display_date).format("MMMM DD")}
          </StyledBodyText>
        </div>
      </Grid>
      <Grid item sm={12} md={10}>
        <BodyText
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(event.text, {
              allowedSchemes: ["https"] + (isDebug ? ["localhost"] : []),
            }),
          }}
        ></BodyText>
        <OrderEventAction event={event} />
      </Grid>
    </StyledGridContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  groupedEventsContainer: {
    padding: "12px 23px 23px 23px",
    background: "#FCFDFE", // request from Jordan, not currently in theme
    border: `1px solid ${colors.blueGray[100]}`,
    borderRadius: 6,
    boxShadow: shadows.inset,
  },
  tabsContainer: {
    boxShadow: "inset 0px -1px 0px #E5E7EB",
  },
  emptyText: {
    paddingTop: 11,
    textAlign: "center",
  },
  tabButton: {
    padding: 0,
    marginRight: 70,
    minWidth: 0,
  },
}))

const DEFAULT_ORDER_EVENTS = {
  [ORDER_EVENT_CATEGORIES.SHIPPING_AND_PAYMENT]: [],
  [ORDER_EVENT_CATEGORIES.SAMPLE_COLLECTION_AND_INSTRUCTIONS]: [],
  [ORDER_EVENT_CATEGORIES.RESULTS_IN]: [],
}

const DEFAULT_CONFIRMATION_MODAL_DATA = {
  eventType: "",
  patientName: "",
  resendData: null,
}

const ORDER_EVENT_STRING_REPRESENTATION = {
  [ORDER_EVENT_CATEGORIES.SHIPPING_AND_PAYMENT]: "Shipping & Payment",
  [ORDER_EVENT_CATEGORIES.SAMPLE_COLLECTION_AND_INSTRUCTIONS]:
    "Sample Collection & Instructions",
  [ORDER_EVENT_CATEGORIES.RESULTS_IN]: "Results In",
}

export function OrderEvents({ order, patient, startExpanded = false }) {
  const [showGroupedEvents] = useFeatureFlag(
    FeatureFlag.GroupedOrderEventsAndReminders
  )
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(startExpanded)
  const [currentTab, setCurrentTab] = React.useState(
    ORDER_EVENT_CATEGORIES.SHIPPING_AND_PAYMENT
  )
  const dispatch = useDispatch()
  const [orderEvents, setOrderEvents] = useState(
    showGroupedEvents ? DEFAULT_ORDER_EVENTS : []
  )
  const [orderEventsPending, setOrderEventsPending] = useState(false)
  const [hasFetchedEvents, setHasFetchedEvents] = useState(false)
  const [latestOrderEvent, setLatestOrderEvent] = useState(null)

  // Resend states
  const [showResendKitModal, setShowResendKitModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationModalData, setConfirmationModalData] = useState(
    DEFAULT_CONFIRMATION_MODAL_DATA
  )
  const [currResendEventData, setCurrResendEventData] = useState(null)

  // Call GET endpoint to fetch order events from the API
  const fetchOrderEvents = async (category) => {
    await axios
      .get(
        getApiBaseUrl() +
          `/api/order/${order.id}/event${
            category ? `?category=${category}` : ""
          }`
      )
      .then((response) => {
        // V2 of order events w/ grouping
        if (showGroupedEvents) {
          setOrderEvents((prevState) => ({
            ...prevState,
            [category]: response.data.results,
          }))
        } else {
          setOrderEvents(response.data.results)
        }
      })
      .catch((error) => {
        dispatch(handleApiError(error))
        setOrderEventsPending(false)
      })
  }

  // Set the current tab to the event category with the latest parent event
  const setToLatestTab = (category) => {
    if (orderEvents[category].length) {
      const latestDate = orderEvents[category].reduce((a, b) =>
        Date(a.display_date) > Date(b.display_date) ? a : b
      ).display_date

      if (!latestOrderEvent || latestDate > latestOrderEvent) {
        setLatestOrderEvent(latestDate)
        setCurrentTab(category)
      }
    }
  }

  // Handle the process of getting order events to populate order event section of order
  const getOrderEvents = async () => {
    setOrderEventsPending(true)

    // order events v2 flag; order event grouping
    if (showGroupedEvents) {
      const categories = Object.keys(orderEvents)
      const fetchPromises = categories.map(
        async (category) => await fetchOrderEvents(category)
      )
      await Promise.all(fetchPromises)
      setOrderEventsPending(false)
      setHasFetchedEvents(true)
    } else {
      fetchOrderEvents()
      setOrderEventsPending(false)
      setHasFetchedEvents(true)
    }
  }

  // Handle toggling of order events section. If expanding for the first time, fetch events
  const handleOrderEventSectionToggle = (event, isExpanded) => {
    if (isExpanded && !hasFetchedEvents) {
      getOrderEvents()
    }
    setExpanded(isExpanded)
  }

  useEffect(() => {
    // Need to get order events in v1; v2 grouped events auto fetched on expansion of section
    getOrderEvents()
  }, [order])

  // Once all events have been fetched, set to the tab with the latest parent event
  useEffect(() => {
    if (hasFetchedEvents) {
      const categories = Object.keys(orderEvents)
      // After fetching all events, show the tab with the latest parent event
      categories.forEach((cat) => {
        setToLatestTab(cat)
      })
    }
  }, [hasFetchedEvents])

  // Non-grouped events show empty div if there are no events
  if (orderEventsPending && !showGroupedEvents) {
    return (
      <div className="flex p-16 justify-center w-full">
        <CircularProgress aria-label="Loading order events" />
      </div>
    )
  } else if ((!orderEvents || orderEvents.length === 0) && !showGroupedEvents) {
    return <div />
  }

  // Handle changing of tabs in v2 grouped events
  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab)

    if (!orderEvents[newTab].length) {
      getOrderEvents()
    }
  }

  // v2 grouped events orderEvents state has at least one event stored
  const hasAtLeastOneEvent = () => {
    for (const key in orderEvents) {
      if (orderEvents[key].length) {
        return true
      }
    }

    return false
  }

  const downloadInvoice = async (event) => {
    try {
      await downloadReceipt(event)
    } catch (error) {
      dispatch(
        showMessage({
          message: "Unable to open the receipt. Please try again later.",
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        })
      )
    }
  }

  // Called when a resend action button is clicked; opens the appropriate resend modal
  const handleResendEventTrigger = async (event, eventType) => {
    await setCurrResendEventData({
      event: event,
      type: eventType,
    })

    setConfirmationModalData({
      eventType: eventType,
      patientName: patient.first_name,
      resendData: null,
    })

    if (eventType === OrderEventType.WEBHOOK) {
      setShowConfirmationModal(true)
    } else if (eventType === OrderEventType.KIT) {
      setShowResendKitModal(true)
    } else if (eventType === OrderEventType.DOWNLOAD) {
      downloadInvoice(event)
    } else {
      setShowConfirmationModal(true)
    }
  }

  // Sets data for successful resending of kit
  const handleResendKit = (data) => {
    setConfirmationModalData({
      ...confirmationModalData,
      resendData: data,
    })
    setShowResendKitModal(false)
    setShowConfirmationModal(true)
  }

  // Sends API request to handle resend order event
  const handleResend = async () => {
    await resendOrderEvent(
      currResendEventData.event.id,
      confirmationModalData.resendData
    )
      .then(() => {
        if (currResendEventData.type === OrderEventType.WEBHOOK) {
          dispatch(handleApiSuccess("Successfully resent event to EHR"))
        } else {
          dispatch(
            handleApiSuccess(
              `Successfully resent ${currResendEventData.type.toLowerCase()} to ${
                patient.first_name
              }`
            )
          )
        }
      })
      .catch((error) => {
        dispatch(handleApiError(error))
      })

    setShowConfirmationModal(false)
  }

  // Feature flag for order events v2 w/ grouped order events
  if (showGroupedEvents && order.status !== ORDER_STATUS.DRAFT) {
    return (
      <>
        {hasAtLeastOneEvent() && (
          <>
            <Divider className="my-2" />
            <OrderEventsExpansionPanelSummary>
              <Typography color="textPrimary" variant="h5">
                Order Events
              </Typography>
            </OrderEventsExpansionPanelSummary>

            <div className={classes.groupedEventsContainer}>
              <Box>
                {orderEventsPending && (
                  <div className="flex p-4 justify-center w-full">
                    <CircularProgress aria-label="Loading order events" />
                  </div>
                )}
              </Box>
              {!orderEventsPending ? (
                <div>
                  <Box className={classes.tabsContainer}>
                    <Tabs
                      value={currentTab}
                      onChange={handleTabChange}
                      textColor="primary"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: primaryColor,
                        },
                      }}
                      aria-label="secondary tabs example"
                      variant="scrollable"
                    >
                      {Object.keys(ORDER_EVENT_CATEGORIES).map(
                        (orderEventCategory) => (
                          <Tab
                            value={orderEventCategory}
                            disabled={!orderEvents[orderEventCategory].length}
                            label={
                              <NavyTooltip
                                arrow
                                placement="top"
                                title={
                                  !orderEvents[orderEventCategory].length
                                    ? "No events yet for this category"
                                    : ""
                                }
                              >
                                <span>
                                  {
                                    ORDER_EVENT_STRING_REPRESENTATION[
                                      orderEventCategory
                                    ]
                                  }
                                </span>
                              </NavyTooltip>
                            }
                            style={{
                              pointerEvents: "auto",
                              cursor: !orderEvents[orderEventCategory].length
                                ? "not-allowed"
                                : "pointer",
                            }}
                            className={classes.tabButton}
                            key={orderEventCategory}
                          />
                        )
                      )}
                    </Tabs>
                  </Box>
                  <Box>
                    <StyledExpansionPanelDetails>
                      {orderEvents[currentTab] &&
                        orderEvents[currentTab].map((event, i) => (
                          <OrderEventNew
                            order={order}
                            event={event}
                            key={event.id}
                            onResend={handleResendEventTrigger}
                          />
                        ))}
                    </StyledExpansionPanelDetails>
                  </Box>
                </div>
              ) : (
                <div className={classes.emptyText}>
                  {!orderEventsPending &&
                    "No order events for this category yet."}
                </div>
              )}
            </div>
          </>
        )}
        <SendNewKitModal
          open={showResendKitModal}
          labTestName="Lab Test"
          patient={patient}
          resendFn={handleResendKit}
          onClose={() => setShowResendKitModal(false)}
        />
        <ResendConfirmationModal
          open={showConfirmationModal}
          data={confirmationModalData}
          onClose={() => setShowConfirmationModal(false)}
          resendFn={handleResend}
        />
      </>
    )
  } else if (order.status === ORDER_STATUS.DRAFT) {
    return <div />
  }

  return (
    <OrderEventsExpansionPanel
      expanded={expanded}
      onChange={handleOrderEventSectionToggle}
      elevation={0}
    >
      <OrderEventsExpansionPanelSummary>
        <StyledBodyText weight="semibold" size="md">
          {orderEvents.length > 1 ? orderEvents.length : ""} Order Events
        </StyledBodyText>
        <ExpandButton expanded={expanded} />
      </OrderEventsExpansionPanelSummary>
      <StyledExpansionPanelDetails>
        {orderEvents &&
          orderEvents.map((event) => (
            <OrderEvent event={event} key={event.id} />
          ))}
      </StyledExpansionPanelDetails>
    </OrderEventsExpansionPanel>
  )
}

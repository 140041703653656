import { ReactNode, useEffect, useState } from "react"

import clsx from "clsx"

import { Container, Divider, Grid, Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { ReactComponent as LeftArrow } from "app/assets/icons/left-arrow.svg"
import { ReactComponent as PatientPortalClipboard } from "app/assets/icons/patient-portal/clipboard.svg"
import { ReactComponent as PatientPortalCube } from "app/assets/icons/patient-portal/cube.svg"
import { ReactComponent as PatientPortalHistogram } from "app/assets/icons/patient-portal/histogram.svg"
import authService from "app/auth/services/simplejwt-auth-service"
import Button from "app/components/design-system/Button"
import { orderHasInHousePhelbTests } from "app/dataServices/labTestDataService"
import { isLineItemActive } from "app/dataServices/orderDataService"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import NavBar from "app/main/patient-checkout/NavBar"
import { PHLEBOTOMY_BOOKING_FROM_LOCATIONS } from "app/main/schedule-blood-draw/constants"
import { attachFromLocationToUrl } from "app/main/schedule-blood-draw/utils"
import { buildLoginUrl, buildPatientPortalUrl } from "app/patient-portal/util"
import {
  trackPhlebotomyBookingEvent,
  PHLEBOTOMY_BOOKING_EVENTS,
} from "app/services/segment"
import { marketingSite } from "app/settings"
import withReducer from "app/store/withReducer"
import { colors, primaryColor } from "app/theme"
import { PatientPortalOrder } from "app/types"
import { PHLEBOTOMY_PROVIDERS } from "app/types"

import { useConfetti } from "../onboarding/confetti/confetti-provider"
import PatientSignup from "../patient-signup/PatientSignup"
import reducer from "./store/reducers"

const useStyles = makeStyles((theme) => ({
  accountButton: {
    width: "100%",
    alignSelf: "center",
    marginBottom: 10,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
  },
  patientSignup: {
    textAlign: "left",
    marginTop: 30,
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  nextSteps: {
    maxWidth: 350,
    alignSelf: "center",
    margin: "10px 0px",
  },
  createAccount: {
    marginTop: 20,
  },
  divider: {
    marginTop: 15,
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 5px",
    gap: 10,
  },
  iconTitle: {
    fontWeight: 600,
    fontSize: 14,
  },
  link: {
    fontWeight: 600,
    color: primaryColor,
  },
  clickable: {
    cursor: "pointer",
    fontWeight: 600,
  },
  icon: {
    marginRight: 5,
  },
  nextStepBannerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "6px",
    border: `1px solid ${colors.blueGray[50]}`,
    background: colors.blueGray[50],
    color: colors.lightBlue[900],
    padding: "12px 16px",
  },
  nextStepTitle: {
    fontWeight: 600,
    fontSize: 16,
  },
  nextStepText: {
    textAlign: "center",
  },
}))

function CheckoutSuccess({ order }: { order: PatientPortalOrder }) {
  const classes = useStyles()
  const hasActiveTests = order.line_items
    .filter((lineItem) => lineItem.ordered_test)
    .some(isLineItemActive)

  const [patientPortalFlagEnabled] = useFeatureFlag(FeatureFlag.PatientPortal)
  const [axlePhlebotomyFlagEnabled] = useFeatureFlag(FeatureFlag.AxlePhlebotomy)
  const isAuthenticated = authService.isAuthenticated()
  const triggerConfetti = useConfetti()

  useEffect(() => {
    // Make it rain confetti when patient checks out.
    triggerConfetti()
  }, [])

  const phlebotomyBookingUrl =
    axlePhlebotomyFlagEnabled && order.phlebotomy_booking_url
      ? attachFromLocationToUrl(
          order.phlebotomy_booking_url,
          PHLEBOTOMY_BOOKING_FROM_LOCATIONS.PATIENT_CHECKOUT_SUCCESS
        )
      : null

  const email = order.patient.user.email
  // workaround allowing us to roll this out to Rupa employees
  // without the feature flag being turned on for everyone
  // TODO https://app.asana.com/0/1205660765244454/1205838782571187/f: remove this workaround
  const patientPortalEnabled =
    order.patient.user.email.endsWith("@rupahealth.com") ||
    patientPortalFlagEnabled
  const next = buildPatientPortalUrl(order.patient.id.toString(), order.id)
  const loginUrl = buildLoginUrl(
    "patient",
    email,
    phlebotomyBookingUrl ? phlebotomyBookingUrl : next
  )

  const showSignup =
    patientPortalEnabled &&
    !isAuthenticated &&
    !order.patient.user.has_password_set
  const showGoToAccount = patientPortalEnabled && isAuthenticated
  const showLogin =
    patientPortalEnabled &&
    !isAuthenticated &&
    order.patient.user.has_password_set

  const titleText = order.requires_vendor_physician_authorization
    ? "Order Submitted 🎊"
    : "Order Confirmed 🎊"

  const nextSteps = (
    <>
      <Typography
        align="center"
        color="textPrimary"
        className={clsx("mt-4", classes.nextSteps)}
      >
        {/* Initial next steps instructions */}
        {hasActiveTests &&
          (patientPortalEnabled
            ? `A confirmation email has been sent to ${email}.`
            : "Look out for an email coming from Rupa team soon with instructions and cheat sheets for completing your tests.")}
        <br />
      </Typography>
      {orderHasInHousePhelbTests(order) ? (
        <div className={classes.nextStepBannerContainer}>
          <div className={classes.nextStepTitle}>Next step...</div>
          <div className={classes.nextStepText}>
            Please wait for an email from Rupa with a link to schedule your
            draw!
          </div>
        </div>
      ) : null}
    </>
  )

  if (phlebotomyBookingUrl && !showSignup) {
    return (
      <MobilePhlebotomyCheckoutSuccess
        phlebotomyBookingUrl={phlebotomyBookingUrl}
        loginUrl={showGoToAccount ? next : loginUrl}
        titleText={titleText}
        nextSteps={nextSteps}
        patientPortalFlagEnabled={patientPortalFlagEnabled}
      />
    )
  }

  return (
    <div>
      <NavBar />
      <Container maxWidth="sm">
        <div
          className={clsx(
            "p-6 md:p-16 mt-8 shadow bg-white rounded-lg",
            classes.container
          )}
        >
          <Typography align="center" variant="h4" color="textPrimary">
            {titleText}
          </Typography>
          {nextSteps}
          {patientPortalEnabled && <Divider className={classes.divider} />}
          {/* for login and go to account options, give this copy */}
          {(showLogin || showGoToAccount) && (
            <Typography
              align="center"
              color="textPrimary"
              className={clsx("mt-4", classes.nextSteps)}
            >
              You can check your order status and access results, sample updates
              & more in your account.
              <br />
              <br />
            </Typography>
          )}
          {/* Go to account button */}
          {showGoToAccount && (
            <Button
              color="secondary"
              size="small"
              className={classes.accountButton}
              href={next}
            >
              Go to Account
            </Button>
          )}
          {/* Log in button */}
          {showLogin && (
            <Button
              color="secondary"
              size="small"
              className={classes.accountButton}
              href={loginUrl}
            >
              Log In
            </Button>
          )}
          {/* If the patient does not have an account, display a sign up option */}
          {showSignup && (
            <>
              <Typography
                align="center"
                color="textPrimary"
                variant="h5"
                className={classes.createAccount}
              >
                Create a patient account for all your order needs!
              </Typography>
              <Typography
                align="center"
                color="textPrimary"
                className={clsx("mt-4", classes.nextSteps)}
              >
                Get access to important information like:
              </Typography>
              <Grid container>
                <PatientAccountIcon
                  icon={<PatientPortalCube />}
                  title="Status of your current lab orders"
                />
                <PatientAccountIcon
                  icon={<PatientPortalClipboard />}
                  title="Instructions on the testing process"
                />
                <PatientAccountIcon
                  icon={<PatientPortalHistogram />}
                  title="Real-time updates on your test results"
                />
              </Grid>
              {phlebotomyBookingUrl && (
                <div>
                  <br />
                  Or do this later and{" "}
                  <a
                    href={phlebotomyBookingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    book your mobile blood draw now.
                  </a>
                  <br />
                </div>
              )}
              <div className={classes.patientSignup}>
                <PatientSignup
                  defaultEmail={order.patient.user.email}
                  defaultPatientPortalToken={order.patient_portal_token}
                  defaultNext={next}
                />
              </div>
            </>
          )}
          {/* If not sign up, then follow with this footer */}
          {!showSignup && (
            <>
              <Typography
                align="center"
                style={{ fontSize: 16 }}
                className="mt-5 font-semibold"
              >
                <Link color="primary" href={marketingSite + "/for-patients"}>
                  Learn more about Rupa Health
                </Link>
              </Typography>
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default withReducer("patientCheckout", reducer)(CheckoutSuccess)

function PatientAccountIcon({
  icon,
  title,
}: {
  icon: ReactNode
  title: string
}) {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={4} md={4} className={classes.iconContainer}>
      {icon}
      <Typography
        color="textPrimary"
        align="center"
        className={classes.iconTitle}
      >
        {title}
      </Typography>
    </Grid>
  )
}

function MobilePhlebotomyCheckoutSuccess({
  phlebotomyBookingUrl,
  loginUrl,
  titleText,
  nextSteps,
  patientPortalFlagEnabled,
}: {
  phlebotomyBookingUrl: string
  loginUrl: string
  titleText: string
  nextSteps: ReactNode
  patientPortalFlagEnabled: boolean
}) {
  const classes = useStyles()

  const [isDoLaterSelected, setIsDoLaterSelected] = useState(false)

  let content = <></>
  if (!isDoLaterSelected) {
    content = (
      <MobilePhlebotomyCheckoutSuccessMainPage
        phlebotomyBookingUrl={phlebotomyBookingUrl}
        titleText={titleText}
        nextSteps={nextSteps}
        onDoLaterSelected={() => setIsDoLaterSelected(true)}
      />
    )
  } else {
    content = (
      <MobilePhlebotomyCheckoutSuccessDoLater
        loginUrl={loginUrl}
        onGoBackSelected={() => setIsDoLaterSelected(false)}
        patientPortalFlagEnabled={patientPortalFlagEnabled}
      />
    )
  }

  return (
    <div>
      <NavBar />
      <Container maxWidth="sm">
        <div
          className={clsx(
            "p-6 md:p-16 mt-8 shadow bg-white rounded-8",
            classes.container
          )}
        >
          {content}
        </div>
      </Container>
    </div>
  )
}

function MobilePhlebotomyCheckoutSuccessMainPage({
  phlebotomyBookingUrl,
  titleText,
  nextSteps,
  onDoLaterSelected,
}: {
  phlebotomyBookingUrl: string
  titleText: string
  nextSteps: ReactNode
  onDoLaterSelected: () => void
}) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography align="center" variant="h4" color="textPrimary">
        {titleText}
      </Typography>
      {nextSteps}
      <Divider className={classes.divider} />
      <br />
      <br />
      <Typography align="center" variant="h5" color="textPrimary">
        Next, schedule your mobile blood draw!
      </Typography>
      <br />
      <Button
        color="primary"
        size="small"
        className={classes.accountButton}
        href={phlebotomyBookingUrl}
        onClick={() => {
          trackPhlebotomyBookingEvent(
            PHLEBOTOMY_BOOKING_EVENTS.PHLEBOTOMY_SCHEDULE_APPOINTMENT_CLICKED,
            { provider: PHLEBOTOMY_PROVIDERS.AXLE }
          )
        }}
      >
        Schedule Appointment
      </Button>
      <Typography
        align="center"
        color="textPrimary"
        className={classes.clickable}
        onClick={onDoLaterSelected}
      >
        I'll do this later
      </Typography>
    </div>
  )
}

function MobilePhlebotomyCheckoutSuccessDoLater({
  loginUrl,
  onGoBackSelected,
  patientPortalFlagEnabled,
}: {
  loginUrl: string
  onGoBackSelected: () => void
  patientPortalFlagEnabled: boolean
}) {
  const classes = useStyles()

  const titleText = patientPortalFlagEnabled
    ? "Book your mobile blood draw anytime in your Patient Portal"
    : "You'll receive reminder emails to book your mobile blood draw"

  const mainContent =
    "Don’t have time to book an appointment now? No problem! " +
    (patientPortalFlagEnabled
      ? "Sign into your portal anytime to book your mobile blood draw, get access to your results, sample updates & more!"
      : "You'll receive reminder emails to book your appointment.")

  return (
    <div className={classes.container}>
      <Typography
        align="center"
        color="textPrimary"
        className={clsx(classes.clickable, classes.link)}
        onClick={onGoBackSelected}
      >
        <LeftArrow fill={primaryColor} className={classes.icon} />
        Go Back
      </Typography>
      <br />
      <Typography align="center" variant="h4" color="textPrimary">
        {titleText}
      </Typography>
      <br />
      <Typography
        align="center"
        color="textPrimary"
        className={classes.nextSteps}
      >
        {mainContent}
      </Typography>
      {patientPortalFlagEnabled && (
        <Button
          color="secondary"
          size="small"
          className={classes.accountButton}
          href={loginUrl}
        >
          Log In
        </Button>
      )}
    </div>
  )
}

import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  Grid,
  makeStyles,
  styled,
  Theme,
  useMediaQuery,
} from "@material-ui/core"

import { UserPaths } from "app/Routes"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import PhysicianAuthorizationSettings from "app/main/settings/components/PhysicianAuthorizationSettings"
import { physicianAuthorizationApplicationPageEvents } from "app/services/segment"
import { trackEventWithProperties } from "app/services/segment.typed"
import { updatePersonalSettings } from "app/store/actions"
import { colors, navy, primaryColor } from "app/theme"
import { Practitioner, RootState } from "app/types"

import SideNav from "./SideNav"
import { PersonalInformation } from "./components/PersonalInformation"
import PersonalPreferences from "./components/PersonalPreferences"
import Separator from "./components/Separator"

const useStyles = makeStyles((theme: Theme) => ({
  navyText: {
    color: navy,
  },
  styledLink: {
    color: primaryColor,
    fontWeight: 600,
    fontSize: 14,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 4,
    },
  },
  learnMoreArrow: {
    width: 10,
  },
  requestKitsButton: {
    marginTop: 12,
    marginLeft: 44,
  },
}))

const Wrapper = styled("div")({
  background: colors.coolGray[50],
  border: `1px solid ${colors.coolGray[200]}`,
  padding: 24,
  minHeight: "100vh",
})

function PersonalSettings({
  shouldOpenPhysicianServicesEnableModalOnLoad = false,
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const practitioner = useSelector<RootState, Practitioner>(
    ({ practitioner }) => practitioner
  )
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  useEffect(() => {
    if (practitioner.id) {
      trackEventWithProperties(
        physicianAuthorizationApplicationPageEvents.VISIT_SETTINGS,
        {
          practitioner_id: practitioner?.id,
          clinic_id: practitioner?.clinic?.id,
        }
      )
    }
  }, [practitioner.id])

  const handleToggleChange = (key: string) => {
    dispatch(
      updatePersonalSettings(
        {
          [key]: !practitioner[key],
        },
        true
      )
    )
  }

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },
            {
              label: "Personal Settings",
            },
          ]}
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title="Personal Settings"
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <Wrapper>
            <PersonalInformation practitioner={practitioner} />

            <Separator />
            <>
              <PhysicianAuthorizationSettings
                handleToggle={handleToggleChange}
                shouldOpenPhysicianServicesEnableModalOnLoad={
                  shouldOpenPhysicianServicesEnableModalOnLoad
                }
              />
              <Separator />
            </>
            <PersonalPreferences
              classes={classes}
              handleToggle={handleToggleChange}
            />
          </Wrapper>
        </Grid>
      </Grid>
    </>
  )
}

export default PersonalSettings

import { faSquareInfo } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@rupahealth/design"

import useResultsOverTimeLegendModal from "../hooks/use-results-over-time-legend-modal"

const ResultsOverTimeLegendButton = () => {
  const resultsOverTimeLegendModal = useResultsOverTimeLegendModal()

  return (
    <Button
      variant={null}
      className="text-primary max-md:px-0 max-md:justify-start hover:bg-slate-100 py-1 px-2 h-auto text-[15px]"
      onClick={() => {
        resultsOverTimeLegendModal.show({
          onClose: () => resultsOverTimeLegendModal.remove(),
        })
      }}
    >
      <FontAwesomeIcon className="mr-2 text-lg" icon={faSquareInfo} /> Legend
    </Button>
  )
}

export default ResultsOverTimeLegendButton

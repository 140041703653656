import KitShipmentTrackingDescription from "app/components/StatusComponents/KitShipmentTrackingDescription"
import {
  LABCORP_COMPANY_KEYS,
  LAB_COMPANY_KEY,
  QUEST_COMPANY_KEYS,
} from "app/constants"
import { ORDERED_TEST_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-portal-types"
import { OrderedTestStatuses } from "../../types/types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({ orderedTest, labCompany }: StatusConfigurationProps) => {
  if (
    labCompany &&
    labCompany.attributes.key ===
      LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS &&
    !Boolean(orderedTest.attributes.requisition)
  ) {
    return <>Requisition is Processing</>
  } else if (
    labCompany &&
    (LABCORP_COMPANY_KEYS.includes(labCompany.attributes.key) ||
      QUEST_COMPANY_KEYS.includes(labCompany.attributes.key))
  ) {
    return <>Requisition Ready</>
  } else if (orderedTest.attributes.instant_requisition) {
    return <span>Requisition is Ready</span>
  } else {
    return <span>Kit Shipped to You</span>
  }
}

const SubTitle = ({ orderedTest, labCompany }: StatusConfigurationProps) => {
  if (!labCompany) return null
  const labcorp = LABCORP_COMPANY_KEYS.includes(labCompany?.attributes.key)
  const quest = QUEST_COMPANY_KEYS.includes(labCompany?.attributes.key)
  if (
    labCompany?.attributes.key ===
      LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS &&
    !Boolean(orderedTest.attributes.requisition)
  ) {
    return (
      <span>
        Your requisition form can be downloaded from this page once it's ready.
      </span>
    )
  } else if (labcorp || quest) {
    return (
      <span>
        Print out your requisition form and schedule your appointment{" "}
        <a
          className="text-body font-semibold underline"
          href={
            labcorp
              ? "https://www.labcorp.com/labs-and-appointments"
              : "https://www.questdiagnostics.com/locations/search"
          }
        >
          here
        </a>
        .
      </span>
    )
  } else if (!orderedTest.attributes.instant_requisition) {
    return (
      <KitShipmentTrackingDescription
        estimated_arrival_date={orderedTest.attributes.estimated_arrival_date}
        shipment_tracking_link={orderedTest.attributes.shipment_tracking_link}
        lab_company_notifies_sample_at_lab={
          labCompany.attributes.notifies_sample_at_lab
        }
        lab_company_short_name={labCompany.attributes.short_name}
      />
    )
  }
  return null
}

const orderedWithLabStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: ({
      orderedTestStatus,
      labCompanyNotifiesSampleAtLab,
    }: OrderedTestStatuses) =>
      orderedTestStatus === ORDERED_TEST_STATUS.ORDERED_WITH_LAB ||
      (orderedTestStatus === ORDERED_TEST_STATUS.AT_LAB &&
        !labCompanyNotifiesSampleAtLab),
    progressPercentage: PROGRESS_PERCENTAGES.ORDERED_WITH_LAB,
    title: Title,
    subTitle: SubTitle,
  }

export default orderedWithLabStatusConfiguration

import * as React from "react"
import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"

import { MTable, MTablePagination } from "material-table"
import { useHistory, useLocation } from "react-router-dom"

import EmptyPatientOrdersImage from "app/assets/images/empty-patient-orders.svg"
import Loading from "app/components/Loading"
import PageEmptyState from "app/components/PageEmptyState"
import BodyText from "app/components/design-system/BodyText"
import { ORDER_STATUS } from "app/constants"
import useQuery from "app/hooks/use-query"
import useAppSelector from "app/hooks/useAppSelector"
import { Patient } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import PatientOrdersOrder from "./PatientOrdersOrder"
import * as Actions from "./store/actions"

const useStyles = makeAppStyles({
  container: {
    display: "flex",
    flexFlow: "column",
    marginBottom: 24,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 14,
  },
  paginationControls: {
    alignSelf: "flex-end",
    justifySelf: "flex-end",

    // Reverses the margin that the MuiTable pagination already has
    marginBottom: -9,
  },
})

function useEnsurePageIsSynced(
  orderId: string | null,
  page: number,
  showDrafts: boolean,
  offset?: number
) {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (!offset || !orderId) {
      return
    }

    const expectedPage =
      Math.floor(offset / Actions.PATIENT_ORDERS_PAGE_SIZE) + 1
    if (expectedPage !== page) {
      let query = new URLSearchParams(location.search)

      query.append("page", expectedPage.toString())

      if (showDrafts) {
        query.append("status", ORDER_STATUS.DRAFT)
      }

      history.replace(`${location.pathname}?${query.toString()}`)
    }
  }, [page, offset])
}

function useOrderListTitle() {
  const ordersPage = useAppSelector(({ patient }) => patient.orders)
  const scheduledOrdersPage = useAppSelector(
    ({ patient }) => patient.scheduledOrders
  )

  const isPaginated =
    (ordersPage?.count || 0) > Actions.PATIENT_ORDERS_PAGE_SIZE
  const hasScheduledOrders = Boolean(scheduledOrdersPage?.results?.length)

  if (hasScheduledOrders) {
    return "Placed Orders"
  } else if (isPaginated) {
    return "Orders"
  }

  return null
}

const PatientOrdersList = ({
  showDrafts,
  patient,
}: {
  showDrafts: boolean
  patient: Patient
}) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const styles = useStyles()
  const query = useQuery()

  const ordersPage = useAppSelector(({ patient }) => patient.orders)
  const page = query.get("page") || "1"
  const orderId = query.get("orderId")
  let cleanedPage = parseInt(page, 10)
  cleanedPage = isNaN(cleanedPage) ? 0 : cleanedPage
  const title = useOrderListTitle()

  const handleChangePage = useCallback(
    (_: Event, page: number) => {
      let path = location.pathname
      const urlParams = new URLSearchParams(location.search)

      if (page) {
        urlParams.set("page", (page + 1).toString())
      } else {
        urlParams.delete("page")
      }

      const query = urlParams.toString()
      if (query) {
        path = `${path}?${query}`
      }

      history.push(path)
    },
    [location]
  )

  // Keep the page query param synced with the data we have
  useEnsurePageIsSynced(orderId, cleanedPage, showDrafts, ordersPage?.offset)

  // Load orders whenever the page or showDrafts changes
  useEffect(() => {
    dispatch(
      Actions.getOrdersByPatient({
        patientId: patient.id,
        page: cleanedPage,
        showDrafts,
        orderId,
      })
    )

    return () => {
      dispatch(Actions.clearOrders())
    }
  }, [patient.id, dispatch, cleanedPage, showDrafts])

  if (!ordersPage) {
    return <Loading ariaLabel="Loading orders" />
  }

  const { results: orders, count, next } = ordersPage

  if (orders.length === 0) {
    return (
      <PageEmptyState
        title={`${patient.first_name} doesn’t have any submitted orders yet.`}
        subtitle={
          showDrafts
            ? `Are you looking for a submitted order? Click View Submitted to see ${patient.first_name}’s orders.`
            : `Are you looking for a draft order? Click View Drafts to see ${patient.first_name}’s drafts.`
        }
        image={EmptyPatientOrdersImage}
      />
    )
  }

  let paginationControls: React.ReactNode = null
  const showPagination = next || cleanedPage > 1

  if (showPagination) {
    paginationControls = (
      <div className={styles.paginationControls}>
        <MTablePagination
          count={count}
          rowsPerPage={Actions.PATIENT_ORDERS_PAGE_SIZE}
          page={cleanedPage - 1}
          onChangePage={handleChangePage}
          // @ts-ignore
          icons={MTable.defaultProps.icons}
          showFirstLastPageButtons={false}
        />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {Boolean(title || paginationControls) && (
        <div className={styles.header}>
          <BodyText size="md" weight="semibold">
            {title}
          </BodyText>
          {paginationControls}
        </div>
      )}
      {orders.map((order, index) => (
        <PatientOrdersOrder key={order.id} order={order} orderIndex={index} />
      ))}
      {paginationControls}
    </div>
  )
}

export default PatientOrdersList

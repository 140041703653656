import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { FormProvider, useController, useForm } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"
import { Grid, Dialog, Paper, makeStyles } from "@material-ui/core"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"

import CloseIcon from "app/assets/icons/close-icon-navy.svg"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { showMessage } from "app/store/actions"
import resourceRequest from "app/swr/utils/resource-request"
import { colors, navy } from "app/theme"

import {
  ControlledTextField,
  ControlledSelectField,
} from "../patient-checkout/fields"
import { PractitionerProfileService } from "./types"
import {
  CONNECTION_TYPE_EMAIL,
  CONNECTION_TYPE_PHONE_NUMBER,
  CONNECTION_TYPE_WEBSITE_URL,
  emailPassesRegex,
  getConnectionType,
  urlPassesRegex,
} from "./utils"
import { ConnectionType } from "./utils"

const styles = (theme) => ({
  contentWrapper: {
    padding: theme.spacing(2),
    backgroundColor: colors.coolGray[100],
  },
  formWrapper: {
    padding: theme.spacing(1.5),
  },
  dialogWrapper: {
    "& .MuiDialog-paper": {
      border: `1px solid ${colors.blueGray[300]}`,
      margin: "8px",
      borderRadius: 8,
    },
  },
  dialogTitle: {
    borderBottom: `1px solid ${colors.blueGray[300]}`,
  },
  dialogPaper: {
    margin: 15,
    borderRadius: 8,
    maxWidth: 640,
  },
  selectFormField: {},
  titleContainer: {
    alignItems: "center",
    display: "flex",
    "text-align": "center",
    margin: "2px 2px 0",
    justifyContent: "space-between",
  },
  teamMemberContainer: {
    alignItems: "center",
    background: colors.blueGray[50],
    borderRadius: 12,
    color: colors.blueGray[500],
    display: "flex",
    "flex-direction": "column",
    padding: "33px 20px",
    margin: "0px 50px 50px 50px",
    "text-align": "center",
    "& > *": {
      margin: "5px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px 45px 0px",
    },
  },
  teamMemberListContainer: {
    display: "flex",
    "flex-direction": "row",
    "& > img": {
      marginRight: "5px",
    },
    marginBottom: "9px",
  },
  actionContainer: {
    alignItems: "center",
    background: colors.blueGray[50],
    borderTop: `1px solid ${colors.blueGray[300]}`,
    padding: "15px 22px",
    [theme.breakpoints.down("sm")]: {
      "& > * > *": {
        width: "100%",
      },
    },
  },
  fieldSelect: {},
  noSelection: {
    // Placeholder text color
    "& .MuiSelect-select.MuiSelect-select": {
      color: navy,
      opacity: 0.5,
    },
  },
  divider: {
    margin: "10px 0px",
    width: "100%",
  },
  closeIcon: {
    cursor: "pointer",
  },
})

const useStyles = makeStyles(styles)

const TitleSection = ({ title, handleClose }) => {
  const classes = useStyles()

  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle}>
      <div className={classes.titleContainer}>
        <DisplayText weight="semibold" size="xl">
          {title}
        </DisplayText>
        <img
          src={CloseIcon}
          className={classes.closeIcon}
          alt="close icon"
          onClick={handleClose}
        />
      </div>
    </MuiDialogTitle>
  )
}

const AdditionalFormField = ({ displayType, selectedType }) => {
  if (displayType !== selectedType || !selectedType) {
    return null
  }

  if (displayType === CONNECTION_TYPE_WEBSITE_URL) {
    return (
      <ControlledTextField
        required
        aria-label="Website URL"
        fullWidth
        label="Website URL"
        name="websiteUrl"
      />
    )
  }

  if (displayType === CONNECTION_TYPE_PHONE_NUMBER) {
    return (
      <ControlledTextField
        required
        aria-label="Phone Number"
        fullWidth
        label="Phone Number"
        name="phoneNumber"
      />
    )
  }

  if (displayType === CONNECTION_TYPE_EMAIL) {
    return (
      <ControlledTextField
        required
        aria-label="Email"
        fullWidth
        label="Email"
        name="email"
      />
    )
  }

  return null
}

const EditServiceFields = ({
  connectionTypeOptions,
  serviceTypeOptions,
  connectionEnumType,
  methods,
}) => {
  const classes = useStyles()

  const { field: serviceTypeField } = useController({
    control: methods.control,
    name: "serviceType",
    shouldUnregister: true,
  })

  const { field: connectionTypeField } = useController({
    control: methods.control,
    name: "connectionType",
    shouldUnregister: true,
  })

  return (
    <MuiDialogContent className={classes.contentWrapper}>
      <Paper className={classes.formWrapper}>
        <FormProvider {...methods}>
          <form>
            <Grid container spacing={3} style={{ padding: "5px" }}>
              <Grid item xs={12}>
                <ControlledSelectField
                  className={
                    !serviceTypeField.value ? classes.noSelection : undefined
                  }
                  aria-label="Service Type"
                  name="serviceType"
                  fullWidth
                  placeholder="Select In-Person Visit, 1:1 Video Visit, Course, etc"
                  required
                  label="Service Type"
                >
                  {serviceTypeOptions
                    .map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.attributes.field_name}
                      </option>
                    ))
                    .reverse()}
                </ControlledSelectField>
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  aria-label="Title"
                  required
                  fullWidth
                  label="Title"
                  name="title"
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  aria-label="Short Description"
                  required
                  fullWidth
                  label="Short Description"
                  name="shortDescription"
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledSelectField
                  className={
                    !connectionTypeField.value ? classes.noSelection : undefined
                  }
                  aria-label="How to Connect"
                  name="connectionType"
                  fullWidth
                  placeholder="Website URL, Phone or Email"
                  required
                  label="How to Connect"
                >
                  {connectionTypeOptions.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.attributes.field_name}
                      </option>
                    )
                  })}
                </ControlledSelectField>
              </Grid>

              <Grid item xs={12}>
                <AdditionalFormField
                  displayType={CONNECTION_TYPE_WEBSITE_URL}
                  selectedType={connectionEnumType}
                />
                <AdditionalFormField
                  displayType={CONNECTION_TYPE_EMAIL}
                  selectedType={connectionEnumType}
                />
                <AdditionalFormField
                  displayType={CONNECTION_TYPE_PHONE_NUMBER}
                  selectedType={connectionEnumType}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Paper>
    </MuiDialogContent>
  )
}

export const ServiceModal = ({
  open,
  handleClose,
  service,
  connectionTypeOptions,
  serviceTypeOptions,
  mutateServices,
  mutateProfile,
}) => {
  const classes = useStyles()
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [connectionEnumType, setConnectionEnumType] =
    useState<ConnectionType | null>(null)
  const dispatch = useDispatch()

  const defaultValues = {
    serviceType: service?.relationships?.service_type?.data?.id,
    connectionType: service?.relationships?.how_to_connect?.data?.id,
    title: service?.attributes?.name,
    shortDescription: service?.attributes?.description,
    websiteUrl: service?.attributes?.website_url,
    email: service?.attributes?.email,
    phoneNumber: service?.attributes?.phone_number,
  }
  const methods = useForm({
    defaultValues,
    resolver: zodResolver(
      z.object({
        serviceType: z.string().nonempty("This field is required."),
        connectionType: z.string().nonempty("This field is required."),
        title: z.string().nonempty("This field is required."),
        shortDescription: z.string().nonempty("This field is required."),
        websiteUrl: z
          .string()
          .refine(
            (value) => urlPassesRegex(value),
            "Please enter a valid URL including http:// or https://"
          ),
        email: z
          .string()
          .refine(
            (value) => emailPassesRegex(value),
            "Please enter a valid email address."
          ),
        phoneNumber: z.string().nonempty("This field is required."),
      })
    ),
    mode: "onChange",
  })

  useEffect(() => {
    if (open) {
      methods.reset(defaultValues)
    }
  }, [open, service])

  useEffect(() => {
    const howToConnectId = service?.relationships?.how_to_connect?.data?.id
    if (howToConnectId) {
      const type = getConnectionType(howToConnectId, connectionTypeOptions)

      setConnectionEnumType(type)
    }
  }, [service, connectionTypeOptions])

  const getIsValid = (values) => {
    const type = getConnectionType(values.connectionType, connectionTypeOptions)

    if (
      !values.serviceType ||
      !values.connectionType ||
      !values.title ||
      !values.shortDescription
    ) {
      return false
    }

    if (
      type === CONNECTION_TYPE_WEBSITE_URL &&
      (!values.websiteUrl || !urlPassesRegex(values.websiteUrl))
    ) {
      return false
    }
    if (
      type === CONNECTION_TYPE_EMAIL &&
      (!values.email || !emailPassesRegex(values.email))
    ) {
      return false
    }
    if (type === CONNECTION_TYPE_PHONE_NUMBER && !values.phoneNumber) {
      return false
    }

    return true
  }
  const [isValid, setIsValid] = useState(getIsValid(defaultValues))

  useEffect(() => {
    const subscription = methods.watch((values, { name, type }) => {
      if (name === "connectionType") {
        const type = getConnectionType(values[name], connectionTypeOptions)

        setConnectionEnumType(type)
      }

      setIsValid(getIsValid(values))
    })

    return () => subscription.unsubscribe()
  }, [methods, connectionTypeOptions])

  const handleError = () => {
    setDeleting(false)
    setSaving(false)
    dispatch(
      showMessage({
        message:
          "There was an error updating your highlighted services, please refresh or try again.",
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      })
    )
  }

  const onSubmit = async () => {
    if (!isValid) {
      return
    }

    setSaving(true)

    const values = methods.getValues()
    const type = getConnectionType(values.connectionType, connectionTypeOptions)

    const conditionalOptions = {
      website_url:
        type === CONNECTION_TYPE_WEBSITE_URL ? values.websiteUrl : null,
      email: type === CONNECTION_TYPE_EMAIL ? values.email : null,
      phone_number:
        type === CONNECTION_TYPE_PHONE_NUMBER ? values.phoneNumber : null,
    }

    const data = {
      type: "PractitionerProfileService",
      attributes: {
        name: values.title,
        description: values.shortDescription,
        ...conditionalOptions,
      },
      relationships: {
        service_type: {
          data: {
            type: "WebflowFieldOption",
            id: values.serviceType,
          },
        },
        how_to_connect: {
          data: {
            type: "WebflowFieldOption",
            id: values.connectionType,
          },
        },
      },
    }

    try {
      if (service?.id) {
        data["id"] = service.id

        await resourceRequest<PractitionerProfileService>({
          url: `/practitioner_profile_services/${service.id}/`,
          data: { data },
          method: "PATCH",
        })
      } else {
        await resourceRequest<PractitionerProfileService>({
          url: "/practitioner_profile_services/",
          data: { data },
          method: "POST",
        })
      }
    } catch (error) {
      handleError()
    }

    mutateServices()
    mutateProfile()
    setSaving(false)
    handleClose()
  }

  const onDelete = async () => {
    setDeleting(true)
    await resourceRequest<PractitionerProfileService>({
      url: `/practitioner_profile_services/${service.id}/`,
      method: "DELETE",
    })

    setDeleting(false)
    mutateServices()
    mutateProfile()
    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="service-dialog"
      open={open}
      className={classes.dialogWrapper}
      maxWidth="sm"
    >
      <TitleSection title="Service" handleClose={handleClose} />
      <EditServiceFields
        methods={methods}
        connectionTypeOptions={connectionTypeOptions}
        serviceTypeOptions={serviceTypeOptions}
        connectionEnumType={connectionEnumType}
      />
      <MuiDialogActions className={classes.actionContainer}>
        <Grid container direction="row-reverse" spacing={1}>
          <Grid item xs={3}>
            <DesignSystemButton
              color="primary"
              onClick={onSubmit}
              disabled={!isValid}
              variant="contained"
              size="small"
              fullWidth
              loading={saving}
            >
              Save
            </DesignSystemButton>
          </Grid>
          {!!service?.id && (
            <Grid item xs={3}>
              <DesignSystemButton
                color="destructiveSecondary"
                onClick={onDelete}
                variant="contained"
                size="small"
                fullWidth
                loading={deleting}
              >
                Delete
              </DesignSystemButton>
            </Grid>
          )}
        </Grid>
      </MuiDialogActions>
    </Dialog>
  )
}

import { ReactComponent as ChevronRight } from "app/assets/icons/patient-portal/chevron-right.svg"
import BodyText from "app/components/design-system/BodyText"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import {
  PatientPortalClinic,
  PatientPortalPatient,
  PatientPortalStorefront,
} from "../types"

interface Props {
  patient: PatientPortalPatient
  onClick: (patient: PatientPortalPatient) => void
}

const useStyles = makeAppStyles((theme) => ({
  card: {
    width: "100%",
    padding: "20px 19px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "white",
    borderRadius: 12,
    transition: "box-shadow 0.2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      boxShadow: shadows.md,
      transition: "box-shadow 0.2s ease-in-out",
    },
  },
}))

const PatientPortalPatientCard = ({ patient, onClick }: Props) => {
  const classes = useStyles()

  const clinic = useCachedResource<PatientPortalClinic>(
    patient?.relationships.clinic.data
  )

  const labshop = useCachedResource<PatientPortalStorefront>(
    patient?.relationships.storefront.data
  )

  return (
    <div
      className={classes.card}
      onClick={() => onClick(patient)}
      data-testid="patient-portal-patient-card"
    >
      <div>
        <BodyText size="md" weight="semibold">
          {patient.attributes.full_name}
        </BodyText>
        <BodyText size="sm" weight="regular">
          {labshop ? labshop.attributes.name : clinic?.attributes.name}
        </BodyText>
      </div>
      <ChevronRight fill={primaryColor} />
    </div>
  )
}

export default PatientPortalPatientCard

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import draftStatusConfiguration from "../draft-status-configuration"
import pausedStatusConfiguration from "../paused-status-configuration"
import canceledPhysServicesStatusConfiguration from "./canceled-phys-services-status-configuration"
import canceledStatusConfiguration from "./canceled-status-configuration"
import orderedWithLabStatusConfiguration from "./ordered-with-lab-status-configuration"
import paidStatusConfiguration from "./paid-status-configuration"
import pendingPhysServicesAuthStatusConfiguration from "./pending-phys-services-auth-status-configuration"
import resultsInStatusConfiguration from "./results-in-status-configuration"
import sampleAtLabStatusConfiguration from "./sample-at-lab-status-configuration"
import {
  processingSpecimenIssueStatusConfiguration,
  recollectingSpecimenIssueStatusConfiguration,
  actionRequiredSpecimenIssueStatusConfiguration,
} from "./specimen-issue-status-configuration"
import submittedStatusConfiguration from "./submitted-status-configuration"

export const statusConfigurations: StatusConfiguration<StatusConfigurationProps>[] =
  [
    draftStatusConfiguration,
    pausedStatusConfiguration,
    canceledPhysServicesStatusConfiguration,
    canceledStatusConfiguration,
    resultsInStatusConfiguration,
    sampleAtLabStatusConfiguration,
    orderedWithLabStatusConfiguration,
    pendingPhysServicesAuthStatusConfiguration,
    paidStatusConfiguration,
    submittedStatusConfiguration,
    actionRequiredSpecimenIssueStatusConfiguration,
    recollectingSpecimenIssueStatusConfiguration,
    processingSpecimenIssueStatusConfiguration,
  ]

export default statusConfigurations

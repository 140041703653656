import { useEffect, useRef, useState } from "react"
import * as React from "react"
import { useDispatch } from "react-redux"

import classNames from "classnames"

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  InputAdornment,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import * as Popover from "@radix-ui/react-popover"

import SearchIcon from "app/assets/icons/search-icon.svg"
import HugIcon from "app/assets/icons/settings/hug.svg"
import Loading from "app/components/Loading"
import BodyText from "app/components/design-system/BodyText"
import useResizeObserver from "app/hooks/use-resize-observer"
import { showMessage } from "app/store/actions/fuse"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, navy, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { fuzzySearch } from "app/utils/search-utils"
import { Practitioner, PractitionerIdentifier } from "types/practitioner"
import { User } from "types/user"

import {
  useNotificationsSettingsResource,
  useUpdatePractitionerPoc,
} from "./hooks/use-notifications-settings-resource"

const useStyles = makeAppStyles((theme) => ({
  notificationWrapper: {
    marginTop: 24,
    "border-radius": "6px",
    border: `1px solid ${colors.blueGray[200]}`,
    "box-shadow": "0px 1px 3px 0px #0000001A",
  },
  notificationHeader: {
    backgroundColor: colors.blueGray[50],
    "box-shadow": "0px 1px 2px 0px #0000000F",
  },
  notificationHeaderRow: {
    borderBottom: `1.5px solid ${colors.blueGray[300]}`,
  },
  notificationHeaderCell: {
    padding: "15px 20px",
    width: "35%",
  },
  practitionerRow: {
    backgroundColor: "#FFFFFF",
  },
  practitionerInfoContainer: {
    paddingLeft: "22px",
  },
  pocSelectionContainer: {
    borderColor: colors.blueGray[300],
    maxWidth: 300,
    display: "flex",
    "flex-grow": 0,
    "flex-direction": "row",
    "justify-content": "space-between",
    "align-items": "center",
    "border-radius": "6px",
    border: `1px solid ${colors.blueGray[300]}`,
    "box-shadow": "0px 1px 2px 0px #0000000D",
    padding: "9px 13px",
    color: navy,
  },
  changedPocSelectionContainer: {
    borderColor: primaryColor,
    border: `1px solid ${primaryColor}`,
    color: primaryColor,
  },
  errorPocSelectionContainer: {
    borderColor: colors.red[500],
  },
  personNameText: {
    "font-weight": 600,
  },
  pocSelectionPopover: {
    backgroundColor: colors.blueGray[50],
  },
  pocDropdown: {
    margin: 0,
  },

  loadingContainer: {
    marginTop: 48,
  },
  errorInformation: {
    marginTop: 12,
    color: navy,
  },
  searchContainer: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
  },
  searchIcon: {
    width: 18,
    height: 18,
  },
  textField: {
    backgroundColor: "white",
    "& fieldset": {
      borderRadius: "6px",
      border: "1px solid #D3DCE3",
    },
    color: colors.blueGray[400],
  },
  placeholder: {
    "&::placeholder": {
      color: navy,
      opacity: 1,
    },
  },
}))

interface AutocompleteOption {
  id: string
  name: string
  email: string
  practitionerInfo: Practitioner
}

export default function NotificationsTable() {
  const classes = useStyles()
  const dispatch = useDispatch()
  let { allPractitioners, nonClinicStaff, practitionerUsers, ready, error } =
    useNotificationsSettingsResource()

  const nonClinicStaffWithUser = nonClinicStaff?.map((practitioner) => ({
    ...practitioner,
    user: practitionerUsers?.find(
      (user) => practitioner.relationships.user.data.id === user.id
    ),
  }))

  const [searchValue, setSearchValue] = useState("")
  const [filteredNonClinicStaff, setFilteredNonClinicStaff] = useState<any>([])
  const [pagedNonClinicStaff, setPagedNonClinicStaff] = useState<any>([])

  const practitionersPerPage = 10

  const [page, setPage] = useState(1)

  useEffect(() => {
    setFilteredNonClinicStaff(nonClinicStaffWithUser)
    setPagedNonClinicStaff(
      nonClinicStaffWithUser?.slice(0, practitionersPerPage)
    )
  }, [nonClinicStaff?.length])

  const paginateToIndex = (pageIndex) => {
    const currentPageOfPractitioners = filteredNonClinicStaff?.slice(
      pageIndex * practitionersPerPage,
      (pageIndex + 1) * practitionersPerPage
    )
    setPage(pageIndex + 1)
    setPagedNonClinicStaff(currentPageOfPractitioners)
  }

  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value
    const pracsMatchingSearch = fuzzySearch(
      searchValue,
      nonClinicStaffWithUser || [],
      ["user.attributes.email", "user.attributes.full_name"]
    )

    setSearchValue(searchValue)
    setPage(1)
    setFilteredNonClinicStaff(pracsMatchingSearch)
    setPagedNonClinicStaff(pracsMatchingSearch.slice(0, practitionersPerPage))
  }

  if (!ready || !practitionerUsers || !nonClinicStaff?.length) {
    return (
      <div className={classes.loadingContainer}>
        <Loading ariaLabel="Loading Practitioners" />
      </div>
    )
  }

  if (error) {
    dispatch(
      showMessage({
        message: `There was an error loading your notification preferences. Please refresh to try again or reach out to hello@rupahealth.com for support with this issue.`,
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      })
    )

    return (
      <BodyText className={classes.errorInformation}>
        There was an error loading your notification preferences. Please refresh
        to try again or reach out to hello@rupahealth.com for support.
      </BodyText>
    )
  }

  const clinicMemberDropdownOpts =
    allPractitioners?.map((member) => {
      const user = practitionerUsers?.find(
        (user) => member.relationships.user.data.id === user.id
      )
      return {
        id: member?.id,
        name: `${user?.attributes.full_name}`,
        email: user?.attributes.email,
        practitionerInfo: member,
      } as AutocompleteOption
    }) || []
  return (
    <TableContainer className={classes.notificationWrapper}>
      <Table>
        <NotificationHeader
          searchValue={searchValue}
          updateSearchValue={updateSearchValue}
        />
        <TableBody>
          {pagedNonClinicStaff?.map((practitioner) => (
            <PractitionerRow
              key={practitioner.id}
              practitioner={practitioner}
              clinicMemberDropdownOpts={clinicMemberDropdownOpts}
            />
          ))}
        </TableBody>
      </Table>
      <div className={classes.paginationContainer}>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          rowsPerPage={practitionersPerPage}
          count={filteredNonClinicStaff?.length || 0}
          page={page - 1}
          onChangePage={(_, newPageIndex) => paginateToIndex(newPageIndex)}
          className={classes.pagination}
        />
      </div>
    </TableContainer>
  )
}

function NotificationHeader({ searchValue, updateSearchValue }) {
  const classes = useStyles()

  return (
    <TableHead className={classes.notificationHeader}>
      <TableRow className={classes.notificationHeaderRow}>
        <TableCell className={classes.notificationHeaderCell}>
          <div className={classes.searchContainer} tabIndex={-1}>
            <TextField
              fullWidth
              multiline
              placeholder="Practitioner"
              variant="outlined"
              size="small"
              InputProps={{
                classes: { input: classes.placeholder },
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={SearchIcon}
                      alt="Search"
                      className={classes.searchIcon}
                    />
                  </InputAdornment>
                ),
              }}
              className={classes.textField}
              onChange={updateSearchValue}
              value={searchValue}
            />
          </div>
        </TableCell>
        <TableCell>Messages Go To...</TableCell>
      </TableRow>
    </TableHead>
  )
}

function PractitionerRow(props: {
  practitioner: Practitioner
  clinicMemberDropdownOpts: AutocompleteOption[]
}) {
  const classes = useStyles()
  return (
    <TableRow className={classes.practitionerRow}>
      <PractitionerInfo practitioner={props.practitioner} />
      <PractitionerPOCSelection
        practitioner={props.practitioner}
        clinicMemberDropdownOpts={props.clinicMemberDropdownOpts}
      />
    </TableRow>
  )
}

function PractitionerInfo(props: { practitioner: Practitioner }) {
  const classes = useStyles()
  const user = useCachedResource<User>(
    props.practitioner.relationships.user.data
  )
  return (
    <TableCell className={classes.practitionerInfoContainer}>
      <div className={classes.personNameText}>{user?.attributes.full_name}</div>
      <div>{user?.attributes.email}</div>
    </TableCell>
  )
}

function SelectedPractitionerPoc(props: {
  selectedPoc: AutocompleteOption | undefined
}) {
  const classes = useStyles()
  const poc = props.selectedPoc?.practitionerInfo
  const user = useCachedResource<User>(poc?.relationships?.user?.data)
  return (
    <div>
      <div className={classes.personNameText}>{user?.attributes.full_name}</div>
      <div>{user?.attributes.email}</div>
    </div>
  )
}

function PractitionerPOCSelection(props: {
  practitioner: Practitioner
  clinicMemberDropdownOpts: AutocompleteOption[]
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const pracPocId =
    props.practitioner?.relationships?.point_of_contact?.data?.id
  const initialPoc =
    (pracPocId
      ? props.clinicMemberDropdownOpts.find((member) => member.id === pracPocId)
      : props.clinicMemberDropdownOpts.find(
          (member) => member.id === props.practitioner?.id
        )) || undefined
  const [selectedPoc, setSelectedPoc] = useState<AutocompleteOption | null>(
    null
  )
  const triggerRef = useRef<any>()
  const { width: triggerWidth = "auto" } = useResizeObserver({
    ref: triggerRef,
    box: "border-box",
  })
  const [showContactSelection, setShowContactSelection] = useState(false)
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false)
  const [inErrorState, setErrorState] = useState(false)

  const toggleContactSelectionOpen = (isOpen) => {
    // Popover.Root onOpenChange doesn't play nice with blurs of AutoComplete.
    // If the event comes from Popover.Root, it will have an isOpen which we should respect.
    // If not, we should toggle the state.

    // Toggling autocomplete on needs a delay so the popover can find its position
    // before posititioning the autocomplete.

    // If we don't have a value, toggle
    if (isOpen === undefined) {
      const shouldOpen = !showContactSelection
      setShowContactSelection(shouldOpen)
      setTimeout(() => setAutoCompleteOpen(shouldOpen), 1)
    } else {
      // Otherwise, use the value
      setShowContactSelection(isOpen)
      setTimeout(() => setAutoCompleteOpen(isOpen), 1)
    }
  }

  const updatePoc = useUpdatePractitionerPoc(props.practitioner)
  useEffect(() => {
    if (!selectedPoc) {
      return
    }

    const pocUpdate = {
      id: selectedPoc.id,
      type: "practitioner",
    } as PractitionerIdentifier
    updatePoc(pocUpdate)
      .then(() => {
        setErrorState(false)
        dispatch(
          showMessage({
            message: "Update Successful",
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          })
        )
      })
      .catch(() => {
        setErrorState(true)
      })
  }, [selectedPoc])

  const changedPocSelection =
    (!selectedPoc && initialPoc?.id !== props.practitioner.id) ||
    (selectedPoc && selectedPoc?.id !== props.practitioner.id)

  const selectionContainerClasses = classNames(classes.pocSelectionContainer, {
    [classes.changedPocSelectionContainer]: changedPocSelection,
    [classes.errorPocSelectionContainer]: inErrorState,
  })

  return (
    <TableCell>
      <Popover.Root
        open={showContactSelection}
        onOpenChange={toggleContactSelectionOpen}
      >
        <Popover.Trigger
          asChild
          onClick={(e) => {
            e.preventDefault()
          }}
          ref={triggerRef}
        >
          <div
            className={selectionContainerClasses}
            onClick={() => {
              // Leave this undefined. See logic in toggleContactSelectionOpen
              toggleContactSelectionOpen(undefined)
            }}
          >
            <SelectedPractitionerPoc selectedPoc={selectedPoc || initialPoc} />
            <IconButton>
              <img src={HugIcon} alt="Change Selection" />
            </IconButton>
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className={classes.pocSelectionPopover}
            style={{ width: triggerWidth, maxWidth: triggerWidth }}
          >
            <Autocomplete
              options={props.clinicMemberDropdownOpts}
              getOptionLabel={(option) => option.name}
              value={selectedPoc || initialPoc}
              open={autoCompleteOpen}
              autoComplete
              disableClearable
              onClose={() => {
                setAutoCompleteOpen(false)
              }}
              onOpen={() => {
                setTimeout(() => setAutoCompleteOpen(true), 1)
              }}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="outlined"
                  defaultValue={""}
                />
              )}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  style={{
                    ...props.style,
                    marginTop: "0px",
                  }}
                />
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(_, newValue) => {
                if (newValue) {
                  setSelectedPoc(newValue as AutocompleteOption)
                  setShowContactSelection(false)
                  setAutoCompleteOpen(false)
                }
              }}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </TableCell>
  )
}

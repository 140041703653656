import { useState } from "react"
import { useDispatch } from "react-redux"

import { ReactComponent as TestTubesIcon } from "app/assets/icons/test-tubes.svg"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { IOKOnboardingModal } from "app/main/in-office-kits/IOKOnboardingModal"
import { StyledSwitch } from "app/main/settings/components/StyledSwitch"
import { trackIOKOnboardingLearnMoreClicked } from "app/services/segment"
import { updatePractitionerState } from "app/store/actions"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Clinic } from "types/clinic"

import { MarkAsComplete } from "../MarkAsCompleteButton"
import TaskContainer from "../TaskContainer"
import taskStyles from "../TaskStyles"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

const useStyles = makeAppStyles((theme) => ({
  subheaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 15,
    backgroundColor: colors.blue[50],
  },
  toggleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  linkText: {
    color: theme.palette.primary.main,
  },
}))

const InOfficeKits: React.FC<OnboardingTaskItemProps> = ({
  task,
  markTaskAsComplete,
  onTaskComplete,
  practitioner,
}) => {
  const handleApiError = useHandleApiError()
  const classes = useStyles()
  const taskClasses = taskStyles()
  const [inOfficeKits, setInOfficeKits] = useState(false)
  const [showIOKModal, setShowIOKModal] = useState(false)
  const dispatch = useDispatch()

  const { data: clinicData, mutate } = useResourceSWR<Clinic>(
    `/clinics/${practitioner.clinic.id}/`
  )

  async function enableKitsOnClinic() {
    if (!clinicData) return

    try {
      await mutate(
        async () => {
          const { data } = await resourceRequest<ResourceResponse<Clinic>>({
            method: "PATCH",
            url: `/clinics/${clinicData.id}/`,
            data: {
              data: {
                type: "clinic",
                id: clinicData.id,
                attributes: {
                  clinic_features: [
                    ...clinicData.attributes.clinic_features,
                    "allow_in_office_kits",
                  ],
                },
              },
            },
          })

          return data
        },
        {
          revalidate: false,
        }
      )
      // Dispatch here to update nav bar
      let features = clinicData.attributes.clinic_features
      features.push("allow_in_office_kits")
      const newPractitioner = {
        ...practitioner,
        clinic: { ...practitioner.clinic, clinic_features: features },
      }
      dispatch(updatePractitionerState(newPractitioner))
    } catch (error) {
      handleApiError(error)
    }
  }

  const handleInOfficeKitsToggleSwitch = () => {
    const currentClinic = practitioner.clinic
    if (!currentClinic.clinic_features.includes("allow_in_office_kits")) {
      if (!inOfficeKits) {
        setShowIOKModal(true)
      }
      setInOfficeKits(true)

      enableKitsOnClinic()
      onTaskComplete(task.key)
    }
  }

  const subCardContent = (
    <div className={classes.subheaderContainer}>
      <MarkAsComplete onClick={() => markTaskAsComplete(task.key)} />
    </div>
  )

  return (
    <TaskContainer
      taskKey={task.key}
      header="Stock Kits In-Office"
      isTaskComplete={task.complete}
      timeTag={"30 Secs"}
      subCardContent={subCardContent}
    >
      <TestTubesIcon
        className={taskClasses.physServicesIcon}
        style={{ marginTop: 5 }}
      />
      <div className={taskClasses.taskTextContainer}>
        <div className={taskClasses.taskText}>
          Rupa can manage your orders for kits handed out in person, or blood
          draws done in-house.
        </div>
        <div className={taskClasses.taskText} style={{ fontWeight: 400 }}>
          We'll handle ordering your supplies, managing your billing, and your
          patient's requisitions. Please note, In-Office Kits can't be ordered
          with Physician Services.
          <a
            href="https://www.rupahealth.com/in-office-kits"
            target="_blank"
            rel="noreferrer"
            className={classes.linkText}
            onClick={() => trackIOKOnboardingLearnMoreClicked(practitioner.id)}
          >
            Learn more
          </a>
        </div>
      </div>
      <div className={classes.toggleContainer}>
        <StyledSwitch
          checked={inOfficeKits}
          onChange={handleInOfficeKitsToggleSwitch}
          name="in_office_kits"
        />
        <div className={taskClasses.taskText} style={{ padding: 0 }}>
          Stock Kits In-Office
        </div>
      </div>
      <IOKOnboardingModal
        showModal={showIOKModal}
        onExit={() => setShowIOKModal(false)}
        onContinue={() => setShowIOKModal(false)}
      />
    </TaskContainer>
  )
}

export default InOfficeKits

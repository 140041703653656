import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { useHistory, useLocation } from "react-router-dom"

import { GuestPaths, UserPaths } from "app/Routes"
import authService from "app/auth/services/simplejwt-auth-service"
import { showMessage } from "app/store/actions"

/**
 * Route component for verifying user email.
 *
 * Steps:
 * 1. Call the /verifyemail endpoint with the token.
 * 2. Once the verification succeeds or fails, redirect to the dashboard and display
 *    a message to the user.
 */
function VerifyEmail() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const searchParams = new URLSearchParams(location.search)
  const verifyToken = searchParams.get("verify_token")
  const email = searchParams.get("email")

  const handleEmailVerifySuccess = () => {
    window.Intercom("trackEvent", "email-verified")
    dispatch(
      showMessage({
        message: "Your email address has been verified.",
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      })
    )
  }

  const handleEmailVerifyFail = () => {
    dispatch(
      showMessage({
        message: "Unable to verify your email address.",
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      })
    )
  }

  const successRedirect = UserPaths.ONBOARDING
  const failureRedirect = GuestPaths.LOGIN

  // Call the email verification API
  useEffect(() => {
    const verifyEmailRequest = async () => {
      try {
        // Send the request
        await authService.verifyEmail(verifyToken, successRedirect)

        handleEmailVerifySuccess()
      } catch (error) {
        history.push(failureRedirect)
        handleEmailVerifyFail()
      }
    }
    verifyEmailRequest()
  }, [email, verifyToken])

  return null
}

export default VerifyEmail

import { Redirect, useHistory, useParams } from "react-router-dom"
import { useUpdateEffect } from "react-use"

import PatientPortalBGNoOrders from "app/assets/images/patient-portal/gradient3.jpeg"
import Portal from "app/components/Portal"
import DisplayText from "app/components/design-system/DisplayText"
import { ORDER_STATUS } from "app/constants.typed"
import {
  PATIENT_PORTAL_TRACKING_EVENTS,
  trackPatientPortalEvent,
} from "app/services/segment"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import makeAppStyles from "app/utils/makeAppStyles"

import Card from "../Card"
import PatientPortalBackground from "../PatientPortalBackground"
import PatientPortalEmptyState from "../PatientPortalEmptyState"
import PatientPortalGeneralQuickLinks from "../footer/PatientPortalGeneralQuickLinks"
import {
  PatientPortalOrder,
  PatientPortalPatient,
  PatientPortalUser,
} from "../types"
import { buildPatientPortalUrl } from "../util"
import PatientPortalPatientOrderCard from "./PatientPortalPatientOrderCard"
import PatientPortalPatientOrdersLoadingState from "./PatientPortalPatientOrdersLoadingState"
import usePatientPortalPatientOrders from "./hooks/use-patient-portal-patient-orders"

const useStyles = makeAppStyles((theme) => ({
  container: {
    padding: "24px 0px",
    display: "flex",
    gap: 16,
    flexDirection: "column",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      gap: 24,
      padding: "40px 24px",
    },
  },
  cardsContainer: {
    display: "flex",
    gap: 24,
    width: "100%",
    flexDirection: "column",
  },
}))

const PatientPortalPatientOrders = () => {
  const classes = useStyles()

  const history = useHistory()

  const { patientId } = useParams<{
    patientId: string
  }>()

  const { orders, isLoading } = usePatientPortalPatientOrders(patientId)

  const patient = useCachedResource<PatientPortalPatient>({
    id: patientId,
    type: "patient_portal_patient",
  })

  const patientUser = useCachedResource<PatientPortalUser>(
    patient?.relationships.user.data
  )

  const checkoutOrderStatuses: String[] = [
    ORDER_STATUS.PENDING_PAYMENT,
    ORDER_STATUS.PENDING_SHIPPING_INFO,
  ]
  const orderRedirectsToCheckout = (order: PatientPortalOrder) =>
    checkoutOrderStatuses.includes(order.attributes.status)
  const shouldRedirectToOrderPage =
    orders.length === 1 && !orderRedirectsToCheckout(orders[0])

  useUpdateEffect(() => {
    // Do not track if we are just going to redirect to order page
    if (!isLoading && !shouldRedirectToOrderPage) {
      trackPatientPortalEvent(
        PATIENT_PORTAL_TRACKING_EVENTS.ORDERS_PAGE_VIEWED,
        { patient_id: patientId }
      )
    }
  }, [isLoading])

  const handleCardClick = (order: PatientPortalOrder) => {
    trackPatientPortalEvent(PATIENT_PORTAL_TRACKING_EVENTS.ORDER_CLICKED, {
      order_id: order.id,
    })

    if (
      orderRedirectsToCheckout(order) &&
      order.attributes.patient_checkout_url
    ) {
      window.open(order.attributes.patient_checkout_url)
    } else {
      history.push(buildPatientPortalUrl(patientId, order.id))
    }
  }

  if (isLoading) {
    return (
      <Card className={classes.container}>
        <PatientPortalPatientOrdersLoadingState />
      </Card>
    )
  }

  if (shouldRedirectToOrderPage) {
    return <Redirect to={buildPatientPortalUrl(patientId, orders[0].id)} />
  }

  const isEmpty = orders.length === 0

  return (
    <div className="flex flex-col gap-4">
      <Card className={classes.container}>
        <DisplayText size="lg" weight="semibold">
          {isEmpty
            ? `Welcome, ${patient?.attributes.first_name}`
            : "All Orders"}
        </DisplayText>

        {isEmpty ? (
          <PatientPortalEmptyState patientUser={patientUser} />
        ) : (
          <div className={classes.cardsContainer}>
            {orders.map((order) => (
              <PatientPortalPatientOrderCard
                order={order}
                onClick={handleCardClick}
                key={order.id}
              />
            ))}
          </div>
        )}
      </Card>
      {isEmpty && (
        <>
          <Portal id="patient-portal-background">
            <PatientPortalBackground image={PatientPortalBGNoOrders} />
          </Portal>
          <PatientPortalGeneralQuickLinks />
        </>
      )}
    </div>
  )
}

export default PatientPortalPatientOrders

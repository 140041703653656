import * as React from "react"

import { CircularProgress, InputAdornment, TextField } from "@material-ui/core"

import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg"
import InRangeFilter from "app/main/patient-orders/results-over-time/filter-components/InRangeFilter"
import { InRangeOption } from "app/main/patient-orders/results-over-time/types/types"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { BodySystem } from "types/body-system"

import GroupByToggle, {
  BloodLabDashboardsGroupByGroupings,
} from "./BloodLabDashboardsGroupByToggle"
import BodySystemDropdownFilter from "./BodySystemDropdownFilter"

const useStyles = makeAppStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  searchSection: {
    border: `1px solid ${colors.blueGray[300]}`,
    background: "var(--White, #FFF)",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07)",
    borderRadius: "6px",
  },
  textInput: {
    margin: 0,
    marginTop: 2,
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      background: "white",
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-input::placeholder": {
      color: colors.blueGray[500],
    },
    "& .MuiOutlinedInput-adornedStart": {
      background: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "6px",
      fontSize: 14,
    },
  },
}))

export default function BloodLabDashboardsSearchAndFilter({
  onSearch,
  searchText,
  handleInRangeValueChange,
  inRangeValue,
  filterBodySystemId,
  setFilterBodySystemId,
  onGroupBy,
  groupBy,
  availableBodySystems,
  isValidating,
}: {
  onSearch: (searchText: string) => void
  searchText: string
  handleInRangeValueChange: (value: InRangeOption) => void
  inRangeValue: InRangeOption
  filterBodySystemId: string
  setFilterBodySystemId: (bodySystem: string) => void
  onGroupBy: (group: BloodLabDashboardsGroupByGroupings) => void
  groupBy: BloodLabDashboardsGroupByGroupings
  availableBodySystems: BodySystem[]
  isValidating?: boolean
}) {
  const classes = useStyles()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value)
  }

  return (
    <div className={classes.container}>
      <TextField
        value={searchText}
        onChange={handleSearchChange}
        variant="outlined"
        placeholder="Search for a biomarker"
        className={classes.textInput}
        margin="dense"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon width={16} height={16} fill={colors.blueGray[500]} />
            </InputAdornment>
          ),
          endAdornment: isValidating && (
            <InputAdornment position="end">
              <CircularProgress size={16} />
            </InputAdornment>
          ),
        }}
      ></TextField>
      <div className="mt-[10px] md:mt-0 md:ml-3 w-full md:w-auto">
        <BodySystemDropdownFilter
          availableBodySystems={availableBodySystems}
          filterBodySystemId={filterBodySystemId}
          setFilterBodySystemId={setFilterBodySystemId}
        />
      </div>
      <div className="mt-[10px] md:mt-0 md:ml-3 w-full md:w-auto">
        <InRangeFilter
          handleInRangeValueChange={handleInRangeValueChange}
          inRangeValue={inRangeValue}
        />
      </div>
      <div className="mt-[10px] md:mt-0 md:ml-3 w-full md:w-auto">
        <GroupByToggle
          currentGrouping={groupBy}
          handleSetGrouping={onGroupBy}
        />
      </div>
    </div>
  )
}

import { useEffect } from "react"
import { useDispatch } from "react-redux"

import Loading from "app/components/Loading"
import useUpdateOrder from "app/main/patient-checkout/hooks/use-update-order"
import {
  trackPhlebotomyBookingEvent,
  PHLEBOTOMY_BOOKING_EVENTS,
} from "app/services/segment"
import { showMessage } from "app/store/actions/fuse"
import { colors, white, navy, shadows } from "app/theme"
import { PhlebotomyProvider, PHLEBOTOMY_PROVIDERS } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { CombinedBloodDraw } from "./CombinedBloodDraw"
import { SelectProvider } from "./SelectProvider"
import {
  LABCORP_BODY_TEXT,
  FILLER_BODY_TEXT,
  QUEST_BODY_TEXT,
} from "./constants"
import usePhlebotomyProviderAvailability from "./use-phlebotomy-provider-availability"
import { updateProviderList, resetProviderList } from "./utils"

const useStyles = makeAppStyles((theme) => ({
  container: {
    padding: "24px",
    borderRadius: "8px",
    border: `1px solid ${colors.blueGray[200]}`,
    backgroundColor: white,
    boxShadow: shadows.default,
  },
  title: {
    color: navy,
    fontFamily: "Josefin Sans",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
  },
  body: {
    marginTop: 8,
    color: navy,
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
  },
  semiBold: {
    fontWeight: 600,
  },
  howDoesThisWorkSection: {
    marginTop: 34,
  },
}))

interface BloodDrawProps {
  patientBirthday: string | null
  zipCode: string | null
  checkoutToken: string
  orderPhlebotomyProviders: PhlebotomyProvider[]
  labCompanyNamesBloodDrawNotIncluded: string[]
  requiredNumberOfPhlebotomyProviders: number
  onBloodDrawModified: () => void
  onBloodDrawConfirmed: () => void
}

export const BloodDraw = ({
  patientBirthday,
  zipCode,
  checkoutToken,
  orderPhlebotomyProviders,
  labCompanyNamesBloodDrawNotIncluded,
  requiredNumberOfPhlebotomyProviders,
  onBloodDrawModified,
  onBloodDrawConfirmed,
}: BloodDrawProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { isLoading, axleAvailable, notificationMessage } =
    usePhlebotomyProviderAvailability(zipCode, checkoutToken, patientBirthday)

  const includesLabcorp = orderPhlebotomyProviders
    .map((item) => item.provider_name)
    .includes(PHLEBOTOMY_PROVIDERS.LABCORP)

  const includesQuest = orderPhlebotomyProviders
    .map((item) => item.provider_name)
    .includes(PHLEBOTOMY_PROVIDERS.QUEST)
  const { updateOrder } = useUpdateOrder()

  const currentProviderNames = orderPhlebotomyProviders.map(
    (item) => item.provider_name
  )
  useEffect(() => {
    if (zipCode && !isLoading) {
      trackPhlebotomyBookingEvent(
        PHLEBOTOMY_BOOKING_EVENTS.PHLEBOTOMY_OPTIONS_SHOWN,
        {
          zip_code: zipCode,
          options: axleAvailable
            ? [PHLEBOTOMY_PROVIDERS.AXLE, PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN]
            : [PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN],
        }
      )
    }
  }, [zipCode, isLoading])

  // Remove patient chosen from order when axle is available to force selection
  useEffect(() => {
    if (
      zipCode &&
      !isLoading &&
      axleAvailable &&
      currentProviderNames.includes(PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN)
    ) {
      updateOrder({
        phlebotomy_providers: resetProviderList(currentProviderNames),
      })
    }
  }, [axleAvailable, zipCode, isLoading])

  // Add patient chosen if axle not available
  useEffect(() => {
    if (
      zipCode &&
      !isLoading &&
      !axleAvailable &&
      orderPhlebotomyProviders?.length < requiredNumberOfPhlebotomyProviders
    ) {
      onBloodDrawConfirmed()
      updateOrder({
        phlebotomy_providers: updateProviderList(
          PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN,
          currentProviderNames
        ),
      }).catch(() => {
        dispatch(
          showMessage({
            message:
              "Something went wrong adding phlebotomy from order. Please contact support",
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          })
        )
      })
    }
  }, [axleAvailable, zipCode, isLoading, orderPhlebotomyProviders])

  // Remove Axle from phlebotomy providers if not available
  useEffect(() => {
    if (
      zipCode &&
      !axleAvailable &&
      orderPhlebotomyProviders
        .map((provider) => provider.provider_name)
        .includes(PHLEBOTOMY_PROVIDERS.AXLE)
    ) {
      updateOrder({
        phlebotomy_providers: updateProviderList(
          PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN,
          currentProviderNames
        ),
      })
        .then(() => {
          onBloodDrawConfirmed()
          dispatch(
            showMessage({
              message: notificationMessage,
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            })
          )
        })
        .catch(() => {
          dispatch(
            showMessage({
              message:
                "Something went wrong removing phlebotomy from order. Please contact support",
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            })
          )
        })
    }
  }, [axleAvailable, notificationMessage, zipCode])

  let body = <></>
  if (isLoading) {
    body = <Loading ariaLabel="Loading blood draw options" />
  } else if (requiredNumberOfPhlebotomyProviders > 1) {
    body = (
      <CombinedBloodDraw
        zipCode={zipCode}
        orderPhlebotomyProviders={orderPhlebotomyProviders}
        axleAvailable={axleAvailable}
        onBloodDrawModified={onBloodDrawModified}
        onBloodDrawConfirmed={onBloodDrawConfirmed}
        labCompanyNamesBloodDrawNotIncluded={
          labCompanyNamesBloodDrawNotIncluded
        }
        requiredNumberOfPhlebotomyProviders={
          requiredNumberOfPhlebotomyProviders
        }
      />
    )
  } else if (includesLabcorp) {
    body = (
      <IncludedProviderBody
        providerName={"Labcorp"}
        providerText={LABCORP_BODY_TEXT}
      />
    )
  } else if (includesQuest) {
    body = (
      <IncludedProviderBody
        providerName={"Quest"}
        providerText={QUEST_BODY_TEXT}
      />
    )
  } else if (!zipCode) {
    body = <div>{FILLER_BODY_TEXT}</div>
  } else if (!patientBirthday) {
    body = (
      <div>
        Your order requires a blood draw. Please enter your birthday above to
        see the options.
      </div>
    )
  } else if (zipCode && !isLoading && !axleAvailable) {
    body = (
      <div>
        <div>
          Your order requires a blood draw. <i>Not included in price.</i>
        </div>
        <div className={classes.howDoesThisWorkSection}>
          <span className={classes.semiBold}>How does this work? </span>We’ll
          email you instructions on how to find and schedule your blood draw.
          You will pay a separate fee directly to the blood draw site (typically
          $30-$100, varies per location).
        </div>
      </div>
    )
  } else if (zipCode && !isLoading && axleAvailable) {
    body = (
      <div>
        Your order requires a blood draw.
        <br></br>
        <br></br>
        <div className={classes.semibold}>Select an option below:</div>
        <br></br>
        <SelectProvider
          orderPhlebotomyProviders={orderPhlebotomyProviders}
          onFormIsModified={onBloodDrawModified}
          onFormIsConfirmed={onBloodDrawConfirmed}
        />
      </div>
    )
  } else {
    body = <div>Something went wrong.</div>
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>Blood Draw</div>
      <div className={classes.body}>{body}</div>
    </div>
  )
}

interface IncludedProviderBodyProps {
  providerName: string
  providerText: string
}

export const IncludedProviderBody = ({
  providerName,
  providerText,
}: IncludedProviderBodyProps) => {
  const classes = useStyles()
  return (
    <div>
      <div>Your order requires a blood draw at a {providerName} draw site.</div>
      <div className={classes.howDoesThisWorkSection}>
        <span className={classes.semiBold}>How does this work? </span>{" "}
        {providerText}
      </div>
    </div>
  )
}

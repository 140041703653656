import React from "react"

import { getBiomarkerStatus } from "app/main/blood-lab-dashboards/utils"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import { PatientPortalBloodReportResult } from "app/patient-portal/types"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import interleave from "app/utils/interleave"
import { Biomarker } from "types/biomarker"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"
import { BloodReportResult } from "types/blood-report-result"

import { PdfBiomarkerRow } from "./PdfBiomarkerRow"

export const PdfAbnormalMarkersSection: React.FC<{
  bloodReportResults: BloodReportResult[] | PatientPortalBloodReportResult[]
  showHighLowDescriptions?: boolean
  biomarkerCustomDescriptionsMapping: Record<string, BiomarkerCustomDescription>
}> = ({
  bloodReportResults,
  showHighLowDescriptions,
  biomarkerCustomDescriptionsMapping,
}) => {
  return (
    <>
      {interleave(
        bloodReportResults.map((bloodReportResult) => (
          <div className="break-inside-avoid" key={bloodReportResult.id}>
            <PdfBiomarkerRow bloodReportResult={bloodReportResult} />
            {showHighLowDescriptions && (
              <div className="mb-6 mt-1">
                <BiomarkerHighLowDescription
                  bloodReportResult={bloodReportResult}
                  biomarkerCustomDescription={
                    biomarkerCustomDescriptionsMapping[
                      bloodReportResult.relationships.biomarker.data.id
                    ]
                  }
                />
              </div>
            )}
          </div>
        )),
        (_, key) => (
          <BiomarkerDivider key={`sep_${key}`} />
        )
      )}
    </>
  )
}

const BiomarkerHighLowDescription: React.FC<{
  bloodReportResult: BloodReportResult | PatientPortalBloodReportResult
  biomarkerCustomDescription: BiomarkerCustomDescription | undefined
}> = ({ bloodReportResult, biomarkerCustomDescription }) => {
  const biomarkerStatus = getBiomarkerStatus(bloodReportResult)
  const biomarker = useCachedResource<Biomarker>(
    bloodReportResult.relationships.biomarker.data
  )

  if (!biomarker || !biomarkerStatus) return null

  const descriptionsMapping = {
    [BiomarkerStatus.LOW]:
      biomarkerCustomDescription?.attributes.low_description ??
      biomarker?.attributes.low_description,
    [BiomarkerStatus.BELOW_OPTIMAL]:
      biomarkerCustomDescription?.attributes.low_description ??
      biomarker?.attributes.low_description,
    [BiomarkerStatus.HIGH]:
      biomarkerCustomDescription?.attributes.high_description ??
      biomarker?.attributes.high_description,
    [BiomarkerStatus.ABOVE_OPTIMAL]:
      biomarkerCustomDescription?.attributes.high_description ??
      biomarker?.attributes.high_description,
    [BiomarkerStatus.NORMAL]:
      biomarkerCustomDescription?.attributes.description ??
      biomarker?.attributes.description,
    [BiomarkerStatus.OPTIMAL]:
      biomarkerCustomDescription?.attributes.description ??
      biomarker?.attributes.description,
    [BiomarkerStatus.ABNORMAL]:
      biomarkerCustomDescription?.attributes.description ??
      biomarker?.attributes.description,
  }

  const arrowMapping = {
    [BiomarkerStatus.LOW]: "▼ ",
    [BiomarkerStatus.BELOW_OPTIMAL]: "▼ ",
    [BiomarkerStatus.HIGH]: "▲ ",
    [BiomarkerStatus.ABOVE_OPTIMAL]: "▲ ",
    [BiomarkerStatus.NORMAL]: "",
    [BiomarkerStatus.OPTIMAL]: "",
    [BiomarkerStatus.ABNORMAL]: "",
  }

  return (
    <div>
      {descriptionsMapping[biomarkerStatus] && arrowMapping[biomarkerStatus] && (
        <div className="text-navy text-xs font-normal mt-2">
          {arrowMapping[biomarkerStatus]}
          {descriptionsMapping[biomarkerStatus]}
        </div>
      )}
    </div>
  )
}

const BiomarkerDivider: React.FC = () => {
  return <div className="w-full bg-slate-50 h-0.5 mt-2 mb-2"></div>
}

import { useParams } from "react-router-dom"

import PatientPortalOrderQuickLinks from "app/patient-portal/footer/PatientPortalOrderQuickLinks"
import { PatientPortalPatient } from "app/patient-portal/types"
import useCachedResource from "app/swr/hooks/use-cached-resource"

import PatientPortalFAQs from "./PatientPortalFAQs"

const PatientPortalSupport = () => {
  const { patientId } = useParams<{
    patientId: string
  }>()
  const patient = useCachedResource<PatientPortalPatient>({
    id: patientId,
    type: "patient_portal_patient",
  })

  return (
    <div className="flex flex-col gap-3 sm:gap-5">
      <PatientPortalOrderQuickLinks
        title="Get Support"
        messageSupportButtonColor="primary"
      />
      <PatientPortalFAQs faqs={patient?.attributes.faqs} />
    </div>
  )
}

export default PatientPortalSupport

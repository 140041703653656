import { Patient } from "app/types"
import { SimpleBodySystem } from "types/body-system"

import ResultsOverTimeTable from "./table/ResultsOverTimeTable"
import { InRangeOption, ResultsOverTimeDateGrouping } from "./types/types"

interface Props {
  primaryBodySystems: SimpleBodySystem[]
  availableDates: string[]
  patient: Patient
  timeSeriesGrouping: ResultsOverTimeDateGrouping
  inRangeValue: InRangeOption
  bodySystemId: string
  isAvailableDatesLoading: boolean
  biomarkerSearchValue: string
}

const ResultsOverTimeContainer = ({
  primaryBodySystems,
  availableDates,
  patient,
  timeSeriesGrouping,
  inRangeValue,
  bodySystemId,
  isAvailableDatesLoading,
  biomarkerSearchValue,
}: Props) => {
  return (
    <div>
      <ResultsOverTimeTable
        primaryBodySystems={primaryBodySystems}
        groupedBy={timeSeriesGrouping}
        patient={patient}
        availableDates={availableDates}
        inRangeValue={inRangeValue}
        dropdownBodySystemId={bodySystemId}
        isAvailableDatesLoading={isAvailableDatesLoading}
        biomarkerSearchValue={biomarkerSearchValue}
      />
    </div>
  )
}

export default ResultsOverTimeContainer

import * as React from "react"

import { Typography } from "@material-ui/core"

import { ReactComponent as FoodPlanListItemIcon } from "app/assets/images/food-plan-list-item.svg"
import FoodPlanListItemDropdownMenu from "app/food-plans/components/PatientFoodPlanListToolbar/FoodPlanListItemDropdownMenu"
import useEventCallback from "app/hooks/use-event-callback"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceIdentifier } from "app/swr/types"
import { colors, primaryColor, textPrimaryColor } from "app/theme"
import formatDateForHuman from "app/utils/format-date-for-human"
import makeAppStyles from "app/utils/makeAppStyles"
import { FoodPlan } from "types/food-plan"
import { FoodPlanVersion } from "types/food-plan-version"

const useStyles = makeAppStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.0, 1.5),
    gap: theme.spacing(1.5),
    backgroundColor: colors.blueGray[100],
    border: `1px solid ${colors.blueGray[200]}`,
    borderRadius: 8,
    cursor: "pointer",
    "&:hover, &:focus": {
      backgroundColor: colors.blue[50],
      borderColor: primaryColor,
    },
  },
  labels: {
    whiteSpace: "nowrap",
  },
  labelFoodPlan: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: -2,
    color: textPrimaryColor,
    lineHeight: 1.35,
  },
  labelEditedDate: {
    fontSize: 14,
    fontWeight: 400,
    color: textPrimaryColor,
    lineHeight: 1.35,
  },
  actions: {
    padding: theme.spacing(1.0),
  },
}))

export interface FoodPlanListItemProps {
  identifier: ResourceIdentifier
  isDownloading?: boolean
  onDelete: () => void
  onDownload: () => void
  onEdit: () => void
}

export default function FoodPlanListItem({
  identifier,
  isDownloading,
  onDelete,
  onDownload,
  onEdit,
}: FoodPlanListItemProps) {
  const classes = useStyles()
  const foodPlan = useCachedResource<FoodPlan>(identifier)
  const foodPlanVersion = useCachedResource<FoodPlanVersion>(
    foodPlan?.relationships?.latest_version?.data
  )

  const onPointerDown = useEventCallback((event: React.PointerEvent) => {
    if (event.button === 0 && event.ctrlKey === false) {
      onEdit()
    }
  })

  const onKeyDown = useEventCallback((event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === " ") {
      onEdit()
    }
  })

  if (!foodPlanVersion) {
    return null
  }

  return (
    <div
      className={classes.root}
      onPointerDown={onPointerDown}
      onKeyDown={onKeyDown}
      tabIndex={0}
      role="button"
    >
      <FoodPlanListItemIcon viewBox="0 0 34 42" />

      <div className={classes.labels}>
        <Typography className={classes.labelFoodPlan} variant="h6">
          Food Plan
        </Typography>
        <Typography
          className={classes.labelEditedDate}
          component="span"
          variant="body1"
        >
          Edited:{" "}
          {formatDateForHuman(
            foodPlanVersion.attributes.updated_at ||
              foodPlanVersion.attributes.created_at
          )}
        </Typography>
      </div>

      <div
        className={classes.actions}
        // Prevent clicks on the dropdown menu from bubbling up to the list item
        onPointerDown={(e) => {
          e.stopPropagation()
        }}
        // Prevent keyboard events on the dropdown menu from bubbling up to the list item
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <FoodPlanListItemDropdownMenu
          isDownloading={isDownloading}
          onDelete={onDelete}
          onDownload={onDownload}
          onEdit={onEdit}
        />
      </div>
    </div>
  )
}

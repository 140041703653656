import { useMemo } from "react"

import { orderBy } from "lodash"
import { useHistory } from "react-router-dom"

import { getFoodPlanPath } from "app/food-plans/utils/paths"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useQuery from "app/hooks/use-query"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { Patient } from "app/types"
import { FoodPlan, FoodPlanCreate } from "types/food-plan"
import {
  PatientDocument,
  PatientDocumentType,
  PatientWithDocuments,
} from "types/patient-with-documents"
import { UserResult } from "types/user-result"

import {
  DOCUMENT_TYPE_QUERY_PARAM_KEY,
  DocumentTypeQueryParamValue,
  getDocumentParamValueOption,
  getDocumentTypeOption,
} from "../DocumentTypeSelect"
import useDeleteDocumentConfirmModal from "./use-delete-document-confirm-modal"

interface Props {
  patient: Patient
}

export function usePatientDocuments({ patient }: Props) {
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()

  const history = useHistory()
  const query = useQuery()
  const {
    data: patientWithDocuments,
    mutate,
    ...patientSwr
  } = useResourceSWR<PatientWithDocuments>(
    `/patient_with_documents/${patient.id}/`,
    {
      include: [
        "food_plans.latest_version",
        "results_interpretations.clinic",
        "ordered_results.kit",
        "patient_results.user",
      ],
    },
    {
      // Ensures that if you quickly switch tabs it will reload the data
      dedupingInterval: 100,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  )

  const onCreateFoodPlan = useEventCallback(async () => {
    try {
      const { data } = await resourceRequest<
        ResourceResponse<FoodPlan>,
        FoodPlanCreate
      >({
        method: "post",
        url: "/food_plans/",
        include: ["latest_version"],
        data: {
          data: {
            type: "food_plan",
            relationships: {
              patient: {
                data: {
                  type: "patient",
                  id: patient.id,
                },
              },
            },
          },
        },
      })

      history.push(getFoodPlanPath(patient.id, data.id))
    } catch (error) {
      handleApiError(error)
    }
  })

  const onDeleteFoodPlan = useEventCallback(async (foodPlanId: string) => {
    try {
      await mutate(
        async (previousData) => {
          if (!previousData) return previousData

          await resourceRequest({
            url: `/food_plans/${foodPlanId}/`,
            method: "delete",
          })

          return {
            ...previousData,
            relationships: {
              ...previousData.relationships,
              food_plans: {
                meta: {
                  count: previousData.relationships.food_plans.meta.count - 1,
                },
                data: previousData.relationships.food_plans.data.filter(
                  (foodPlan) => foodPlan.id !== foodPlanId
                ),
              },
            },
          }
        },
        { revalidate: false, throwOnError: true }
      )
    } catch (error) {
      handleApiError(error)
    }
  })

  const onDeleteResultsSummary = useEventCallback(async (id: string) => {
    try {
      await mutate(
        async (previousData) => {
          if (!previousData) return previousData

          await resourceRequest({
            url: `/results_interpretations/${id}/`,
            method: "delete",
          })

          return {
            ...previousData,
            relationships: {
              ...previousData.relationships,
              results_interpretations: {
                meta: {
                  count:
                    previousData.relationships.results_interpretations.data
                      .length - 1,
                },
                data: previousData.relationships.results_interpretations.data.filter(
                  (resultsInterpretation) => resultsInterpretation.id !== id
                ),
              },
            },
          }
        },
        { revalidate: false, throwOnError: true }
      )
    } catch (error) {
      handleApiError(error)
    }
  })

  const onDeletePatientResult = useEventCallback(async (id: string) => {
    try {
      await mutate(
        async (previousData) => {
          if (!previousData) return previousData

          await resourceRequest({
            method: "delete",
            url: `/user_results/${id}/`,
          })

          // Update global SWR cache used for modal too
          await mutateResource<UserResult>(
            { id: id, type: "user_result" },
            undefined
          )

          return {
            ...previousData,
            relationships: {
              ...previousData.relationships,
              patient_results: {
                meta: {
                  count:
                    previousData.relationships.patient_results.data.length - 1,
                },
                data: previousData.relationships.patient_results.data.filter(
                  (patientResult) => patientResult.id !== id
                ),
              },
            },
          }
        },
        { revalidate: false, throwOnError: true }
      )
    } catch (error) {
      handleApiError(error)
    }
  })

  const deleteDocument = async (document: PatientDocument) => {
    if (document.type === PatientDocumentType.FOOD_PLAN) {
      onDeleteFoodPlan(document.id)
    } else if (document.type === PatientDocumentType.RESULTS_SUMMARY) {
      onDeleteResultsSummary(document.id)
    } else if (document.type === PatientDocumentType.PATIENT_RESULT) {
      onDeletePatientResult(document.id)
    }
  }

  const deleteDocumentConfirmModal = useDeleteDocumentConfirmModal()
  const onDocumentDelete = (document: PatientDocument) => {
    deleteDocumentConfirmModal.show({
      patientName: patient.first_name,
      documentTitle: getDocumentTypeOption(document.type)?.label,
      onDelete: () => deleteDocument(document),
    })
  }

  // Get all the documents in one collection because we need to sort them
  const foodPlanIdentifiers =
    patientWithDocuments?.relationships.food_plans.data || []
  const orderedResultIdentifiers =
    patientWithDocuments?.relationships.ordered_results.data || []
  const resultsInterpretationIdentifiers =
    patientWithDocuments?.relationships.results_interpretations.data || []
  const patientResultIdentifiers =
    patientWithDocuments?.relationships.patient_results.data || []

  let documentIdentifiers = foodPlanIdentifiers.concat(
    orderedResultIdentifiers,
    resultsInterpretationIdentifiers,
    patientResultIdentifiers
  )
  const totalDocumentCount = documentIdentifiers.length

  // Filter the results
  const documentType = query.get(DOCUMENT_TYPE_QUERY_PARAM_KEY)
  const documentTypeOption = getDocumentParamValueOption(documentType)
  if (documentTypeOption && documentType !== DocumentTypeQueryParamValue.ALL) {
    documentIdentifiers = documentIdentifiers.filter(
      (identifier) => identifier.type === documentTypeOption.type
    )
  }

  const cachedDocuments =
    useCachedCollection<PatientDocument>(documentIdentifiers)
  const sortedDocuments = useMemo(
    () => orderBy(cachedDocuments, "attributes.created_at", "desc"),
    [cachedDocuments]
  )
  return {
    ...patientSwr,
    data: sortedDocuments,
    totalDocumentCount,
    selectedDocumentType: documentTypeOption,
    onCreateFoodPlan,
    onDocumentDelete,
  }
}

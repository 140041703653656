import { useMemo } from "react"

import { Dictionary } from "lodash"

import {
  groupOrderedTests,
  LAB_COMPANY_PANELS,
} from "app/dataServices/labTestDataService"
import { orderedTestWithHighestPrioritySpecimenIssue } from "app/dataServices/specimenIssueDataService"
import PatientOrdersOrderedTestDashboard from "app/main/dashboard/OrderList/PatientOrdersOrderedTestDashboard"
import {
  OrderedTest,
  Patient,
  PatientOrdersOrder as PatientOrdersOrderType,
} from "app/types"

import PatientOrdersOrderedTest from "./PatientOrdersOrderedTest"

const PatientOrdersOrderedTestList = ({
  order,
  patient,
  dashboardView,
  dashboardColumns,
  appliedFilters,
  isStorefrontDashboard,
}: {
  order: PatientOrdersOrderType
  patient: Patient
  dashboardView?: boolean
  dashboardColumns?: number
  appliedFilters?: any
  isStorefrontDashboard?: boolean
}) => {
  const PatientOrdersOrderedTestComponent = dashboardView
    ? PatientOrdersOrderedTestDashboard
    : PatientOrdersOrderedTest

  const groupedOrderedTests: Dictionary<OrderedTest[]> = useMemo(() => {
    const groupedTests = groupOrderedTests(order.ordered_tests)

    // Sort the tests so that the tests with results are at the top
    return (
      Object.entries(groupedTests)
        // Any groups with results should be at the top
        .sort(([groupKeyA], [groupKeyB]) => {
          if (
            groupedTests[groupKeyA].some(
              (orderedTest) => orderedTest?.latest_ordered_result
            )
          ) {
            return -1
          }
          if (
            groupedTests[groupKeyB].some(
              (orderedTest) => orderedTest?.latest_ordered_result
            )
          ) {
            return 1
          }
          return 0
        })
        .reduce(
          // Reformat list of [key, value] pairs back into an object that is sorted correctly
          (obj, [groupKey, orderedTests]) => {
            // Sort ordered tests within group so that tests with results are at the top
            obj[groupKey] = orderedTests.sort((a, b) => {
              if (a.latest_ordered_result && !b.latest_ordered_result) {
                return -1
              }
              if (!a.latest_ordered_result && b.latest_ordered_result) {
                return 1
              }
              return 0
            })
            return obj
          },
          {}
        )
    )
  }, [order.ordered_tests])

  return (
    <>
      {Object.entries(groupedOrderedTests).map(([groupKey, orderedTests]) => {
        // Render panels as a single test
        if (LAB_COMPANY_PANELS.includes(groupKey) && orderedTests.length > 1) {
          // For panels, we use the first test that has results.
          // if none have results, then just take the first test.
          const orderedTestWithResults = orderedTests.find(
            (test) => test.results
          )
          let orderedTest = orderedTestWithResults || orderedTests[0]

          const orderedTestWithSpecimenIssue =
            orderedTestWithHighestPrioritySpecimenIssue(orderedTests)

          if (orderedTestWithSpecimenIssue) {
            orderedTest = {
              ...orderedTest,
              status: orderedTestWithSpecimenIssue.status,
              specimen_issue: orderedTestWithSpecimenIssue.specimen_issue,
            }
          }

          return (
            <PatientOrdersOrderedTestComponent
              key={orderedTest.id}
              order={order}
              orderedTest={orderedTest}
              patient={patient}
              displayName={`${groupKey} Panel`}
              panelLabTestNames={orderedTests.map((t) => t.lab_test.name)}
              dashboardColumns={dashboardColumns}
              appliedFilters={appliedFilters}
              isStorefrontDashboard={isStorefrontDashboard}
            />
          )
        }

        // Add-ons and unrelated tests are rendered individually
        return orderedTests.map((orderedTest) => (
          <PatientOrdersOrderedTestComponent
            key={orderedTest.id}
            order={order}
            orderedTest={orderedTest}
            displayName={orderedTest.lab_test.name}
            patient={patient}
            dashboardColumns={dashboardColumns}
            appliedFilters={appliedFilters}
            isStorefrontDashboard={isStorefrontDashboard}
          />
        ))
      })}
    </>
  )
}

export default PatientOrdersOrderedTestList

import { Typography, makeStyles } from "@material-ui/core"
import { StripeError } from "@stripe/stripe-js"

import Button from "app/components/design-system/Button"

const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: 380,
    width: "100%",
  },
  error: {
    marginTop: theme.spacing(1),
  },
}))

function getLoadingErrorMessage() {
  return "A problem occurred setting up identity verification. Refresh to try again. Contact support if the problem persists."
}

function getVerifyErrorMessage(verifyError: StripeError) {
  switch (verifyError.code) {
    case "consent_declined":
      return "Consent declined. Please, try again."
    case "session_cancelled":
      return "Session cancelled. Please, try again."
    default:
      return "Session error. Please, try again."
  }
}

export interface IdentityVerificationButtonProps {
  error?: StripeError
  loading: boolean
  loadingError?: Error
  onClick: () => Promise<void>
  verifying: boolean
}

export default function IdentityVerificationButton({
  error,
  loading,
  loadingError,
  onClick,
  verifying,
}: IdentityVerificationButtonProps) {
  const classes = useStyles()
  return (
    <>
      <Button
        className={classes.button}
        color="primary"
        disabled={verifying || loading || Boolean(loadingError)}
        loading={verifying || loading}
        onClick={onClick}
        size="large"
        variant="contained"
      >
        Verify Identity
      </Button>

      {loadingError && (
        <Typography color="error" className={classes.error}>
          {getLoadingErrorMessage()}
        </Typography>
      )}

      {error && (
        <Typography color="error" className={classes.error}>
          {getVerifyErrorMessage(error)}
        </Typography>
      )}
    </>
  )
}

import { CircularProgress } from "@material-ui/core"

import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg"
import TextField from "app/components/forms/TextField"
import { navy, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles<{ filterApplied?: boolean }>((theme) => ({
  endAdornment: {
    position: "absolute",
    right: 9,
    pointerEvents: "none",
  },
  textField: {
    backgroundColor: "white",
    minWidth: 200,
    maxWidth: 300,
    fontSize: 14,
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "6px",
      border: (props) =>
        props.filterApplied ? `2px solid ${primaryColor}` : "1px solid #D3DCE3",
      minWidth: 200,
      maxWidth: 300,
    },
  },
  placeholder: {
    "&::placeholder": {
      color: navy,
      opacity: 1,
    },
    color: (props) => (props.filterApplied ? primaryColor : "inherit"),
  },
}))

export interface BiomarkerFilterProps {
  isLoading?: boolean
  isMore?: boolean
  label?: string
  onChange: (value?: string) => void
  value?: any
}

export default function BiomarkerFilter({
  isLoading,
  label,
  onChange,
  value,
}: BiomarkerFilterProps) {
  const classes = useStyles({ filterApplied: Boolean(value) })

  const icon = (
    <SearchIcon
      width={16}
      height={16}
      fill={primaryColor}
      className={classes.endAdornment}
    />
  )

  return (
    <div className={classes.textField}>
      <TextField
        aria-label={label}
        fullWidth
        placeholder={label}
        size="small"
        variant="outlined"
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          classes: { input: classes.placeholder },
          endAdornment: (
            <>
              {isLoading ? (
                <CircularProgress
                  className={classes.endAdornment}
                  color="primary"
                  size={16}
                />
              ) : (
                icon
              )}
            </>
          ),
        }}
        {...(value ? { focused: Boolean(value) } : {})}
      />
    </div>
  )
}

import { useMemo, useRef } from "react"

import * as Popover from "@radix-ui/react-popover"

import BodyText from "app/components/design-system/BodyText"
import useDebounceState from "app/hooks/use-debounce-state"
import AddBiomarkerSearchBiomarkerOptions from "app/main/settings/RupaBloodDashboards/BiomarkerCustomDescriptionTable/AddBiomarkerSearchBiomarkerOptions"
import useAvailableBiomarkers from "app/main/settings/RupaBloodDashboards/BiomarkerCustomDescriptionTable/hooks/use-available-biomarkers"
import useBiomarkerSearchCursorTracking from "app/main/settings/RupaBloodDashboards/BiomarkerCustomDescriptionTable/hooks/use-biomarker-search-cursor-tracking"
import AddBiomarkerSearchInput from "app/results-summary/components/ResultsSummaryEditor/components/AddBiomarker/AddBiomarkerSearchInput"
import AddBiomarkerSearchLoading from "app/results-summary/components/ResultsSummaryEditor/components/AddBiomarker/AddBiomarkerSearchLoading"
import AddBiomarkerSearchPopoverContent from "app/results-summary/components/ResultsSummaryEditor/components/AddBiomarker/AddBiomarkerSearchPopoverContent"
import { Biomarker } from "types/biomarker"

interface Props {
  onBiomarkerSelect: (biomarker: Biomarker) => void
  existingBiomarkerIds: string[]
  widthOfToggleContent: string | number
  isFromBiomarkerForm?: boolean
  actionText: string
  toggleSearch: (isOpen: boolean) => void
}

const BiomarkerSearchPopover = ({
  onBiomarkerSelect,
  existingBiomarkerIds,
  widthOfToggleContent,
  isFromBiomarkerForm,
  actionText,
  toggleSearch,
}: Props) => {
  const triggerRef = useRef<any>()
  const contentRef = useRef<any>()

  const [debounceSearch, search, setSearch] = useDebounceState("", 300)

  const { biomarkers, isLoading, isValidating } = useAvailableBiomarkers(
    debounceSearch,
    true
  )

  const {
    biomarkerCursor,
    moveBiomarkerCursorDown,
    moveBiomarkerCursorTo,
    moveBiomarkerCursorUp,
  } = useBiomarkerSearchCursorTracking(biomarkers)

  const filteredBiomarkers = useMemo(() => {
    return biomarkers?.filter(
      (biomarker) => !existingBiomarkerIds?.includes(biomarker.id)
    )
  }, [biomarkers, existingBiomarkerIds])

  const handleBiomarkerSelect = (biomarkerCursor: Biomarker) => {
    onBiomarkerSelect(biomarkerCursor)
    toggleSearch(false)
  }

  const handleToggleAdding = () => {
    setSearch("")
    toggleSearch(false)
  }

  return (
    <div className={isFromBiomarkerForm ? "w-full h-[38px]" : "w-full"}>
      <Popover.Root open onOpenChange={handleToggleAdding}>
        <AddBiomarkerSearchInput
          moveBiomarkerCursorDown={moveBiomarkerCursorDown}
          moveBiomarkerCursorUp={moveBiomarkerCursorUp}
          onEnterSelect={() =>
            biomarkerCursor && handleBiomarkerSelect(biomarkerCursor)
          }
          search={search}
          setSearch={setSearch}
          showLoading={isValidating}
          triggerRef={triggerRef}
          onToggleAdding={() => handleToggleAdding()}
          customRootClasses={
            isFromBiomarkerForm
              ? "px-2 py-1 bg-slate-50 border border-slate-200 gap-0 text-sm rounded-[5px] h-[38px]"
              : ""
          }
          customInputClasses={isFromBiomarkerForm ? "bg-slate-50" : ""}
        />

        <AddBiomarkerSearchPopoverContent
          contentRef={contentRef}
          width={widthOfToggleContent}
          zIndex={9999}
        >
          {filteredBiomarkers?.length ? (
            <AddBiomarkerSearchBiomarkerOptions
              cursor={biomarkerCursor}
              identifiers={filteredBiomarkers}
              actionText={actionText}
              moveCursorTo={moveBiomarkerCursorTo}
              onSelect={handleBiomarkerSelect}
            />
          ) : isLoading || isValidating ? (
            <AddBiomarkerSearchLoading />
          ) : (
            <BodyText variant="body1">
              {`No biomarkers found${
                search ? ` matching search "${search}"` : ""
              }`}
            </BodyText>
          )}
        </AddBiomarkerSearchPopoverContent>
      </Popover.Root>
    </div>
  )
}

export default BiomarkerSearchPopover

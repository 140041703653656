import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import _ from "lodash"

import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core"

import UpRightArrow from "app/assets/icons/up-right-arrow.svg"
import rupaHealthLogo from "app/assets/images/logos/rupa-logo-mark.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import useConfirmationModal from "app/components/modals/generic/hooks/use-confirmation-modal"
import {
  PHYSICIAN_AUTHORIZATION_GUIDE_URL,
  PHYSICIAN_SERVICES_ENABLE_METHODS,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { getPractitioner, updatePersonalSettings } from "app/store/actions"
import { primaryColor } from "app/theme"
import { RootState } from "app/types"
import { getOrderTypeLabel } from "app/utils/order-utils"

import { StyledFormControlLabel } from "./StyledFormControlLabel"
import { StyledSwitch } from "./StyledSwitch"

const styles = (theme: Theme) => ({
  header: {
    alignItems: "center",
    display: "flex",
  },
  logo: {
    marginRight: 8,
    marginBottom: -6,
    width: "25px",
    height: "auto",
  },
  learnMoreArrow: {
    width: 10,
  },
  styledLink: {
    color: primaryColor,
    fontWeight: 600,
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
    },
  },
})

const useStyles = makeStyles(styles)

/**
 * Settings component for Physician Authorization
 */
const PhysicianAuthorizationSettings = ({
  handleToggle,
  shouldOpenPhysicianServicesEnableModalOnLoad,
}) => {
  const classes = useStyles(styles)
  const practitioner = useSelector(
    ({ practitioner }: RootState) => practitioner
  )
  const dispatch = useDispatch()

  const physicianServicesOptInModal = usePhysicianServicesOptInModal()
  const confirmationModal = useConfirmationModal()

  const [physicianServicesBackfillPhase1Enabled] = useFeatureFlag(
    FeatureFlag.PhysicianServicesBackfillPhase1
  )

  const onDisable = () => {
    dispatch(
      updatePersonalSettings({
        vendor_physician_authorization_enabled: false,
        physician_services_enable_method: "",
      })
    )
  }

  const handlePhysicianServicesToggle = () => {
    if (!practitioner.vendor_physician_authorization_enabled) {
      physicianServicesOptInModal.show({
        onClose: () => {
          physicianServicesOptInModal.remove()
        },
      })
    } else {
      let confirmationMessage =
        practitioner.has_published_physician_services_labshop
          ? `Removing ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} will turn off all active Labshops, and cancel any unpaid orders. Are you sure you’d like to do this?`
          : `Removing ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} will cancel any unpaid orders. Are you sure you'd like to do this?`
      confirmationModal.show({
        title: "Are you sure?",
        message: confirmationMessage,
        backButtonTitle: "Go back",
        confirmButtonTitle: `Turn Off ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}`,
        confirmButtonColor: "destructiveSecondary",
        onClose: confirmationModal.remove,
        loading: false,
        handleConfirm: () => {
          confirmationModal.remove()
          onDisable()
        },
      })
    }
  }

  const isBlocked = practitioner.physician_services_blocked
  const isLoading = _.isEmpty(practitioner)

  const toolTipContent = () => {
    if (isBlocked) {
      return (
        <BodyText>
          Unable to turn on {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}. Please
          message our team to continue.
        </BodyText>
      )
    }
    if (practitioner.is_clinic_staff) {
      return (
        <BodyText>
          Each practitioner must enable {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}{" "}
          within their own accounts.
        </BodyText>
      )
    }
    return <></>
  }

  const informationText = (
    <BodyText>
      By adding {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to your account, your
      clients can gain access to testing on Rupa. A Signing Physician will
      review the results for a critical value.
      <a
        href={PHYSICIAN_AUTHORIZATION_GUIDE_URL}
        target="_blank"
        rel="noreferrer"
        className={classes.styledLink}
      >
        {" "}
        Learn more{" "}
        <img className={classes.learnMoreArrow} src={UpRightArrow} alt="" />
      </a>
    </BodyText>
  )

  useEffect(() => {
    dispatch(getPractitioner())
  }, [dispatch])

  useEffect(() => {
    const updatedTerms =
      physicianServicesBackfillPhase1Enabled &&
      practitioner.vendor_physician_authorization_enabled &&
      practitioner.physician_services_enable_method !==
        PHYSICIAN_SERVICES_ENABLE_METHODS.OPT_IN
    if (
      shouldOpenPhysicianServicesEnableModalOnLoad &&
      !isLoading &&
      (!practitioner.vendor_physician_authorization_enabled || updatedTerms)
    ) {
      physicianServicesOptInModal.show({
        updatedTerms: updatedTerms,
        practitionerHasPhysicianServicesLabshop:
          practitioner.has_published_physician_services_labshop,
        onClose: () => {
          physicianServicesOptInModal.remove()
        },
      })
    }
  }, [
    isLoading,
    shouldOpenPhysicianServicesEnableModalOnLoad,
    practitioner,
    physicianServicesBackfillPhase1Enabled,
  ])

  // Loading state
  if (isLoading) {
    return <CircularProgress ingaria-label="Loading" size={28} />
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.header}>
          <BodyText weight="semibold" size="lg">
            <img className={classes.logo} src={rupaHealthLogo} alt="" />
            {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
          </BodyText>
        </Grid>
      </Grid>

      <div>
        <StyledFormControlLabel
          control={
            <Tooltip
              title={toolTipContent()}
              disableHoverListener={!practitioner.is_clinic_staff && !isBlocked}
              placement="bottom"
              arrow
            >
              {/* Must place in div for tooltip to work with safari */}
              <div>
                <StyledSwitch
                  checked={practitioner.vendor_physician_authorization_enabled}
                  onChange={handlePhysicianServicesToggle}
                  name="vendor_physician_authorization_enabled"
                  disabled={practitioner.is_clinic_staff || isBlocked}
                />
              </div>
            </Tooltip>
          }
          label={
            <>
              <BodyText weight="semibold">
                {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
              </BodyText>
              {informationText}
            </>
          }
        />
        {practitioner.vendor_physician_authorization_enabled &&
          !practitioner.is_clinic_staff &&
          !isBlocked && (
            <StyledFormControlLabel
              control={
                <StyledSwitch
                  name="default_physician_authorization"
                  checked={practitioner.default_physician_authorization}
                  onChange={() =>
                    handleToggle("default_physician_authorization")
                  }
                  disabled={practitioner.is_clinic_staff || isBlocked}
                />
              }
              label={
                <>
                  <BodyText weight="semibold">
                    Use the {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} Signing
                    Physician as the default for new{" "}
                    {getOrderTypeLabel(true).toLowerCase()}s
                  </BodyText>
                  <BodyText>
                    Save time by automatically using{" "}
                    {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} for new{" "}
                    {getOrderTypeLabel(true).toLowerCase()}s. You can still
                    choose whether to use a different signing practitioner every
                    time you start a {getOrderTypeLabel(true).toLowerCase()}.
                  </BodyText>
                </>
              }
            />
          )}
      </div>
    </>
  )
}

export default PhysicianAuthorizationSettings

import { useEffect, useState } from "react"

import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"
import { JobStatus } from "types/job-status"

const MAX_POLLING_TIME_SEC = 300
const POLLING_INTERVAL = 1000

/**
 * Hook that returns the status of a job given its id.
 *
 * As soon as we have a job id, we start polling for its status and exit only when we have been polling for more
 * than 5 minutes or the polling is killed by the parent component.
 *
 * We poll every 1 second by default
 */
export default function useStatus({
  jobId,
  pollingInterval = POLLING_INTERVAL,
  maxPollingTimeSec = MAX_POLLING_TIME_SEC,
  fullSummary = false,
}: {
  jobId?: string
  pollingInterval?: number
  maxPollingTimeSec?: number
  fullSummary?: boolean
}) {
  // Number of seconds that have passed since start of polling
  const [pollingCountSec, setPollingCountSec] = useState(0)

  let pathname = jobId ? `/api/normalized/status/${jobId}` : null
  if (pathname && fullSummary) {
    pathname = `${pathname}?full_summary`
  }

  const { data: jobStatus } = useSWR<JobStatus>(
    pathname,
    async (url) => {
      const response = await axios.get(getApiBaseUrl() + url)
      return response.data
    },
    {
      refreshInterval:
        pollingCountSec < MAX_POLLING_TIME_SEC ? pollingInterval : 0,
    }
  )

  useEffect(() => {
    setPollingCountSec((count) => count + 1)
  }, [jobStatus])

  return {
    jobStatus,
    stopPolling: () => setPollingCountSec(maxPollingTimeSec),
  }
}

import * as Sentry from "@sentry/react"

import { API } from "app/api"
import { showMessage } from "app/store/actions/fuse"
import { SplitItInitiateResponse } from "app/types"

export const START_INITIATE_SPLITIT =
  "[PATIENT CHECKOUT: SPLITIT] START INITIATE SPLITIT"
export const SUCCESS_INITIATE_SPLITIT =
  "[PATIENT CHECKOUT: SPLITIT] SUCCESS INITIATE SPLITIT"
export const FAILURE_INITIATE_SPLITIT =
  "[PATIENT CHECKOUT: SPLITIT] FAILURE INITIATE SPLITIT"
export const RECEIVE_SPLITIT_UPDATE =
  "[PATIENT CHECKOUT: SPLITIT] RECEIVE SPLITIT UPDATE"

export function initiateSplitIt(checkoutToken: string) {
  return async (dispatch) => {
    dispatch(startInitiateSplitIt())

    try {
      const response = await API.SplitIt.initiate(checkoutToken)
      const data: SplitItInitiateResponse = response.data

      dispatch(successInitiateSplitIt(data))

      return data
    } catch (error) {
      Sentry.captureException(error)
      dispatch(failureInitiateSplitIt(error))
    }
  }
}

export function updateSplitIt(checkoutToken: string, payload: any) {
  return async (dispatch) => {
    try {
      const response = await API.SplitIt.update(checkoutToken, payload)
      const data: SplitItInitiateResponse = response.data

      dispatch(receiveSplitItUpdate(data))

      return data
    } catch (error) {
      dispatch(
        showMessage({
          message:
            "There was a problem with your Splitit payment. Please try again!",
          variant: "error",
          anchorOrigin: {
            vertical: "bottom", //top bottom
            horizontal: "left", //left center right
          },
        })
      )

      // re-throw error for component-level error handling
      throw error
    }
  }
}

export function startInitiateSplitIt() {
  return {
    type: START_INITIATE_SPLITIT,
  }
}

export function successInitiateSplitIt(data: SplitItInitiateResponse) {
  return {
    type: SUCCESS_INITIATE_SPLITIT,
    payload: data,
  }
}

export function failureInitiateSplitIt(error: any) {
  return {
    type: FAILURE_INITIATE_SPLITIT,
    payload: error,
  }
}

export function receiveSplitItUpdate(update: SplitItInitiateResponse) {
  return {
    type: RECEIVE_SPLITIT_UPDATE,
    payload: update,
  }
}

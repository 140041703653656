import { useMemo } from "react"

import { format, getDaysInMonth, setDate, setMonth, setYear } from "date-fns"
import { range, reverse } from "lodash"

import { KeyboardDatePickerProps } from "@material-ui/pickers"
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@rupahealth/design"

export type DateInputProps = Omit<
  KeyboardDatePickerProps,
  "inputVariant" | "value"
> & {
  value: string | number | Date
}

/*
 * A date component comprised of a select field for year, month, and day.
 *
 * The year provides options for the past 120 years.
 */
const DateInput = ({
  InputProps,
  className,
  value,
  onChange,
  ...inputProps
}: DateInputProps) => {
  const date = useMemo(() => {
    if (!value) {
      return null
    }

    return new Date(value)
  }, [value])

  // Generate the options for each select.
  const {
    days,
    months,
    years: items,
  } = useMemo(() => {
    const now = new Date()
    const thisYear = now.getFullYear()

    // Months are formatted in their long name format, with
    // the number (0, 11), given as the value.
    const months = range(0, 12).map((month) => ({
      label: format(new Date(2000, month, 1), "MMMM"),
      value: month.toString(),
    }))

    // Years are rendered for the past 120 years.
    const years = reverse(range(thisYear - 120, thisYear + 1)).map((year) => ({
      label: year.toString(),
      value: year.toString(),
    }))

    // If there's no date, then we can't render options for the days.
    if (!date) {
      return {
        days: [],
        months,
        years,
      }
    }

    // With a date, we can calculate the days in the selected month.
    return {
      days: range(1, getDaysInMonth(date) + 1).map((day) => ({
        label: day.toString(),
        value: day.toString(),
      })),
      months,
      years,
    }
  }, [date])

  // Build props for each select input.
  const selects = useMemo(() => {
    const newDate = date || new Date()
    return [
      {
        label: "Month",
        value: date ? date.getMonth().toString() : undefined,
        onChangeValue: (month: string) => {
          if (month) {
            onChange(setMonth(newDate, parseInt(month)))
          }
        },
        items: months,
      },
      {
        label: "Day",
        value: date ? date.getDate().toString() : undefined,
        onChangeValue: (day: string) => {
          if (day) {
            onChange(setDate(newDate, parseInt(day)))
          }
        },
        items: days,
      },
      {
        label: "Year",
        value: date ? date.getFullYear().toString() : undefined,
        onChangeValue: (year: string) => {
          if (year) {
            onChange(setYear(newDate, parseInt(year)))
          }
        },
        items: items,
      },
    ]
  }, [date])

  return (
    <div className="flex gap-2 z-[2000]">
      {selects.map(({ label, value, onChangeValue, items }) => (
        <Select value={value} onValueChange={onChangeValue}>
          <SelectTrigger className="text-body" aria-label={label}>
            <SelectValue placeholder={label} />
          </SelectTrigger>
          <SelectContent className="z-[2000]">
            {items.map((item) => (
              <SelectItem value={item.value.toString()} key={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      ))}
    </div>
  )
}

export default DateInput

import { useEffect, useMemo, useState } from "react"

import { faPerson } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress } from "@material-ui/core"
import { Button } from "@rupahealth/design"

import { ReactComponent as FlaskIcon } from "app/assets/icons/flask.svg"
import { ReactComponent as QuestionCircleIcon } from "app/assets/icons/rupa-blood-dashboards/question-circle-outlined.svg"
import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { primaryColor } from "app/theme"
import { Patient } from "app/types"

import ResultsOverTimeContainer from "./ResultsOverTimeContainer"
import ResultsOverTimeEmptyState from "./ResultsOverTimeEmptyState"
import ResultVisualiztionNotifications from "./components/ResultVisualizationNotifications"
import ResultsOverTimeImportButton from "./components/ResultsOverTimeImportButton"
import ResultsOverTimeLegendButton from "./components/ResultsOverTimeLegendButton"
import {
  IMPORT_RESULTS_INTERCOM_SURVEY_ID,
  SUPPORTED_LABS_INTERCOM_ARTICLE_ID,
  SUPPORTED_LABS_INTERCOM_ARTICLE_URL,
} from "./constants/constants"
import BiomarkerSearchBar from "./filter-components/BiomarkerSearchBar"
import BodySystemFilter from "./filter-components/BodySystemFilter"
import InRangeFilter from "./filter-components/InRangeFilter"
import TimeSeriesGroupingToggle from "./filter-components/TimeSeriesGroupingToggle"
import useAvailableResultsOverTimeDates from "./hooks/use-available-results-over-time-dates"
import useBodySystems from "./hooks/use-body-systems"
import {
  DropDownOption,
  InRangeOption,
  ResultsOverTimeDateGrouping,
} from "./types/types"

const BODY_SYSTEM_OPTION_STARTER: DropDownOption[] = [
  {
    key: "all",
    label: <>Body System</>,
    icon: (
      <FontAwesomeIcon
        className="text-slate-500 text-[14px]"
        fill="currentColor"
        icon={faPerson}
      />
    ),
  },
]

interface Props {
  patient: Patient
}

const ResultsOverTime = ({ patient }: Props) => {
  const handleImportResultsClick = () => {
    window.Intercom("startSurvey", IMPORT_RESULTS_INTERCOM_SURVEY_ID)
  }

  const [isResultsOverTimeManualUploadEnabled] = useFeatureFlag(
    FeatureFlag.ResultsOverTimeManualResultsUpload
  )
  const [showResultVisualizationNotifications] = useFeatureFlag(
    FeatureFlag.ShowResultVisualizationNotifications
  )

  const [timeSeriesGrouping, setTimeSeriesGrouping] =
    useState<ResultsOverTimeDateGrouping>(ResultsOverTimeDateGrouping.MONTH)
  const [inRangeValue, setInRangeValue] = useState<InRangeOption>(
    InRangeOption.ALL
  )
  const [bodySystemId, setBodySystemId] = useState<string>("all")
  const [biomarkerSearchValue, setBiomarkerSearchValue] = useState<string>("")

  const {
    bodySystemsForDropdown,
    bodySystemsForDropdownError,
    bodySystemsForTable,
    bodySystemsForTableLoading,
    bodySystemsForTableValidating,
    bodySystemsForTableError,
  } = useBodySystems(patient.id)

  const {
    availableDates,
    error: availableDatesError,
    isLoading: isAvailableDatesLoading,
    isValidating: isAvailableDatesValidating,
  } = useAvailableResultsOverTimeDates(
    patient.id,
    timeSeriesGrouping,
    inRangeValue,
    bodySystemId
  )

  const buildBodySystemOptions = () => {
    return bodySystemsForDropdown.map((bodySystem) => {
      return {
        key: bodySystem.id,
        label: <>{bodySystem.attributes.name}</>,
        icon: <img src={bodySystem.attributes.logo} alt="" width={15} />,
      }
    })
  }

  const availableBodySystemOptions = useMemo(() => {
    const newBodySystems = buildBodySystemOptions()
    return [...BODY_SYSTEM_OPTION_STARTER, ...newBodySystems]
  }, [bodySystemsForDropdown])

  // We want to clear out the search query when the body system or in range dropdowns change
  useEffect(() => {
    setBiomarkerSearchValue("")
  }, [bodySystemId, inRangeValue])

  // TODO: Get designs from Jordan on what this behavior should look like: https://linear.app/rupa-health/issue/PROD-664/handle-results-over-time-api-errors
  if (
    bodySystemsForTableError ||
    bodySystemsForDropdownError ||
    availableDatesError
  ) {
    return <div>Something went wrong</div>
  }

  const showEmptyState =
    !Boolean(availableDates?.length) &&
    !bodySystemsForTableLoading &&
    !isAvailableDatesLoading &&
    inRangeValue === InRangeOption.ALL &&
    bodySystemId === "all" &&
    biomarkerSearchValue === ""

  return (
    <div className="flex flex-col gap-5">
      {showResultVisualizationNotifications && (
        <ResultVisualiztionNotifications patient={patient} />
      )}
      <div className="rounded-lg p-7 bg-white shadow-md mb-2">
        <div className="flex justify-between items-center mb-4 pb-2 border-b-[1px] border-slate-200">
          <div>
            <BodyText
              size="lg"
              weight="semibold"
              className="flex items-center justify-center"
            >
              Trends{" "}
              <Button
                variant={null}
                className="text-primary pl-[6px]"
                onClick={() =>
                  window.Intercom
                    ? window.Intercom(
                        "showArticle",
                        SUPPORTED_LABS_INTERCOM_ARTICLE_ID
                      )
                    : window.open(SUPPORTED_LABS_INTERCOM_ARTICLE_URL, "_blank")
                }
              >
                <QuestionCircleIcon
                  fill={primaryColor}
                  width={15}
                  height={15}
                />
              </Button>
              {(bodySystemsForTableValidating ||
                isAvailableDatesValidating) && (
                <div className="flex flex-col items-center justify-center ml-2">
                  <CircularProgress size={18} />
                </div>
              )}
            </BodyText>
          </div>
          {isResultsOverTimeManualUploadEnabled ? (
            <div className="flex">
              <ResultsOverTimeLegendButton />
              <div className="bg-slate-200 h-[25px] w-[2px] mx-2"></div>
              <ResultsOverTimeImportButton patient={patient} />
            </div>
          ) : (
            <Button
              variant={null}
              className="text-primary max-md:px-0 max-md:justify-start"
              onClick={handleImportResultsClick}
            >
              <FlaskIcon className="mr-2" fill={primaryColor} /> Import Results
              (Coming Soon)
            </Button>
          )}
        </div>

        <div className="flex w-full flex-col gap-7">
          <div className="flex flex-col lg:flex-row gap-3 lg:gap-2 lg:items-center">
            <BiomarkerSearchBar
              handleBiomarkerSearch={(value) =>
                setBiomarkerSearchValue(value.toLowerCase())
              }
              biomarkerSearchValue={biomarkerSearchValue}
            />

            <BodySystemFilter
              availableBodySystemOptions={availableBodySystemOptions}
              bodySystemId={bodySystemId}
              handleBodySystemIdChange={setBodySystemId}
            />

            <InRangeFilter
              inRangeValue={inRangeValue}
              handleInRangeValueChange={setInRangeValue}
            />

            <TimeSeriesGroupingToggle
              currTimeSeriesGrouping={timeSeriesGrouping}
              handleSetTimeSeriesGrouping={setTimeSeriesGrouping}
            />
          </div>

          {Boolean(bodySystemsForTableLoading) && (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          )}

          {Boolean(bodySystemsForTable.length) &&
            !bodySystemsForTableLoading && (
              <ResultsOverTimeContainer
                primaryBodySystems={bodySystemsForTable}
                availableDates={availableDates || []}
                patient={patient}
                timeSeriesGrouping={timeSeriesGrouping}
                inRangeValue={inRangeValue}
                bodySystemId={bodySystemId}
                isAvailableDatesLoading={isAvailableDatesLoading}
                biomarkerSearchValue={biomarkerSearchValue}
              />
            )}
        </div>

        {showEmptyState && <ResultsOverTimeEmptyState patient={patient} />}
      </div>
    </div>
  )
}

export default ResultsOverTime

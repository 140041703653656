import { faChartLineUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BodyText from "app/components/design-system/BodyText"

interface Props {
  title: string
}

const PendingTrackingInTrendsSubTitle = ({ title }: Props) => {
  return (
    <span className="flex items-center gap-[3px]">
      <div className="bg-amber-500 rounded-full p-[4px] flex items-center justify-center">
        <FontAwesomeIcon
          icon={faChartLineUp}
          className="text-white text-[8px]"
        />
      </div>
      <BodyText size="xs" weight="semibold">
        {title}
      </BodyText>
    </span>
  )
}

export default PendingTrackingInTrendsSubTitle

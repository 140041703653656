import { useCallback } from "react"
import { useDispatch } from "react-redux"

import Formsy from "formsy-react"

import { styled, withStyles } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import Switch from "@material-ui/core/Switch"
// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import * as Sentry from "@sentry/react"

import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { FormTextField, SelectFormField } from "app/components/forms/fields"
import { usePractitionerTypeOptions } from "app/hooks/usePractitionerTypes"
import { updatePractitioner } from "app/store/actions"
import { colors, primaryColor } from "app/theme"

export const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 37,
    height: 20,
    padding: 0,
    margin: "0px 10px",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: primaryColor,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "white",
      border: "6px solid white",
    },
  },
  thumb: {
    width: 18,
    height: 18,
    backgroundColor: "white",
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${colors.coolGray[400]}`,
    backgroundColor: colors.coolGray[400],
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    // Conditional tooltip for in-office delivery switch
    <Tooltip
      arrow
      interactive
      placement="bottom"
      title={props?.tooltiptext == null ? "" : props?.tooltiptext}
    >
      <div>
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
        />
      </div>
    </Tooltip>
  )
})

export const StyledFormControlLabel = styled(FormControlLabel)({
  alignItems: "flex-start",
  marginTop: 24,
})

export const PersonalInformation = ({ practitioner }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async ({ first_name, last_name, npi_number, phone_number }) => {
      let hasChangedAttribute = false
      if (
        npi_number !== practitioner.npi_number ||
        first_name !== practitioner?.user?.first_name ||
        last_name !== practitioner?.user?.last_name ||
        phone_number !== practitioner?.phone_number
      ) {
        hasChangedAttribute = true
      }

      if (hasChangedAttribute) {
        dispatch(
          updatePractitioner(
            {
              user: {
                first_name,
                last_name,
              },
              npi_number,
              phone_number,
            },
            true
          )
        )
      }
    },
    [practitioner]
  )

  const { practitionerTypeOptions, isLoading: practitionerTypeOptionsLoading } =
    usePractitionerTypeOptions(
      practitioner?.primary_practitioner_type?.id
        ? [practitioner.primary_practitioner_type]
        : undefined
    )

  const onPractitionerTypeChange = (event) => {
    const value = event.target.value
    if (value !== practitioner?.primary_practitioner_type?.id) {
      let newPracType = practitionerTypeOptions.find(
        (opt) => opt.value === value
      )

      if (!newPracType) {
        Sentry.captureException(
          new Error(
            `PersonalInformation: PractitionerType not found in practitionerTypeOptions. Type: ${value} | Options: [${practitionerTypeOptions
              .map((o) => o.value)
              .join(", ")}]`
          )
        )
        return
      }

      dispatch(
        updatePractitioner({ primary_practitioner_type: newPracType }, true)
      )
    }
  }

  return (
    <>
      <div className="flex items-center">
        <BodyText weight="semibold" size="lg">
          Personal Information
        </BodyText>
      </div>
      <div className="pt-6">
        <Formsy onValidSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTextField
                label="First Name"
                name="first_name"
                value={practitioner?.user?.first_name}
                clickToEdit={true}
                required={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Last Name"
                name="last_name"
                value={practitioner?.user?.last_name}
                clickToEdit={true}
                required={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Email"
                name="email"
                value={practitioner?.user?.email}
                disabled={true}
                required={false}
              />
            </Grid>
            {practitioner?.phone_number && (
              <Grid item xs={12} md={6}>
                <FormTextField
                  label="Phone Number"
                  name="phone_number"
                  value={practitioner?.phone_number}
                  clickToEdit={true}
                  required={false}
                />
              </Grid>
            )}
            {/* Do not display the NPI Number for Clinic Staff members */}
            {!practitioner?.is_clinic_staff && (
              <Grid item xs={12} md={6}>
                <FormTextField
                  label="NPI Number"
                  name="npi_number"
                  value={practitioner?.npi_number}
                  required={false}
                  validations={{
                    matchRegexp: /^[0-9]+$/,
                    isLength: 10,
                  }}
                  validationErrors={{
                    matchRegexp: "NPI Number must only be numbers",
                    isLength: "NPI Number must be 10 digits long",
                  }}
                  // Disable but display the NPI Number if the practitioner has already sent and order.
                  clickToEdit={!Boolean(practitioner.first_order_date)}
                  disabled={Boolean(practitioner.first_order_date)}
                />
              </Grid>
            )}
            {!practitioner?.is_clinic_staff && (
              <Grid item xs={12} md={6}>
                <SelectFormField
                  label="Practitioner Type"
                  name="primary_practitioner_type_id"
                  value={practitioner?.primary_practitioner_type?.id}
                  required={false}
                  clickToEdit={true}
                  disabled={
                    Boolean(practitioner.first_order_date) ||
                    practitionerTypeOptionsLoading
                  }
                  onChange={onPractitionerTypeChange}
                  hideEmptyOption
                >
                  {practitionerTypeOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </SelectFormField>
              </Grid>
            )}
          </Grid>
        </Formsy>
      </div>
    </>
  )
}

import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import { styled, useMediaQuery } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import { UserPaths } from "app/Routes"
import { API } from "app/api"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { FormTextField, SelectFormField } from "app/components/forms/fields"
import GenericConfirmationModal from "app/components/modals/GenericConfirmationModal"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useAppSelector from "app/hooks/useAppSelector"
import { IOKOnboardingModal } from "app/main/in-office-kits/IOKOnboardingModal"
import { US_STATES } from "app/main/patient-checkout/utils/usStates"
import EMRSettings from "app/main/settings/components/EMRSettings"
import ResultsSummaryLogoSettings from "app/main/settings/components/ResultsSummaryLogoSettings"
import Separator from "app/main/settings/components/Separator"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { updatePractitioner, updatePractitionerState } from "app/store/actions"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import resourceRequest from "app/swr/utils/resource-request"
import { colors } from "app/theme"

import SideNav from "./SideNav"
import InOfficeKitsToggle from "./components/InOfficeKitsToggle"
import {
  ClinicInputFieldNames,
  DEFAULT_SPECIMEN_ISSUE_EMAIL_RECIPIENT,
  SPECIMEN_ISSUE_EMAIL_RECIPIENT_CHOICES,
  NJ_NY_RI_RESTRICTIONS_URL,
} from "./constants"

const Wrapper = styled("div")({
  background: colors.coolGray[50],
  border: `1px solid ${colors.coolGray[200]}`,
  padding: 24,
  minHeight: "100vh",
})

function Clinic() {
  const handleApiError = useHandleApiError()
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const [inOfficeKits, setInOfficeKits] = useState(false)
  const [showIOKModal, setShowIOKModal] = useState(false)
  const [disableIOKModalOpen, setDisableIOKModalOpen] = useState(false)
  const [validCountries, setValidCountries] = useState([])
  const [nyWarning, setNYWarning] = useState(false)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const dispatch = useDispatch()

  const [clinicId, setClinicId] = useState(null)

  // const specimenIssueEmailRecipientValue = useMemo(() => {
  //   return (
  //     practitioner?.clinic?.specimen_issue_email ||
  //     DEFAULT_SPECIMEN_ISSUE_EMAIL_RECIPIENT
  //   )
  // }, [practitioner?.clinic?.specimen_issue_email])

  const [specimenIssueEmailRecipient, setSpecimenIssueEmailRecipient] =
    useState()

  useEffect(() => {
    const getValidCountries = async () => {
      const { data } = await API.SupportedCountries.getList()
      setValidCountries(data)
    }

    getValidCountries()
  }, [])

  useEffect(() => {
    if (practitioner?.clinic?.id) {
      setClinicId(practitioner.clinic.id)
      setInOfficeKits(
        practitioner?.clinic?.clinic_features?.includes("allow_in_office_kits")
      )
      setSpecimenIssueEmailRecipient(
        practitioner.clinic.specimen_issue_email_recipient ||
          DEFAULT_SPECIMEN_ISSUE_EMAIL_RECIPIENT
      )
    }

    if (practitioner?.clinic?.state === "NY") {
      setNYWarning(true)
    }
  }, [practitioner])

  const onSelectChange = (event, field) => {
    if (
      field === ClinicInputFieldNames.ClinicState &&
      event.target.value !== practitioner?.clinic?.state
    ) {
      dispatch(
        updatePractitioner({ clinic: { state: event.target.value } }, true)
      )

      setNYWarning(event.target.value === "NY")
    } else if (
      field === ClinicInputFieldNames.ClinicProvinceRegion &&
      event.target.value !== practitioner?.clinic?.province_region
    ) {
      dispatch(
        updatePractitioner(
          { clinic: { province_region: event.target.value } },
          true
        )
      )
    } else if (
      field === ClinicInputFieldNames.ClinicCountry &&
      event.target.value !== practitioner?.clinic?.country
    ) {
      dispatch(
        updatePractitioner(
          {
            clinic: {
              country: event.target.value,
              // If we are changing from US to non-US, clear out the state (only relevant to US)
              // If change is non-US to US, clear out province_region (only relevant to non-US)
              state:
                event.target.value === "US" ? practitioner?.clinic?.state : "",
              province_region:
                event.target.value === "US"
                  ? ""
                  : practitioner?.clinic?.province_region,
            },
          },
          true
        )
      )
    } else if (
      field === ClinicInputFieldNames.ClinicSpecimenIssueEmailRecipient &&
      event.target.value !==
        practitioner?.clinic?.specimen_issue_email_recipient
    ) {
      dispatch(
        updatePractitioner(
          { clinic: { specimen_issue_email_recipient: event.target.value } },
          true
        )
      )
    }
  }

  const onSubmit = useCallback(
    async ({ clinic_name, clinic_street, clinic_city, clinic_zipcode }) => {
      let hasChangedAttribute = false
      if (
        clinic_name !== practitioner?.clinic?.name ||
        clinic_street !== practitioner?.clinic?.street ||
        clinic_city !== practitioner?.clinic?.city ||
        clinic_zipcode !== practitioner?.clinic?.zipcode
      ) {
        hasChangedAttribute = true
      }

      if (hasChangedAttribute) {
        dispatch(
          updatePractitioner(
            {
              clinic: {
                name: clinic_name,
                street: clinic_street,
                city: clinic_city,
                zipcode: clinic_zipcode,
              },
            },
            true
          )
        )
      }
    },
    [practitioner]
  )

  const [emrAccountSettingsEnabled] = useFeatureFlag(
    FeatureFlag.EMRAccountSettings
  )
  const [resultsSummaryLogosEnabled] = useFeatureFlag(
    FeatureFlag.ResultsSummaryLogos
  )
  const [inOfficeKitsV2] = useFeatureFlag(FeatureFlag.InOfficeKitsV2)
  const [specimenIssueVisibility] = useFeatureFlag(
    FeatureFlag.SpecimenIssueVisibility
  )
  const [isInternationalClinicsEnabled] = useFeatureFlag(
    FeatureFlag.InternationalClinics
  )

  const emrIntegrations = useSelector(
    ({ emrIntegrations }) => emrIntegrations.results
  )

  const stripeConnectAccount = useSelector((state) => {
    const customFees = state.customFees
    if (
      Array.isArray(customFees.stripeConnectAccounts) &&
      customFees.stripeConnectAccounts.length
    ) {
      return customFees.stripeConnectAccounts[0]
    }
  })
  const customFeesEnabledForClinic = useAppSelector(
    (state) => state?.practitioner?.clinic?.custom_fees_enabled
  )

  const customFeesArePresent =
    Boolean(customFeesEnabledForClinic) || Boolean(stripeConnectAccount)
  const disableCountryDropdown =
    customFeesArePresent ||
    practitioner?.clinic?.has_active_prac_license_labshop

  let disabledCountryDropdownTooltipText = ""

  if (customFeesArePresent) {
    disabledCountryDropdownTooltipText =
      "You cannot change your country as your clinic has set up Custom Fees. Please reach out to us via chat to help you completely remove Custom Fees from your account."
  } else if (practitioner?.clinic?.has_active_prac_license_labshop) {
    disabledCountryDropdownTooltipText =
      "You cannot change your country as one or more of your LabShops are using a practitioner's license. Please reach out to us via chat if you would still like to change your country."
  }

  const { data: clinicData, mutate } = useResourceSWR(
    clinicId ? `/clinics/${clinicId}/` : null,
    { revalidateOnMount: true }
  )

  useEffect(() => {
    const allowInOfficeKits = clinicData?.attributes?.clinic_features?.includes(
      "allow_in_office_kits"
    )
    setInOfficeKits(Boolean(allowInOfficeKits))
  }, [clinicData])

  async function toggleInOfficeKits() {
    try {
      API.Clinic.get(clinicData.id).then((res) => {
        let updatedFeatures = res.data.data.attributes.clinic_features
        if (updatedFeatures.includes("allow_in_office_kits")) {
          updatedFeatures = updatedFeatures.filter(
            (feature) => feature !== "allow_in_office_kits"
          )
          setInOfficeKits(false)
        } else {
          updatedFeatures.push("allow_in_office_kits")
          setInOfficeKits(true)
        }
        mutate(
          async () => {
            try {
              const { data } = await resourceRequest({
                method: "PATCH",
                url: `/clinics/${clinicData.id}/`,
                data: {
                  data: {
                    type: "clinic",
                    id: clinicData.id,
                    attributes: {
                      clinic_features: updatedFeatures,
                    },
                  },
                },
              })
              return data
            } catch (error) {
              handleApiError(error)
            }
          },
          {
            revalidate: true,
          }
        )
        practitioner.clinic.clinic_features = updatedFeatures
        dispatch(updatePractitionerState(practitioner))
      })
    } catch (error) {
      handleApiError(error)
    }
  }

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },

            {
              label: "Clinic Settings",
            },
          ]}
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title="Clinic Settings"
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <Wrapper>
            <div className="flex items-center">
              <BodyText weight="semibold" size="lg">
                Clinic Information
              </BodyText>
            </div>
            <div className="pt-6">
              <Formsy onValidSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      label="Practice Name"
                      name={ClinicInputFieldNames.ClinicName}
                      value={practitioner?.clinic?.name}
                      clickToEdit={true}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      label="Street Address"
                      name={ClinicInputFieldNames.ClinicStreet}
                      value={practitioner?.clinic?.street}
                      clickToEdit={true}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={isInternationalClinicsEnabled ? 3 : 4}>
                    <FormTextField
                      label="City"
                      name={ClinicInputFieldNames.ClinicCity}
                      value={practitioner?.clinic?.city}
                      clickToEdit={true}
                      required={false}
                    />
                  </Grid>
                  {isInternationalClinicsEnabled &&
                  practitioner?.clinic?.is_international_clinic ? (
                    <Grid item xs={12} md={3}>
                      <SelectFormField
                        label="Province"
                        name={ClinicInputFieldNames.ClinicProvinceRegion}
                        value={practitioner?.clinic?.province_region}
                        clickToEdit={true}
                        onChange={(e) =>
                          onSelectChange(
                            e,
                            ClinicInputFieldNames.ClinicProvinceRegion
                          )
                        }
                        required={false}
                      >
                        {practitioner?.clinic?.country &&
                          validCountries
                            .find(
                              (country) =>
                                country.countryShortCode ===
                                practitioner?.clinic?.country
                            )
                            ?.regions?.map((regionData) => (
                              <option
                                key={regionData.shortCode}
                                value={regionData.name}
                              >
                                {regionData.name}
                              </option>
                            ))}
                      </SelectFormField>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      md={isInternationalClinicsEnabled ? 3 : 4}
                    >
                      <SelectFormField
                        label="State"
                        name={ClinicInputFieldNames.ClinicState}
                        value={practitioner?.clinic?.state}
                        clickToEdit={true}
                        onChange={(e) =>
                          onSelectChange(e, ClinicInputFieldNames.ClinicState)
                        }
                        required={false}
                      >
                        {Object.entries(US_STATES).map(([code, name]) => (
                          <option key={code} value={code}>
                            {name}
                          </option>
                        ))}
                      </SelectFormField>
                    </Grid>
                  )}
                  {isInternationalClinicsEnabled && (
                    <Grid item xs={12} md={3}>
                      <Tooltip
                        title={disabledCountryDropdownTooltipText}
                        placement="bottom"
                        arrow
                      >
                        <div>
                          <SelectFormField
                            label="Country"
                            name={ClinicInputFieldNames.ClinicCountry}
                            value={practitioner?.clinic?.country}
                            clickToEdit={true}
                            onChange={(e) =>
                              onSelectChange(
                                e,
                                ClinicInputFieldNames.ClinicCountry
                              )
                            }
                            required={false}
                            disabled={true}
                          >
                            {validCountries.map((countryData) => (
                              <option
                                key={countryData.countryShortCode}
                                value={countryData.countryShortCode}
                                disabled={
                                  countryData.countryShortCode !==
                                    practitioner?.clinic?.country &&
                                  disableCountryDropdown
                                }
                              >
                                {countryData.countryName}
                              </option>
                            ))}
                          </SelectFormField>
                        </div>
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid item xs={12} md={isInternationalClinicsEnabled ? 3 : 4}>
                    <FormTextField
                      label={
                        isInternationalClinicsEnabled &&
                        practitioner?.clinic?.is_international_clinic
                          ? "Postal Code"
                          : "Zip Code"
                      }
                      name="clinic_zipcode"
                      value={practitioner?.clinic?.zipcode}
                      clickToEdit={true}
                      required={false}
                    />
                  </Grid>
                  {nyWarning && (
                    <Grid>
                      <div className="border-1 rounded-full py-1 px-3 ml-2 bg-red-300 border-red-900 text-white">
                        Clinics in New York State have access to a restricted
                        catalogue, in accordance with state law.{" "}
                        <a
                          className="text-white"
                          href={NJ_NY_RI_RESTRICTIONS_URL}
                          target="_blank"
                        >
                          Learn more.
                        </a>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Formsy>
            </div>

            {emrAccountSettingsEnabled && (
              <>
                <Separator />
                <EMRSettings emrIntegrations={emrIntegrations} />
              </>
            )}

            {resultsSummaryLogosEnabled && (
              <>
                <Separator />
                <ResultsSummaryLogoSettings clinic={practitioner?.clinic} />
              </>
            )}
            {inOfficeKitsV2 && (
              <>
                <InOfficeKitsToggle
                  inOfficeKits={inOfficeKits}
                  onChange={() => {
                    // If previously disabled, open the onboarding modal.
                    if (!inOfficeKits) {
                      setShowIOKModal(true)
                    } else {
                      // If previously enabled, open the confirmation modal.
                      setDisableIOKModalOpen(true)
                    }
                  }}
                  inOfficeKitsDisabledReason={
                    practitioner?.clinic?.in_office_kits_disabled_reason
                  }
                />
                {showIOKModal && (
                  <IOKOnboardingModal
                    showModal={showIOKModal}
                    onExit={() => setShowIOKModal(false)}
                    onContinue={() => {
                      setShowIOKModal(false)
                      toggleInOfficeKits()
                    }}
                  />
                )}
                <GenericConfirmationModal
                  open={disableIOKModalOpen}
                  data={{
                    title: "Turn off In-Office Supplies?",
                    text: "Turning off In-Office Supplies will disable it for your entire clinic.",
                    buttonText: "Disable In-Office Supplies",
                  }}
                  onClose={() => setDisableIOKModalOpen(false)}
                  actionFn={() => {
                    toggleInOfficeKits()
                    setDisableIOKModalOpen(false)
                  }}
                  actionButtonColor="destructiveSecondary"
                />
              </>
            )}
            {specimenIssueVisibility && (
              <Formsy className="my-6 text-xl">
                <SelectFormField
                  label="Specimen Issue emails go to:"
                  labelClass="text-body text-base15"
                  name={ClinicInputFieldNames.ClinicSpecimenIssueEmailRecipient}
                  value={specimenIssueEmailRecipient}
                  disabled={!specimenIssueEmailRecipient}
                  clickToEdit={true}
                  onChange={(e) =>
                    onSelectChange(
                      e,
                      ClinicInputFieldNames.ClinicSpecimenIssueEmailRecipient
                    )
                  }
                  required={false}
                  hideEmptyOption={true}
                >
                  {SPECIMEN_ISSUE_EMAIL_RECIPIENT_CHOICES.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </SelectFormField>
              </Formsy>
            )}
          </Wrapper>
        </Grid>
      </Grid>
    </>
  )
}

export default Clinic

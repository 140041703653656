import {
  Resource,
  ResourceRelationshipMany,
  ResourceRelationshipSingle,
} from "app/swr/types"

export enum PatientDocumentType {
  FOOD_PLAN = "food_plan_document",
  ORDERED_RESULT = "ordered_result_document",
  RESULTS_SUMMARY = "results_interpretation_document",
  PATIENT_RESULT = "patient_result_document",
}

export interface PatientWithDocuments extends Resource {
  type: "patient_with_documents"
  id: string
  attributes: {}
  relationships: {
    food_plans: ResourceRelationshipMany
    ordered_results: ResourceRelationshipMany
    results_interpretations: ResourceRelationshipMany
    patient_results: ResourceRelationshipMany
  }
}

export interface FoodPlanDocument extends Resource {
  type: PatientDocumentType.FOOD_PLAN
  id: string
  attributes: {
    created_at: string
    created_by_name: string | null
  }
  relationships: {
    latest_version: ResourceRelationshipSingle
  }
}

export interface OrderedResultDocument extends Resource {
  type: PatientDocumentType.ORDERED_RESULT
  id: string
  attributes: {
    created_at: string
    supports_blood_report: boolean
    lab_company_name: string | null
    result_file: string | null
    result_file_thumbnail: string | null
    supports_trends_visualization: boolean
  }
  relationships: {
    blood_lab_dashboard_report_snapshots: ResourceRelationshipMany
    kit: ResourceRelationshipSingle
  }
}

export interface ResultsSummaryDocument extends Resource {
  type: PatientDocumentType.RESULTS_SUMMARY
  id: string
  attributes: {
    created_at: string
    order_id: string
  }
  relationships: {
    clinic: ResourceRelationshipSingle
  }
}

export interface PatientResultDocument extends Resource {
  type: PatientDocumentType.PATIENT_RESULT
  id: string
  attributes: {
    created_at: string
    received_date: string
    source: string
    total_biomarker_results_count: number
    latest_thumbnail: string | null
    file?: string
    file_type: string
    file_name: string | null
    has_snapshot: boolean
    clinic_name: string
    supports_results_visualizations: boolean
    lab_company_name: string | null
    lab_company_short_name: string | null
    lab_company_results_name: string | null
    lab_company_key: string | null
    collection_date: string | null
  }
  relationships: {
    user: ResourceRelationshipSingle
  }
}

export type PatientDocument =
  | FoodPlanDocument
  | OrderedResultDocument
  | ResultsSummaryDocument
  | PatientResultDocument

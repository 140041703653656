import { useEffect, useMemo, useRef, useState } from "react"

import useResizeObserver from "use-resize-observer"

import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Theme } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import useAppSelector from "app/hooks/useAppSelector"
import { Patient } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { SimpleBodySystem } from "types/body-system"
import { PatientSettings } from "types/patient"

import {
  STICKY_TABLE_COLUMN_WIDTH,
  STICKY_TABLE_COLUMN_WIDTH_MOBILE,
} from "../constants/constants"
import { InRangeOption, ResultsOverTimeDateGrouping } from "../types/types"
import BiomarkerResultRowsContainer from "./BiomarkerResultRowsContainer"
import ResultsOverTimeTableDatesHeader from "./ResultsOverTimeTableDatesHeader"
import ResultsOverTimeTableDatesHeaderSkeleton from "./ResultsOverTimeTableDatesHeaderSkeleton"

const useStyles = makeAppStyles<{ fullWidthScroller: boolean }>(
  (theme: Theme) => ({
    table: {
      border: "none",
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      "& td": {
        textAlign: "center",
      },
      background: "white",
    },
    wrapper: {
      position: "relative",
      width: "100%",
    },
    scroller: {
      marginLeft: ({ fullWidthScroller }) =>
        fullWidthScroller ? 0 : STICKY_TABLE_COLUMN_WIDTH,
      overflowX: ({ fullWidthScroller }) =>
        fullWidthScroller ? "hidden" : "scroll",
      direction: "rtl",
      overflowY: "visible",
      paddingBottom: 5,
      width: ({ fullWidthScroller }) =>
        fullWidthScroller
          ? "100%"
          : `calc(100% - ${STICKY_TABLE_COLUMN_WIDTH}px)`,
      [theme.breakpoints.down(640)]: {
        marginLeft: ({ fullWidthScroller }) =>
          fullWidthScroller ? 0 : STICKY_TABLE_COLUMN_WIDTH_MOBILE,
        width: ({ fullWidthScroller }) =>
          fullWidthScroller
            ? "100%"
            : `calc(100% - ${STICKY_TABLE_COLUMN_WIDTH_MOBILE}px)`,
      },
    },
  })
)

interface Props {
  primaryBodySystems: SimpleBodySystem[]
  groupedBy: ResultsOverTimeDateGrouping
  patient: Patient
  availableDates: string[]
  inRangeValue: InRangeOption
  dropdownBodySystemId: string
  isAvailableDatesLoading: boolean
  biomarkerSearchValue: string
}

const ResultsOverTimeTable = ({
  primaryBodySystems,
  groupedBy,
  patient,
  availableDates,
  inRangeValue,
  dropdownBodySystemId,
  isAvailableDatesLoading,
  biomarkerSearchValue,
}: Props) => {
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const clinic = practitioner?.clinic
  const clinicName = clinic?.name

  // Used to keep track of which body systems have no search results
  const [emptySearchResults, setEmptySearchResults] = useState<string[]>([])
  // Set to true if all body systems have no search results
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [showEmptyFilterState, setShowEmptyFilterState] = useState(false)

  const classes = useStyles({
    fullWidthScroller: showEmptyState || showEmptyFilterState,
  })

  const tableRef = useRef<any>(null)
  const { width: tableWidth } = useResizeObserver<HTMLDivElement>({
    ref: tableRef,
  })

  const showHighLowDescriptions = useMemo(() => {
    return patient?.patient_settings.includes(
      PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS
    )
  }, [patient])

  // Adds or removes body system ids from the emptySearchResults array.
  // Once this array contins the same number of body system ids as displayed,
  // we know to show the empty state to the user.
  const handleEmptySearchResults = (id: string, add: boolean) => {
    if (add) {
      setEmptySearchResults((prev) =>
        !prev.includes(id) ? [...prev, id] : prev
      )
    } else {
      setEmptySearchResults((prev) => prev.filter((prevId) => prevId !== id))
    }
  }

  // Effect used to determine whether or not we should show an empty state
  // when a user has entered a biomarker search query that renders 0 results.
  useEffect(() => {
    // If the user has entered a search query and all body systems have no results,
    // we want to show the empty state.
    if (
      biomarkerSearchValue !== "" &&
      emptySearchResults.length === primaryBodySystems.length
    ) {
      setShowEmptyState(true)
    } else if (
      // If the user has entered a search query and a specific body system has no results, then we show empty state
      biomarkerSearchValue !== "" &&
      dropdownBodySystemId !== "all" &&
      emptySearchResults.length === 1
    ) {
      setShowEmptyState(true)
    } else {
      setShowEmptyState(false)
    }
  }, [emptySearchResults])

  // We want to clear out search results when the body system or in range dropdowns change
  useEffect(() => {
    setEmptySearchResults([])
  }, [dropdownBodySystemId, inRangeValue])

  useEffect(() => {
    if (
      inRangeValue !== InRangeOption.ALL &&
      availableDates.length === 0 &&
      !isAvailableDatesLoading
    ) {
      setShowEmptyFilterState(true)
    } else {
      setShowEmptyFilterState(false)
    }
  }, [availableDates, isAvailableDatesLoading])

  // Don't render the table if there is no data and we are not loading
  if (
    availableDates.length === 0 &&
    !isAvailableDatesLoading &&
    !showEmptyFilterState
  ) {
    return null
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.scroller} ref={tableRef}>
        <table className={classes.table}>
          <thead>
            {isAvailableDatesLoading ? (
              <ResultsOverTimeTableDatesHeaderSkeleton />
            ) : showEmptyState || showEmptyFilterState ? null : (
              <ResultsOverTimeTableDatesHeader
                dates={availableDates}
                groupedBy={groupedBy}
                tableWidth={tableWidth}
              />
            )}
          </thead>
          <tbody>
            {(showEmptyState || showEmptyFilterState) && (
              <tr>
                <td style={{ direction: "ltr" }}>
                  <BodyText>
                    <FontAwesomeIcon
                      fill="currentColor"
                      icon={faCircleExclamation}
                    />{" "}
                    <span>
                      Oops! We couldn’t find any biomarkers that match the
                      selected search and filters!
                    </span>
                  </BodyText>
                </td>
              </tr>
            )}

            {dropdownBodySystemId === "all" ? (
              primaryBodySystems.map((bodySystem) => {
                return (
                  <BiomarkerResultRowsContainer
                    key={bodySystem.id}
                    primaryBodySystem={bodySystem}
                    groupedBy={groupedBy}
                    patientId={patient.id}
                    clinicName={clinicName}
                    availableDates={availableDates}
                    inRangeValue={inRangeValue}
                    dropdownBodySystemId={dropdownBodySystemId}
                    biomarkerSearchValue={biomarkerSearchValue}
                    handleSetEmptySearchResults={handleEmptySearchResults}
                    tableWidth={tableWidth}
                    showHighLowDescriptions={showHighLowDescriptions || false}
                  />
                )
              })
            ) : (
              <BiomarkerResultRowsContainer
                key={dropdownBodySystemId}
                primaryBodySystem={null}
                groupedBy={groupedBy}
                patientId={patient.id}
                clinicName={clinicName}
                availableDates={availableDates}
                inRangeValue={inRangeValue}
                dropdownBodySystemId={dropdownBodySystemId}
                biomarkerSearchValue={biomarkerSearchValue}
                handleSetEmptySearchResults={handleEmptySearchResults}
                tableWidth={tableWidth}
                showHighLowDescriptions={showHighLowDescriptions || false}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ResultsOverTimeTable

import * as React from "react"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { detect } from "detect-browser"
import { find, last } from "lodash"

import {
  Button,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  Zoom,
} from "@material-ui/core"
import capitalize from "@material-ui/core/utils/capitalize"
import * as Sentry from "@sentry/react"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { ReactComponent as EllipsesIcon } from "app/assets/icons/bundles/ellipses.svg"
import AddBankIcon from "app/assets/images/add-bank.svg"
import AddCardIcon from "app/assets/images/add-card.svg"
import BankIcon from "app/assets/images/bank-icon.svg"
import CreditCardIcon from "app/assets/images/credit-card-icon.svg"
import DisclosureCircleIcon from "app/assets/images/disclosure-circle-icon.svg"
import MastercardIcon from "app/assets/images/mastercard-icon.svg"
import VisaIcon from "app/assets/images/visa-icon.svg"
import { LoadingButton } from "app/components/LoadingButton"
import Tooltip from "app/components/Tooltip"
import TooltipNav from "app/components/design-system/TooltipNav"
import useConfirmationModal from "app/components/modals/generic/hooks/use-confirmation-modal"
import {
  LAB_COMPANIES_REQUIRING_IOK_ACTIVATION_ID,
  LAB_COMPANY_KEY,
  STATES_WITH_ORDERING_RIGHTS,
} from "app/constants"
import {
  DOCTORS_DATA_GENOVA_CLINIC_STATES_PRACTITIONER_PAY_NOT_ALLOWED,
  MOSAIC_DSL_CLINIC_STATES_PRACTITIONER_PAY_NOT_ALLOWED,
  orderHasOneOfLabCompaniesAndClinicState,
} from "app/dataServices/orderingRights"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import * as Actions from "app/main/checkout/store/actions"
import {
  plaidEnvironment,
  plaidPublicKey,
  stripePublishableAPIKey,
} from "app/settings"
import { colors } from "app/theme"
import { BankAccount, OrderPayer, PaymentCard } from "app/types"
import { isPractitionerPayingOrder, loadScript } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

import orderContainsLabTestFromCompany, {
  orderedTestsContainingInOfficeKitFromAGivenCompany,
} from "../../utils/order-contains-lab-test-from-company"
import useCheckoutDraft from "./hooks/use-checkout-draft"
import { usePaymentMethodNavItems } from "./hooks/use-payment-method-nav-items"
import { PatientInfoButton } from "./instantRequisitions/PatientInfoButton"

class PlaidError extends Error {
  constructor(message) {
    super(message)
    this.name = "PlaidError"
  }
}

const PAYMENT_CARD_PREFIX = "card_"
const BANK_ACCOUNT_PREFIX = "bank_"

const stripePromise = loadStripe(stripePublishableAPIKey)

export default function PractitionerPayment({ order }) {
  const dispatch = useDispatch()
  const { updateValues, values } = useCheckoutDraft()

  const hasBioreferenceTests = useMemo(() => {
    return orderContainsLabTestFromCompany(order, [
      LAB_COMPANY_KEY.BIOREFERENCE,
    ])
  }, [order])

  const clinicStateMosaicPractitionerPayNotAllowed = useMemo(() => {
    return orderHasOneOfLabCompaniesAndClinicState(
      order,
      [LAB_COMPANY_KEY.MOSAIC_DIAGNOSTICS],
      MOSAIC_DSL_CLINIC_STATES_PRACTITIONER_PAY_NOT_ALLOWED
    )
  }, [order])

  const clinicStateDoctorsDataPractitionerPayNotAllowed = useMemo(() => {
    return orderHasOneOfLabCompaniesAndClinicState(
      order,
      [LAB_COMPANY_KEY.DOCTORS_DATA],
      DOCTORS_DATA_GENOVA_CLINIC_STATES_PRACTITIONER_PAY_NOT_ALLOWED
    )
  }, [order])

  const clinicStateGenovaPractitionerPayNotAllowed = useMemo(() => {
    return orderHasOneOfLabCompaniesAndClinicState(
      order,
      [LAB_COMPANY_KEY.GENOVA],
      DOCTORS_DATA_GENOVA_CLINIC_STATES_PRACTITIONER_PAY_NOT_ALLOWED
    )
  }, [order])

  const clinicStateDSLPractitionerPayNotAllowed = useMemo(() => {
    return orderHasOneOfLabCompaniesAndClinicState(
      order,
      [LAB_COMPANY_KEY.DSL],
      MOSAIC_DSL_CLINIC_STATES_PRACTITIONER_PAY_NOT_ALLOWED
    )
  }, [order])

  const hasInsuranceTests = useMemo(() => {
    return Object.keys(order.associated_uninsured_tests_by_ordered_test_id)
      .length
  }, [order])

  const testsWithIOKRequiringActivation = useMemo(() => {
    return orderedTestsContainingInOfficeKitFromAGivenCompany(
      order,
      LAB_COMPANIES_REQUIRING_IOK_ACTIVATION_ID
    )
  }, [order])

  const blockedState = useMemo(() => {
    if (values.shipping_state) {
      return STATES_WITH_ORDERING_RIGHTS.includes(values.shipping_state)
    } else {
      return false
    }
  }, [values.shipping_state, STATES_WITH_ORDERING_RIGHTS])

  const practitionerPayDisabled = useMemo(() => {
    return (
      hasBioreferenceTests ||
      order.custom_fee_line_items.length ||
      blockedState ||
      hasInsuranceTests ||
      clinicStateMosaicPractitionerPayNotAllowed ||
      clinicStateDoctorsDataPractitionerPayNotAllowed ||
      clinicStateGenovaPractitionerPayNotAllowed ||
      clinicStateDSLPractitionerPayNotAllowed
    )
  }, [
    hasBioreferenceTests,
    hasInsuranceTests,
    order.custom_fee_line_items,
    blockedState,
    clinicStateMosaicPractitionerPayNotAllowed,
    clinicStateDoctorsDataPractitionerPayNotAllowed,
    clinicStateGenovaPractitionerPayNotAllowed,
    clinicStateDSLPractitionerPayNotAllowed,
  ])

  const patientPayDisabled = !!testsWithIOKRequiringActivation?.length

  const checkboxDisabled = practitionerPayDisabled || patientPayDisabled

  const useCardChecked = useSelector(
    ({ orders }: any) => orders.paymentCards.useCardChecked
  )
  const paymentCards: PaymentCard[] = useSelector(
    ({ orders }: any) => orders.paymentCards.paymentCards
  )
  const paymentCardsPending = useSelector(
    ({ orders }: any) => orders.paymentCards.pending
  )
  const defaultPaymentCard = last(paymentCards) || null

  const bankAccounts: BankAccount[] = useSelector(
    ({ orders }: any) => orders.bankAccounts.bankAccounts
  )
  const bankAccountsPending = useSelector(
    ({ orders }: any) => orders.bankAccounts.pending
  )
  const addAccountPending = useSelector(
    ({ orders }: any) => orders.bankAccounts.addAccountPending
  )
  const defaultBankAccount = last(bankAccounts) || null

  const [plaidLinkHandler, setPlaidLinkHandler] = useState<any | null>(null)

  useEffect(() => {
    loadScript(
      "plaid",
      "https://cdn.plaid.com/link/v2/stable/link-initialize.js",
      () => {
        window.Plaid &&
          setPlaidLinkHandler(
            window.Plaid.create({
              env: plaidEnvironment,
              clientName: "Rupa Health",
              key: plaidPublicKey,
              product: ["auth", "transactions"],
              selectAccount: true,
              onSuccess: async function (public_token, metadata) {
                try {
                  const bankAccount = (await dispatch(
                    Actions.addBankAccount(public_token, metadata.account_id)
                  )) as any

                  updateValues({
                    bank_account: bankAccount,
                    payment_card: undefined,
                    order_payer: OrderPayer.PRACTITIONER,
                  })
                } catch (error) {
                  // do nothing, as we report any errors in #addBankAccount
                }
              },
              onExit: function (error, metadata) {
                // If there's not an error it means the user just exited the Plaid flow.
                // An error implies something went wrong with the auth process.
                if (error != null) {
                  Sentry.withScope(function (scope) {
                    scope.setExtra("error", JSON.stringify(error))
                    Sentry.captureException(
                      new PlaidError("Failed to authenticate bank account")
                    )
                  })
                }
              },
            })
          )
      }
    )
  }, [])

  useEffect(
    () => {
      dispatch(Actions.getPaymentCards())
      dispatch(Actions.getBankAccounts())

      if (isPractitionerPayingOrder(order) && !useCardChecked) {
        dispatch(Actions.setUseCardChecked(true))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (practitionerPayDisabled) {
      dispatch(Actions.setUseCardChecked(false))
    }
  }, [practitionerPayDisabled])

  useEffect(() => {
    if (patientPayDisabled) {
      togglePractitionerPay(true)
    }
  }, [patientPayDisabled])

  async function togglePractitionerPay(checked = true) {
    dispatch(Actions.setUseCardChecked(checked))

    if (checked) {
      await Promise.all(
        order.custom_fee_line_items.map((customFee) =>
          dispatch(Actions.removeCustomFeeLineItem(order.id, customFee.id))
        )
      )
      updateValues({
        payment_card: defaultPaymentCard,
        bank_account: !defaultPaymentCard ? defaultBankAccount : null,
        order_payer: OrderPayer.PRACTITIONER,
      })
    } else {
      updateValues({
        payment_card: null,
        bank_account: null,
        order_payer: OrderPayer.PATIENT,
      })
    }
  }

  let practitionerPayDisabledMessage =
    "Only patient-pay is available for orders containing BioReference lab tests."
  if (order.custom_fee_line_items.length) {
    practitionerPayDisabledMessage =
      "Custom Fees can only be added to patient-pay orders. Remove the Custom Fee to change to practitioner pay."
  }
  if (blockedState) {
    practitionerPayDisabledMessage =
      "You cannot use practitioner pay for orders with patients based in NY, NJ or RI."
  }
  if (hasInsuranceTests) {
    practitionerPayDisabledMessage =
      "You cannot use practitioner pay for insurance orders."
  }
  if (patientPayDisabled) {
    practitionerPayDisabledMessage = `Patient Pay is not available with ${testsWithIOKRequiringActivation[0].lab_test.lab_company.short_name} in-office kits.`
  }
  if (clinicStateMosaicPractitionerPayNotAllowed) {
    practitionerPayDisabledMessage =
      "Practitioner Pay cannot be used for Mosaic testing."
  }
  if (clinicStateDoctorsDataPractitionerPayNotAllowed) {
    practitionerPayDisabledMessage =
      "Practitioner Pay cannot be used for Doctor's Data testing."
  }
  if (clinicStateGenovaPractitionerPayNotAllowed) {
    practitionerPayDisabledMessage =
      "Practitioner Pay cannot be used for Genova testing."
  }
  if (clinicStateDSLPractitionerPayNotAllowed) {
    practitionerPayDisabledMessage =
      "Practitioner Pay cannot be used for Diagnostic Solutions Laboratory testing."
  }

  return (
    <div>
      <Tooltip
        arrow
        interactive
        placement="bottom"
        title={<Typography>{practitionerPayDisabledMessage}</Typography>}
        disableHoverListener={!checkboxDisabled}
        disableFocusListener={!checkboxDisabled}
        disableTouchListener={!checkboxDisabled}
      >
        <label className="flex items-center">
          <Checkbox
            checked={useCardChecked}
            onChange={(event) => togglePractitionerPay(event.target.checked)}
            className="p-0"
            color="primary"
            disabled={checkboxDisabled}
            id="practitioner-pay-checkbox"
          />
          <Typography
            className={`ml-1.5 fs-exclude ${
              checkboxDisabled ? "text-gray-400" : "text-gray-800"
            }`}
          >
            I will pay for {`${order?.patient?.first_name}'s`} order
          </Typography>
        </label>
      </Tooltip>

      <Collapse in={useCardChecked}>
        {paymentCardsPending || bankAccountsPending || addAccountPending ? (
          <div className="flex justify-center my-2">
            <CircularProgress aria-label="Loading payment info" />
          </div>
        ) : (
          <>
            <PaymentPicker
              cards={paymentCards}
              bankAccounts={bankAccounts}
              isPaymentExpanded={useCardChecked}
              onAddBank={() => {
                plaidLinkHandler?.open()
              }}
              updateValues={updateValues}
              values={values}
              isInternationalClinic={order.clinic?.is_international_clinic}
            />
            <Collapse in={useCardChecked}>
              <div className="mt-4" />
              <PatientInfoButton />
            </Collapse>
          </>
        )}
      </Collapse>
    </div>
  )
}

const PaymentPicker = ({
  cards,
  bankAccounts,
  isPaymentExpanded,
  onAddBank,
  updateValues,
  values,
  isInternationalClinic,
}) => {
  const ADD_NEW_CARD = "ADD_NEW_CARD"
  const ADD_NEW_BANK = "ADD_NEW_BANK"

  const handleChange = (event) => {
    const selectedId =
      event.target.value === ADD_NEW_CARD ? null : event.target.value
    const payment_card = selectedId
      ? find(cards, (card) => `${PAYMENT_CARD_PREFIX}${card.id}` === selectedId)
      : null
    const bank_account = selectedId
      ? find(
          bankAccounts,
          (bank) => `${BANK_ACCOUNT_PREFIX}${bank.id}` === selectedId
        )
      : null
    updateValues({
      payment_card: payment_card,
      bank_account: bank_account,
      order_payer: OrderPayer.PRACTITIONER,
    })
  }

  // Convert to a string as all radio values are strings, and the id is an integer.
  // eslint-disable-next-line no-mixed-operators
  const orderPaymentCardId = `${
    (values.payment_card &&
      `${PAYMENT_CARD_PREFIX}${values.payment_card.id}`) ||
    ""
  }`
  // eslint-disable-next-line no-mixed-operators
  const orderBankAccountId = `${
    (values.bank_account &&
      `${BANK_ACCOUNT_PREFIX}${values.bank_account.id}`) ||
    ""
  }`

  const radioGroupValue = isPaymentExpanded
    ? orderPaymentCardId || orderBankAccountId || ADD_NEW_CARD
    : "CLOSED"

  return (
    <div className="shadow rounded-lg border border-gray-300 mt-4 pt-2 pb-1.5">
      <RadioGroup value={radioGroupValue} onChange={handleChange}>
        {cards &&
          cards.map((card) => (
            <CardRow card={card} key={`${PAYMENT_CARD_PREFIX}${card.id}`} />
          ))}
        {bankAccounts &&
          bankAccounts.map((bank) => (
            <BankAccountRow
              bankAccount={bank}
              key={`${BANK_ACCOUNT_PREFIX}${bank.id}`}
            />
          ))}
        <div>
          <div className="flex flex-row items-center" onClick={onAddBank}>
            {/* The Add New Bank option is intended to act like a button, not a radio, as it's just going to present
            the Plaid modal. However, visually it needs to fit in the same group.
            So, we use a disabled radio button to keep it visually in sync.*/}
            <Radio
              color="primary"
              value={ADD_NEW_BANK}
              id="add-bank-radio"
              disabled={true}
              style={{ color: "rgba(0, 0, 0, 0.54)" }}
              aria-label="Add a bank account"
            />
            <InputLabel
              htmlFor="add-bank-radio"
              className="flex items-center w-full"
            >
              <img
                src={AddBankIcon}
                className="mr-2"
                height={20}
                width={30}
                alt="Bank"
              />
              <Typography color="primary">Add a bank account</Typography>
            </InputLabel>
          </div>
          <div className="flex items-center">
            <Radio
              color="primary"
              value={ADD_NEW_CARD}
              id="add-new-card-radio"
            />
            <InputLabel
              htmlFor="add-new-card-radio"
              className="flex items-center w-full"
            >
              <img
                src={AddCardIcon}
                className="mr-2"
                height={20}
                width={30}
                alt="Credit Card Icon"
              />
              <Typography color="primary">Add a new card</Typography>
            </InputLabel>
          </div>
          <Collapse in={radioGroupValue === ADD_NEW_CARD}>
            <Elements stripe={stripePromise}>
              <AddCardForm isInternationalClinic={isInternationalClinic} />
            </Elements>
          </Collapse>
        </div>
      </RadioGroup>
    </div>
  )
}

const usePaymentMethodStyles = makeAppStyles({
  pillContainer: {
    background: colors.blueGray[200],
    color: colors.blueGray[500],
    borderRadius: 49,
    padding: "2px 8px",
    width: "fit-content",
    fontWeight: 700,
    marginBottom: 1,
    textTransform: "uppercase",
    fontSize: 13,
  },
  menuButton: {
    padding: "4px 16px",
    borderRadius: 4,
  },
})

const CardRow = ({ card }) => {
  const classes = usePaymentMethodStyles()

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [tooltipMenuIsOpen, setTooltipMenuIsOpen] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const { updateValues, values } = useCheckoutDraft()

  const [isClinicPaymentMethodsEnabled] = useFeatureFlag(
    FeatureFlag.ClinicPaymentMethodsEnabled
  )

  const confirmationDeleteModal = useConfirmationModal()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const confirmDelete = async () => {
    try {
      await dispatch(Actions.deleteCard(card.id))

      // If the deleted card was the one selected then it should be unselected from the Order.
      if (values.payment_card && values.payment_card.id === card.id) {
        updateValues({ payment_card: null })
      }

      handleClose()
    } catch (error) {
      // do nothing as we've reported the error in #deleteCard
    }
  }

  const handleDelete = async () => {
    if (card.is_shared_with_clinic) {
      setTooltipMenuIsOpen(false)

      confirmationDeleteModal.show({
        title: "Delete Shared Payment Method",
        message:
          "This will remove this payment method from every member of the clinic’s cart. This action cannot be reversed.",
        backButtonTitle: "Actually, Let's Keep It",
        confirmButtonTitle: "Delete Payment Method",
        confirmButtonColor: "destructiveSecondary",
        onClose: confirmationDeleteModal.hide,
        loading: isDeleteLoading,
        handleConfirm: async () => {
          await confirmDelete()
          setIsDeleteLoading(false)
          confirmationDeleteModal.hide()
        },
      })
    } else {
      await confirmDelete()
    }
  }

  const handleShare = async () => {
    await dispatch(Actions.shareCard(card.id, !card.is_shared_with_clinic))
    setTooltipMenuIsOpen(false)
  }

  const cardIcon = (brand) => {
    switch (brand) {
      case "visa":
        return VisaIcon
      case "mastercard":
        return MastercardIcon
      default:
        return CreditCardIcon
    }
  }

  const { navItems } = usePaymentMethodNavItems({
    paymentMethod: card,
    handleDelete,
    handleShare,
  })

  return (
    <ClickAwayListener onClickAway={() => setTooltipMenuIsOpen(false)}>
      <div className="flex items-center" key={card.id}>
        <Radio
          color="primary"
          value={`${PAYMENT_CARD_PREFIX}${card.id}`}
          id={`payment-card-radio-${card.id}`}
        />
        <InputLabel
          htmlFor={`payment-card-radio-${card.id}`}
          className="flex items-center w-full"
        >
          <img
            src={cardIcon(card.brand)}
            className="mr-2"
            height={20}
            width={30}
            alt="Credit Card Icon"
          />
          <div>
            {isClinicPaymentMethodsEnabled && card.is_shared_with_clinic && (
              <div className={classes.pillContainer}>Clinic Card</div>
            )}
            <Typography className="fs-exclude">
              {capitalize(card.brand)} ending in{" "}
              <span className="font-semibold">{card.last_4_digits}</span>
            </Typography>
          </div>
        </InputLabel>
        {isClinicPaymentMethodsEnabled ? (
          <Tooltip
            open={tooltipMenuIsOpen}
            onClose={() => setTooltipMenuIsOpen(false)}
            title={<TooltipNav children={navItems} />}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            TransitionComponent={Zoom}
            arrow
            interactive
          >
            <IconButton
              className={classes.menuButton}
              onClick={() => setTooltipMenuIsOpen(!tooltipMenuIsOpen)}
              aria-label="Bundle info"
            >
              <EllipsesIcon
                fill={colors.blueGray[400]}
                viewBox="0 0 15 15"
                width={16}
                height={16}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Button
              aria-controls={`${card.id}-card-menu`}
              aria-haspopup="true"
              onClick={handleClick}
              aria-label="Card options"
            >
              <img src={DisclosureCircleIcon} height={16} width={16} alt="" />
            </Button>
            <Menu
              id={`${card.id}-card-menu`}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
          </>
        )}
      </div>
    </ClickAwayListener>
  )
}

const BankAccountRow = ({ bankAccount }) => {
  const classes = usePaymentMethodStyles()

  const dispatch = useDispatch()
  const { updateValues, values } = useCheckoutDraft()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [tooltipMenuIsOpen, setTooltipMenuIsOpen] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const [isClinicPaymentMethodsEnabled] = useFeatureFlag(
    FeatureFlag.ClinicPaymentMethodsEnabled
  )

  const confirmationDeleteModal = useConfirmationModal()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const confirmDelete = async () => {
    try {
      await dispatch(Actions.deleteBankAccount(bankAccount.id))

      // If the deleted bank account was the one selected then it should be unselected from the Order.
      if (values.bank_account && values.bank_account.id === bankAccount.id) {
        updateValues({ bank_account: null })
      }

      handleClose()
    } catch (error) {
      // do nothing, as we've reported the error in #deleteBankAccount
    }
  }

  const handleDelete = async () => {
    if (bankAccount.is_shared_with_clinic) {
      setTooltipMenuIsOpen(false)

      confirmationDeleteModal.show({
        title: "Delete Shared Payment Method",
        message:
          "This will remove this payment method from every member of the clinic’s cart. This action cannot be reversed.",
        backButtonTitle: "Actually, Let's Keep It",
        confirmButtonTitle: "Delete Payment Method",
        confirmButtonColor: "destructiveSecondary",
        onClose: confirmationDeleteModal.hide,
        loading: isDeleteLoading,
        handleConfirm: async () => {
          await confirmDelete()
          setIsDeleteLoading(false)
          confirmationDeleteModal.hide()
        },
      })
    } else {
      await confirmDelete()
    }
  }

  const handleShare = async () => {
    await dispatch(
      Actions.shareBankAccount(
        bankAccount.id,
        !bankAccount.is_shared_with_clinic
      )
    )
    setTooltipMenuIsOpen(false)
  }

  const { navItems } = usePaymentMethodNavItems({
    paymentMethod: bankAccount,
    handleDelete,
    handleShare,
  })

  return (
    <div
      className="flex items-center"
      key={`${BANK_ACCOUNT_PREFIX}${bankAccount.id}`}
    >
      <Radio
        color="primary"
        value={`${BANK_ACCOUNT_PREFIX}${bankAccount.id}`}
        id={`bank-account-radio-${bankAccount.id}`}
      />
      <InputLabel
        htmlFor={`bank-account-radio-${bankAccount.id}`}
        className="flex items-center w-full"
      >
        <img
          src={BankIcon}
          className="mr-2"
          height={20}
          width={30}
          alt="Bank"
        />
        <div>
          {isClinicPaymentMethodsEnabled &&
            bankAccount.is_shared_with_clinic && (
              <div className={classes.pillContainer}>Clinic Bank</div>
            )}
          <Typography className="fs-exclude">
            {capitalize(bankAccount.bank_name)} #
            <span className="font-semibold">{bankAccount.last_4_digits}</span>
          </Typography>
        </div>
      </InputLabel>
      {isClinicPaymentMethodsEnabled ? (
        <Tooltip
          open={tooltipMenuIsOpen}
          onClose={() => setTooltipMenuIsOpen(false)}
          title={<TooltipNav children={navItems} />}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Zoom}
          arrow
          interactive
        >
          <IconButton
            className={classes.menuButton}
            onClick={() => setTooltipMenuIsOpen(!tooltipMenuIsOpen)}
            aria-label="Bundle info"
          >
            <EllipsesIcon
              fill={colors.blueGray[400]}
              viewBox="0 0 15 15"
              width={16}
              height={16}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <Button
            aria-controls={`${bankAccount.id}-bank-account-menu`}
            aria-haspopup="true"
            onClick={handleClick}
            aria-label="Bank account options"
          >
            <img src={DisclosureCircleIcon} height={16} width={16} alt="" />
          </Button>
          <Menu
            id={`${bankAccount.id}-bank-account-menu`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </>
      )}
    </div>
  )
}

class StripePaymentError extends Error {
  constructor(message) {
    super(message)
    this.name = "StripePaymentError"
  }
}

const AddCardForm = ({
  isInternationalClinic,
}: {
  isInternationalClinic: boolean
}) => {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const { updateValues } = useCheckoutDraft()

  const [zipCode, setZipCode] = useState("")
  const [pendingSubmission, setPendingSubmission] = useState(false)
  const [cardReflowCompleted, setCardReflowCompleted] = useState(false)
  const [shareCardUponCreation, setShareCardUponCreation] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [isClinicPaymentMethodsEnabled] = useFeatureFlag(
    FeatureFlag.ClinicPaymentMethodsEnabled
  )

  const clearCardForm = () => {
    if (elements) {
      elements.getElement(CardNumberElement)?.clear()
      elements.getElement(CardExpiryElement)?.clear()
      elements.getElement(CardCvcElement)?.clear()
      setZipCode("")
    }
  }

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardNumberElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(CardNumberElement)!

    setPendingSubmission(true)

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      billing_details: {
        address: {
          postal_code: zipCode,
        },
      },
    })

    if (error) {
      // If it's a basic validation error, such as having not filled in the card number, then don't log to sentry.
      if (error.type !== "validation_error") {
        Sentry.withScope(function (scope) {
          scope.setExtra("response", JSON.stringify(error))
          Sentry.captureException(
            new StripePaymentError("Failed to create payment method")
          )
        })
      }

      setErrorMessage(error.message || "")
      setPendingSubmission(false)
      return
    }

    try {
      const paymentCard = (await dispatch(
        Actions.addCard(paymentMethod.id, shareCardUponCreation)
      )) as any
      updateValues({
        payment_card: paymentCard,
        order_payer: OrderPayer.PRACTITIONER,
      })
      setErrorMessage("")
      setPendingSubmission(false)
      clearCardForm()
    } catch (error: any) {
      setErrorMessage(error.message)
      setPendingSubmission(false)
    }
  }

  const browser = detect()

  const isSafariBrowserV14_0 =
    browser &&
    browser.name === "safari" &&
    (browser.version === "14.0.3" ||
      browser.version === "14.0.2" ||
      browser.version === "14.0.1" ||
      browser.version === "14.0")
      ? true
      : false

  /*
  Temporary fix for stripe credit card field not working on Safari v14.0.x
  More details :- https://github.com/stripe/react-stripe-js/issues/136
  */
  function forceReflowForCardNumberField() {
    const el: HTMLElement | null = document.querySelector(".StripeElement")

    if (el) {
      const initialDisplay = el.style.display
      el.style.display = "none"

      // This is required for the dom to get updated
      setTimeout(() => {
        el.style.display = initialDisplay
        setCardReflowCompleted(true)
      }, 300)
    }
  }

  const inputClassNames = "rounded border border-gray-300 p-2"

  return (
    <form onSubmit={handleSubmit} className="p-3">
      <div>
        <Typography
          className="font-semibold text-sm13 mb-2"
          style={{ opacity: 0.8 }}
        >
          Card Details
        </Typography>
        <Grid item xs={12} md={12} data-testid="stripe-card-number">
          <CardNumberElement
            className={inputClassNames}
            onReady={() =>
              isSafariBrowserV14_0 &&
              !cardReflowCompleted &&
              forceReflowForCardNumberField()
            }
          />
        </Grid>

        <div className="flex mt-2">
          <Grid
            item
            xs={12}
            md={6}
            className="mr-2"
            data-testid="stripe-card-exp"
          >
            <CardExpiryElement className={inputClassNames} />
          </Grid>

          <Grid item xs={12} md={6} data-testid="stripe-card-cvc">
            <CardCvcElement className={inputClassNames} />
          </Grid>
        </div>
      </div>

      <div className="mt-3">
        <Typography
          className="font-semibold text-sm13 mb-2"
          style={{ opacity: 0.8 }}
        >
          Billing Address
        </Typography>
        <input
          value={zipCode}
          onChange={(event) => setZipCode(event.target.value)}
          placeholder={isInternationalClinic ? "Postal Code" : "Zip Code"}
          className={inputClassNames + " w-full fs-exclude"}
        />
      </div>

      {isClinicPaymentMethodsEnabled && (
        <div className="mt-3">
          <label className="flex items-center">
            <Checkbox
              checked={shareCardUponCreation}
              onChange={(event) =>
                setShareCardUponCreation(event.target.checked)
              }
              className="p-0"
              color="primary"
              disabled={false}
              id="share-card-checkbox"
            />
            <Typography className={`ml-1.5 fs-exclude text-gray-800`}>
              Share with other practitioners in my clinic
            </Typography>
          </label>
        </div>
      )}

      {errorMessage && (
        <Typography className="mt-2" color="error">
          {errorMessage}
        </Typography>
      )}

      <div className="flex flex-row-reverse mt-5 mb-2">
        <LoadingButton
          loading={pendingSubmission}
          type="submit"
          disabled={!stripe}
          color="primary"
          variant="contained"
        >
          Save Card
        </LoadingButton>
      </div>
    </form>
  )
}

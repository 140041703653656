import {
  LABCORP_COMPANY_KEYS,
  ORDER_PAYER,
  QUEST_COMPANY_KEYS,
  REQUISITION_STATUS_LAB_COMPANY_KEYS,
} from "app/constants"

export const getRequisitionLabCompanyName = (labCompanyKey: string) => {
  let requisitionLabCompanyName = ""

  if (LABCORP_COMPANY_KEYS.includes(labCompanyKey)) {
    requisitionLabCompanyName = "Labcorp"
  } else if (QUEST_COMPANY_KEYS.includes(labCompanyKey)) {
    requisitionLabCompanyName = "Quest"
  }

  return requisitionLabCompanyName
}

export const getRequisitionStatusMetadata = (
  labCompanyKey: string,
  requisitionLabCompanyName: string,
  orderPayer?: string,
  datePatientCheckout?: string,
  forPatientPortal?: boolean
) => {
  let progressPercentage = 25
  let description = "Invoice Paid"
  let subtitle = ""

  if (REQUISITION_STATUS_LAB_COMPANY_KEYS.includes(labCompanyKey)) {
    description = `Awaiting requisition from ${requisitionLabCompanyName}`
    subtitle = `Please do not go to a ${requisitionLabCompanyName} location until you have your requisition form.`
  } else if (orderPayer !== ORDER_PAYER.PATIENT) {
    if (datePatientCheckout) {
      description = forPatientPortal
        ? "Consent Form Complete"
        : "Patient Consent Form Complete"
    } else {
      progressPercentage = 10
      description = forPatientPortal
        ? "Consent Form Pending"
        : "Patient Consent Form Pending"
    }
  }

  return {
    description,
    progressPercentage,
    subtitle,
  }
}

import { Redirect, useHistory, useParams } from "react-router-dom"
import { useUpdateEffect } from "react-use"

import PatientPortalBGNoOrders from "app/assets/images/patient-portal/gradient3.jpeg"
import Portal from "app/components/Portal"
import DisplayText from "app/components/design-system/DisplayText"
import { ORDER_STATUS } from "app/constants.typed"
import {
  PATIENT_PORTAL_TRACKING_EVENTS,
  trackPatientPortalEvent,
} from "app/services/segment"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import makeAppStyles from "app/utils/makeAppStyles"

import Card from "../Card"
import PatientPortalBackground from "../PatientPortalBackground"
import PatientPortalEmptyState from "../PatientPortalEmptyState"
import PatientPortalGeneralQuickLinks from "../footer/PatientPortalGeneralQuickLinks"
import PatientPortalPatientOrderCard from "../patient-orders/PatientPortalPatientOrderCard"
import PatientPortalPatientOrdersLoadingState from "../patient-orders/PatientPortalPatientOrdersLoadingState"
import { PatientPortalOrder, PatientPortalUser } from "../types"
import { buildPatientPortalUserUrl } from "../util"
import usePatientPortalUserOrders from "./hooks/use-patient-portal-user-orders"

const useStyles = makeAppStyles((theme) => ({
  container: {
    padding: "24px 0px",
    display: "flex",
    gap: 16,
    flexDirection: "column",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      gap: 24,
      padding: "40px 24px",
    },
  },
}))

const PatientPortalUserOrders = () => {
  const classes = useStyles()

  const history = useHistory()

  const { userId } = useParams<{
    userId: string
  }>()

  const { orders, isLoading } = usePatientPortalUserOrders(userId)

  const user = useCachedResource<PatientPortalUser>({
    id: userId,
    type: "patient_portal_user",
  })

  const checkoutOrderStatuses: String[] = [
    ORDER_STATUS.PENDING_PAYMENT,
    ORDER_STATUS.PENDING_SHIPPING_INFO,
  ]
  const orderRedirectsToCheckout = (order: PatientPortalOrder) =>
    checkoutOrderStatuses.includes(order.attributes.status)
  const shouldRedirectToOrderPage =
    orders.length === 1 && !orderRedirectsToCheckout(orders[0])

  useUpdateEffect(() => {
    // Do not track if we are just going to redirect to order page
    if (!isLoading && !shouldRedirectToOrderPage) {
      trackPatientPortalEvent(
        PATIENT_PORTAL_TRACKING_EVENTS.USER_ORDERS_PAGE_VIEWED,
        { user_id: userId }
      )
    }
  }, [isLoading])

  const handleCardClick = (order: PatientPortalOrder) => {
    trackPatientPortalEvent(PATIENT_PORTAL_TRACKING_EVENTS.USER_ORDER_CLICKED, {
      order_id: order.id,
    })

    if (
      orderRedirectsToCheckout(order) &&
      order.attributes.patient_checkout_url
    ) {
      window.open(order.attributes.patient_checkout_url)
    } else {
      history.push(buildPatientPortalUserUrl(userId, order.id))
    }
  }

  if (isLoading) {
    return (
      <Card className={classes.container}>
        <PatientPortalPatientOrdersLoadingState />
      </Card>
    )
  }

  if (shouldRedirectToOrderPage) {
    return <Redirect to={buildPatientPortalUserUrl(userId, orders[0].id)} />
  }

  const isEmpty = orders.length === 0

  if (isEmpty) {
    return (
      <div className="flex flex-col gap-4">
        <Card className={classes.container}>
          {user?.attributes.first_name && (
            <DisplayText size="lg" weight="semibold">
              Welcome {user?.attributes.first_name}
            </DisplayText>
          )}

          <PatientPortalEmptyState patientUser={user} />
        </Card>
        <>
          <Portal id="patient-portal-background">
            <PatientPortalBackground image={PatientPortalBGNoOrders} />
          </Portal>
          <PatientPortalGeneralQuickLinks />
        </>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <Card className={classes.container}>
        <DisplayText size="lg" weight="semibold">
          All Orders
        </DisplayText>
        <div className={"flex gap-6 w-full flex-col"}>
          {orders.map((order) => (
            <PatientPortalPatientOrderCard
              order={order}
              onClick={handleCardClick}
              key={order.id}
            />
          ))}
        </div>
      </Card>
    </div>
  )
}

export default PatientPortalUserOrders

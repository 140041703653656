import KitShipmentTrackingDescription from "app/components/StatusComponents/KitShipmentTrackingDescription"
import { LAB_COMPANIES_THAT_HAVE_REQUISITIONS } from "app/constants"
import { ORDERED_TEST_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { OrderedTestStatuses } from "../../types/types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({ orderedTest, labCompany }: StatusConfigurationProps) => {
  if (orderedTest.instant_requisition || labCompany.only_in_house_phlebotomy) {
    if (
      Boolean(orderedTest.requisition) ||
      LAB_COMPANIES_THAT_HAVE_REQUISITIONS.includes(
        orderedTest.lab_test.lab_company.key
      )
    ) {
      return <>{"Requisition is Ready"}</>
    }

    return <>{"Requisition is Processing"}</>
  }
  return <>{"Kit Shipped to Patient"}</>
}

const SubTitle = ({ orderedTest, labCompany }: StatusConfigurationProps) => {
  if (orderedTest.instant_requisition || labCompany.only_in_house_phlebotomy) {
    return null
  }
  return (
    <KitShipmentTrackingDescription
      estimated_arrival_date={orderedTest.estimated_arrival_date}
      shipment_tracking_link={orderedTest.shipment_tracking_link}
      lab_company_notifies_sample_at_lab={labCompany.notifies_sample_at_lab}
      lab_company_short_name={labCompany.short_name}
    />
  )
}

const orderedWithLabStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: ({
      orderedTestStatus,
      labCompanyNotifiesSampleAtLab,
    }: OrderedTestStatuses) =>
      orderedTestStatus === ORDERED_TEST_STATUS.ORDERED_WITH_LAB ||
      (orderedTestStatus === ORDERED_TEST_STATUS.AT_LAB &&
        !labCompanyNotifiesSampleAtLab),
    progressPercentage: PROGRESS_PERCENTAGES.ORDERED_WITH_LAB,
    title: Title,
    subTitle: SubTitle,
  }

export default orderedWithLabStatusConfiguration

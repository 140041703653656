import { ReactComponent as ResultsSummaryDocumentIcon } from "app/assets/images/results-summary-document-icon.svg"
import useEventCallback from "app/hooks/use-event-callback"
import { useLoadAndDownloadResultsSummary } from "app/results-summary/hooks/use-download-results-summary"
import useResultsSummaryModal from "app/results-summary/hooks/use-results-summary-modal"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Clinic } from "types/clinic"
import { ResultsSummaryDocument } from "types/patient-with-documents"

import DocumentCardActionsMenu from "./DocumentCardActionsMenu"
import PatientDocumentCard from "./PatientDocumentCard"

interface Props {
  resultsSummary: ResultsSummaryDocument
  onDelete: (resultsSummary: ResultsSummaryDocument) => void
}

const ResultsSummaryDocumentCard: React.FC<Props> = ({
  resultsSummary,
  onDelete,
}) => {
  const clinic = useCachedResource<Clinic>(
    resultsSummary?.relationships.clinic.data
  )
  const { download, isDownloading } =
    useLoadAndDownloadResultsSummary(resultsSummary)
  const editModal = useResultsSummaryModal()
  const onEdit = useEventCallback(() =>
    editModal.show({ orderId: resultsSummary.attributes.order_id })
  )
  return (
    <PatientDocumentCard
      onClick={onEdit}
      Icon={(className) => <ResultsSummaryDocumentIcon className={className} />}
      title="Results Summary"
      subTitle={!!clinic && `Created by ${clinic.attributes.name}`}
      date={resultsSummary.attributes.created_at}
      actions={
        <DocumentCardActionsMenu
          onDelete={() => onDelete(resultsSummary)}
          onDownload={download}
          isDownloading={isDownloading}
          onEdit={onEdit}
        />
      }
    />
  )
}

export default ResultsSummaryDocumentCard

import { useHistory, useLocation } from "react-router-dom"

export default function useSetQueryParam() {
  const history = useHistory()
  const location = useLocation()

  const setQueryParam = (key: string, value: string | undefined) => {
    const params = new URLSearchParams(location.search)

    params.delete(key)

    if (value) {
      params.set(key, value)
    }

    history.replace({
      ...location,
      search: params.toString(),
    })
  }
  return setQueryParam
}

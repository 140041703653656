import { LAB_COMPANY_ALERT_TYPES, LAB_COMPANY_KEY } from "app/constants"
import {
  KitGroupTypeUnion,
  LabTestTypeKeyUnion,
  OrderPayerUnion,
  OrderStatusUnion,
  OrderedTestStatusUnion,
} from "app/constants.typed"
import {
  Resource,
  ResourceRelationshipMany,
  ResourceRelationshipSingle,
} from "app/swr/types"
import { PatientInstruction as PatientInstructionType } from "app/types"

import { BiomarkerStatus } from "./blood-lab-dashboard/constants"

export interface PatientPortalUser extends Resource {
  attributes: {
    first_name: string
    last_name: string
    full_name: string
    phone_number: string
    birthday: string
    gender: string
    email: string
  }
}

export interface PatientPortalPractitioner extends Resource {
  attributes: {
    titled_full_name: string
  }
  relationships: {
    clinic: ResourceRelationshipSingle
  }
}

export interface PatientPortalClinic extends Resource {
  attributes: {
    name: string
    logo: string | null
    clinic_features: string[]
    clinic_settings: string[]
  }
  relationships: {
    clinic: ResourceRelationshipSingle
  }
}

export interface PatientPortalFaq {
  question: string
  answer: string
}

export interface PatientPortalPatient extends Resource {
  attributes: {
    given_consent: boolean
    has_order_info: boolean
    first_name: string
    last_name: string
    full_name: string
    phone_number?: string | null
    birthday?: string | null
    biological_sex?: string | null
    num_active_orders: number
    faqs: PatientPortalFaq[]
    patient_settings: string[]
  }
  relationships: {
    user: ResourceRelationshipSingle
    default_shipping_address: ResourceRelationshipSingle
    clinic: ResourceRelationshipSingle
    storefront: ResourceRelationshipSingle
  }
}
export interface PatientPortalOrder extends Resource {
  attributes: {
    status: OrderStatusUnion
    payer: OrderPayerUnion
    date_patient_checkout: string
    notes_to_patient: string
    patient_instructions: PatientInstructionType[]
    results_interpretation_pdf: string | null
    faqs: PatientPortalFaq[]
    total_completed_ordered_tests: number
    total_ordered_tests: number
    patient_checkout_url?: string
    phlebotomy_booking_url?: string
    requires_vendor_physician_authorization: boolean
  }
  relationships: {
    kits: ResourceRelationshipMany
    ordered_tests: ResourceRelationshipMany
    patient: ResourceRelationshipSingle
    practitioner: ResourceRelationshipSingle
    payments: ResourceRelationshipMany
    storefront: ResourceRelationshipSingle
    clinic: ResourceRelationshipSingle
  }
}

export interface PatientPortalOrderedTest extends Resource {
  attributes: {
    date_kit_delivered_to_patient: string | null
    date_kit_expected_delivery: string | null
    date_kit_shipped_from_lab: string | null
    date_ordered_by_practitioner: string | null
    date_ordered_by_rupa: string | null
    date_results_expected_from_lab: string | null
    estimated_results_date: string | null
    date_results_received_from_lab: string | null
    date_results_sent_to_patient: string | null
    date_sample_received_at_lab: string | null
    status: OrderedTestStatusUnion
    updated_at: string
    lab_results?: string
    instant_requisition: boolean
    shipment_tracking_link?: string
    estimated_arrival_date?: string
    requisition?: string
    requires_fasting: boolean
    results_delayed_reason?: string
  }
  relationships: {
    lab_test: ResourceRelationshipSingle
    order: ResourceRelationshipSingle
    active_alerts: ResourceRelationshipMany
    specimen_issue: ResourceRelationshipSingle
  }
}

// Must match the SuperbillUnavailableReason in the backend
export const SUPERBILL_UNAVAILABLE_REASON = {
  ORDER_IS_PRAC_PAY: "order_is_prac_pay",
  ORDER_REQUIRES_PHYS_AUTH: "order_requires_phys_auth",
  ORDER_FROM_LABSHOP: "order_is_from_labshop",
  ORDER_HAS_COUPONS: "order_has_coupons",
  UNAVAILABLE_PAYMENT_STATUS: "unavailable_payment_status",
  RESULTS_NOT_RECEIVED: "results_not_received",
  SPLITIT_PAYMENT_INCOMPLETE: "splitit_payment_incomplete",
}

type SuperbillUnavailableReasonUnion =
  typeof SUPERBILL_UNAVAILABLE_REASON[keyof typeof SUPERBILL_UNAVAILABLE_REASON]

export interface PatientPortalPayment extends Resource {
  attributes: {
    id: string
    created_at: string
    receipt_url: string | null
    superbill_url: string | null
    superbill_unavailable_reason: SuperbillUnavailableReasonUnion | null
  }
}

export interface PatientPortalKit extends Resource {
  attributes: {
    name: string
    group_type: KitGroupTypeUnion
    is_rupa_blood_dashboard_kit: boolean
    cheatsheet_url: string
    requires_fasting: boolean
  }
  relationships: {
    order: ResourceRelationshipSingle
    ordered_tests: ResourceRelationshipMany
    ordered_results: ResourceRelationshipMany
    lab_company: ResourceRelationshipSingle
  }
}

export interface PatientPortalLabTest extends Resource {
  attributes: {
    name: string
    fasting_duration: number | null
    cheatsheet_url: string
  }
  relationships: {
    lab_company: ResourceRelationshipSingle
    lab_test_types: ResourceRelationshipMany
    biomarkers: ResourceRelationshipMany
  }
}

export interface PatientPortalLabCompany extends Resource {
  attributes: {
    name: string
    short_name: string
    provides_results_eta: boolean
    notifies_sample_at_lab: boolean
    key: keyof typeof LAB_COMPANY_KEY
    only_in_house_phlebotomy: boolean
  }
}

export interface PatientPortalLabTestType extends Resource {
  attributes: {
    id: string
    name: string
    key: LabTestTypeKeyUnion
    phlebotomy_required: boolean
  }
}

export interface PatientPortalStorefront extends Resource {
  attributes: {
    id: string
    name: string
  }
}

export interface PatientPortalBloodLabDashboardReportSnapshot extends Resource {
  type: "patient_portal_blood_lab_dashboard_report_snapshot"
  attributes: {
    created_at: string
    patient_age_months: number
    patient_biological_sex: string
    patient_phase_of_cycle: string
    latest_snapshot_update: string
  }
  relationships: {
    patient: ResourceRelationshipSingle
    ordered_result: ResourceRelationshipSingle
    lab_company: ResourceRelationshipSingle
  }
}

export interface PatientPortalBloodReportResult extends Resource {
  type: "patient_portal_blood_report_result"
  attributes: {
    optimal_range_min: string
    optimal_range_max: string
    standard_range_min: string
    standard_range_max: string
    value: string
    display_value: string
    alternate_value: string
    unit: string
    status: BiomarkerStatus
    biomarker_short_name: string
    biomarker_long_name: string
    out_of_optimal_range: boolean
    out_of_standard_range: boolean
    is_range_value: boolean
    is_clinic_optimal_range: boolean
  }
  relationships: {
    biomarker: ResourceRelationshipSingle
  }
}

export interface PatientPortalLabCompanyAlert extends Resource {
  attributes: {
    alert_type: keyof typeof LAB_COMPANY_ALERT_TYPES
    title: string
    subtitle: string
    date_alert_began: string
    date_alert_ended: string | null
    days_delayed: number | null
    patient_facign_issue_description: string
  }
  relationships: {}
}

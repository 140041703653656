import { REQUISITION_STATUS_LAB_COMPANY_KEYS } from "app/constants"
import { ORDERED_TEST_STATUS, ORDER_PAYER } from "app/constants.typed"
import { getRequisitionLabCompanyName } from "app/utils/order-status-utils"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-portal-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({ order, labCompany }: StatusConfigurationProps) => {
  if (
    labCompany &&
    REQUISITION_STATUS_LAB_COMPANY_KEYS.includes(labCompany.attributes.key)
  ) {
    const requisitionLabCompanyName = getRequisitionLabCompanyName(
      labCompany.attributes.key
    )
    return <>{`Awaiting requisition from ${requisitionLabCompanyName}`}</>
  } else if (order && order.attributes.payer !== ORDER_PAYER.PATIENT) {
    return (
      <>
        {order.attributes.date_patient_checkout
          ? "Consent Form Complete"
          : "Consent Form Pending"}
      </>
    )
  }
  return <>Invoice Paid</>
}

const SubTitle = ({ labCompany }: StatusConfigurationProps) => {
  if (
    labCompany &&
    REQUISITION_STATUS_LAB_COMPANY_KEYS.includes(labCompany.attributes.key)
  ) {
    const requisitionLabCompanyName = getRequisitionLabCompanyName(
      labCompany.attributes.key
    )
    return (
      <>
        {`Please do not go to a ${requisitionLabCompanyName} location until you have your requisition form.`}
      </>
    )
  }
  return null
}

const progressPercentage = ({
  order,
  labCompany,
}: StatusConfigurationProps) => {
  return (labCompany &&
    REQUISITION_STATUS_LAB_COMPANY_KEYS.includes(labCompany.attributes.key)) ||
    (order && order.attributes.payer !== ORDER_PAYER.PATIENT)
    ? PROGRESS_PERCENTAGES.PAID
    : PROGRESS_PERCENTAGES.AWAITING_PATIENT_CHECKOUT
}

const paidStatusConfiguration: StatusConfiguration<StatusConfigurationProps> = {
  when: { orderedTestStatus: ORDERED_TEST_STATUS.PAID },
  progressPercentage: progressPercentage,
  title: Title,
  subTitle: SubTitle,
}

export default paidStatusConfiguration

import { PatientPortalPatient } from "app/patient-portal/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"

export default function usePatientPortalPatients() {
  const { data, isLoading } = useCollectionSWR<
    ResourceCollection<PatientPortalPatient>
  >(
    `/patient_portal/patient/`,
    {
      include: ["clinic", "storefront", "user"],
    },
    {
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  )

  const patients = useCachedCollection<PatientPortalPatient>(data)

  return {
    patients,
    isLoading,
  }
}

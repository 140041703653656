import { useMemo } from "react"

import { UseFormReturn } from "react-hook-form"

import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BodyText from "app/components/design-system/BodyText"
import ControlledDatePicker from "app/components/forms/ControlledDatePicker"
import {
  ControlledAutoCompleteField,
  ControlledSelectField,
} from "app/main/patient-checkout/fields"
import useInputFormStyles from "app/main/results-upload/hooks/use-input-form-styles"
import { ResultsUploadManualEntryBaseFormData } from "app/main/results-upload/hooks/use-results-upload-manual-entry-form"
import { LabCompany, LabTestType } from "app/types"

interface Props {
  availableLabCompanies: LabCompany[] | null
  availableLabTestTypes: LabTestType[]
  labCompanyFieldValue?: string
  methods: UseFormReturn<ResultsUploadManualEntryBaseFormData>
}

const ResultsUploadManualEntryBaseForm = ({
  availableLabCompanies,
  availableLabTestTypes,
  methods,
}: Props) => {
  const classes = useInputFormStyles()

  const labCompanyNameOptions = useMemo(() => {
    return (
      // We use results_name so that custom selections from the DB will show
      availableLabCompanies?.map((labCompany) => labCompany.results_name) || []
    )
  }, [availableLabCompanies])

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
      <ControlledDatePicker
        name="sample_collection_date"
        calendarProps={{
          fromYear: new Date().getFullYear() - 20,
          toYear: new Date().getFullYear() + 1,
          captionLayout: "dropdown",
        }}
        fieldLabelProps={{ label: "Sample Collection Date", required: true }}
        align="start"
        modal
      />

      <ControlledSelectField label="Fasted" name="fasted">
        <option value={"yes"}>Yes</option>
        <option value={"no"}>No</option>
      </ControlledSelectField>

      <ControlledAutoCompleteField
        name="lab_company"
        label="Lab Company"
        getOptionLabel={(option) => option}
        options={labCompanyNameOptions}
        required
        className="w-full"
        inputClassName={classes.customInput}
        showErrors={true}
        valueFromOption={(option) => option}
        optionFromValue={(value, _options) => value}
        openOnFocus={false}
        placeholder="Enter Lab Company"
        includeInputTextInOptions
        dropdownOnlyWhileTyping
        freeSolo={true}
      />

      <ControlledSelectField
        label="Sample Type"
        infoText={
          <InfoTextTooltipContent content="Markers collected at different times are tracked together only if they share identical sample types. This ensures their values are directly comparable." />
        }
        alternateIcon={
          <FontAwesomeIcon className="text-primary" icon={faCircleQuestion} />
        }
        name="sample_type"
        required
      >
        {availableLabTestTypes.map((labTestType) => (
          <option key={labTestType.id} value={labTestType.id}>
            {labTestType.name}
          </option>
        ))}
      </ControlledSelectField>
    </div>
  )
}

const InfoTextTooltipContent = ({ content }: { content: string }) => {
  return (
    <div className="flex flex-col gap-1 items-center justify-center">
      <BodyText size="xs">{content}</BodyText>
    </div>
  )
}

export default ResultsUploadManualEntryBaseForm

import { LAB_COMPANY_KEY } from "app/constants"

export const EXTERNAL_PHLEBOTOMY_COPY =
  "Price varies from $15-60 for in person draws, and $40-150 for mobile blood draws, depending on patient preferences. Phlebotomy is booked and paid directly through Phlebotomist."

export enum TYPE_KEYS {
  BIOMARKER = "biomarker",
  BIOMARKER_GROUPING = "biomarker_grouping",
}

export const PANEL_BUILDER_LAB_COMPANIES = [
  LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS,
  LAB_COMPANY_KEY.BOSTON_HEART,
  LAB_COMPANY_KEY.VIBRANT_AMERICA,
  LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS,
  LAB_COMPANY_KEY.LABSFIRST_QUEST,
]

export const BIOMARKERS_TO_SHOW_SAMPLE_TYPES = {
  Selenium: ["Serum", "Whole Blood"],
  Iron: ["Serum", "Whole Blood"],
  "Vitamin A": ["Serum", "Whole Blood"],
  "Vitamin B1": ["Plasma", "Serum", "Whole Blood"],
  "Vitamin B2": ["Plasma", "Serum", "Whole Blood"],
  "Vitamin B3": ["Serum", "Whole Blood"],
  "Vitamin B5": ["Serum", "Whole Blood"],
  "Vitamin B6": ["Plasma", "Serum", "Whole Blood"],
  "Vitamin B12": ["Plasma", "Serum", "Whole Blood"],
  "Vitamin C": ["Plasma", "Serum", "Whole Blood"],
  "Vitamin D3": ["Serum", "Whole Blood"],
  "Vitamin E": ["Serum", "Whole Blood"],
  "Vitamin K1": ["Serum", "Whole Blood"],
  "Vitamin K2": ["Serum", "Whole Blood"],
  Folate: ["Plasma", "Serum", "Whole Blood"],
  Cysteine: ["Serum", "Whole Blood"],
  Asparagine: ["Serum", "Whole Blood"],
  Glutamine: ["Serum", "Whole Blood"],
  Serine: ["Serum", "Whole Blood"],
  Arginine: ["Serum", "Whole Blood"],
  Choline: ["Serum", "Whole Blood"],
  Inositol: ["Serum", "Whole Blood"],
  Carnitine: ["Serum", "Whole Blood"],
  Manganese: ["Serum", "Whole Blood"],
  Magnesium: ["Serum", "Whole Blood"],
  Zinc: ["Plasma", "Serum", "Whole Blood"],
  Copper: ["Plasma", "Serum", "Whole Blood"],
  Chromium: ["Serum", "Whole Blood"],
  Leucine: ["Serum", "Whole Blood"],
  Valine: ["Serum", "Whole Blood"],
  Isoleucine: ["Serum", "Whole Blood"],
  Citrulline: ["Serum", "Whole Blood"],
  "Alpha-Linolenic Acid": ["Plasma", "Serum", "Whole Blood"],
  "Docosahexaenoic Acid": ["Plasma", "Serum", "Whole Blood"],
  "Eicosapentaenoic Acid": ["Plasma", "Serum", "Whole Blood"],
  "Docosapentaenoic Acid": ["Plasma", "Whole Blood"],
  "Arachidonic Acid": ["Plasma", "Serum", "Whole Blood"],
  "Linoleic Acid": ["Plasma", "Serum", "Whole Blood"],
  "EPA/AA Ratio": ["Plasma", "Serum", "Whole Blood"],
  "AA/EPA Ratio": ["Plasma", "Serum", "Whole Blood"],
  "Omega-6 Index": ["Plasma", "Serum", "Whole Blood"],
  "Omega-3/Omega-6 Ratio": ["Plasma", "Serum", "Whole Blood"],
  "Monounsaturated Fatty Acids Index": ["Plasma", "Serum", "Whole Blood"],
}

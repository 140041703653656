import { clamp, isEmpty } from "lodash"

import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import { PatientPortalBloodReportResult } from "app/patient-portal/types"
import { BloodReportResult } from "types/blood-report-result"

import { Status } from "./constants"

/*
 * This function is used to get the color of the gradient for a given percentage
 * @param {string} gradientName - The name of through the gradient to use
 * @param {number} percentage - The percentage of the gradient to get the color for
 * @param {boolean} reverse - Whether to reverse the gradient
 * @returns {string} The color of the gradient at the given percentage
 */
export function getColorFromGradient(
  gradient: string,
  percentage: number,
  reverse = false
) {
  // Parse the gradient string to extract colors and positions
  const regex = /#[0-9a-fA-F]{6}/g
  const colors = gradient.match(regex)
  const positions = gradient.match(/[\d.]+(?=%)/g)?.map(Number)

  if (!colors || !positions) {
    return ""
  }

  // Reverse the colors if the gradient is reversed
  if (reverse) {
    colors.reverse()
  }

  // Find the color stop range that corresponds to the given percentage
  for (let i = 1; i < positions.length; i++) {
    if (percentage <= positions[i]) {
      const lowerColor = colors[i - 1]
      const upperColor = colors[i]
      const lowerPosition = positions[i - 1]
      const upperPosition = positions[i]

      // Interpolate between the two colors based on the percentage
      const t = (percentage - lowerPosition) / (upperPosition - lowerPosition)
      return interpolateColors(lowerColor, upperColor, t)
    }
  }

  // Return the last color if percentage exceeds 100%
  return colors[colors.length - 1]
}

/*
 * This function is used to interpolate between two colors
 * @param color1 - The first color
 * @param color2 - The second color
 * @param t - The percentage to interpolate between the two colors
 * @returns The interpolated color
 */
function interpolateColors(color1: string, color2: string, t: number): string {
  const hex1 = color1.slice(1)
  const hex2 = color2.slice(1)

  const r1 = parseInt(hex1.substr(0, 2), 16)
  const g1 = parseInt(hex1.substr(2, 2), 16)
  const b1 = parseInt(hex1.substr(4, 2), 16)

  const r2 = parseInt(hex2.substr(0, 2), 16)
  const g2 = parseInt(hex2.substr(2, 2), 16)
  const b2 = parseInt(hex2.substr(4, 2), 16)

  const r = Math.round(r1 + (r2 - r1) * t)
  const g = Math.round(g1 + (g2 - g1) * t)
  const b = Math.round(b1 + (b2 - b1) * t)

  return `rgb(${r}, ${g}, ${b})`
}

/*
 * This function is used to get the status of a numeric biomarker
 * @param {string} value - The value of the biomarker
 * @param {string} normalMin - The normal minimum value of the biomarker
 * @param {string} normalMax - The normal maximum value of the biomarker
 * @param {string} optimalMin - The optimal minimum value of the biomarker
 * @param {string} optimalMax - The optimal maximum value of the biomarker
 * @returns {string} The status of the biomarker
 */
export function getNumericStatus(
  value: string,
  normalMin: string | undefined,
  normalMax: string | undefined,
  optimalMin: string | undefined,
  optimalMax: string | undefined
) {
  const valueNumber = Number(value)

  if (!normalMin && !normalMax) {
    return undefined
  }

  const normalMinNumber = !isEmpty(normalMin)
    ? Number(normalMin)
    : valueNumber - 0.00001
  const normalMaxNumber = !isEmpty(normalMax)
    ? Number(normalMax)
    : valueNumber + 0.00001
  const optimalMinNumber = !isEmpty(optimalMin)
    ? Number(optimalMin)
    : valueNumber - 0.00001
  const optimalMaxNumber = !isEmpty(optimalMax)
    ? Number(optimalMax)
    : valueNumber + 0.00001

  if (valueNumber < optimalMinNumber || valueNumber < normalMinNumber) {
    if (valueNumber < normalMinNumber) {
      return Status.Low
    } else {
      return Status.BelowOptimal
    }
  } else if (valueNumber > optimalMaxNumber || valueNumber > normalMaxNumber) {
    if (valueNumber > normalMaxNumber) {
      return Status.High
    } else {
      return Status.AboveOptimal
    }
  } else {
    if (!isEmpty(optimalMin) || !isEmpty(optimalMax)) {
      return Status.Optimal
    } else {
      return Status.Normal
    }
  }
}

/*
 * This function is used to get the ratio of a numeric biomarker between two points
 * @param {string} value - The value of the biomarker
 * @param {string} normalMin - The normal minimum value of the biomarker
 * @param {string} normalMax - The normal maximum value of the biomarker
 * @param {string} optimalMin - The optimal minimum value of the biomarker
 * @param {string} optimalMax - The optimal maximum value of the biomarker
 * @returns {string} The gradient of the biomarker
 */
export function getRatioBetweenPoints(
  value: string,
  normalMin: string | undefined,
  normalMax: string | undefined,
  optimalMin: string | undefined,
  optimalMax: string | undefined
) {
  const { upperValue, lowerValue } = getLimitsFromValue(
    value,
    normalMin,
    normalMax,
    optimalMin,
    optimalMax
  )

  // Get the ratio the value is between the two points
  return getNumericRatio(value, lowerValue, upperValue)
}

/*
 * This function is used to get the two min/max values the value is between
 * @param {string} value - The value of the biomarker
 * @param {string} normalMin - The normal minimum value of the biomarker
 * @param {string} normalMax - The normal maximum value of the biomarker
 * @param {string} optimalMin - The optimal minimum value of the biomarker
 * @param {string} optimalMax - The optimal maximum value of the biomarker
 * @returns {string} The gradient of the biomarker
 */
export function getLimitsFromValue(
  value: string,
  normalMin: string | undefined,
  normalMax: string | undefined,
  optimalMin: string | undefined,
  optimalMax: string | undefined
) {
  const valueNumber = Number(value)
  const order = [normalMin, optimalMin, optimalMax, normalMax]

  // Where the value lays in the order
  let valueIndex = order.findIndex((item) =>
    !isEmpty(item) ? Number(item) >= valueNumber : false
  )

  if (valueIndex === -1) {
    valueIndex = order.length
  }

  let upperValue = ""
  let lowerValue = ""

  // Get the value below the current value
  for (let i = valueIndex; i < order.length; i++) {
    if (!isEmpty(order[i])) {
      upperValue = order[i] as string
      break
    }
  }

  // Get the value above the current value
  for (let i = valueIndex - 1; i >= 0; i--) {
    if (!isEmpty(order[i])) {
      lowerValue = order[i] as string
      break
    }
  }

  return { upperValue, lowerValue }
}

/*
 * This function is used to get the ratio between two values
 * @param {string} value - The value of the biomarker
 * @param {string} min - The minimum value of the biomarker
 * @param {string} normalMax - The normal maximum value of the biomarker
 * @returns {number} The ratio of the biomarker
 */
export function getNumericRatio(value: string, min: string, normalMax: string) {
  const valueNumber = Number(value)
  const hasMin = !isEmpty(min)
  const hasMax = !isEmpty(normalMax)
  let minNumber = hasMin ? Number(min) : valueNumber
  let maxNumber = hasMax ? Number(normalMax) : valueNumber

  // Handle the 0 case
  if (minNumber === 0) {
    minNumber = 0.1
  }
  if (maxNumber === 0) {
    maxNumber = 0.1
  }

  // Base case: value is the same value as the upper bounds
  if (minNumber === valueNumber && maxNumber === valueNumber) {
    return 0.0
  } else if (hasMin && hasMax) {
    const spread = maxNumber - minNumber
    return clamp((valueNumber - minNumber) / spread, 0.0, 1.0)
  } else if (hasMin) {
    return clamp((valueNumber - minNumber) / minNumber, 0.0, 1.0)
  } else if (hasMax) {
    return clamp(1 - (maxNumber - valueNumber) / maxNumber, 0.0, 1.0)
  } else {
    return 0.5
  }
}

/**
 * Returns the value to pass to the numeric biomarker graphic component
 * Should only pass in value if not a range value
 * @param bloodReportResult
 * @returns
 */
export function getValueForNumericBiomarkerGraphicFromBloodReportResult(
  bloodReportResult: BloodReportResult | PatientPortalBloodReportResult
): string {
  return getValueForNumericBiomarkerGraphic(
    bloodReportResult.attributes.is_range_value,
    bloodReportResult.attributes.value || ""
  )
}

export function getValueForNumericBiomarkerGraphic(
  isRangeValue: boolean,
  value: string
): string {
  return !isRangeValue ? value : ""
}

export function getActiveSectionLabelFromStatus(
  status: BiomarkerStatus,
  isCondensed: boolean
): { sectionLabelValue: string; secondarySectionLabelValue: string } {
  let sectionLabelValue = ""
  let secondarySectionLabelValue = ""
  switch (status) {
    case BiomarkerStatus.LOW:
      sectionLabelValue = "Low"
      break
    case BiomarkerStatus.BELOW_OPTIMAL:
      if (isCondensed) {
        sectionLabelValue = "Normal"
        secondarySectionLabelValue = "(Below Optimal)"
      } else {
        sectionLabelValue = "Below Optimal"
      }
      break
    case BiomarkerStatus.HIGH:
      sectionLabelValue = "High"
      break
    case BiomarkerStatus.ABOVE_OPTIMAL:
      if (isCondensed) {
        sectionLabelValue = "Normal"
        secondarySectionLabelValue = "(Above Optimal)"
      } else {
        sectionLabelValue = "Above Optimal"
      }
      break
    case BiomarkerStatus.OPTIMAL:
      sectionLabelValue = "Optimal"
      break
    default:
      sectionLabelValue = "Normal"
  }
  return { sectionLabelValue, secondarySectionLabelValue }
}

export function getMiddleSectionLabel(
  hasOptimalMin: boolean,
  hasOptimalMax: boolean
): string {
  return hasOptimalMax || hasOptimalMin ? "Optimal" : "Normal"
}

import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"

import { InRangeOption, ResultsOverTimeDateGrouping } from "../types/types"

export default function useAvailableResultsOverTimeDates(
  patientId: string,
  timeSeriesGrouping: ResultsOverTimeDateGrouping,
  inRangeValue: InRangeOption,
  bodySystemId: string
) {
  // Need to establish a unique key here to ensure that the SWR cache is
  // invalidated when one or more of the dropdowns change.
  const key = [
    `/results_over_time/${patientId}/available_dates/`,
    timeSeriesGrouping,
    inRangeValue,
    bodySystemId,
  ]

  const {
    data: availableDates,
    error,
    isLoading,
    isValidating,
  } = useSWR(
    key,
    async (key) => {
      const url = key[0]

      const response = await axios.request<string[]>({
        baseURL: getApiBaseUrl() + "/api/",
        url,
        method: "get",
        params: {
          group_by: timeSeriesGrouping,
          results_range_status: inRangeValue,
          body_system_id: bodySystemId === "all" ? null : bodySystemId,
          primary_body_system_only: Boolean(bodySystemId === "all"),
        },
      })

      return response.data
    },
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  return {
    availableDates,
    error,
    isLoading,
    isValidating,
  }
}

import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import Button from "app/components/design-system/Button"
import useUpdateOrder from "app/main/patient-checkout/hooks/use-update-order"
import {
  ADDITIONAL_AXLE_PHLEBOTOMY_KIT_FEE,
  AXLE_PHLEBOTOMY_FEE,
} from "app/main/patient-checkout/sections/blood-draw/constants"
import {
  trackPhlebotomyBookingEvent,
  PHLEBOTOMY_BOOKING_EVENTS,
} from "app/services/segment"
import { showMessage } from "app/store/actions/fuse"
import { white, primaryColor } from "app/theme"
import { PhlebotomyProvider, PHLEBOTOMY_PROVIDERS } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { updateProviderList } from "./utils"

const useStyles = makeAppStyles((theme) => ({
  radioContainer: {
    borderRadius: "7px",
    border: "1px solid #DEE0E5",
    background: white,
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07)",
  },
  radioSelection: {
    paddingLeft: "12px",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%", // equivalent to 20.25px
    cursor: "pointer",
  },
  radioSelectionTitle: {
    color: primaryColor,
    fontWeight: 600,
  },
  radioSelectionBody: {
    paddingRight: "24px",
  },
  label: {
    display: "flex",
    alignItems: "start",
    padding: "16px",
    paddingLeft: 24,
    cursor: "pointer",
  },
  radioInput: {
    marginTop: "4px",
  },
  separator: {
    height: "1px",
    width: "100%",
    background: "#DEE0E5",
  },
  semibold: {
    fontWeight: 600,
  },
  button: {
    marginTop: 23,
  },
}))

interface SelectProviderProps {
  orderPhlebotomyProviders: PhlebotomyProvider[]
  onFormIsModified: () => void
  onFormIsConfirmed: () => void
}

export const SelectProvider = ({
  orderPhlebotomyProviders,
  onFormIsModified,
  onFormIsConfirmed,
}: SelectProviderProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { updateOrder } = useUpdateOrder()

  const currentProviderNames = orderPhlebotomyProviders.map(
    (item) => item.provider_name
  )

  const [confirmDisabled, setConfirmDisabled] = useState(false)

  const [selectedProvider, setSelectedProvider] = useState(
    orderPhlebotomyProviders
      .map((provider) => provider.provider_name)
      .includes(PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN)
      ? PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN
      : PHLEBOTOMY_PROVIDERS.AXLE
  )

  const handleProviderChange = (event) => {
    setSelectedProvider(event.target.value)
  }

  // Check if the form has been modified
  const isFormModified = !currentProviderNames.includes(selectedProvider)

  useEffect(() => {
    if (isFormModified) {
      setConfirmDisabled(false)
      onFormIsModified()
    }
  }, [selectedProvider, isFormModified])

  const onConfirm = () => {
    setConfirmDisabled(true)
    onFormIsConfirmed()
    const newPhlebotomyProviders = updateProviderList(
      selectedProvider,
      currentProviderNames
    )
    updateOrder({
      phlebotomy_providers: newPhlebotomyProviders,
    })
      .then(() => {
        trackPhlebotomyBookingEvent(
          PHLEBOTOMY_BOOKING_EVENTS.PHLEBOTOMY_OPTIONS_PICKED,
          {
            selected_option: selectedProvider,
          }
        )
        dispatch(
          showMessage({
            message:
              selectedProvider === PHLEBOTOMY_PROVIDERS.AXLE
                ? "Mobile blood draw added to order!"
                : "Selection confirmed!",
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          })
        )
      })
      .catch(() => {
        dispatch(
          showMessage({
            message: "Something went wrong. Please try again.",
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          })
        )
      })
  }

  return (
    <div>
      <fieldset>
        <div className={classes.radioContainer}>
          <label htmlFor={PHLEBOTOMY_PROVIDERS.AXLE} className={classes.label}>
            <input
              type="radio"
              id={PHLEBOTOMY_PROVIDERS.AXLE}
              name="phleb_selection"
              value={PHLEBOTOMY_PROVIDERS.AXLE}
              className={classes.radioInput}
              onChange={handleProviderChange}
              checked={selectedProvider === PHLEBOTOMY_PROVIDERS.AXLE}
            />
            <RadioSelection
              body={`A certified phlebotomist will come to the address provided above. You will pay a ${AXLE_PHLEBOTOMY_FEE} fee for the first kit today (+ ${ADDITIONAL_AXLE_PHLEBOTOMY_KIT_FEE} for each additional kit) and schedule an appointment after checkout.`}
              title={"I want to schedule a mobile blood draw & pay now"}
            />
          </label>

          <div className={classes.separator}></div>

          <label
            htmlFor={PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN}
            className={classes.label}
          >
            <input
              type="radio"
              id={PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN}
              name="phleb_selection"
              value={PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN}
              className={classes.radioInput}
              onChange={handleProviderChange}
              checked={selectedProvider === PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN}
            />
            <RadioSelection
              body={
                "We'll email you suggestions of places to get your blood drawn. Scheduling and payment will be handled directly between you and your selected blood draw site. Price varies per site (typically $30-100)."
              }
              title={"I want to coordinate a blood draw on my own & pay later"}
            />
          </label>
        </div>
        {isFormModified && (
          <Button
            className={classes.button}
            onClick={onConfirm}
            disabled={confirmDisabled}
          >
            Confirm Selection
          </Button>
        )}
      </fieldset>
    </div>
  )
}

interface RadioSelectionProps {
  title: string
  body: string
}

const RadioSelection = ({ title, body }: RadioSelectionProps) => {
  const classes = useStyles()

  return (
    <div className={classes.radioSelection}>
      <div className={classes.radioSelectionTitle}>{title}</div>
      <div className={classes.radioSelectionBody}>{body}</div>
    </div>
  )
}

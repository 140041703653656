import { useEffect, useMemo } from "react"

import { Controller } from "react-hook-form"

import { faBox } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Input } from "@material-ui/core"

import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { colors, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { useIOKOrderFormContext } from "../order-history/StartOrderModal"
import { InOfficeKitProduct, InOfficeKitProductInventoryConfig } from "../types"

const useStyles = makeAppStyles(() => ({
  textFieldError: {
    width: 108,
    backgroundColor: colors.blueGray[50],
    borderRadius: 6,
    border: "1px solid red",
    boxShadow: shadows.sm,
    padding: "9px 13px",
    height: 35,
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
  },
  textField: {
    width: 108,
    backgroundColor: colors.blueGray[50],
    borderRadius: 6,
    border: `1px solid ${colors.coolGray[300]}`,
    boxShadow: shadows.sm,
    padding: "9px 13px",
    height: 35,
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
  },
}))

interface Props {
  product: InOfficeKitProduct
  initialQuantity: string | number | undefined
  inventoryConfig: InOfficeKitProductInventoryConfig | undefined
}

const OrderFormProduct = ({
  product,
  initialQuantity,
  inventoryConfig,
}: Props) => {
  const { setValue, control } = useIOKOrderFormContext()
  const classes = useStyles()

  useEffect(() => {
    setValue(`product:${product.id}`, initialQuantity?.toString() ?? "")
  }, [initialQuantity, setValue, product.id])

  const current_clinic_inventory =
    product.attributes.clinic_current_or_pending_inventory

  const max_orderable = useMemo(() => {
    const max_inventory =
      inventoryConfig?.attributes.inventory_max ??
      product.attributes.inventory_max

    // return early if max_inventory is not defined
    if (max_inventory === null || max_inventory === undefined) return

    return max_inventory - current_clinic_inventory
  }, [inventoryConfig, product, current_clinic_inventory])

  const product_max_order_quantity =
    inventoryConfig?.attributes.order_max ?? product.attributes.max_quantity

  return (
    <div
      key={product.attributes.name}
      className="flex flex-row w-full justify-between px-3 items-center"
    >
      <div className="flex flex-col items-start">
        <BodyText className="text-body pt-2 text-base15">
          {product.attributes.name}
        </BodyText>
        {product.attributes.description && (
          <Tooltip
            placement="bottom"
            arrow
            interactive
            title={
              <div
                className="text-xs text-start"
                dangerouslySetInnerHTML={{
                  __html: product.attributes.description,
                }}
              />
            }
          >
            <div className="flex w-fit gap-1 items-center text-primary">
              <FontAwesomeIcon icon={faBox} />
              <p>Details</p>
            </div>
          </Tooltip>
        )}
      </div>
      <Controller
        name={`product:${product.id}`}
        control={control}
        rules={{
          ...(product.attributes.min_quantity && {
            min: {
              value: product.attributes.min_quantity,
              message: `Min ${product.attributes.min_quantity} kits per order.`,
            },
          }),
          ...(product_max_order_quantity && {
            max: {
              value: product_max_order_quantity,
              message: `Max ${product_max_order_quantity} kits per order.`,
            },
          }),
          validate: {
            max_clinic_inventory: (value) => {
              const val = Number(value)

              // If there is no max orderable, then there is no limit
              if (!val || max_orderable === undefined) return

              // Error if they are trying to order more than the max orderable
              if (val > max_orderable) {
                return `Because you already have ${current_clinic_inventory} in stock or on the way, you can only order up to ${max_orderable} more.`
              }
            },
          },
        }}
        render={({ field: { value, ...field }, fieldState: { error } }) => (
          <div className="flex flex-col">
            <div className="flex flex-row items-end justify-end">
              <Input
                className={error ? classes.textFieldError : classes.textField}
                type="number"
                placeholder="Quantity"
                disableUnderline={true}
                // undefined raises annoying React console errors
                value={value ?? ""}
                {...field}
              />
            </div>
            {error && (
              <div className="flex flex-row items-start justify-start max-w-xs text-end">
                <BodyText
                  variant="body2"
                  color="error"
                  style={{ marginTop: 5 }}
                >
                  {error.message}
                </BodyText>
              </div>
            )}
          </div>
        )}
      />
    </div>
  )
}

export default OrderFormProduct

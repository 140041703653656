const PatientPortalUserEmptyState = ({
  mainText,
  supplementalText,
  imageNode,
}: {
  mainText: string
  supplementalText: string
  imageNode: React.ReactNode
}) => {
  return (
    <div className="py-7 px-4 flex flex-col justify-center items-center mt-7">
      {imageNode}
      <div className="flex flex-col justify-center items-center gap-y-1">
        <div className="text-navy font-semibold">{mainText}</div>
        <div className="text-navy">{supplementalText}</div>
      </div>
    </div>
  )
}

export default PatientPortalUserEmptyState

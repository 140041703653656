import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Input } from "@rupahealth/design"

interface Props {
  handleBiomarkerSearch: (searchText: string) => void
  biomarkerSearchValue: string
}

const BiomarkerSearchBar = ({
  handleBiomarkerSearch,
  biomarkerSearchValue,
}: Props) => {
  return (
    <div className="w-full relative flex items-center justify-start">
      <FontAwesomeIcon
        className="absolute mr-2 ml-2 w-6 text-slate-500 text-sm"
        icon={faMagnifyingGlass}
      />
      <Input
        placeholder="Search for a biomarker"
        className="py-1 pr-2 pl-8 rounded-lg shadow-sm border border-slate-200 placeholder:text-slate-500"
        onChange={(e) => handleBiomarkerSearch(e.target.value)}
        value={biomarkerSearchValue}
        autoComplete="off"
        autoCapitalize="off"
        spellCheck={false}
      />
    </div>
  )
}

export default BiomarkerSearchBar

import { useCallback, useEffect, useState } from "react"

import { debounce, isEmpty } from "lodash"
import {
  FieldErrors,
  ResolverOptions,
  useForm,
  UseFormReturn,
} from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"

import { LabCompany } from "app/types"
import { UserBiomarkerResult } from "types/user-biomarker-result"

import { UserResultContextProps } from "../providers/UserResultDataProvider"
import useUpdateUserResult from "./use-update-user-result"

// interface for the UserBiomarkerResult data that we use in each item of the list form
export interface BiomarkerResultFormData {
  // id and type only present for existing records
  id?: UserBiomarkerResult["id"]
  type?: UserBiomarkerResult["type"]
  attributes: UserBiomarkerResult["attributes"]
  relationships: Pick<
    UserBiomarkerResult["relationships"],
    "sample_type" | "biomarker"
  >
}

// interface for the entire form data
export interface ResultsUploadManualEntryBaseFormData {
  sample_collection_date?: Date | string
  fasted?: string
  lab_company?: string
  other_lab_company_name?: string
  sample_type?: string
  results?: BiomarkerResultFormData[]
}

export const BASE_FORM_FIELDS: (keyof ResultsUploadManualEntryBaseFormData)[] =
  ["sample_collection_date", "fasted", "lab_company", "sample_type"]

const DEFAULT_VALUES: ResultsUploadManualEntryBaseFormData = {
  sample_collection_date: "",
  fasted: "no",
  lab_company: "",
  other_lab_company_name: "",
  sample_type: "",
  results: [],
}

// Hook Input Props
interface Props {
  userResultId: string
  labCompanies: LabCompany[]
  updateUserBiomarkerResult: UserResultContextProps["updateUserBiomarkerResult"]
  requireReview: boolean // currently unused
}

// Hook Return Props
export interface ResultsUploadManualEntryFormReturn {
  methods: UseFormReturn<ResultsUploadManualEntryBaseFormData, any, undefined>
  isUserTyping: boolean
  indexOfLoadingBiomarkerResult: number | undefined
}

const BiomarkerResultDataSchema = z.object({
  attributes: z.object({
    value: z.string().nonempty("Please enter a value."),
    units: z.string().nullable(),
    normal_min: z.string().nullable(),
    normal_max: z.string().nullable(),
  }),
  relationships: z.object({
    sample_type: z.object({
      data: z
        .object({
          id: z.string().nonempty("Please select a sample type."),
          type: z.string().optional(),
        })
        .refine((val) => val !== null, "Please select a sample type."),
    }),
    biomarker: z.object({
      data: z.object({
        id: z.string().nullable(),
        type: z.string().optional(),
      }),
    }),
  }),
})

const BaseFormSchema = z
  .object({
    sample_collection_date: z.date({
      invalid_type_error: "Must provide a valid sample collection date",
      required_error: "Please provide your sample collection date",
    }),
    fasted: z.string().optional(),
    lab_company: z.string().nonempty("Please select a lab company."),
    other_lab_company_name: z.string().nullable(),
    sample_type: z.string().nonempty("Please select a sample type."),
    results: z.array(BiomarkerResultDataSchema),
  })
  .transform((data) => ({
    ...data,
    fasted: data.fasted ? data.fasted.toLowerCase() === "yes" : undefined, // Convert to boolean
  }))

const useResultsUploadManualEntryForm = ({
  userResultId,
  updateUserBiomarkerResult,
  labCompanies,
}: Props) => {
  const [isUserTyping, setIsUserTyping] = useState(false)
  const [indexOfLoadingBiomarkerResult, setIndexOfLoadingBiomarkerResult] =
    useState<number | undefined>()

  const { patchUserResult } = useUpdateUserResult(userResultId)

  const methods = useForm<ResultsUploadManualEntryBaseFormData>({
    criteriaMode: "firstError",
    defaultValues: DEFAULT_VALUES,
    mode: "onSubmit",
    resolver: zodResolver(BaseFormSchema),
    shouldFocusError: true,
  })

  const { handleSubmit, watch, trigger, getFieldState } = methods

  // Validate the base form data without sending validation updates to the form itself.
  const validateBaseFormData = async (
    formData: ResultsUploadManualEntryBaseFormData
  ) => {
    const options: ResolverOptions<ResultsUploadManualEntryBaseFormData> = {
      criteriaMode: "all",
      shouldUseNativeValidation: false,
      fields: {},
    }

    const result = await zodResolver(BaseFormSchema)(formData, {}, options)

    // Filter out errors that have a .results property as biomarkers don't need to be validated to save user results data
    let errorsCopy: FieldErrors = Object.assign({}, result.errors)
    delete errorsCopy.results

    if (isEmpty(errorsCopy)) {
      return true
    } else {
      return false
    }
  }

  // Validate the biomarker result form data without sending validation updates to the form itself.
  const validateBiomarkerResultFormData = async (
    formData: BiomarkerResultFormData
  ) => {
    const options: ResolverOptions<BiomarkerResultFormData> = {
      criteriaMode: "all",
      shouldUseNativeValidation: false,
      fields: {},
    }

    const result = await zodResolver(BiomarkerResultDataSchema)(
      formData,
      {},
      options
    )

    if (isEmpty(result.errors)) {
      return true
    } else {
      return false
    }
  }

  const updateUserResult = async (
    data: ResultsUploadManualEntryBaseFormData
  ) => {
    const labCompanyId = labCompanies?.find(
      (labCompany) => labCompany.results_name === data.lab_company
    )?.id

    let otherLabCompanyName: string | null = null

    if (!labCompanyId) {
      otherLabCompanyName = data.lab_company || ""
    }

    await patchUserResult({
      sample_collection_date: data.sample_collection_date,
      fasted: data.fasted,
      lab_company: labCompanyId,
      other_lab_company_name: otherLabCompanyName,
      sample_type: data.sample_type,
    })
  }

  const handleUpdateUserBiomarkerResult = async (
    data: BiomarkerResultFormData,
    index: number
  ) => {
    // Shouldn't happen, but if the data doesn't have an id, we can't update the biomarker result
    if (!data.id) return

    setIndexOfLoadingBiomarkerResult(index)
    await updateUserBiomarkerResult(data.id, data)

    setIndexOfLoadingBiomarkerResult(undefined)
  }

  const setIsUserTypingImmediately = useCallback(() => {
    setIsUserTyping(true)
  }, [])

  const debounceUserResultOnSubmit = useCallback(
    debounce(async (data: ResultsUploadManualEntryBaseFormData) => {
      const isFormValid = await validateBaseFormData(data)
      if (isFormValid) {
        updateUserResult(data)
      }
    }, 500),
    [handleSubmit, labCompanies]
  )

  const debounceUserBiomarkerResultOnSubmit = useCallback(
    debounce(
      async (data: BiomarkerResultFormData | undefined, index: number) => {
        if (!data) return

        const isFormValid = await validateBiomarkerResultFormData(data)
        if (isFormValid) {
          handleUpdateUserBiomarkerResult(data, index)
        }
      },
      500
    ),
    [handleSubmit]
  )

  /**
   * Debounce the form submission on change to avoid sending too many requests to the server.
   * Whenever a field is updated, we try to update either the UserResult or one of the UserBiomarkerResult records.
   * If the form is invalid, we don't send the request.
   */
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!name) return

      const formValues = value as ResultsUploadManualEntryBaseFormData

      // Trigger validation if the changed field previously had an error
      if (getFieldState(name)?.error) {
        trigger(name)
      }

      if (name.includes("results")) {
        // Update one of the UserBiomarkerResult records if it's valid

        // Name is in the format of results.1., results.2., etc. when coming from the results array of forms.
        const regex = /results.(\d+)./
        const match = name.match(regex)
        const index = match ? parseInt(match[1], 10) : -1

        // We only want to debounce submit once the user has moved on to the next biomarker
        if (index >= 0) {
          const data = formValues.results?.[index]
          if (data) debounceUserBiomarkerResultOnSubmit(data, index)
        }
      } else {
        // Update the UserResult record if it's valid
        setIsUserTypingImmediately() // Set is user typing immediately, but only once on init of subscription
        debounceUserResultOnSubmit(formValues)
      }
    }, DEFAULT_VALUES)

    return () => {
      debounceUserResultOnSubmit.flush() // Ensures the save still goes through on unmount
      debounceUserBiomarkerResultOnSubmit.flush() // Ensures the save still goes through on unmount
      subscription.unsubscribe()
    }
  }, [
    debounceUserResultOnSubmit,
    debounceUserBiomarkerResultOnSubmit,
    setIsUserTypingImmediately,
    watch,
    trigger,
    getFieldState,
  ])

  return {
    methods,
    isUserTyping,
    indexOfLoadingBiomarkerResult,
  }
}

export default useResultsUploadManualEntryForm

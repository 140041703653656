import clsx from "clsx"

import BodyText from "app/components/design-system/BodyText"
import BloodLabDashboardsBiomarkerStatusIndicator from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/statuses/BloodLabDashboardsBiomarkerStatusIndicator"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"

import { TOOLTIP_WIDTH_PX } from "./constants"

export interface CustomTooltipData {
  status?: BiomarkerStatus
  value: number
  display_value: string
  unit?: string
  name: string
  subText?: string
}

interface Props {
  data?: CustomTooltipData
}

const CustomTooltip = ({ data }: Props) => {
  const statusColor = {
    [BiomarkerStatus.HIGH]: "text-red-900",
    [BiomarkerStatus.LOW]: "text-red-900",
    [BiomarkerStatus.ABOVE_OPTIMAL]: "text-yellow-600",
    [BiomarkerStatus.BELOW_OPTIMAL]: "text-yellow-600",
    [BiomarkerStatus.NORMAL]: "text-emerald-500",
    [BiomarkerStatus.OPTIMAL]: "text-emerald-500",
  }
  return (
    <div
      className={`flex items-center justify-center rounded-md bg-white-500 w-100 p-3 shadow-md bg-white w-[${TOOLTIP_WIDTH_PX}px] h-[130px]`}
    >
      <div className="bg-white">
        <BodyText
          size="xs"
          weight="bold"
          className={clsx(
            data?.status ? statusColor[data.status] : "",
            "text-center uppercase"
          )}
        >
          {data?.status?.replace("_", " ")}
        </BodyText>
        <div className="text-center">
          <BloodLabDashboardsBiomarkerStatusIndicator
            biomarkerStatus={data?.status}
          />
          <span className="text-body-500 font-bold text-lg ml-1">
            {data?.display_value}
          </span>
          <span className="text-slate-400 font-base text-base">
            {data?.unit}
          </span>
        </div>

        <div className="text-center mt-1">
          <BodyText className="text-slate-400 text-[12px]">
            {data?.subText}
          </BodyText>
        </div>
      </div>
      <div className="absolute -bottom-[0px] left-[50%] -translate-x-1/2 translate-y-1/2 transform">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="7"
          viewBox="0 0 33 7"
          fill="none"
        >
          <path
            d="M12.6011 4.58034C14.4406 6.93945 18.0082 6.93946 19.8477 4.58034C22.0243 1.78895 25.3663 0.156985 28.906 0.156985H32.2138H0.789062H3.54276C7.08245 0.156985 10.4245 1.78895 12.6011 4.58034Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  )
}

export default CustomTooltip

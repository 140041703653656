import React, { isValidElement, useContext } from "react"

import clsx from "clsx"

import { makeStyles, Theme } from "@material-ui/core"

import BodyText from "../design-system/BodyText"
import NavBarContext from "./NavBarContext"

interface Props {
  title?: string
  animationOrder?: number
  children: React.ReactNode
}

const useStyles = makeStyles<
  Theme,
  {
    collapsed?: boolean
  }
>((theme) => ({
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    gap: 8,
  },
  itemContainerTitle: {
    textTransform: "uppercase",
    color: "rgba(255, 255, 255, 0.9)",
    marginBottom: 16,
    transition: "opacity 0.24s",

    [theme.breakpoints.up("sm")]: {
      opacity: 0,
    },

    [theme.breakpoints.up("lg")]: {
      opacity: (props) => (props.collapsed ? 0 : 0.9),
    },
  },
}))

function NavBarItemGroup({ title, animationOrder = 0, children }: Props) {
  const { collapsed } = useContext(NavBarContext)
  const { itemContainer, itemContainerTitle } = useStyles({
    collapsed,
  })

  return (
    <div className={itemContainer}>
      {title && (
        <BodyText
          className={clsx(
            itemContainerTitle,
            "navbar-item",
            "navbar__item-container-title"
          )}
          style={{
            "--animation-order": animationOrder,
          }}
          size="xs"
          weight="semibold"
        >
          {title}
        </BodyText>
      )}
      {React.Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
          return child
        }

        let animationOrderOffset = title ? animationOrder : animationOrder - 1

        return React.cloneElement(child, {
          ...child.props,
          animationOrder: animationOrderOffset + index,
        })
      })}
    </div>
  )
}

export default NavBarItemGroup

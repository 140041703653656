import { ComponentProps, useEffect, useState } from "react"

import clsx from "clsx"

import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  IconButton,
  Theme,
  Zoom,
} from "@material-ui/core"
import { Table } from "@tanstack/react-table"

import { ReactComponent as EllipsesIcon } from "app/assets/icons/bundles/ellipses.svg"
import DeleteIcon from "app/assets/icons/bundles/tooltip-nav-icons/delete-action.svg"
import { ReactComponent as FiltersActiveIcon } from "app/assets/icons/filters-active.svg"
import { ReactComponent as FiltersInactiveIcon } from "app/assets/icons/filters-inactive.svg"
import Tooltip from "app/components/Tooltip"
import DesignSystemButton from "app/components/design-system/Button"
import TooltipNav from "app/components/design-system/TooltipNav"
import { cancelRed, colors, primaryColor, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

import { GlobalFilterState } from "./hooks/use-table"

const useStyles = makeAppStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 20,
    },
  },
  tabGroup: {
    borderRadius: 6,
    flexWrap: "wrap",
  },
  tabButton: {
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    background: "#fff",
    whiteSpace: "nowrap",
    borderRadius: 0,
    borderColor: colors.blueGray[200],
    color: textPrimaryColor,
    padding: "0.5px 26px",
    fontSize: 15,
    transition: "none",
    "&:hover": {
      background: "rgba(0, 117, 205, 0.05)",
      boxShadow: "none",
    },
    "&:first-of-type": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    },
    "&:last-of-type": {
      borderTopRightRadius: 6,
      borderbottomRightRadius: 6,
    },
  },
  tabButtonActive: {
    background: "rgba(0, 117, 205, 0.05)",
    color: primaryColor,
  },
  tabButtonContent: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  filterButton: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 4,
    },
  },
}))

export interface Tab {
  id: string
  label: string
}

export interface TabGroup {
  tabs: Tab[]
}

interface TableTabsRendererProps {
  availablLabCompanyTabs: any[]
  handleDeleteClick: (labCompanyKey: string) => void
  handleToggleHideUnsetRangesClick: () => void
  table: Table<LabCompanyBiomarkerRange>
}

export default function TableTabsRenderer({
  availablLabCompanyTabs,
  handleDeleteClick,
  handleToggleHideUnsetRangesClick,
  table,
}: TableTabsRendererProps) {
  const classes = useStyles()
  const {
    labCompanyKey: currentLabCompanyKey,
    hideUnsetRanges: isHideUnsetRangesFilterSet,
  } = table.getState().globalFilter as GlobalFilterState

  const [disableFilterButton, setDisableFilterButton] = useState(false)

  useEffect(() => {
    const searchFilter = table
      .getState()
      .columnFilters.find((filter) => filter.id === "biomarkerName")
    if (searchFilter && searchFilter.value !== "") {
      setDisableFilterButton(true)
      table.setGlobalFilter((previousGlobalFilter: GlobalFilterState) => ({
        ...previousGlobalFilter,
        hideUnsetRanges: false,
      }))
    } else {
      setDisableFilterButton(false)
    }
    return
  }, [table.getState().columnFilters])

  return (
    <div className={classes.root}>
      <ButtonGroup className={classes.tabGroup} variant="outlined">
        {availablLabCompanyTabs.map((tab) => (
          <TabButton
            key={tab.id}
            isActive={tab.id === currentLabCompanyKey}
            onClick={() =>
              table.setGlobalFilter(
                (previousGlobalFilter: GlobalFilterState) => ({
                  ...previousGlobalFilter,
                  labCompanyKey: tab.id,
                })
              )
            }
          >
            <TabButtonContent tab={tab} handleDeleteClick={handleDeleteClick} />
          </TabButton>
        ))}
      </ButtonGroup>

      <Tooltip
        title={
          disableFilterButton
            ? "Clear your search to filter by optimal ranges that are set."
            : ""
        }
        TransitionComponent={Zoom}
        arrow
      >
        <span>
          {isHideUnsetRangesFilterSet ? (
            <DesignSystemButton
              color="text"
              onClick={handleToggleHideUnsetRangesClick}
              startIcon={
                <FiltersActiveIcon
                  fill={disableFilterButton ? colors.gray[300] : primaryColor}
                />
              }
              className={classes.filterButton}
              disabled={disableFilterButton}
            >
              Show All Markers
            </DesignSystemButton>
          ) : (
            <DesignSystemButton
              color="text"
              onClick={handleToggleHideUnsetRangesClick}
              startIcon={
                <FiltersInactiveIcon
                  fill={disableFilterButton ? colors.gray[300] : primaryColor}
                />
              }
              className={classes.filterButton}
              disabled={disableFilterButton}
            >
              See Set Ranges
            </DesignSystemButton>
          )}
        </span>
      </Tooltip>
    </div>
  )
}
const TabButtonContent = ({ tab, handleDeleteClick }) => {
  const classes = useStyles()

  const [tooltipMenuIsOpen, setTooltipMenuIsOpen] = useState(false)

  return (
    <div className={classes.tabButtonContent}>
      {tab.label}{" "}
      <ClickAwayListener onClickAway={() => setTooltipMenuIsOpen(false)}>
        <Tooltip
          open={tooltipMenuIsOpen}
          onClose={() => setTooltipMenuIsOpen(false)}
          title={
            <TooltipNav
              children={[
                {
                  id: "delete",
                  name: "Remove Optimal Ranges",
                  icon: DeleteIcon,
                  onClick: () => handleDeleteClick(tab.id),
                  color: cancelRed,
                },
              ]}
            />
          }
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Zoom}
          arrow
          interactive
        >
          <IconButton
            onClick={() => setTooltipMenuIsOpen(true)}
            aria-label="Bundle info"
          >
            <EllipsesIcon
              fill={colors.blueGray[400]}
              viewBox="0 0 15 15"
              width={15}
              height={15}
            />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </div>
  )
}

const TabButton = ({
  className,
  isActive,
  onClick,
  ...other
}: {
  isActive: boolean
  onClick: () => void
} & ComponentProps<typeof Button>) => {
  const classes = useStyles()
  return (
    <Button
      {...other}
      className={clsx(className, classes.tabButton, {
        [classes.tabButtonActive]: isActive,
      })}
      disableRipple
      onClick={onClick}
    />
  )
}

import { Fragment, useState } from "react"

import { makeStyles, Paper } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import CloseIcon from "@material-ui/icons/Close"

import { ChangePasswordError } from "app/auth/store/actions"
import TextField from "app/components/forms/TextField"
import { alertYellow, colors } from "app/theme"

const styles = (theme) => ({
  root: {
    minWidth: 270,
    position: "absolute",
    zIndex: 10000,
  },
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#333",
  },
  alert: {
    borderRadius: 8,
    background: colors.yellow[50],
    border: `2px solid ${alertYellow}`,
    color: colors.yellow[900],
    margin: 6,
  },
  alertLink: {
    color: colors.yellow[900],
    padding: "0px",
  },
  alertLinkIcon: {
    verticalAlign: "top",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    alignItems: "center",
  },
  formContainer: {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "1px",
    paddingBottom: "5px",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "right",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  inputLabel: {
    flex: "1 1 0px",
    textAlign: "right",
    paddingTop: "20px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "4px",
      paddingLeft: "0px",
    },
    paddingLeft: "6px",
    paddingRight: theme.spacing(2),
  },
  helperTextContainer: {
    width: "100%",
    backgroundColor: "white",
  },
  helperText: {
    width: "205px",
  },
})
const useStyles = makeStyles(styles)

export default function NewPasswordModal({
  open,
  allowClose = true,
  onClose = () => {},
  openForgotPasswordModal = () => {},
  onChangePassword,
  tokenExpired,
}) {
  const classes = useStyles(styles)
  const [password, setPassword] = useState("")
  const [formChanged, setFormChanged] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errorResponse, setErrorResponse] = useState(null)

  const passwordValidationErrorMessage = validatePassword(
    password,
    confirmPassword
  )
  const isValidPassword = !passwordValidationErrorMessage

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && isValidPassword) {
      e.preventDefault()
      handleSavePassword()
    }
  }

  const handleSavePassword = async () => {
    try {
      await onChangePassword(password)
      onClose()
    } catch (error) {
      if (error instanceof ChangePasswordError) {
        setErrorResponse(error.errors.join(" "))
      }
    }
  }

  const closeAndToggleForgotPassword = (e) => {
    e.preventDefault()
    onClose()
    if (openForgotPasswordModal) {
      openForgotPasswordModal()
    }
  }

  const hasError = (formChanged && !isValidPassword) || !!errorResponse
  const helperText = errorResponse || passwordValidationErrorMessage
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="new-password-modal-title"
      open={open}
      disableBackdropClick={true}
      className={classes.root}
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth="sm"
    >
      <Title
        allowClose={allowClose}
        onClose={onClose}
        id="new-password-modal-title"
        className={classes.title}
      >
        Set Your Password
      </Title>
      {tokenExpired ? (
        <Content>
          <div className={`flex items-center justify-between ${classes.alert}`}>
            <Typography className="text-base15 p-2">
              This link has expired.{" "}
              <Button
                onClick={closeAndToggleForgotPassword}
                className={`font-semibold ${classes.alertLink}`}
              >
                Click here to send a new link{" "}
                <ChevronRightIcon className={classes.alertLinkIcon} />
              </Button>
            </Typography>
          </div>
        </Content>
      ) : (
        <Fragment>
          <Content>
            <Paper background="bg-white">
              <div className={classes.formContainer}>
                <div className={classes.inputContainer}>
                  <div className={classes.inputLabel}>
                    <Typography className="text-gray-800 text-sm font-semibold">
                      Password
                    </Typography>
                  </div>
                  <TextField
                    autoFocus
                    className="bg-gray-100 rounded border-0 w-72 fs-exclude"
                    placeholder="New password"
                    name="new-password"
                    autoComplete="current-password"
                    value={password}
                    type="password"
                    error={hasError}
                    onChange={(e) => {
                      setPassword(e.target.value)
                      setFormChanged(true)
                      setErrorResponse(null)
                    }}
                    onKeyDown={handleKeyDown}
                    margin="dense"
                    variant="outlined"
                  />
                </div>

                <div className={classes.inputContainer}>
                  <div className={classes.inputLabel}>
                    <Typography className="text-gray-800 text-sm font-semibold">
                      Confirm Password
                    </Typography>
                  </div>
                  <TextField
                    className="bg-gray-100 rounded border-0 w-72 fs-exclude"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    name="new-password-confirm"
                    type="password"
                    helperText={
                      <div className={classes.helperTextContainer}>
                        <div className={classes.helperText}>{helperText}</div>
                      </div>
                    }
                    error={hasError}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                      setErrorResponse(null)
                    }}
                    onKeyDown={handleKeyDown}
                    margin="dense"
                    variant="outlined"
                  />
                </div>
              </div>
            </Paper>
          </Content>
          <Actions>
            <Button
              disabled={!isValidPassword || !password || !confirmPassword}
              onClick={handleSavePassword}
              color="primary"
              variant="contained"
            >
              Set Password
            </Button>
          </Actions>
        </Fragment>
      )}
    </Dialog>
  )
}

const Title = withStyles(styles)((props) => {
  const { allowClose, children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className="bg-gray-100" {...other}>
      <Typography className="font-semibold text-xl19">{children}</Typography>
      {allowClose && onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: colors.coolGray[100],
  },
}))(MuiDialogContent)

const Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),

    borderTop: "1px solid",
    borderColor: colors.blueGray[300],
  },
}))(MuiDialogActions)

function validatePassword(password, confirmPassword) {
  // NB: Password requirements are also defined in the backend here:
  // https://github.com/rupahealth/rupalabs/blob/f84761ebfe7c6e17d04724b7813e9400cc86731a/server/api/apps/core/views/core_views.py#L1995-L2012

  if (!confirmPassword) {
    return
  }

  if (password !== confirmPassword) {
    return "Passwords do not match"
  }

  if (password.length < 8) {
    return "Password is too short"
  }
}

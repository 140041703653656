import { useEffect, useRef, useState } from "react"

import Lottie, { LottieRefCurrentProps } from "lottie-react"
import { useLocation } from "react-router-dom"

import { Button, cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"

import resultsImportSuccessAnimation from "../../assets/results-import-success.json"

const ResultsUploadPdfAllSetCard = ({ onClose }) => {
  const [isShown, setIsShown] = useState(false)
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  const location = useLocation()

  // This effect is used to give the card a transition effect on show
  useEffect(() => {
    // wait 300 ms then show the card
    const timeout = setTimeout(() => {
      setIsShown(true)
    }, 100)

    return () => clearTimeout(timeout)
  }, [])

  const goToTrends = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set("tab", "results-over-time")
    // Force a refresh of the page
    window.open(`${location.pathname}?${searchParams.toString()}`, "_self")
    onClose()
  }

  return (
    <div
      className={cn(
        "py-[53px] px-[75px] flex flex-col items-center gap-[18px] rounded-xl border-2 transition-opacity",
        isShown ? "opacity-100" : "opacity-0"
      )}
      style={{
        background: "linear-gradient(155deg, #E2F4FC 27.65%, #FFF8E9 83.95%)",
        borderColor: "rgba(0,0,0,0)",
      }}
    >
      <div className="w-[175px]">
        <Lottie
          animationData={resultsImportSuccessAnimation}
          loop={false}
          autoplay={true}
          lottieRef={lottieRef}
        />
      </div>
      <div className="flex flex-col justify-center items-center gap-y-1 w-[270px] text-center">
        <BodyText weight="semibold" size="md" className="text-center">
          All Set!
        </BodyText>
        <BodyText className="text-center">
          You can always edit the data later from the Documents tab.
        </BodyText>
      </div>
      <div className="w-full">
        <Button variant="primary" className="w-full" onClick={goToTrends}>
          Go to Trends
        </Button>
      </div>
    </div>
  )
}

export default ResultsUploadPdfAllSetCard

import { useState } from "react"

import axios from "axios"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { getIdentifier } from "app/swr/helpers/resource"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { getApiBaseUrl } from "app/utils"
import {
  UserResult,
  UserResultCreate,
  UserResultPostData,
  UserResultRelationships,
} from "types/user-result"

const useCreateUserResult = () => {
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const createUserResult = useEventCallback(
    async (postData: UserResultPostData, file?: File) => {
      setIsCreating(true)

      try {
        let relationships: UserResultRelationships = {
          patient: {
            data: {
              type: "patient",
              id: postData.patientId,
            },
          },
        }

        if (postData.labCompanyId) {
          relationships["lab_company"] = {
            data: {
              type: "lab_company",
              id: postData.labCompanyId,
            },
          }
        }

        const { data } = await resourceRequest<
          ResourceResponse<UserResult>,
          UserResultCreate
        >({
          method: "post",
          url: "/user_results/",
          data: {
            data: {
              type: "user_result",
              attributes: {
                collection_date: postData.collectionDate,
                fasting_required: postData.fastingRequired,
                has_specimen_issues: false,
                file_type: postData.fileType,
              },
              relationships,
            },
          },
        })

        if (file) {
          const formData = new FormData()
          formData.append("file", file)

          try {
            const { data: fileUploadData } = await axios.post(
              getApiBaseUrl() + `/api/normalized/user_results/${data.id}/file/`,
              formData,
              { headers: { "Content-Type": "multipart/form-data" } }
            )
            data.attributes.file = fileUploadData.attributes.file
          } catch (error) {}
        }

        // Pre-populate the SWR cache with the returned data
        await mutateResource<UserResult>(getIdentifier(data), data)

        return data.id
      } catch (error) {
        handleApiError(error)
      } finally {
        setIsCreating(false)
      }
    }
  )
  return { createUserResult, isCreating }
}

export default useCreateUserResult

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-portal-types"
import pausedStatusConfiguration from "../paused-status-configuration"
import orderedWithLabStatusConfiguration from "./ordered-with-lab-status-configuration"
import paidStatusConfiguration from "./paid-status-configuration"
import pendingPhysServicesAuthStatusConfiguration from "./pending-phys-services-auth-status-configuration"
import resultsInStatusConfiguration from "./results-in-status-configuration"
import sampleAtLabStatusConfiguration from "./sample-at-lab-status-configuration"
import {
  processingSpecimenIssueStatusConfiguration,
  recollectingSpecimenIssueStatusConfiguration,
  actionRequiredSpecimenIssueStatusConfiguration,
} from "./specimen-issue-status-configuration"

const statusConfigurations: StatusConfiguration<StatusConfigurationProps>[] = [
  pausedStatusConfiguration,
  pendingPhysServicesAuthStatusConfiguration,
  paidStatusConfiguration,
  orderedWithLabStatusConfiguration,
  sampleAtLabStatusConfiguration,
  actionRequiredSpecimenIssueStatusConfiguration,
  recollectingSpecimenIssueStatusConfiguration,
  processingSpecimenIssueStatusConfiguration,
  resultsInStatusConfiguration,
]

export default statusConfigurations

import { useMemo } from "react"

import { faPerson } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BodySystemFilter from "app/main/patient-orders/results-over-time/filter-components/BodySystemFilter"
import { DropDownOption } from "app/main/patient-orders/results-over-time/types/types"
import { BodySystem } from "types/body-system"

export const DEFAULT_BODY_SYSTEM_DROPDOWN_ID = "all"

const BODY_SYSTEM_OPTION_STARTER: DropDownOption[] = [
  {
    key: DEFAULT_BODY_SYSTEM_DROPDOWN_ID,
    label: <>Body System</>,
    icon: (
      <FontAwesomeIcon
        className="text-slate-500 text-[14px]"
        fill="currentColor"
        icon={faPerson}
      />
    ),
  },
]

export default function BodySystemDropdownFilter({
  filterBodySystemId,
  setFilterBodySystemId,
  availableBodySystems,
}: {
  filterBodySystemId: string
  setFilterBodySystemId: (bodySystem: string) => void
  availableBodySystems: BodySystem[]
}) {
  const buildBodySystemOptions = () => {
    return availableBodySystems.map((bodySystem) => {
      return {
        key: bodySystem.id,
        label: <>{bodySystem.attributes.name}</>,
        icon: <img src={bodySystem.attributes.logo} alt="" width={15} />,
      }
    })
  }

  const availableBodySystemOptions = useMemo(() => {
    const newBodySystems = buildBodySystemOptions()
    return [...BODY_SYSTEM_OPTION_STARTER, ...newBodySystems]
  }, [availableBodySystems])

  return (
    <BodySystemFilter
      availableBodySystemOptions={availableBodySystemOptions}
      bodySystemId={filterBodySystemId}
      handleBodySystemIdChange={setFilterBodySystemId}
    />
  )
}

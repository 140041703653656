import { useCallback } from "react"

import { useLocation } from "react-router-dom"

import { trackEventWithProperties } from "app/services/segment.typed"
import { Practitioner, PractitionerType } from "app/types"
import { PractitionerLicenseCreateForm } from "types/practitioner-license"

import { CredentialUploadLocation } from "./types"

export enum CredentialEvents {
  CREDENTIAL_UPLOAD_MODAL_OPENED = "Credential Upload Modal Opened",
  CREDENTIAL_UPLOADED = "Credential Uploaded",
  PRACTITIONER_TYPE_CHANGED = "Practitioner Type Changed",
  LICENSE_STATE_CHANGED = "License State Changed",
  SIGNATURE_UPLOADED = "Signature Uploaded",
  EXPLORE_ACCESS_BANNER_CLICKED = "Explore Ordering Access Banner Clicked",
  EXPLORE_ACCESS_SEE_CATALOG_CLICKED = "Explore Ordering Access See Catalog Clicked",
}

interface Props {
  location: CredentialUploadLocation
  practitioner: Practitioner | undefined
}
export default function useCredentialTracking({
  location,
  practitioner,
}: Props) {
  const { pathname } = useLocation()

  return useCallback(
    (event: string, properties?: object) => {
      let eventProperties: object = { location, pathname, ...properties }
      if (practitioner) {
        eventProperties = {
          ...eventProperties,
          practitionerId: practitioner.id,
          hasSignature: practitioner.has_signature,
          hasLicense: practitioner.has_license,
        }
      }
      trackEventWithProperties(event, eventProperties)
    },
    [pathname, location, practitioner]
  )
}

export function getCredentialUploadEventProperties(
  formData: PractitionerLicenseCreateForm,
  practitioner: Practitioner,
  selectedPractitionerType: PractitionerType | undefined
) {
  let eventProperties = {}
  if (formData.state !== practitioner.clinic.state) {
    eventProperties = {
      stateChanged: true,
      clinicState: practitioner.clinic.state,
      licenseState: formData.state,
    }
  }
  if (formData.npi_number !== practitioner.npi_number) {
    eventProperties = {
      ...eventProperties,
      npiChanged: true,
      previousNpi: practitioner.npi_number,
      newNpi: formData.npi_number,
    }
  }
  if (
    selectedPractitionerType?.id !== practitioner.primary_practitioner_type?.id
  ) {
    eventProperties = {
      pracTypeChanged: true,
      previousPracType: practitioner.primary_practitioner_type?.name,
      newPracType: selectedPractitionerType?.name,
    }
  }
  return eventProperties
}

import { useState } from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { Link as RouterLink } from "react-router-dom"

import { styled, Link, useMediaQuery, Theme } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import { UserPaths } from "app/Routes"
import AddUserIcon from "app/assets/icons/add-user.svg"
import PhlebotomistsRedIcon from "app/assets/icons/phlebotomists-red.svg"
import UpRightArrow from "app/assets/icons/up-right-arrow.svg"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PhlebotomyMapCircle from "app/assets/images/phlebotomy-map-circle.svg"
import CardWithIcon from "app/components/CardWithIcon"
import PageToolbar from "app/components/PageToolbar"
import Panel from "app/components/Panel"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import PhlebotomistModal from "app/components/modals/PhlebotomistModal"
import useAppSelector from "app/hooks/useAppSelector"
import * as Actions from "app/store/actions"
import { colors, primaryColor } from "app/theme"

import SideNav from "./SideNav"

interface StyledProps {
  isXsMobile?: boolean
  isMobile?: boolean
  isTablet?: boolean
}

const Wrapper = styled("div")({
  background: colors.coolGray[50],
  border: `1px solid ${colors.coolGray[200]}`,
  minHeight: "100vh",
  padding: 24,
})

const TitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 6,
})

const StyledLink = styled(({ isXsMobile, ...other }) => <Link {...other} />)({
  fontWeight: 600,
  marginLeft: ({ isXsMobile }: StyledProps) => (isXsMobile ? 0 : 4),
  display: "inline-block",
})

const StyledImage = styled("img")({
  width: 10,
  marginLeft: 5,
  marginRight: 10,
})

const PhlebotomistWrapper = styled(({ isXsMobile, isTablet, ...other }) => (
  <Panel {...other} />
))({
  padding: 24,
  height: ({ isXsMobile, isTablet }: StyledProps) =>
    isXsMobile ? 600 : isTablet ? 425 : 350,
})

const PhlebotomistDetailsWrapper = styled("div")({
  padding: "8px 24px",
})

const StyledDesignSystemButton = styled(DesignSystemButton)({
  marginLeft: "auto",
})

const BlockSeperator = styled("div")({
  margin: 24,
})

const PhlebotomistField = styled(BodyText)({
  marginTop: 5,
})

const PhlebotomistFieldLabel = styled("span")({
  fontWeight: 600,
})

const StyledAddButton = styled(({ isMobile, ...other }) => (
  <DesignSystemButton {...other} />
))({
  marginTop: ({ isMobile }: StyledProps) => (isMobile ? 10 : "auto"),
  alignItems: "center",
  float: ({ isMobile }: StyledProps) => (isMobile ? "left" : "right"),
})

const EmptySection = styled("div")({
  display: "flex",
  background: "white",
  justifyContent: "center",
  alignItems: "center",
  border: `2px dashed ${colors.blueGray[200]}`,
  padding: "32px 64px",
  borderRadius: 8,
})

function Phlebotomists() {
  const dispatch = useDispatch()

  const [phlebotmistModalIsOpen, setPhlebotomistMemberModalIsOpen] =
    useState(false)
  const [phlebotomistOpenInEditModal, setPhlebotomistOpenInEditModal] =
    useState(null)

  const phlebotomists = useAppSelector(({ phlebotomist }) => phlebotomist)

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  useEffect(() => {
    dispatch(Actions.getPhlebotomists())
  }, [dispatch])

  function handleOpenPhlebotomistEditModal(phlebotomist) {
    setPhlebotomistOpenInEditModal(phlebotomist)
    setPhlebotomistMemberModalIsOpen(true)
  }

  const handleClosePhlebotomistModal = () => {
    setPhlebotomistOpenInEditModal(null)
    setPhlebotomistMemberModalIsOpen(false)
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const isXsMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  )
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))

  const cardText = (
    <BodyText>
      After you place an order with a blood draw requirement, Rupa sends your
      patients custom instructions with their phlebotomy options. We send your
      patients local phlebotomy options who can draw for their kits.{" "}
      <RouterLink
        to="/phlebotomists"
        style={{ fontWeight: 600, color: primaryColor }}
      >
        See Phlebotomy Network.
      </RouterLink>
    </BodyText>
  )

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },

            {
              label: "Preferred Phlebotomists",
            },
          ]}
          title="Preferred Phlebotomists"
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title={"Preferred Phlebotomists"}
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <Wrapper>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12}>
                <div style={{ marginBottom: 24 }}>
                  <CardWithIcon
                    icon={PhlebotomyMapCircle}
                    title="See Our Phlebotomy Network"
                    text={cardText}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <TitleWrapper>
                  <BodyText weight="semibold" size="lg">
                    {practitioner?.clinic?.name} Preferred Phlebotomists
                  </BodyText>
                </TitleWrapper>
                <BodyText>
                  Have specific phlebotomists you want your patients to see, or
                  work with a phlebotomist in-house? Add a Preferred
                  Phlebotomist and we’ll share their information with patients
                  requiring a blood draw. If your patients are over 25 miles
                  away, we’ll also suggest local options based on their zip
                  code.
                  <StyledLink
                    // This a hardcoded example of an order instructions page.
                    href="https://labs.rupahealth.com/orderinstructions/17830/?token=51c04c947841fbbc64881f1a08c776ab6d319947&expanded-index=2"
                    target="_blank"
                    color="primary"
                    isXsMobile={isXsMobile}
                  >
                    See example patient instructions
                    <StyledImage src={UpRightArrow} alt="up-right-arrow" />
                  </StyledLink>
                </BodyText>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledAddButton
                  color="secondary"
                  startIcon={AddUserIcon}
                  onClick={() => setPhlebotomistMemberModalIsOpen(true)}
                  isMobile={isMobile}
                >
                  Add a Preferred Phlebotomist
                </StyledAddButton>
              </Grid>
            </Grid>
            <BlockSeperator />
            <Grid container spacing={2}>
              {phlebotomists?.length ? (
                phlebotomists.map((phlebotomist) => {
                  return (
                    <Grid item xs={12} md={6} key={phlebotomist.id}>
                      <Phlebotomist
                        phlebotomist={phlebotomist}
                        handleEdit={handleOpenPhlebotomistEditModal}
                        isTablet={isTablet}
                        isXsMobile={isXsMobile}
                      />
                    </Grid>
                  )
                })
              ) : (
                <Grid item xs={12} md={12}>
                  <EmptySection>
                    <BodyText>
                      No Preferred Phlebotomists Have Been Added.
                    </BodyText>
                  </EmptySection>
                </Grid>
              )}
            </Grid>
          </Wrapper>
        </Grid>
      </Grid>
      <PhlebotomistModal
        phlebotomist={phlebotomistOpenInEditModal}
        open={phlebotmistModalIsOpen}
        onClose={handleClosePhlebotomistModal}
      />
    </>
  )
}

export default Phlebotomists

const Phlebotomist = ({ phlebotomist, handleEdit, isTablet, isXsMobile }) => {
  return (
    <PhlebotomistWrapper isTablet={isTablet} isXsMobile={isXsMobile}>
      <div className="flex">
        <StyledImage src={PhlebotomistsRedIcon} alt="phlebotomist" />
        <BodyText weight="semibold">
          {phlebotomist.name} ・
          {phlebotomist.location_types.length === 2
            ? "Both Clinic & Mobile"
            : phlebotomist.location_types[0] === "mobile"
            ? "Mobile"
            : "Clinic"}
        </BodyText>
        <StyledDesignSystemButton
          color="text"
          onClick={() => handleEdit(phlebotomist)}
        >
          Edit
        </StyledDesignSystemButton>
      </div>
      <PhlebotomistDetailsWrapper>
        {phlebotomist.external_notes && (
          <PhlebotomistField>
            <PhlebotomistFieldLabel>Notes: </PhlebotomistFieldLabel>
            {phlebotomist.external_notes}
          </PhlebotomistField>
        )}
        {(phlebotomist.address_street ||
          phlebotomist.address_city ||
          phlebotomist.address_state ||
          phlebotomist.address_zip) && (
          <PhlebotomistField>
            <PhlebotomistFieldLabel>Address: </PhlebotomistFieldLabel>
            {phlebotomist.address_street} {phlebotomist.address_city}{" "}
            {phlebotomist.address_state} {phlebotomist.address_zip}
          </PhlebotomistField>
        )}
        {phlebotomist.phone && (
          <PhlebotomistField>
            <PhlebotomistFieldLabel>Phone: </PhlebotomistFieldLabel>
            {phlebotomist.phone}
          </PhlebotomistField>
        )}
        {phlebotomist.email && (
          <PhlebotomistField>
            <PhlebotomistFieldLabel>Email: </PhlebotomistFieldLabel>
            {phlebotomist.email}
          </PhlebotomistField>
        )}
        {phlebotomist.url && (
          <PhlebotomistField>
            <PhlebotomistFieldLabel>Website: </PhlebotomistFieldLabel>
            {phlebotomist.url}
          </PhlebotomistField>
        )}
        {phlebotomist.hours && (
          <PhlebotomistField>
            <PhlebotomistFieldLabel>Hours: </PhlebotomistFieldLabel>
            {phlebotomist.hours}
          </PhlebotomistField>
        )}
        {phlebotomist.fee && (
          <PhlebotomistField>
            <PhlebotomistFieldLabel>Fee: </PhlebotomistFieldLabel>
            {phlebotomist.fee}
          </PhlebotomistField>
        )}
      </PhlebotomistDetailsWrapper>
    </PhlebotomistWrapper>
  )
}

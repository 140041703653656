import { Dispatch, SetStateAction } from "react"

import { STATUS_CONFIGURATION_TYPES } from "app/components/hooks/use-status-components/consts"
import { Practitioner } from "app/types"
import { DashboardAlert } from "types/dashboard/dashboard-alert"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardLabCompany } from "types/dashboard/dashboard-lab-company"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardOrderedResult } from "types/dashboard/dashboard-ordered-result"
import { DashboardOrderedTest } from "types/dashboard/dashboard-ordered-test"
import { DashboardPatient } from "types/dashboard/dashboard-patient"
import { SpecimenIssue } from "types/specimen-issue"

import { BaseStatusConfigurationProps } from "./types"

export interface SpecimenIssueProps {
  specimenIssue: SpecimenIssue | undefined
  specimenIssueShowMore: boolean
  setSpecimenIssueShowMore: Dispatch<SetStateAction<boolean>>
}

export interface StatusConfigurationProps extends BaseStatusConfigurationProps {
  type: typeof STATUS_CONFIGURATION_TYPES.DASHBOARD
  kit: DashboardKit
  orderedTests: DashboardOrderedTest[]
  order: DashboardOrder
  labCompany: DashboardLabCompany | undefined
  patient: DashboardPatient | undefined
  specimenIssue: SpecimenIssue | undefined
  orderedResult: DashboardOrderedResult | undefined
  labCompanyAlert: DashboardAlert | undefined
  specimenIssueShowMore: boolean
  setSpecimenIssueShowMore: Dispatch<SetStateAction<boolean>>
  specimenIssueResolveModal: any
  pathname: string
  currentPractitioner: Practitioner
  revalidateOrderStatuses: () => Promise<any>
}

export function isDashboardProps(
  props: BaseStatusConfigurationProps
): props is StatusConfigurationProps {
  return props.type === STATUS_CONFIGURATION_TYPES.DASHBOARD
}

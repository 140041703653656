export const LABCORP_BODY_TEXT =
  "Complete your blood draw at a Labcorp location of your choosing. We'll send you an email to schedule your appointment. This order includes your $10 Labcorp Draw Fee when you pay cash."

export const QUEST_BODY_TEXT =
  "Complete your blood draw at a Quest location of your choosing. We'll send you an email to schedule your appointment. This order includes your $10 Quest Draw Fee when you pay cash."

export const FILLER_BODY_TEXT =
  "Your order requires a blood draw. We'll help you find options based on your zip code!"

// When updating need to update AXLE_PHLEBOTOMY_FEE on the backend as well
export const AXLE_PHLEBOTOMY_FEE = "$85.00"

// When updating need to update ADDITIONAL_AXLE_PHLEBOTOMY_KIT_FEE on the backend as well
export const ADDITIONAL_AXLE_PHLEBOTOMY_KIT_FEE = "$50.00"

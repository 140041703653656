import { useLocation } from "react-use"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"

export default function useBiomarkerCustomDescriptions(
  biomarkerIds?: string[],
  practitionerId?: string
) {
  const [isPatientPortalEnabled] = useFeatureFlag(FeatureFlag.PatientPortal)
  const location = useLocation()
  const isNewPatientPortal =
    location.href?.includes("patients") || location.href?.includes("accounts")
  const useNewPatientPortalApi = isPatientPortalEnabled && isNewPatientPortal

  let customDescriptionUrl =
    "/patient_portal_biomarker_custom_descriptions_legacy/"

  if (useNewPatientPortalApi) {
    customDescriptionUrl = "/patient_portal_biomarker_custom_descriptions/"
  }

  const { data, isLoading } = useCollectionSWR<
    ResourceCollection<BiomarkerCustomDescription>
  >(biomarkerIds?.length ? customDescriptionUrl : null, {
    params: {
      "filter[biomarker__id.in]": biomarkerIds?.join(),
      "filter[clinic.practitioner.id]": practitionerId,
    },
  })

  const biomarkerCustomDescriptions =
    useCachedCollection<BiomarkerCustomDescription>(data)

  const biomarkerCustomDescriptionsMapping = biomarkerCustomDescriptions.reduce(
    (acc, item) => {
      if (item.relationships.biomarker.data) {
        acc[item.relationships.biomarker.data.id] = item
      }
      return acc
    },
    {}
  ) as Record<string, BiomarkerCustomDescription>

  return {
    biomarkerCustomDescriptionsMapping,
    isLoading,
  }
}

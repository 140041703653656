import { useState } from "react"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceIdentifier, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { UserResult } from "types/user-result"

export default function useUpdateUserResult(id: string) {
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()

  const [loading, setLoading] = useState(false)

  const identifier: ResourceIdentifier = { id, type: "user_result" }

  const patchUserResult = useEventCallback(async (patchData: any) => {
    setLoading(true)

    const attributes = {
      collection_date: patchData.sample_collection_date,
      fasting_required: patchData.fasted === "yes",
      other_lab_company_name: patchData.other_lab_company_name,
    }

    const relationships = {
      sample_types: {
        data: [
          {
            id: patchData.sample_type,
            type: "lab_test_type",
          },
        ],
      },
    }

    if (patchData.lab_company && patchData.lab_company !== "other") {
      relationships["lab_company"] = {
        data: {
          id: patchData.lab_company,
          type: "lab_company",
        },
      }
    } else {
      // Ensure lab company is removed for other lab company name
      relationships["lab_company"] = {
        data: null,
      }
    }

    const sendPatchRequest = async () => {
      try {
        const { data } = await resourceRequest<ResourceResponse<UserResult>>({
          method: "patch",
          url: `/user_results/${id}/`,
          data: {
            data: {
              ...identifier,
              attributes,
              relationships,
            },
          },
        })

        setLoading(false)

        return data
      } catch (error) {
        handleApiError(error)

        setLoading(false)

        // re-throw so that the caller can handle the error
        throw error
      }
    }

    return mutateResource<UserResult>(identifier, sendPatchRequest, {
      optimisticData(currentData) {
        if (!currentData) {
          // should never be reached, but type error without
          return undefined as any
        }

        return {
          ...currentData,
          attributes: {
            ...currentData.attributes,
            ...attributes,
          },
          relationships: {
            ...currentData.relationships,
            ...relationships,
          },
        }
      },
      rollbackOnError: true,
    })
  })

  return {
    patchUserResult,
    loading,
  }
}

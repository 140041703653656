import { useState } from "react"
import { useDispatch } from "react-redux"

import { CircularProgress } from "@material-ui/core"

import { ReactComponent as PrintBlueIcon } from "app/assets/images/print-blue.svg"
import { LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS } from "app/constants"
import useEventCallback from "app/hooks/use-event-callback"
import { PatientOrderEvent } from "app/main/patient-orders/constants"
import { openLabCompanyRequisition } from "app/main/patient-orders/store/actions/patient.actions"
import { trackPractitionerDashboardAction } from "app/services/segment.typed"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardLabCompany } from "types/dashboard/dashboard-lab-company"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { SpecimenIssueNormalized } from "types/specimen-issue"

import ActionButton from "./ActionButton"

export interface ViewRequisitionActionProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function ViewRequisitionAction({
  kit,
  order,
}: ViewRequisitionActionProps) {
  const openRequisition = useOpenRequisition()
  const [isLoading, setIsLoading] = useState(false)

  const labCompany = useCachedResource<DashboardLabCompany>(
    kit.relationships.lab_company.data
  )
  const specimenIssue = useCachedResource<SpecimenIssueNormalized>(
    kit.relationships.specimen_issue?.data
  )

  const useSpecimenIssueRequisition: boolean = Boolean(
    specimenIssue &&
      labCompany &&
      LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS.includes(
        labCompany.attributes.key
      )
  )

  const isRequisitionAvailable =
    kit.attributes.is_requisition_provided &&
    (useSpecimenIssueRequisition
      ? !!specimenIssue?.attributes.recollection_requisition
      : kit.attributes.is_requisition_ready)

  const requisitionUrl = useSpecimenIssueRequisition
    ? specimenIssue?.attributes.recollection_requisition
    : kit.attributes.requisition_url

  const disabled = !isRequisitionAvailable || isLoading

  const onClick = useEventCallback(async () => {
    if (disabled) {
      // Skip if none provided
      return
    }

    if (requisitionUrl) {
      window.open(requisitionUrl)
    } else {
      setIsLoading(true)

      try {
        // TODO: This could probably be rewritten more optimally to pass the kit ID alone.
        await openRequisition(
          order.id,
          kit.relationships.lab_company?.data.id as string,
          kit.relationships.primary_ordered_test?.data.id as string
        )
      } finally {
        setIsLoading(false)
      }
    }

    trackPractitionerDashboardAction(
      PatientOrderEvent.DOWNLOAD_REQUISTION,
      order.relationships.practitioner?.data.id as string
    )
  })

  return (
    <ActionButton
      disabled={disabled}
      onClick={onClick}
      tooltipTitle={
        isRequisitionAvailable
          ? "View Requisition"
          : "No Digital Requisition Available"
      }
    >
      {isLoading ? (
        <CircularProgress size={15} aria-label="Loading the requisition form" />
      ) : (
        <PrintBlueIcon
          fill="currentColor"
          height="100%"
          width="100%"
          viewBox="0 0 20 19"
        />
      )}
    </ActionButton>
  )
}

function useOpenRequisition() {
  const dispatch = useDispatch()
  return useEventCallback(
    async (orderId: string, labCompanyId: string, orderedTestId: string) => {
      await dispatch(
        openLabCompanyRequisition(orderId, labCompanyId, orderedTestId)
      )
    }
  )
}

import { Redirect, Switch } from "react-router-dom"

import PatientPortalBGSupport from "app/assets/images/patient-portal/gradient1.jpeg"
import Portal from "app/components/Portal"
import PatientPortalBackground from "app/patient-portal/PatientPortalBackground"
import { PatientPortalBloodLabDashboard } from "app/patient-portal/blood-lab-dashboard"
import PatientPortalBloodLabDashboardSnapshot from "app/patient-portal/blood-lab-dashboard/components/PatientPortalBloodLabDashboardSnapshot"
import PatientPortalOrder from "app/patient-portal/order/components/PatientPortalOrder"
import PatientPortalSupport from "app/patient-portal/order/components/PatientPortalSupport"
import PatientPortalPatientOrders from "app/patient-portal/patient-orders/PatientPortalPatientOrders"
import PatientPortalPatients from "app/patient-portal/patients/PatientPortalPatients"

import Route from "../Route"
import { PatientPortalPatientPaths } from "../paths/patient-portal-paths"

export const PatientPortalPatientRoutes = () => (
  <Switch>
    <Route exact path={PatientPortalPatientPaths.ROOT}>
      <Redirect to={PatientPortalPatientPaths.PATIENTS} />
    </Route>
    <Route exact path={PatientPortalPatientPaths.PATIENTS}>
      <PatientPortalPatients />
    </Route>
    <Route exact path={PatientPortalPatientPaths.ORDERS}>
      <PatientPortalPatientOrders />
    </Route>
    <Route exact path={PatientPortalPatientPaths.ORDER}>
      <PatientPortalOrder />
    </Route>
    <Route exact path={PatientPortalPatientPaths.BLOOD_LABS}>
      <PatientPortalBloodLabDashboard />
    </Route>
    <Route exact path={PatientPortalPatientPaths.BLOOD_LABS_SNAPSHOT}>
      <PatientPortalBloodLabDashboardSnapshot />
    </Route>
    <Route exact path={PatientPortalPatientPaths.SUPPORT}>
      <Portal id="patient-portal-background">
        <PatientPortalBackground image={PatientPortalBGSupport} />
      </Portal>
      <PatientPortalSupport />
    </Route>
  </Switch>
)

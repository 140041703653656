import {
  PatientPortalOrder,
  PatientPortalPatient,
  PatientPortalPractitioner,
  PatientPortalUser,
} from "app/patient-portal/types"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useResourceSWR from "app/swr/hooks/use-resource-swr"

/**
 * Fetches the patient portal order resource for the given ID.
 *
 * @param orderId the order ID
 * @returns the order and its resources
 */
export function usePatientPortalOrderResource(
  orderId: string,
  patientId: string
) {
  const {
    data: order,
    error,
    isLoading,
    isValidating,
  } = useResourceSWR<PatientPortalOrder>(
    `/patient_portal/patient/${patientId}/order/${orderId}/`,
    {
      include: [
        "patient.user",
        "practitioner",
        "kits.ordered_tests.lab_test.lab_company",
        "kits.ordered_tests.lab_test.lab_test_types",
        "kits.ordered_tests.active_alerts",
        "kits.ordered_tests.specimen_issue",
        "kits.ordered_results",
        "payments",
      ],
    },
    {
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
      revalidateIfStale: true,
    }
  )

  const practitioner = useCachedResource<PatientPortalPractitioner>(
    order?.relationships.practitioner.data
  )
  const patient = useCachedResource<PatientPortalPatient>(
    order?.relationships.patient.data
  )
  const patientUser = useCachedResource<PatientPortalUser>(
    patient?.relationships.user.data
  )

  type ErrorResult = {
    ready: false
    isLoading: boolean
    isValidating: boolean
    error: NonNullable<typeof error>
    order: null
    practitioner: null
    patient: null
    patientUser: null
  }

  type NotReadyResult = {
    ready: false
    isLoading: boolean
    isValidating: boolean
    error: null
    order: null
    practitioner: null
    patient: null
    patientUser: null
  }

  type ReadyResult = {
    ready: true
    error: null
    isLoading: boolean
    isValidating: boolean
    order: NonNullable<typeof order>
    practitioner: NonNullable<typeof practitioner>
    patient: NonNullable<typeof patient>
    patientUser: NonNullable<typeof patientUser>
  }

  if (error) {
    return {
      ready: false,
      error,
      isLoading,
      isValidating,
      order: null,
      practitioner: null,
      patient: null,
      patientUser: null,
    } as ErrorResult
  }

  if (isLoading || !order || !practitioner || !patient || !patientUser) {
    return {
      ready: false,
      error: null,
      isLoading,
      isValidating,
      order: null,
      practitioner: null,
      patient: null,
      patientUser: null,
    } as NotReadyResult
  }

  return {
    ready: true,
    isLoading,
    isValidating,
    order,
    practitioner,
    patient,
    patientUser,
  } as ReadyResult
}

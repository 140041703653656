import { useState, useMemo, useEffect } from "react"

import clsx from "clsx"
import { Link } from "react-router-dom"

import NiceModal from "@ebay/nice-modal-react"
import { Divider } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import Loading from "app/components/Loading"
import BodyText from "app/components/design-system/BodyText"
import { Dialog } from "app/components/modals"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { InRangeOption } from "app/main/patient-orders/results-over-time/types/types"
import { ClinicSettings } from "app/main/settings/RupaBloodDashboards/constants"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabCompany } from "types/lab-company"
import { PatientSettings } from "types/patient"

import { BloodLabDashboardsGroupByGroupings } from "../BloodLabDashboardsModal/BloodLabDashboardsGroupByToggle"
import { DEFAULT_BODY_SYSTEM_DROPDOWN_ID } from "../BloodLabDashboardsModal/BodySystemDropdownFilter"
import useBiomarkerCustomDescriptions from "../hooks/use-biomarker-custom-descriptions"
import useDiscreteResults from "../hooks/use-discrete-results"
import BloodLabDashboardsAbnormalSection from "./BloodLabDashboardsAbnormalSection"
import BloodLabDashboardsBiomarkerCountOverview from "./BloodLabDashboardsBiomarkerCountOverview"
import BloodLabDashboardsBiomarkerMissingWarning from "./BloodLabDashboardsBiomarkerMissingWarning"
import BloodLabDashboardsBiomarkerSection from "./BloodLabDashboardsBiomarkerSection"
import BloodLabDashboardsDisclaimerText from "./BloodLabDashboardsDisclaimerText"
import BloodLabDashboardsModalHeader from "./BloodLabDashboardsModalHeader"
import BloodLabDashboardsResultsNotSharedWarning from "./BloodLabDashboardsResultsNotSharedWarning"
import BloodLabDashboardsSearchAndFilter from "./BloodLabDashboardsSearchAndFilter"

const useStyles = makeAppStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  dialog: {
    borderRadius: 12,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      margin: 0,
      width: "100vw",
    },
  },
  body: {
    backgroundColor: colors.coolGray[100],
    padding: 22.5,
    display: "flex",
    flexDirection: "column",
    gap: 24,
    maxWidth: 970,
    borderTop: `1px solid ${colors.blueGray[300]}`,
  },
  noResultsText: {
    color: colors.blueGray[400],
    textAlign: "center",
  },
  disabledNotificationContainer: {
    background: colors.blueGray[200],
    padding: "8px 22.5px",
    maxWidth: 970,
    borderTop: `1px solid ${colors.blueGray[300]}`,
  },
  settingsLink: {
    color: primaryColor,
    fontWeight: 600,
  },
  divider: {
    marginTop: 9,
    background: colors.blueGray[200],
  },
  notSharedWarning: {
    maxHeight: 300,
    opacity: 1,
    marginTop: 0,
    transition: "max-height 0.3s ease-in, opacity 0.3s ease-in",
  },
  hideNotSharedWarning: {
    maxHeight: 0,
    opacity: 0,
    marginTop: -24,
    zIndex: -1,
    transition:
      "max-height 0.3s ease-out, opacity 0.3s ease-out, margin-top 0.4s",
  },
}))

interface BloodLabDashboardsIntroductionModalProps {
  onClose: () => void
  orderedResultId: string
  kitSentToPatient: boolean
}

const BloodLabDashboardsModal =
  NiceModal.create<BloodLabDashboardsIntroductionModalProps>((props) => {
    const handleApiError = useHandleApiError()

    const [searchText, setSearchText] = useState("")
    const [inRangeValueFilter, setInRangeValueFilter] = useState<InRangeOption>(
      InRangeOption.ALL
    )
    const [groupBy, setGroupBy] = useState<BloodLabDashboardsGroupByGroupings>(
      BloodLabDashboardsGroupByGroupings.LAB_TEST
    )
    const [hideNotSharedWarning, setHideNotSharedWarning] = useState(
      props.kitSentToPatient
    )

    const classes = useStyles()

    const [filterBodySystemId, setFilterBodySystemId] = useState<string>(
      DEFAULT_BODY_SYSTEM_DROPDOWN_ID
    )

    const {
      discreteResults,
      discreteResultsBiomarkers,
      missingBiomarkers,
      totalBiomarkers,
      bodySystems,
      labTests,
      patient,
      practitioner,
      clinic,
      orderedResult,
      error,
      isLoading,
      getAbnormalDiscreteResults,
      getDiscreteResultsByLabTest,
      getDiscreteResultsByBodySystem,
      mutate,
    } = useDiscreteResults(props.orderedResultId, searchText)

    const {
      biomarkerCustomDescriptionsMapping,
      isLoading: isBiomarkerCustomDescriptionsLoading,
    } = useBiomarkerCustomDescriptions(
      discreteResultsBiomarkers.map((b) => b.id)
    )

    const abnormalDiscreteResults = getAbnormalDiscreteResults()

    const labCompany = useCachedResource<LabCompany>(
      orderedResult?.relationships.lab_company.data
    )

    const showHighLowDescriptions = useMemo(() => {
      return patient?.attributes.patient_settings.includes(
        PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS
      )
    }, [patient])

    const showAllMarkersTitle = useMemo(() => {
      if (
        searchText !== "" ||
        filterBodySystemId !== DEFAULT_BODY_SYSTEM_DROPDOWN_ID
      ) {
        return false
      }

      if (
        groupBy === BloodLabDashboardsGroupByGroupings.BODY_SYSTEM &&
        bodySystems.length === 0
      ) {
        return false
      }

      if (
        groupBy === BloodLabDashboardsGroupByGroupings.LAB_TEST &&
        labTests.length === 0
      ) {
        return false
      }

      return true
    }, [searchText, groupBy, filterBodySystemId])

    const handleOriginalLabReportClick = () => {
      if (orderedResult?.attributes.result_file) {
        window.open(orderedResult.attributes.result_file)
      }
    }

    const optimalRangesSettingTurnedOn = useMemo(() => {
      return clinic?.attributes.clinic_settings.includes(
        ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
      )
    }, [clinic])

    useEffect(() => {
      if (error !== undefined) {
        props.onClose()
        handleApiError(error)
      }
    }, [error])

    const closeNotSharedWarning = useEventCallback(() => {
      setHideNotSharedWarning(true)
    })

    if (isLoading || isBiomarkerCustomDescriptionsLoading) {
      return (
        <Dialog
          open
          onClose={props.onClose}
          aria-labelledby="blood-reports-modal"
          maxWidth={false}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              overflow: "hidden",
            },
          }}
        >
          <Loading ariaLabel="Loading Report" />
        </Dialog>
      )
    }

    return (
      <Dialog
        onClose={props.onClose}
        open
        aria-labelledby="blood-reports-modal"
        className={classes.dialog}
        classes={{ paper: classes.dialog }}
        maxWidth={false}
      >
        <div className={classes.container}>
          <BloodLabDashboardsModalHeader
            onClose={props.onClose}
            downloadableResultUrls={
              orderedResult?.attributes.result_file
                ? [orderedResult?.attributes.result_file]
                : []
            }
            orderId={
              orderedResult?.relationships.order?.data.id
                ? orderedResult?.relationships.order?.data.id
                : ""
            }
            patient={patient}
            orderedResult={orderedResult}
            onUpdatePatient={mutate}
            showCustomizePatientDashboard={Boolean(
              clinic?.attributes.clinic_settings.includes(
                ClinicSettings.USE_RUPA_BLOOD_REPORTS
              )
            )}
          />
          {!clinic?.attributes.clinic_settings.includes(
            ClinicSettings.USE_RUPA_BLOOD_REPORTS
          ) && (
            <div className={classes.disabledNotificationContainer}>
              Your client will receive the original lab report from{" "}
              {labCompany?.attributes.name}. To share Blood Lab Dashboards with
              your patients, turn it on in{" "}
              <Link
                onClick={() => props.onClose()}
                to={UserPaths.RUPA_BLOOD_DASHBOARDS}
                className={classes.settingsLink}
              >
                Settings
              </Link>
              .
            </div>
          )}
          <div className={classes.body}>
            <div
              className={clsx(
                classes.notSharedWarning,
                hideNotSharedWarning ? classes.hideNotSharedWarning : ""
              )}
            >
              <BloodLabDashboardsResultsNotSharedWarning
                patientFirstName={patient?.attributes.first_name || "Patient"}
                kitId={
                  orderedResult?.relationships.kit.data.id
                    ? orderedResult?.relationships.kit.data.id
                    : ""
                }
                orderId={
                  orderedResult?.relationships.order?.data.id
                    ? orderedResult?.relationships.order?.data.id
                    : ""
                }
                practitionerId={
                  practitioner?.attributes.id ? practitioner?.attributes.id : ""
                }
                isBloodDashboardsOn={Boolean(
                  clinic?.attributes.clinic_settings.includes(
                    ClinicSettings.USE_RUPA_BLOOD_REPORTS
                  )
                )}
                onSendResultsToPatient={closeNotSharedWarning}
              />
            </div>
            {searchText === "" &&
              totalBiomarkers.length - discreteResults.length > 0 && (
                <BloodLabDashboardsBiomarkerCountOverview
                  biomarkerCount={discreteResults.length}
                  biomarkerTotal={totalBiomarkers.length}
                />
              )}
            {searchText === "" &&
              totalBiomarkers.length - discreteResults.length === 0 &&
              missingBiomarkers.length > 0 && (
                <BloodLabDashboardsBiomarkerMissingWarning
                  biomarkers={missingBiomarkers}
                  downloadableResultUrls={
                    orderedResult?.attributes.result_file
                      ? [orderedResult?.attributes.result_file]
                      : []
                  }
                  orderedResultId={orderedResult?.id ? orderedResult?.id : ""}
                />
              )}
            <BloodLabDashboardsSearchAndFilter
              handleInRangeValueChange={setInRangeValueFilter}
              inRangeValue={inRangeValueFilter}
              onSearch={setSearchText}
              searchText={searchText}
              filterBodySystemId={filterBodySystemId}
              setFilterBodySystemId={setFilterBodySystemId}
              onGroupBy={setGroupBy}
              groupBy={groupBy}
              availableBodySystems={bodySystems}
            />
            {searchText && bodySystems.length === 0 && (
              <BodyText weight={"semibold"} className={classes.noResultsText}>
                We couldn't find any markers that match your search. 🤔
              </BodyText>
            )}

            {searchText === "" &&
              filterBodySystemId === DEFAULT_BODY_SYSTEM_DROPDOWN_ID &&
              abnormalDiscreteResults.length > 0 && (
                <BloodLabDashboardsAbnormalSection
                  discreteResults={abnormalDiscreteResults}
                  showHighLowDescriptions={showHighLowDescriptions}
                  useOptimalRanges={optimalRangesSettingTurnedOn}
                  biomarkerCustomDescriptionsMapping={
                    biomarkerCustomDescriptionsMapping
                  }
                />
              )}
            {showAllMarkersTitle && (
              <div>
                <BodyText weight="semibold" size="md">
                  All Markers
                </BodyText>
                <Divider className={classes.divider} />
              </div>
            )}
            {groupBy === BloodLabDashboardsGroupByGroupings.BODY_SYSTEM &&
              bodySystems.map((bodySystem) =>
                filterBodySystemId === DEFAULT_BODY_SYSTEM_DROPDOWN_ID ||
                filterBodySystemId === bodySystem.id ? (
                  <BloodLabDashboardsBiomarkerSection
                    discreteResults={getDiscreteResultsByBodySystem(bodySystem)}
                    sectionTitle={bodySystem.attributes.name}
                    showHighLowDescriptions={showHighLowDescriptions}
                    useOptimalRanges={optimalRangesSettingTurnedOn}
                    biomarkerCustomDescriptionsMapping={
                      biomarkerCustomDescriptionsMapping
                    }
                    biomarkersIdsWithoutResults={bodySystem.relationships.biomarkers.data
                      .filter((biomarker) =>
                        missingBiomarkers.find(
                          (discreteResultBiomarker) =>
                            discreteResultBiomarker.id === biomarker.id
                        )
                      )
                      .map((biomarker) => biomarker.id)}
                  />
                ) : null
              )}
            {groupBy === BloodLabDashboardsGroupByGroupings.LAB_TEST &&
              labTests.map((labTest) => (
                <BloodLabDashboardsBiomarkerSection
                  discreteResults={getDiscreteResultsByLabTest(
                    labTest,
                    filterBodySystemId
                  )}
                  sectionTitle={labTest.attributes.name}
                  showHighLowDescriptions={showHighLowDescriptions}
                  useOptimalRanges={optimalRangesSettingTurnedOn}
                  biomarkerCustomDescriptionsMapping={
                    biomarkerCustomDescriptionsMapping
                  }
                  biomarkersIdsWithoutResults={labTest.relationships.biomarkers.data
                    .filter((biomarker) =>
                      missingBiomarkers.find(
                        (discreteResultBiomarker) =>
                          discreteResultBiomarker.id === biomarker.id
                      )
                    )
                    .map((biomarker) => biomarker.id)}
                />
              ))}

            <BloodLabDashboardsDisclaimerText
              optimalRangesSettingTurnedOn={optimalRangesSettingTurnedOn}
              handleOriginalLabReportClick={handleOriginalLabReportClick}
            />
          </div>
        </div>
      </Dialog>
    )
  })

export default BloodLabDashboardsModal

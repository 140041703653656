import { Redirect, useHistory } from "react-router-dom"
import { useUpdateEffect } from "react-use"

import DisplayText from "app/components/design-system/DisplayText"
import useAppSelector from "app/hooks/useAppSelector"
import {
  PATIENT_PORTAL_TRACKING_EVENTS,
  trackPatientPortalEvent,
} from "app/services/segment"

import Card from "../Card"
import PatientPortalEmptyState from "../PatientPortalEmptyState"
import PatientPortalGeneralQuickLinks from "../footer/PatientPortalGeneralQuickLinks"
import PatientPortalOrderQuickLinks from "../footer/PatientPortalOrderQuickLinks"
import { buildPatientPortalUrl } from "../util"
import PatientPortalPatientCard from "./PatientPortalPatientCard"
import PatientPortalPatientsLoadingState from "./PatientPortalPatientsLoadingState"
import usePatientPortalPatients from "./hooks/use-patient-portal-patients"

const PatientPortalPatients = () => {
  const { patients, isLoading } = usePatientPortalPatients()
  const history = useHistory()

  const routeToPatientOrders = (patientId: string) => {
    history.push(buildPatientPortalUrl(patientId))
  }

  const user = useAppSelector(({ auth }) => auth.user)

  const hasMultiplePatients = patients.length > 0
  const shouldRedirectToOrdersPage = patients.length === 1

  useUpdateEffect(() => {
    // Do not track if we are just going to redirect to orders page
    if (!isLoading && !shouldRedirectToOrdersPage) {
      trackPatientPortalEvent(
        PATIENT_PORTAL_TRACKING_EVENTS.PATIENTS_PAGE_VIEWED,
        { user_id: user.id.toString() }
      )
    }
  }, [isLoading])

  if (isLoading) {
    return <PatientPortalPatientsLoadingState />
  }

  if (shouldRedirectToOrdersPage) {
    return <Redirect to={buildPatientPortalUrl(patients[0].id)} />
  }

  return (
    <div className="flex flex-col gap-4">
      <Card>
        {hasMultiplePatients ? (
          <>
            <DisplayText size="lg" weight="semibold">
              Select a Patient
            </DisplayText>

            <div className="flex flex-col gap-4">
              {patients.map((patient) => (
                <PatientPortalPatientCard
                  patient={patient}
                  onClick={(p) => routeToPatientOrders(p.id)}
                  key={patient.id}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <DisplayText size="lg" weight="semibold">
              Welcome, {user?.first_name}!
            </DisplayText>
            <PatientPortalEmptyState authUser={user} />
          </>
        )}
      </Card>
      {hasMultiplePatients ? (
        <PatientPortalOrderQuickLinks />
      ) : (
        <PatientPortalGeneralQuickLinks />
      )}
    </div>
  )
}

export default PatientPortalPatients

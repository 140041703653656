import Tooltip from "app/components/Tooltip"
import Separator from "app/main/settings/components/Separator"
import { StyledSwitch } from "app/main/settings/components/StyledSwitch"
import { navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  inOfficeToggle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  toggleTextContainer: {
    display: "flex",
    flexDirection: "column",
  },
  toggleTitle: {
    fontWeight: 600,
    color: navy,
  },
  toggleDescription: {
    fontWeight: 400,
    color: navy,
  },
  linkText: {
    color: theme.palette.primary.main,
  },
}))

interface InOfficeToggleProps {
  inOfficeKits: boolean
  onChange: () => void
  inOfficeKitsDisabledReason: string
}

const InOfficeKitsToggle = ({
  inOfficeKits,
  onChange,
  inOfficeKitsDisabledReason,
}: InOfficeToggleProps) => {
  const classes = useStyles()

  return (
    <div className="pt-6">
      <Separator />
      <div className={classes.inOfficeToggle}>
        <Tooltip title={inOfficeKitsDisabledReason} placement="top" arrow>
          <div>
            <StyledSwitch
              checked={inOfficeKits}
              onChange={onChange}
              name="allow_in_office_kits"
              value={inOfficeKits}
              disabled={Boolean(inOfficeKitsDisabledReason)}
            />
          </div>
        </Tooltip>
        <div className={classes.toggleTextContainer}>
          <div className={classes.toggleTitle}>Stock Kits In-Office</div>
          <div className={classes.toggleDescription}>
            Rupa can manage your orders for kits handed out in person, or blood
            draws done in-house. We'll handle ordering your supplies, managing
            your billing, and your patient's requisitions. Please note,
            In-Office Kits can't be ordered with Physician Services.{" "}
            <a
              href="https://www.rupahealth.com/in-office-kits"
              target="_blank"
              rel="noreferrer"
              className={classes.linkText}
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InOfficeKitsToggle

import BloodIcon from "app/assets/icons/blood.svg"
import AlertTriangleRed from "app/assets/images/alert-triangle-red.svg"
import AlertTriangleIcon from "app/assets/images/alert-triangle.svg"
import UserBlue from "app/assets/images/user-blue.svg"
import RupaLogoIcon from "app/components/svgs/RupaLogoIcon"
import {
  IOK_ORDER_STATUS,
  KIT_GROUP_TYPE,
  LAB_TEST_TYPE_KEY,
  ORDERED_TEST_STATUS,
  ORDER_PAYER,
  ORDER_STATUS,
} from "app/constants.typed"
import { colors } from "app/theme"
import {
  CheckoutWarningSeverities,
  CheckoutWarningTypes,
  OrderTestWarningTypes,
} from "app/types"

export const SUPER_USER_ID = 1

export const VENDOR_PHYSICIAN_AUTHORIZATION_LABEL = "Physician Services"

// URLs
export const REFERENCE_GUIDE_URL = "https://www.rupahealth.com/reference-guide"
export const PHYSICIAN_AUTHORIZATION_GUIDE_URL =
  "https://www.rupahealth.com/physician-authorization"
export const PATIENT_EXPERIENCE_GUIDE_URL =
  "https://www.rupahealth.com/patient-experience"
export const PHYSICIAN_SERVICES_CLIENT_EXPERIENCE_GUIDE_URL =
  "https://www.rupahealth.com/client-experience"

// Order statuses - DEPRECATED. USE ORDER_STATUS below.
export const DRAFT = 1
export const IN_PROGRESS = 4
export const TESTS_COMPLETED = 5
export const ORDER_RESULTS_REVIEWED = 6
export const ORDER_PAUSED = 7
export const ORDER_CANCELED = 99

// Re-exporting for backwards compatibility. Now in `app/constants.typed`.
export {
  KIT_GROUP_TYPE,
  LAB_TEST_TYPE_KEY,
  ORDERED_TEST_STATUS,
  ORDER_PAYER,
  ORDER_STATUS,
}

export const orderStatuses = {
  [ORDER_STATUS.DRAFT]: {
    label: "Draft",
    background: colors.gray[100],
    text: colors.gray[700],
  },
  [ORDER_STATUS.SCHEDULED]: {
    label: "Scheduled",
    background: colors.blue[100],
    text: colors.blue[900],
  },
  [ORDER_STATUS.PENDING_PAYMENT]: {
    label: "Pending Payment",
    background: colors.yellow[100],
    text: colors.yellow[900],
  },
  [ORDER_STATUS.PENDING_LABSHOP_PAYMENT]: {
    label: "Pending LabShop Payment",
    background: colors.yellow[100],
    text: colors.yellow[900],
  },
  [ORDER_STATUS.PENDING_SHIPPING_INFO]: {
    label: "Pending Shipping Info",
    background: colors.yellow[100],
    text: colors.yellow[900],
  },
  [ORDER_STATUS.PENDING_PHYSICIAN_AUTHORIZATION]: {
    label: "Pending Physician Authorization",
    background: colors.blue[100],
    text: colors.blue[900],
  },
  [ORDER_STATUS.AWAITING_SHIPMENT]: {
    label: "Awaiting Shipment",
    background: colors.blue[100],
    text: colors.blue[900],
  },
  [ORDER_STATUS.IN_PROGRESS]: {
    label: "In Progress",
    background: colors.blue[100],
    text: colors.blue[900],
  },
  [ORDER_STATUS.COMPLETE]: {
    label: "All Results In",
    background: colors.green[100],
    text: colors.green[800],
  },
  [ORDER_STATUS.RESULTS_REVIEWED]: {
    label: "Results Reviewed",
    background: colors.cyan[50],
    text: colors.cyan[900],
  },
  [ORDER_STATUS.CANCELED]: {
    label: "Canceled",
    background: colors.blueGray[100],
    text: colors.coolGray[600],
  },
  [ORDER_STATUS.PAUSED]: {
    label: "Order Paused",
    background: colors.orange[50],
    text: colors.orange[900],
  },
  [ORDER_STATUS.BLOCKED_MISSING_SIGNATURE]: {
    label: "Blocked: Missing Signature",
    background: colors.orange[50],
    text: colors.orange[900],
  },
  [ORDER_STATUS.BLOCKED_PRACTITIONER_ACCOUNT_UNVERIFIED]: {
    label: "Blocked: Practitioner Account Unverified",
    background: colors.orange[50],
    text: colors.orange[900],
  },
  [ORDER_STATUS.DEMO]: {
    label: "Demo Order",
    background: colors.green[100],
    text: colors.green[800],
  },
  [ORDER_STATUS.PENDING_LABSHOP_APPROVAL]: {
    label: "Pending LabShop Approval",
    background: colors.purple[100],
    text: colors.violet[600],
  },
  [IOK_ORDER_STATUS.ORDER_RECEIVED]: {
    label: "Order Received",
    background: colors.yellow[100],
    text: colors.yellow[700],
  },
  [IOK_ORDER_STATUS.PENDING_SHIPMENT]: {
    label: "Pending Shipment",
    background: colors.yellow[100],
    text: colors.yellow[700],
  },
  [IOK_ORDER_STATUS.SHIPPED]: {
    label: "Shipped",
    background: colors.lightBlue[100],
    text: colors.lightBlue[700],
  },
}

export const labTestColors = {
  hair: "purple",
  "blood spot": "pink",
  blood: "pink",
  "whole blood": "pink",
  plasma: "pink",
  "finger stick": "pink",
  saliva: "sky",
  urine: "yellow",
  stool: "tangerine",
  serum: "pink",
  "": "cyan",
}

export const LAB_COMPANY_KEY = {
  AERODIAGNOSTICS: "Aerodiagnostics",
  ALLETESS: "Alletess",
  ACCESS_MEDICAL_LABS: "Access Medical Labs",
  AYUMETRIX: "Ayumetrix",
  BIOREFERENCE: "BioReference",
  BOSTON_HEART: "BostonHeart",
  CELL_SCIENCE_SYSTEMS: "Cell Science Systems",
  CYREX: "Cyrex",
  DOCTORS_DATA: "Doctor's Data",
  DHA_LABORATORY: "DHA Laboratory",
  DNA_LIFE: "dnalife",
  DNA_RX: "DNA RX",
  DSL: "DSL",
  EMPIRE_CITY_LABS: "Empire City Labs",
  GENOVA: "Genova",
  MOSAIC_DIAGNOSTICS: "Great Plains",
  GX_SCIENCES: "GX Sciences",
  IGENEX: "IGeneX",
  IMMUNOSCIENCES_LAB: "Immunosciences Lab",
  INFINITE_ALLERGY: "Infinite Allergy",
  KBMO: "KBMO",
  LABCORP: "Labcorp",
  LABCORP_BY_ACCESS_MEDICAL_LABS: "Labcorp by Access Medical Labs",
  HEALTH_GORILLA_LABCORP: "Health Gorilla - Labcorp",
  PRECISION_ANALYTICAL: "Precision Analytical",
  QUICKSILVER: "Quicksilver",
  REALTIME_LABS: "RealTime Labs",
  SPECTRA_CELL: "SpectraCell",
  SOUND_MEDICAL: "Sound Medical",
  TINY_HEALTH: "Tiny Health",
  US_BIOTEK: "US BioTek",
  VIBRANT_AMERICA: "Vibrant America",
  VIBRANT_WELLNESS: "Vibrant Wellness",
  ZRT: "ZRT",
  LTA_QUEST: "Lab Testing API - Quest",
  LABSFIRST_QUEST: "LabsFirst - Quest",
  HEALTH_GORILLA_QUEST: "Health Gorilla - Quest",
}

export type LabCompanyKey = `${keyof typeof LAB_COMPANY_KEY}`

export const LABCORP_COMPANY_KEYS = [
  LAB_COMPANY_KEY.LABCORP,
  LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS,
  LAB_COMPANY_KEY.HEALTH_GORILLA_LABCORP,
]

export const QUEST_COMPANY_KEYS = [
  LAB_COMPANY_KEY.LTA_QUEST,
  LAB_COMPANY_KEY.LABSFIRST_QUEST,
  LAB_COMPANY_KEY.HEALTH_GORILLA_QUEST,
]

export const REQUISITION_STATUS_LAB_COMPANY_KEYS = [
  ...LABCORP_COMPANY_KEYS,
  ...QUEST_COMPANY_KEYS,
]

export const INSURANCE_COMPANY_KEYS = [
  LAB_COMPANY_KEY.HEALTH_GORILLA_LABCORP,
  LAB_COMPANY_KEY.HEALTH_GORILLA_QUEST,
]

export const VIBRANT_COMPANY_KEYS = [
  LAB_COMPANY_KEY.VIBRANT_AMERICA,
  LAB_COMPANY_KEY.VIBRANT_WELLNESS,
]

// IF YOU NEED TO ADD A NEW LAB COMPANY TO THIS, please migrate this information to a model field instead!
export const COMPANY_KEY_INSURANCE_TOGGLE_PAIRS = [
  {
    uninsuredCompanyKey: LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS,
    insuredCompanyKey: LAB_COMPANY_KEY.HEALTH_GORILLA_LABCORP,
  },
  {
    uninsuredCompanyKey: LAB_COMPANY_KEY.LABSFIRST_QUEST,
    insuredCompanyKey: LAB_COMPANY_KEY.HEALTH_GORILLA_QUEST,
  },
]

export const LAB_COMPANY_ACCOUNT_STATUS = {
  MANUAL_REGISTRATION_REQUIRED: "Manual Registration Required",
  CREATED: "Created",
  PENDING: "Pending",
  CONFIRMED: "Confirmed",
  DENIED: "Denied",
  FAILED: "Failed",
}

export const LAB_TEST_BLOOD_TYPES = [
  LAB_TEST_TYPE_KEY.BLOOD,
  LAB_TEST_TYPE_KEY.PLASMA,
  LAB_TEST_TYPE_KEY.SERUM,
  LAB_TEST_TYPE_KEY.WHOLE_BLOOD,
  LAB_TEST_TYPE_KEY.RED_BLOOD_CELLS,
]

export const ORDER_LINE_ITEM_TYPE = {
  COUPON: "Coupon",
  CREDIT_CARD_FEE: "Credit Card Processing Fee",
  LAB_TEST: "Lab Test",
  PROCESSING_FEE: "Processing Fee",
  INSURANCE_PROCESSING_FEE: "Insurance Processing Fee",
  SHIPPING: "Shipping",
  RUPA_DISCOUNT: "Rupa Discount",
  VIBRANT_MINIMUM: "Vibrant Minimum",
  SCARLET_BLOOD_DRAW_FEE: "Scarlet Blood Draw Fee",
  LABCORP_BLOOD_DRAW_FEE: "Labcorp Blood Draw Fee",
  AXLE_BLOOD_DRAW_FEE: "Axle Blood Draw Fee",
  AXLE_BLOOD_DRAW_ADDITIONAL_KIT_FEE: "Axle Blood Draw Additional Kit Fee",
  QUEST_BLOOD_DRAW_FEE: "Quest Blood Draw Fee",
  QUEST_SERVICE_FEE: "Quest Service Fee",
  PHYSICIAN_AUTHORIZATION_VENDOR_FEE: "Vendor Physician Authorization",
  PHYSICIAN_AUTHORIZATION_RUPA_FEE: "Rupa Physician Authorization",
  PHYSICIAN_SERVICES_VENDOR_FEE: "Physician Services Vendor Fee",
  // NOTE: PHYSICIAN_SERVICES_RUPA_FEE will be deprecated in favor of PHYSICIAN_SERVICES_KIT_RUPA_FEE once we roll out the new promotional experiment changes
  PHYSICIAN_SERVICES_RUPA_FEE: "Physician Services Rupa Fee",
  PHYSICIAN_SERVICES_PANEL_RUPA_FEE: "Physician Services Panel Rupa Fee",
  PHYSICIAN_SERVICES_PROMOTIONAL_RUPA_FEE:
    "Physician Services Promotional Rupa Fee",
  PHYSICIAN_SERVICES_KIT_RUPA_FEE: "Physician Services Kit Rupa Fee",
  CUSTOM_FEE: "Custom Fee",
} as const

export const CUSTOM_FEE_TYPE = {
  WHOLE_ORDER: "whole_order",
  LABSHOP_PRODUCT: "labshop_product",
}

export const COUPON_TYPE = {
  DISCOUNTED_LAB_TEST: "Discounted Lab Test",
  DISCOUNT: "Discount",
  LAB_WIDE_DISCOUNT: "Lab-Wide Discount",
}

export const ORDER_PRICING_TYPE = {
  CASH: "Cash",
  INSURANCE_NON_MEDICARE: "Insurance Non-Medicare",
  INSURANCE_MEDICARE: "Insurance Medicare",
}

export const ORDER_NOTIFICATION_METHOD = {
  EMAIL: "Email",
  EMAIL_AND_TEXT: "Email and Text",
}

export const CERBO_OAUTH_ID = "51"

// eslint-disable-next-line no-useless-escape
export const PO_BOX_REGEX =
  /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/gim

export const DASHBOARD_BANNER_TYPE = {
  Large: "Large",
  Small: "Small",
}

export const MEDICARE = {
  carrier: "Medicare (not Medicare Advantage)",
  group: "Government Carriers",
}
export const TRICARE = {
  carrier: "TriCare",
  group: "Government Carriers",
}

export const MEDICAID = {
  carrier: "Medicaid / Medicaid Managed Care Plans",
  group: "Government Carriers",
}

export const AETNA = {
  carrier: "Aetna / Aetna Medicare Advantage",
  group: "Commercial Carriers",
}
export const BLUE_CROSS = {
  carrier: "Blue Cross Blue Shield (BCBS) / BCBS Medicare Advantage",
  group: "Commercial Carriers",
}

export const BLUE_CROSS_EMPLOYEES = {
  carrier: "Blue Cross Blue Shield Federal Employees Program (FEP)",
  group: "Commercial Carriers",
}

export const CIGNA = { carrier: "Cigna", group: "Commercial Carriers" }

export const HUMANA = {
  carrier: "Humana / Humana Gold / Humana Medicare Advantage",
  group: "Commercial Carriers",
}

export const KAISER = {
  carrier: "Kaiser / Kaiser Medicare Advantage",
  group: "Commercial Carriers",
}

export const PRINCIPLE = { carrier: "Principle", group: "Commercial Carriers" }

export const TUFTS = {
  carrier: "Tufts / Tufts Medicare Advantage",
  group: "Commercial Carriers",
}

export const UNITED_HEALTHCARE = {
  carrier: "United Healthcare",
  group: "Commercial Carriers",
}

export const INSURANCE_PROVIDERS_MEDICARE = [MEDICARE, TRICARE]

export const INSURANCE_PROVIDERS_COMMERIAL_CARRIERS = [
  AETNA,
  BLUE_CROSS,
  BLUE_CROSS_EMPLOYEES,
  CIGNA,
  HUMANA,
  KAISER,
  PRINCIPLE,
  TUFTS,
  UNITED_HEALTHCARE,
]

export const INSURANCE_PROVIDERS_NON_MEDICARE = [
  MEDICAID,
  ...INSURANCE_PROVIDERS_COMMERIAL_CARRIERS,
]

export const TOUR_STATUS = {
  // User is excluded from this tour. This could be because they are an existing user that does not require it.
  USER_EXCLUDED: "User Excluded",

  // Tour will be started once the user performs the required trigger action (such as logging in)
  AWAITING_USER_TRIGGER: "Awaiting User Trigger",

  // Tour has been started, but has not been completed.
  STARTED_AND_INCOMPLETE: "Started And Incomplete",

  // The Tour was manually skipped by the user
  SKIPPED: "Skipped",

  // The tour was fully completed by the user.
  COMPLETED: "Complete",
}

export const SURVEY_STATUS = {
  // User is excluded from the survey. This could be because they are an existing user that does not require it.
  USER_EXCLUDED: "User Excluded",

  // Survey will be started once the user performs the required trigger action (such as logging in)
  AWAITING_USER_TRIGGER: "Awaiting User Trigger",

  // Survey has been started, but has not been completed.
  STARTED_AND_INCOMPLETE: "Started And Incomplete",

  // The Survey was manually skipped by the user
  SKIPPED: "Skipped",

  // The survey was fully completed by the user.
  COMPLETED: "Complete",
}

export const PAUSE_DURATIONS = {
  TWO_WEEKS: "2_weeks",
  ONE_MONTH: "1_month",
  TWO_MONTHS: "2_month",
}

export const DASHBOARD_CARDS = {
  EXPLORE_TEST_CATALOG: "Explore test catalog",
  PRICING_AND_PAYMENT: "Pricing and payment",
  HOW_BLOOD_DRAWS_WORK: "How blood draws work",
  PATIENT_EXPERIENCE: "Patient experience",
  SCHEDULE_1_ON_1: "Schedule 1 on 1",
  ORDERING_BLOODWORK: "Ordering bloodwork",
  LEARN_FUNCTIONAL_LABS: "Learn functional labs",
  INVITE_CLINIC_STAFF: "Invite clinic staff",
}

export const INSURANCE_LAB_COMPANIES = [LAB_COMPANY_KEY.GENOVA]

export const MEDICARE_LAB_COMPANIES = [
  LAB_COMPANY_KEY.GENOVA,
  LAB_COMPANY_KEY.DSL,
  LAB_COMPANY_KEY.INFINITE_ALLERGY,
]

export const LAB_COMPANIES_THAT_HAVE_REQUISITIONS = [
  LAB_COMPANY_KEY.ALLETESS,
  LAB_COMPANY_KEY.BOSTON_HEART,
  LAB_COMPANY_KEY.DSL,
  LAB_COMPANY_KEY.US_BIOTEK,
  LAB_COMPANY_KEY.SPECTRA_CELL,
  LAB_COMPANY_KEY.PRECISION_ANALYTICAL,
  LAB_COMPANY_KEY.DOCTORS_DATA,
]

export const LAB_COMPANIES_WE_GENERATE_REQUISITIONS_FOR_IOK_ONLY = [
  LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS,
]

export const LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS = [
  LAB_COMPANY_KEY.AERODIAGNOSTICS,
  LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS,
  LAB_COMPANY_KEY.BOSTON_HEART,
  LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS,
  LAB_COMPANY_KEY.LABSFIRST_QUEST,
  LAB_COMPANY_KEY.CELL_SCIENCE_SYSTEMS,
]

export const OTHER_PRACTITIONER_TYPE = "Other"

export const LAB_COMPANY_ALERT_TYPES = {
  SHIPPING_DELAY: "shipping_delay",
  RESULTS_DELAY: "results_delay",
}

export const ORDER_TEST_WARNINGS = {
  [OrderTestWarningTypes.SHIPPING_DELAY]: {
    icon: AlertTriangleRed,
  },
  [OrderTestWarningTypes.AGE_RESTRICTION]: {
    icon: UserBlue,
  },
}

export const SCARLET_BLOOD_DRAW_PRICE = "$25"
export const SCARLET_LEARN_MORE_URL =
  "https://support.rupahealth.com/article/1397-bioreference"

export const SUPPORT_EMAIL = "support@rupahealth.com"

export const CHAMELEON_TOUR_ID = "61f5a6629fdb520011c5b90d"
export const CHAMELEON_GROUP_CODE_TOUR_ID = "6215db716f8ea1001cf24656"

export const BLOCKED_OVERRIDE_LAB_COMPANIES = [
  LAB_COMPANY_KEY.DSL,
  LAB_COMPANY_KEY.BIOREFERENCE,
]

export const ENABLED_OVERRIDE_LAB_COMPANIES = [
  LAB_COMPANY_KEY.ALLETESS,
  LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS,
  LAB_COMPANY_KEY.AYUMETRIX,
  LAB_COMPANY_KEY.BOSTON_HEART,
  LAB_COMPANY_KEY.CYREX,
  LAB_COMPANY_KEY.DOCTORS_DATA,
  LAB_COMPANY_KEY.GENOVA,
  LAB_COMPANY_KEY.MOSAIC_DIAGNOSTICS,
  LAB_COMPANY_KEY.IGENEX,
  LAB_COMPANY_KEY.INFINITE_ALLERGY,
  LAB_COMPANY_KEY.KBMO,
  LAB_COMPANY_KEY.PRECISION_ANALYTICAL,
  LAB_COMPANY_KEY.QUICKSILVER,
  LAB_COMPANY_KEY.SPECTRA_CELL,
  LAB_COMPANY_KEY.US_BIOTEK,
  LAB_COMPANY_KEY.VIBRANT_AMERICA,
  LAB_COMPANY_KEY.VIBRANT_WELLNESS,
  LAB_COMPANY_KEY.ZRT,
]

export const CERBO_CAPITALIZED = "CERBO"
export const PRACTICEQ_CAPITALIZED = "PRACTICEQ"
export const PRACTICE_BETTER_CAPITALIZED = "PRACTICE_BETTER"
export const OPTIMANTRA_CAPITALIZED = "OPTIMANTRA"
export const ELATION_HEALTH_CAPITALIZED = "ELATION_HEALTH"
export const BODYSITE_CAPITALIZED = "BODYSITE"
export const OPTIMAL_DX_CAPITALIZED = "OPTIMAL_DX"
export const MY_HOLISTIC_OFFICE_CAPITALIZED = "MY_HOLISTIC_OFFICE"
export const KALIX_CAPITALIZED = "KALIX"
export const PRACTICEQ_TITLE_CASE = "practiceQ"
export const CERBO_TITLE_CASE = "Cerbo"
export const OPTIMANTRA_TITLE_CASE = "OptiMantra"
export const PRACTICE_BETTER_TITLE_CASE = "Practice Better"
export const BODYSITE_TITLE_CASE = "BodySite"
export const OPTIMAL_DX_TITLE_CASE = "Optimal DX"
export const ELATION_HEALTH_TITLE_CASE = "Elation Health"
export const HEALTHIE_TITLE_CASE = "Healthie"
export const HEALTHIE_CAPITALIZED = "HEALTHIE"
export const MY_HOLISTIC_OFFICE_TITLE_CASE = "Holistic Office"
export const KALIX_TITLE_CASE = "Kalix"

export const CHECKOUT_WARNINGS = {
  [CheckoutWarningTypes.MULTIPLE_BLOOD_LABS]: {
    title: "Blood Tests From Multiple Lab Companies",
    warning:
      "You are including blood tests from multiple lab companies. Your patient will need separate blood draws for each and get different results PDFs for these labs.",
    severity: CheckoutWarningSeverities.WARNING,
  },

  [CheckoutWarningTypes.ORDER_WITH_LTA_QUEST_TEST]: {
    title: "Quest tests cannot be completed in NY, NJ, RI, CT or MA",
    warning:
      "You are including tests from Quest Diagnostics which cannot be completed in NY, NJ, RI, CT or MA. Please remove these tests from your order if your patient is located in one of these 5 states.",
    severity: CheckoutWarningSeverities.ERROR,
  },

  [CheckoutWarningTypes.MIXED_AML_ORDER]: {
    title: "Tests from Access Medical and Labcorp",
    warning:
      "You are including tests from both Access Medical Labs and Labcorp. Please remove tests from one of these lab companies to continue.",
    severity: CheckoutWarningSeverities.ERROR,
  },
  [CheckoutWarningTypes.MIXED_AML_ORDER_LABSHOP]: {
    title: "Tests from Access Medical and Labcorp",
    warning:
      "This order contains blood tests from two different labs, and would require two separate blood draws. Please remove tests from one of these lab companies to continue.",
    severity: CheckoutWarningSeverities.ERROR,
  },
  [CheckoutWarningTypes.DUPLICATE_BIOMARKERS]: {
    title: "Duplicate Biomarkers",
    warning:
      "Heads up -- You have duplicate biomarkers in the cart from the following lab tests:",
    severity: CheckoutWarningSeverities.WARNING,
  },
  [CheckoutWarningTypes.HEALTH_GORILLA_LABCORP_AND_MEDICARE]: {
    title: "Labcorp and Medicare Order",
    warning:
      "You cannot mix Labcorp insurance orders with insurance orders from other companies at this time. Please place separate orders for the tests.",
    severity: CheckoutWarningSeverities.ERROR,
  },
  [CheckoutWarningTypes.FIRST_LABCORP_ORDER]: {
    title: "First Labcorp Order",
    warning:
      "Your first Labcorp requisition form will take a few hours to generate for initial registration. Future orders are instant post-payment! We'll email your patient the form once it's ready for their draw.",
    severity: CheckoutWarningSeverities.HIGHLIGHT,
  },
  [CheckoutWarningTypes.LABCORP_PHYSICIAN_SERVICES]: {
    title: "Labcorp Requisition via Physician Services",
    warning:
      "The Labcorp requisition will take a few hours to generate, pending Authorizing Physician approval. We'll email your client the form once it's ready for their blood draw.",
    severity: CheckoutWarningSeverities.HIGHLIGHT,
  },
  [CheckoutWarningTypes.AML_MIXED_DROPSHIP_IOK]: {
    title: "Mixed Dropship and IOK Order",
    warning:
      "You are including tests drawn in-office and shipped tests from Access Medical Labs. Please place separate orders for these in-office and shipped tests.",
    severity: CheckoutWarningSeverities.ERROR,
  },
}

export const ORDER_EVENT_CATEGORIES = {
  SHIPPING_AND_PAYMENT: "SHIPPING_AND_PAYMENT",
  SAMPLE_COLLECTION_AND_INSTRUCTIONS: "SAMPLE_COLLECTION_AND_INSTRUCTIONS",
  RESULTS_IN: "RESULTS_IN",
}

// Deprecated.  TODO: remove
export const IN_OFFICE_KIT_LAB_COMPANIES = {
  ACCESS: LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS,
  ALLETESS: LAB_COMPANY_KEY.ALLETESS,
  BOSTON_HEART: LAB_COMPANY_KEY.BOSTON_HEART,
  DIAGNOSTIC_SOLUTIONS: LAB_COMPANY_KEY.DSL,
  DUTCH: LAB_COMPANY_KEY.PRECISION_ANALYTICAL,
  US_BIOTEK: LAB_COMPANY_KEY.US_BIOTEK,
}

export const LAB_COMPANIES_REQUIRING_IOK_ACTIVATION_ID = [
  LAB_COMPANY_KEY.MOSAIC_DIAGNOSTICS,
  LAB_COMPANY_KEY.GENOVA,

  // Coming soon ⬇️
  // LAB_COMPANY_KEY.ZRT,
]

export const LAB_COMPANY_ACTIVATION_ID_NAME_MAP = {
  [LAB_COMPANY_KEY.GENOVA]: "Activation Number",
  [LAB_COMPANY_KEY.MOSAIC_DIAGNOSTICS]: "Kit ID",
}

export const LABS_REQUIRING_CONFIRMED_ACCOUNT_FOR_IOK_ORDERS = [
  LAB_COMPANY_KEY.DSL,
  LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS,
]

export const LABS_REQUIRING_CONFIRMED_ACCOUNT_WITH_ID_FOR_IOK_ORDERS = [
  LAB_COMPANY_KEY.BOSTON_HEART,
  LAB_COMPANY_KEY.DOCTORS_DATA,
  LAB_COMPANY_KEY.MOSAIC_DIAGNOSTICS,
  LAB_COMPANY_KEY.GENOVA,
]

// Actions for CTR analysis.
// DETAILS == details button clicked in lab test search
// ADD_TO_CART == add to cart button clicked in lab test search OR details modal
export const LAB_TEST_SEARCH_CTR_ACTION = {
  DETAILS: "details",
  ADD_TO_CART: "add_to_cart",
}

export const ORDER_TERMINOLOGY = {
  ORDER: "Order",
  CART: "Cart",
}

export const physAuthTooltipText = `The ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} fee includes the cost for an
independent physician to place this order and screen for critical results. For a full results review, please
follow up with your primary care physician or practitioner. The ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} fee is non refundable
after patient checkout.`

export const additionalLineItemInfo = {
  [ORDER_LINE_ITEM_TYPE.VIBRANT_MINIMUM]: {
    tooltipMessage:
      "Vibrant America and Vibrant Wellness have a small order fee for orders below $200. This also applies when ordering directly from Vibrant.",
    icon: AlertTriangleIcon,
  },
  [ORDER_LINE_ITEM_TYPE.SCARLET_BLOOD_DRAW_FEE]: {
    tooltipMessage:
      "Blood draws for BioReference tests must be done through their mobile phlebotomy service, Scarlet.\n\n**Things to know:**\n\n- Scarlet is only available in CA, TX, FL\n- Scarlet will reach out to your patient to coordinate their blood draw at any serviceable address by Scarlet\n- Scarlet phlebotomists will bring the kit and requisition form to your patient",
    icon: BloodIcon,
  },
  [ORDER_LINE_ITEM_TYPE.LABCORP_BLOOD_DRAW_FEE]: {
    tooltipMessage:
      'The panel must be collected at a Labcorp Location. We\'ll send your patient their requisition form and instructions to find a Labcorp site. You can check out Labcorp locations <a href="https://www.labcorp.com/labs-and-appointments" target="_blank">here</a>.',
    icon: BloodIcon,
  },
  [ORDER_LINE_ITEM_TYPE.QUEST_BLOOD_DRAW_FEE]: {
    tooltipMessage:
      'The panel must be collected at a Quest Location. We\'ll send your patient their requisition form and instructions to find a Quest site. You can check out Quest locations <a href="https://www.questdiagnostics.com/locations/search" target="_blank">here</a>.',
    icon: BloodIcon,
  },
  [ORDER_LINE_ITEM_TYPE.QUEST_SERVICE_FEE]: {
    tooltipMessage:
      "Your Quest order is going through Physician Services. The Quest Service fee includes the costs for an independent clinician to review your information, determine whether testing is appropriate, and provide follow-up on your results, plus fees for other administrative and operational support.  The Quest Service fee is Non-Refundable after the order has been placed or if the order is cancelled.",
    icon: BloodIcon,
  },
  [ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_VENDOR_FEE]: {
    tooltipMessage: physAuthTooltipText,
    icon: <RupaLogoIcon width="19" height="19" />,
  },
}

export const PHYSICIAN_SERVICES_FEE_AMOUNT = 25

export const STATES_WITH_ORDERING_RIGHTS = ["NY", "NJ", "RI"]

export const BUNDLE_TYPES = {
  BASIC: "BASIC",
  PANEL: "PANEL",
}

export const PHYSICIAN_SERVICES_ENABLE_METHODS = {
  OPT_IN: "opt_in",
  APPLICATION_AUTO_APPROVED: "application_auto_approved",
  APPLICATION_APPROVED: "application_approved",
}

export const ORDERING_RIGHTS_ACTIONS = {
  ADD_TO_CART: "ADD_TO_CART",
  ADD_TO_BUNDLE: "ADD_TO_BUNDLE",
  START_ORDER_WITH: "START_ORDER_WITH",
  ADD_TO_LABSHOP: "ADD_TO_LABSHOP",
  COMPARE: "COMPARE",
  CATALOG: "CATALOG",
}

export const ORDERING_ISSUE_SOURCE_TYPES = {
  LAB_TEST: "LAB_TEST",
  LAB_COMPANY: "LAB_COMPANY",
}
export type OrderingIssueSourceType =
  `${keyof typeof ORDERING_ISSUE_SOURCE_TYPES}`

export const ORDERING_ISSUE_LEVELS = {
  WARNING: "WARNING",
  ERROR: "ERROR",
  IGNORE: "IGNORE",
}
export type OrderingIssueLevel = `${keyof typeof ORDERING_ISSUE_LEVELS}`

export const ORDERING_ISSUE_TYPES = {
  ADD_ON: "ADD_ON",
  CLINIC_LOCATION: "CLINIC_LOCATION",
  GENERAL_AVAILABILITY: "GENERAL_AVAILABILITY",
  NPI: "NPI",
  PHYSICIAN_SERVICES: "PHYSICIAN_SERVICES",
  PRIMARY_PRACTITIONER_TYPE: "PRIMARY_PRACTITIONER_TYPE",
  SHIPPING_STATE: "SHIPPING_STATE",
}
export type OrderingIssueType = `${keyof typeof ORDERING_ISSUE_TYPES}`

export const ORDERING_ISSUE_KEYS = {
  LAB_TEST_NOT_AVAILABLE: "LAB_TEST_NOT_AVAILABLE",
  LAB_TEST_NOT_AVAILABLE_FOR_PHYSICIAN_SERVICES:
    "LAB_TEST_NOT_AVAILABLE_FOR_PHYSICIAN_SERVICES",
  PHYSICIAN_SERVICES_NOT_ENABLED: "PHYSICIAN_SERVICES_NOT_ENABLED",
  LAB_TEST_SHIPPING_STATE_NOT_ALLOWED: "LAB_TEST_SHIPPING_STATE_NOT_ALLOWED",
  LAB_TEST_SHIPPING_STATE_ALLOWED_WITH_WAIVER:
    "LAB_TEST_SHIPPING_STATE_ALLOWED_WITH_WAIVER",
  LAB_TEST_SAMPLE_COLLECTION_STATE_NOT_ALLOWED:
    "LAB_TEST_SAMPLE_COLLECTION_STATE_NOT_ALLOWED",
  LAB_COMPANY_PHYSICIAN_SERVICES_CLINIC_STATE_NOT_ALLOWED:
    "LAB_COMPANY_PHYSICIAN_SERVICES_CLINIC_STATE_NOT_ALLOWED",
  LAB_TEST_CLINIC_STATE_NOT_ALLOWED: "LAB_TEST_CLINIC_STATE_NOT_ALLOWED",
  LAB_COMPANY_CLINIC_COUNTRY_NOT_ALLOWED:
    "LAB_COMPANY_CLINIC_COUNTRY_NOT_ALLOWED",
  LAB_COMPANY_CLINIC_STATE_NOT_ALLOWED: "LAB_COMPANY_CLINIC_STATE_NOT_ALLOWED",
  LAB_TEST_IS_ADD_ON: "LAB_TEST_IS_ADD_ON",
  ADD_ON_MISSING_PARENT: "ADD_ON_MISSING_PARENT",
  LAB_COMPANY_NPI_REQUIRED: "LAB_COMPANY_NPI_REQUIRED",
  LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_CLINIC_STATE_NOT_ALLOWED_OVERRIDE_ALLOWED:
    "LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_CLINIC_STATE_NOT_ALLOWED_OVERRIDE_ALLOWED",
  LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_CLINIC_STATE_NOT_ALLOWED:
    "LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_CLINIC_STATE_NOT_ALLOWED",
  LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_NOT_ALLOWED_OVERRIDE_ALLOWED:
    "LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_NOT_ALLOWED_OVERRIDE_ALLOWED",
  LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_NOT_ALLOWED:
    "LAB_COMPANY_PRIMARY_PRACTITIONER_TYPE_NOT_ALLOWED",
}
export type OrderingIssueKey = `${keyof typeof ORDERING_ISSUE_KEYS}`

export const PRACTITIONER_SETTINGS = {
  DONT_SHOW_PHYSICIAN_SERVICES_ORDER_CONFIRMATION_MODAL:
    "dont_show_physician_services_order_confirmation_modal",
}

export const PHYSICIAN_SERVICES_INTENTIONS = {
  SELF_CREDENTIAL: "self_credential",
  PHYSICIAN_SERVICES_AND_SELF: "physician_services_and_self",
  PHYSICIAN_SERVICES: "physician_services",
}

import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { FormProvider, UseFormReturn } from "react-hook-form"

import MuiDialogContent from "@material-ui/core/DialogContent"

import { API } from "app/api"
import FormFooter from "app/main/in-office-kits/components/FormFooter"
import FormHeader from "app/main/in-office-kits/components/FormHeader"
import {
  trackIOKSuppliesOrderFormConfirming,
  trackIOKSuppliesOrderFormSubmit,
} from "app/services/segment"
import { showMessage } from "app/store/actions/fuse"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { Practitioner } from "app/types"
import { handleApiSuccess } from "app/utils"

import { IOK_PRODUCT_CATEGORY_TYPE_LIMITS } from "../const"
import {
  InOfficeKitLabCompanyType,
  InOfficeKitOrder,
  InOfficeKitProduct,
  InOfficeKitProductInventoryConfig,
} from "../types"
import { InOfficeKitOrderFormInputs } from "./StartOrderModal"

export interface CategoryValidation {
  name: string
  order_max: number
  quantity: number
}

interface ConfirmOrderFormProps {
  onCloseModal: () => void
  selectedLabCompany: InOfficeKitLabCompanyType
  iokProducts: InOfficeKitProduct[]
  selectedPractitioner: Practitioner
  iokInventoryConfigs: InOfficeKitProductInventoryConfig[]
  orderFormValues?: Object
  setOrderFormValues: (values: Object | undefined) => void
  onSuccess?: () => void
  setIsConfirming: (isConfirming: boolean) => void
  methods: UseFormReturn<InOfficeKitOrderFormInputs>
}

export const ConfirmOrderForm = ({
  onCloseModal,
  selectedLabCompany,
  iokProducts,
  selectedPractitioner,
  iokInventoryConfigs,
  orderFormValues,
  setOrderFormValues,
  onSuccess,
  setIsConfirming,
  methods,
}: ConfirmOrderFormProps) => {
  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [isOverValidOrderQuantities, setIsOverValidOrderQuantities] =
    useState<boolean>(false)

  const onSubmit = async (data: InOfficeKitOrderFormInputs) => {
    setIsSubmitting(true)

    const orderData = {
      data: {
        type: "in_office_kit_order",
        attributes: {
          street: data.streetAddress1,
          street_2: data.streetAddress2,
          city: data.city,
          state: data.state,
          zipcode: data.zipCode,
          in_office_kit_lab_company: selectedLabCompany?.id,
          signing_practitioner: selectedPractitioner?.id,
        },
      },
    }

    let productPayload = {}
    Object.keys(data).forEach((key) => {
      if (key.startsWith("product:")) {
        if (data[key]) {
          productPayload[key.replace("product:", "")] = data[key]
        }
      }
    })

    // TODO: the SWR way
    try {
      const response = await resourceRequest<
        ResourceResponse<InOfficeKitOrder>
      >({
        url: "/in_office_kit_order/",
        data: orderData,
        method: "POST",
      })
      const orderId = (response as any).data.id
      const productList: {
        in_office_kit_product: string
        quantity: Number
      }[] = []
      Object.keys(data).forEach((key) => {
        if (key.startsWith("product:")) {
          if (data[key] === "") {
            // Skip empty products because we won't place an order for 0 items
            return
          }
          // Cast quantity to number
          const quantity = Number(data[key])
          productList.push({
            in_office_kit_product: key.replace("product:", ""),
            quantity: quantity,
          })
        }
      })

      productPayload = { ordered_products: productList }

      // TODO: normalize, properly type this
      // Also not sure about chaining together API calls like this

      await API.InOfficeKitOrderedProducts.create(orderId, productPayload)
      trackIOKSuppliesOrderFormSubmit(
        selectedPractitioner.id,
        selectedLabCompany.id,
        !!(categoriesOverMax?.length > 0),
        productsOnOrder
      )

      setIsSubmitting(false)
      onCloseModal()
      dispatch(handleApiSuccess("Order successfully created!"))
      // TODO: once we normalize, pass created objects to onSuccess so we can
      // update the cache rather than re-fetching
      onSuccess?.()
    } catch (error: any) {
      dispatch(
        showMessage({
          message: error.response.data.errors,
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        })
      )
      setIsSubmitting(false)
    }
  }

  const values = useMemo(() => methods.getValues(), [])

  const productsOnOrder = useMemo(() => {
    return Object.keys(values)
      .filter((key) => key.startsWith("product:") && values[key])
      .map((key) => {
        const product = iokProducts.find(
          (product) => product.id === key.replace("product:", "")
        )
        if (!product) {
          return null
        }
        return {
          product,
          quantity: Number(values[key]),
        }
      })
      .filter(Boolean) as {
      product: InOfficeKitProduct
      quantity: number
      category: string
    }[]
  }, [values])

  const { categoryQuantities, categoryTypeQuantities } = useMemo(() => {
    const categoryQuantities: Record<string, CategoryValidation> = {}
    productsOnOrder.forEach(({ product, quantity }) => {
      const category = product.attributes.in_office_kit_product_category || {
        id: "no-category",
        name: "Other",
        max_order_quantity: null,
      }
      if (!categoryQuantities[category.id]) {
        categoryQuantities[category.id] = {
          name: category.name,
          quantity: 0,
          order_max:
            iokInventoryConfigs.find(
              (config) =>
                config.relationships?.in_office_kit_product_category?.data
                  ?.id === category.id
            )?.attributes.order_max ||
            category.max_order_quantity ||
            Infinity,
        }
      }
      categoryQuantities[category.id].quantity += quantity
    })

    const categoryTypeQuantities: Record<string, CategoryValidation> = {}
    productsOnOrder.forEach(({ product, quantity }) => {
      const categoryType =
        product.attributes.in_office_kit_product_category?.category_type || ""
      if (!categoryTypeQuantities[categoryType]) {
        categoryTypeQuantities[categoryType] = {
          name: categoryType
            .replace(/_/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          quantity: 0,
          order_max:
            iokInventoryConfigs.find(
              (config) =>
                config.attributes.in_office_kit_product_category_type ===
                categoryType
            )?.attributes.order_max ||
            IOK_PRODUCT_CATEGORY_TYPE_LIMITS[categoryType] ||
            Infinity,
        }
      }
      categoryTypeQuantities[categoryType].quantity += quantity
    })
    return { categoryQuantities, categoryTypeQuantities }
  }, [productsOnOrder, iokInventoryConfigs])

  const categoriesOverMax = useMemo(() => {
    return [
      ...Object.values(categoryQuantities).filter(
        (category) =>
          category.quantity &&
          category.order_max &&
          category.quantity > category.order_max
      ),
      ...Object.values(categoryTypeQuantities).filter(
        (categoryType) =>
          categoryType.quantity &&
          categoryType.order_max &&
          categoryType.quantity > categoryType.order_max
      ),
    ].filter(Boolean) as CategoryValidation[]
  }, [categoryQuantities, categoryTypeQuantities])

  useEffect(() => {
    if (categoriesOverMax.length) {
      setIsOverValidOrderQuantities(true)
    }
  }, [categoriesOverMax])

  // Set order form values for going back to the previous step
  useEffect(() => {
    const productValues = {}
    Object.keys(values).forEach((key) => {
      if (key.startsWith("product:")) {
        if (values[key]) {
          productValues[key.replace("product:", "")] = values[key]
        } else if (orderFormValues) {
          delete orderFormValues[key.replace("product:", "")]
        }
      }
    })
    const originalValues = orderFormValues || {}
    setOrderFormValues({
      ...originalValues,
      ...productValues,
    })
    trackIOKSuppliesOrderFormConfirming(
      selectedPractitioner.id,
      selectedLabCompany.id,
      !!(categoriesOverMax?.length > 0),
      productsOnOrder
    )
  }, [])

  return (
    <>
      <FormHeader headerText="Confirm Your Order" onClose={onCloseModal} />
      <MuiDialogContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-5">
              <div className="flex flex-col items-start justify-center w-full h-full gap-4 bg-white rounded-lg shadow px-6 py-5 mt-2.5">
                <div className="flex flex-col gap-[14px]">
                  <div className="text-sm font-semibold">Shipping Address</div>
                  <div className="flex flex-col gap-1">
                    <p>{values.streetAddress1}</p>
                    {values.streetAddress2 && <p>{values.streetAddress2}</p>}
                    <p>{values.city + ", " + values.state}</p>
                    <p>{values.zipCode}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-center w-full h-full gap-4 bg-white rounded-lg shadow px-6 py-5 mt-2.5">
                <div className="flex flex-col gap-[14px] w-full">
                  <div className="text-sm font-semibold">Supplies</div>
                  {productsOnOrder.map(
                    ({ product, quantity }, index, array) => {
                      return (
                        <div key={product.id}>
                          <div className="flex flex-row justify-between">
                            <p>{product.attributes.name}</p>
                            <p className="font-semibold">{quantity}</p>
                          </div>
                          {index < array.length - 1 && (
                            <div className="w-full h-[1px] bg-gray-100"></div>
                          )}
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </MuiDialogContent>

      <FormFooter
        onSubmit={methods.handleSubmit(onSubmit)}
        loading={isSubmitting}
        buttonText="Submit Order"
        canOrder={true}
        secondaryButtonText="Back"
        onSecondaryClick={() => {
          setIsOverValidOrderQuantities(false)
          setIsConfirming(false)
        }}
        selectedLabCompany={selectedLabCompany}
        categoriesOverMax={categoriesOverMax}
        isOverValidOrderQuantities={isOverValidOrderQuantities}
        setIsOverValidOrderQuantities={setIsOverValidOrderQuantities}
      />
    </>
  )
}

import { useState } from "react"
import { useDispatch } from "react-redux"

import { useLocation } from "react-router-dom"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { Dialog, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

import ModalWrapper from "app/components/modals/PhysicianServicesOptInModal/ModalWrapper"
import useConfirmationModal from "app/components/modals/generic/hooks/use-confirmation-modal"
import {
  PHYSICIAN_SERVICES_ENABLE_METHODS,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import { trackPhysicianServicesOptInClose } from "app/services/segment"
import { getPractitioner, updatePersonalSettings } from "app/store/actions"
import { navy } from "app/theme"
import { handleApiSuccess } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

import AllSetStep from "./AllSetStep"
import ApplyToAccountStep from "./ApplyToAccountStep"
import HowItWorksStep from "./HowItWorksStep"
import WhatIsStep from "./WhatIsStep"

export interface PhysicianServicesOptInModalProps {
  onClose?: (finishedApplication?: boolean) => void
  defaultPhysAuthOn?: boolean
  returnToDescriptor?: string
  updatedTerms?: boolean
  disableClose?: boolean
  practitionerHasPhysicianServicesLabshop?: boolean
}

const useStyles = makeAppStyles((theme) => ({
  closeButton: {
    color: navy,
    position: "absolute" as "absolute",
    top: 10,
    right: 10,
    width: 40,
    height: 40,
  },
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
    width: "800px",
    [theme.breakpoints.down("sm")]: {
      width: "96%",
      height: "100%",
      margin: 0,
    },
  },
}))

const STEPS = {
  WHAT_IS: "WHAT_IS",
  HOW_IT_WORKS: "HOW_IT_WORKS",
  APPLY_TO_ACCOUNT: "APPLY_TO_ACCOUNT",
  ALL_SET: "ALL_SET",
}

const PhysicianServicesOptInModal = ({
  onClose,
  defaultPhysAuthOn = true,
  returnToDescriptor = "Settings",
  updatedTerms = false,
  disableClose = false,
  practitionerHasPhysicianServicesLabshop = false,
}: PhysicianServicesOptInModalProps) => {
  const modal = useModal()
  const muiDialogControl = muiDialog(modal)
  const dispatch = useDispatch()
  const confirmationModal = useConfirmationModal()
  const location = useLocation()

  const onEnable = () => {
    dispatch(
      updatePersonalSettings({
        vendor_physician_authorization_enabled: true,
        physician_services_enable_method:
          PHYSICIAN_SERVICES_ENABLE_METHODS.OPT_IN,
        default_physician_authorization: defaultPhysAuthOn,
      })
    )
  }

  const handleClose = () => {
    if (disableClose) return

    trackPhysicianServicesOptInClose({
      step: step,
      location: location.pathname,
      showing_updated_terms_copy: updatedTerms,
      practitioner_has_physician_services_labshop:
        practitionerHasPhysicianServicesLabshop,
    })
    onClose?.()
  }

  const disablePhysicianServices = () => {
    dispatch(
      updatePersonalSettings({
        vendor_physician_authorization_enabled: false,
        physician_services_enable_method: "",
      })
    )
    dispatch(
      handleApiSuccess(
        `${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} is disabled. You can always turn it back on in settings!`
      )
    )
    handleClose()
  }

  const onDisable = () => {
    if (practitionerHasPhysicianServicesLabshop) {
      confirmationModal.show({
        title: "Are you sure?",
        message: `Removing ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} will turn off all active Labshops. Are you sure you’d like to do this?`,
        backButtonTitle: "Go back",
        confirmButtonTitle: `Turn Off ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}`,
        confirmButtonColor: "destructiveSecondary",
        onClose: confirmationModal.remove,
        loading: false,
        handleConfirm: () => {
          confirmationModal.remove()
          disablePhysicianServices()
        },
      })
    } else {
      disablePhysicianServices()
    }
  }

  const classes = useStyles()

  const [step, setStep] = useState(
    updatedTerms ? STEPS.APPLY_TO_ACCOUNT : STEPS.WHAT_IS
  )

  const content = (() => {
    switch (step) {
      case STEPS.WHAT_IS:
        return (
          <WhatIsStep
            onSuccess={() => {
              setStep(STEPS.HOW_IT_WORKS)
            }}
          />
        )
      case STEPS.HOW_IT_WORKS:
        return (
          <HowItWorksStep
            onGoBack={() => {
              setStep(STEPS.WHAT_IS)
            }}
            onSuccess={() => {
              setStep(STEPS.APPLY_TO_ACCOUNT)
            }}
          />
        )
      case STEPS.APPLY_TO_ACCOUNT:
        return (
          <ApplyToAccountStep
            updatedTerms={updatedTerms}
            onGoBack={() => {
              setStep(STEPS.HOW_IT_WORKS)
            }}
            onSuccess={() => {
              onEnable()
              setStep(STEPS.ALL_SET)
            }}
            onDisable={onDisable}
          />
        )
      case STEPS.ALL_SET:
        return (
          <AllSetStep
            onSuccess={() => {
              dispatch(getPractitioner())
              // Don't track trackPhysicianServicesOptInClose because this is the last step of modal
              onClose?.(true)
            }}
            returnToDescriptor={returnToDescriptor}
          />
        )
      default:
        return <></>
    }
  })()

  return (
    <Dialog
      aria-labelledby="physician-services-opt-in-modal"
      {...muiDialogControl}
      onClose={handleClose}
      className="fs-unmask"
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth="md"
    >
      {!disableClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          key="close-button"
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      ) : (
        <></>
      )}

      <ModalWrapper content={content} />
    </Dialog>
  )
}

const Modal = NiceModal.create(PhysicianServicesOptInModal)

export default Modal

import React from "react"

import { PDFDocument } from "pdf-lib"

import { CircularProgress } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import { ReactComponent as PrintIcon } from "app/assets/images/print-blue.svg"
import bloodLabDashboardFooterGenerator from "app/hooks/use-pdf-generator/footer-generation/bloodLabDashboardFooterGenerator"
import usePDFGenerator from "app/hooks/use-pdf-generator/use-pdf-generator"
import { BloodLabDashboardPdf } from "app/main/blood-lab-dashboards/BloodLabDashboardsPdfExport/BloodLabDashboardPdf"
import { trackBloodLabDashboardPdfGeneration } from "app/services/segment"

import { ResultsPdfPayload } from "./use-bundle-results-pdf-payload"

// As the pdf gets larger sometimes we have issues with pdf generation failing on certain platforms (iOS specifically)
// We can use the following table of page estimates to scale the pdf down for larger reports
const SCALE_TABLE = {
  3: 4,
  7: 3,
  11: 2.5,
  20: 2,
}

// Utility function to get the appropriate scale value
const getScaleValue = (length: number) => {
  const keys = Object.keys(SCALE_TABLE)
    .map(Number)
    .sort((a, b) => a - b)
  for (let i = 0; i < keys.length; i++) {
    if (length <= keys[i]) {
      return SCALE_TABLE[keys[i]]
    }
  }
  return SCALE_TABLE[keys[keys.length - 1]] // return the largest value if no match found
}

interface ResultsPdfPrintButtonProps {
  payload: ResultsPdfPayload
  wide?: boolean
}

function getCurrentDate() {
  const today = new Date()
  const month = String(today.getMonth() + 1).padStart(2, "0")
  const day = String(today.getDate()).padStart(2, "0")
  const year = today.getFullYear()
  return `${month}_${day}_${year}`
}

const ResultsPdfPrintButton: React.FC<ResultsPdfPrintButtonProps> = ({
  payload,
  wide,
}) => {
  const snapshotId = payload.snapshotId || ""

  const footerGenerator = (pdfDoc: PDFDocument) =>
    bloodLabDashboardFooterGenerator(
      pdfDoc,
      payload.descriptionsByText,
      payload.rangesByText
    )

  const fileName = payload.patient?.attributes.full_name
    ? `${payload.patient?.attributes.full_name.replace(
        " ",
        "_"
      )}_${getCurrentDate()}.pdf`
    : `Patient_Results_${getCurrentDate()}.pdf`

  const totalBloodLabResults = payload.bloodReportResultsByLabTest.reduce(
    (total, labTest) => {
      return total + labTest.bloodReportResults.length
    },
    0
  )

  // About 13 biomarkers fit on a page
  // Estimating 5 per out of range biomarker
  const pageEstimate = Math.floor(
    totalBloodLabResults / 13 + payload.outOfRangeBloodReportResults.length / 5
  )

  const scaleValue = getScaleValue(pageEstimate)

  const { RenderContent, isReady, generatePDF } = usePDFGenerator(
    <BloodLabDashboardPdf payload={payload} />,
    fileName,
    footerGenerator,
    {
      margins: [10, 0, 40, 0],
      scale: scaleValue,
    },
    {
      pdf_type: "blood_lab_dashboard",
      snapshot_id: snapshotId,
      descriptions_by: payload.descriptionsByText,
      ranges_by: payload.rangesByText,
    }
  )

  const isDisabled = !isReady || totalBloodLabResults < 1 || payload.isLoading

  return (
    <div className="flex align-middle items-center">
      <RenderContent />
      <button
        className={cn(
          "text-primary font-semibold flex align-middle items-center",
          wide ? "gap-2.5" : "gap-1"
        )}
        onClick={() => {
          trackBloodLabDashboardPdfGeneration({
            snapshot_id: snapshotId,
            descriptions_by: payload.descriptionsByText,
            ranges_by: payload.rangesByText,
          })
          generatePDF()
        }}
        disabled={isDisabled}
      >
        {payload.isLoading || !isReady ? (
          <CircularProgress size={15} />
        ) : (
          <PrintIcon />
        )}{" "}
        Print
      </button>
    </div>
  )
}

export default ResultsPdfPrintButton

import { REQUISITION_STATUS_LAB_COMPANY_KEYS } from "app/constants"
import { ORDERED_TEST_STATUS, ORDER_PAYER } from "app/constants.typed"
import {
  getRequisitionLabCompanyName,
  getRequisitionStatusMetadata,
} from "app/utils/order-status-utils"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({ order, labCompany }: StatusConfigurationProps) => {
  let title = <>{"Invoice Paid"}</>

  let requisitionLabCompanyName = ""
  if (labCompany) {
    requisitionLabCompanyName = getRequisitionLabCompanyName(labCompany.key)

    const metadata = getRequisitionStatusMetadata(
      labCompany.key,
      requisitionLabCompanyName,
      order.payer,
      order.date_patient_checkout || undefined
    )

    title = <>{metadata.description}</>
  }

  return title
}

const progressPercentage = ({ order }: StatusConfigurationProps) => {
  if (
    order.payer === ORDER_PAYER.PRACTITIONER &&
    !order.date_patient_checkout
  ) {
    return PROGRESS_PERCENTAGES.AWAITING_PATIENT_CHECKOUT
  }
  return PROGRESS_PERCENTAGES.PAID
}

const SubTitle = ({ labCompany }: StatusConfigurationProps) => {
  let subTitle = <></>

  let requisitionLabCompanyName = ""
  if (labCompany) {
    requisitionLabCompanyName = getRequisitionLabCompanyName(labCompany.key)
  }

  if (
    labCompany &&
    REQUISITION_STATUS_LAB_COMPANY_KEYS.includes(labCompany.key)
  ) {
    subTitle = (
      <>
        {`Please do not go to a ${requisitionLabCompanyName} location until you have your requisition form.`}
      </>
    )
  }

  return subTitle
}

const paidStatusConfiguration: StatusConfiguration<StatusConfigurationProps> = {
  when: { orderedTestStatus: ORDERED_TEST_STATUS.PAID },
  progressPercentage: progressPercentage,
  title: Title,
  subTitle: SubTitle,
}

export default paidStatusConfiguration

import { useState } from "react"

import { ClipboardModal } from "app/components/modals/ClipboardModal/ClipboardModal"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  linkText: {
    color: theme.palette.primary.main,
  },
}))

export const IOKOnboardingModal = ({ showModal, onExit, onContinue }) => {
  const classes = useStyles()
  const modalTitle = "Stock In-Office Kit Supplies Through Rupa"
  const sections = [
    {
      title: "How it Works",
      items: [
        "You’ll be able to order bulk inventory in a new In-Office Kit Supplies Tab",
        "When you’re ready to use your kits on patients, you must activate the kit on Rupa by placing an order (same way as drop ship!)",
        <div>
          When placing the order, select “In Office Delivery Method” in
          Checkout.&nbsp;
          <a
            className={classes.linkText}
            href="https://www.rupahealth.com/videos/order-supplies-place-in-office-kit-orders"
            target="_blank"
            rel="noreferrer"
          >
            View a tutorial here!
          </a>
        </div>,
        "Once the order is placed, your kit is activated and when needed, we’ll give you an auto-filled Requisition to print & ship back.",
      ],
    },
    {
      title: "Key things to know",
      items: [
        "All kits must be activated on Rupa! We cannot process results if the order is not placed on Rupa.",
        "There is no cost to order supplies on Rupa",
        "In-Office Kits cannot be used with Physician Services",
        "You must have access or select a Signing Practitioner with access to the Lab to order Supplies",
      ],
    },
  ]
  const [currentStep, setCurrentStep] = useState(0)

  if (!showModal) {
    return null
  }
  return (
    <>
      <ClipboardModal
        modalTitle={modalTitle}
        clipboardText={sections[currentStep].title}
        items={sections[currentStep].items}
        showModal={showModal}
        onExit={onExit}
        onContinue={
          currentStep === 0
            ? () => {
                setCurrentStep(currentStep + 1)
              }
            : onContinue
        }
        buttonText={currentStep === 0 ? "Next" : "I Understand!"}
        ariaLabel="in-office-kits-onboarding-modal"
        showCloseButton
      />
    </>
  )
}

import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { showMessage } from "app/store/actions"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import {
  TransactionReport,
  TransactionReportInvocation,
} from "types/transaction-report"

import { TransactionReportInvocationStatus } from "../constants"

/**
 * Hook that returns SWR resources for a transaction report download.
 *
 * As soon as we have a transaction report id, we start polling for the transaction report invocation
 * and exit only upon a status change to "successful" or "failed", or when we have been polling
 * for more than 2 minutes.
 */
export default function useTransactionReportResources(
  transactionReportId?: string
) {
  const dispatch = useDispatch()
  const [unableToDownload, setUnableToDownload] = useState(false)
  // Number of seconds that have passed since start of polling
  const [pollingCountSec, setPollingCountSec] = useState(0)

  const { data: transactionReport } = useResourceSWR<TransactionReport>(
    transactionReportId ? `/transaction_reports/${transactionReportId}` : null,
    {
      include: ["transaction_report_invocation"],
    },
    {
      refreshInterval(latestData) {
        // Polling count is used to exit polling after 2 minutes
        if (!latestData || pollingCountSec > 120) {
          return 0
        }

        switch (transactionReportInvocation?.attributes.state) {
          case TransactionReportInvocationStatus.READY:
          case TransactionReportInvocationStatus.PROCESSING:
            return 1000
          case TransactionReportInvocationStatus.SUCCESSFUL:
          case TransactionReportInvocationStatus.FAILED:
            return 0
          default:
            return 1000
        }
      },
      dedupingInterval: 1000,
      onSuccess(data, _key, _config) {
        if (
          transactionReportInvocation?.attributes.state ===
          TransactionReportInvocationStatus.FAILED
        ) {
          setUnableToDownload(true)
          dispatch(
            showMessage({
              message:
                "Unable to download report. Please try again or reach out to support@rupahealth.com",
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            })
          )
        }
      },
    }
  )
  const transactionReportInvocation =
    useCachedResource<TransactionReportInvocation>(
      transactionReport?.relationships.transaction_report_invocation.data
    )

  // Increment polling when transaction report changes (equates to every second)
  useEffect(() => {
    setPollingCountSec((count) => count + 1)
  }, [transactionReport])

  useEffect(() => {
    if (!transactionReportId) {
      setUnableToDownload(false)
      setPollingCountSec(0)
    }
  }, [transactionReportId])

  return {
    transactionReport,
    unableToDownload,
  }
}

export type UseTransactionReportResources = ReturnType<
  typeof useTransactionReportResources
>

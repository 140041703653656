import React from "react"

import { PatientPortalBloodReportResult } from "app/patient-portal/types"
import interleave from "app/utils/interleave"
import { BloodReportResult } from "types/blood-report-result"

import { PdfBiomarkerRow } from "./PdfBiomarkerRow"

export const PdfAllMarkersLabTestSection: React.FC<{
  bloodReportResults: BloodReportResult[] | PatientPortalBloodReportResult[]
  labTestTitle: string
}> = ({ bloodReportResults, labTestTitle }) => {
  return (
    <div className="break-inside-avoid">
      <div className="w-full bg-slate-100 rounded-lg font-bold text-navy mb-2 mt-8 p-1 pl-3">
        {labTestTitle}
      </div>
      <div className="m-2 ml-4 mr-4">
        {interleave(
          bloodReportResults.map((bloodReportResult) => (
            <PdfBiomarkerRow
              bloodReportResult={bloodReportResult}
              key={bloodReportResult.id}
            />
          )),
          (_, key) => (
            <BiomarkerDivider key={`sep_${key}`} />
          )
        )}
      </div>
    </div>
  )
}

const BiomarkerDivider: React.FC = () => {
  return <div className="w-full bg-slate-50 h-0.5 mt-2 mb-2"></div>
}

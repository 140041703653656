import { useCallback, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { Button as MuiButton, CircularProgress } from "@material-ui/core"

import { ReactComponent as CalendarBlue } from "app/assets/images/calendar-blue.svg"
import { ReactComponent as PrintBlue } from "app/assets/images/print-blue.svg"
import { NavyTooltip } from "app/components/NavyTooltip"
import {
  LAB_COMPANIES_THAT_HAVE_REQUISITIONS,
  ORDER_STATUS,
  LAB_COMPANY_KEY,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
  LAB_COMPANIES_WE_GENERATE_REQUISITIONS_FOR_IOK_ONLY,
  LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS,
} from "app/constants"
import {
  instantRequisitionDisabled,
  isOrderedTestForCompany,
} from "app/dataServices/orderDataService"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { trackPractitionerDashboardAction } from "app/services/segment.typed"
import { colors, shadows } from "app/theme"
import {
  OrderedTest,
  Patient,
  PatientOrdersOrder,
  PhysicianAuthorizationApprovalStatus,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { getOrderTypeLabel } from "app/utils/order-utils"

import { PatientOrderEvent } from "../constants"
import * as Actions from "../store/actions"
import PatientOrdersOrderedTestDownloadActions from "./PatientOrdersOrderedTestDownloadActions"
import PatientOrdersOrderedTestMarkReviewedAction from "./PatientOrdersOrderedTestMarkReviewedAction"
import PatientOrdersOrderedTestResultsAction from "./PatientOrdersOrderedTestResultsAction"
import { getClinicalConsultAvailability } from "./clinicalConsultAvailability"

export const useStyles = makeAppStyles((theme) => ({
  orderActionsWrapper: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 0,

    [theme.breakpoints.up("md")]: {
      marginLeft: 8,
      justifyContent: "flex-end",
    },
  },
  actionButton: {
    height: 36,
    width: 36,
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    padding: "5px 8px",
    minWidth: 0,
    marginRight: 8,
    backgroundColor: "white",
    boxShadow: shadows.sm,
    "&:disabled": {
      opacity: 0.5,
    },

    [theme.breakpoints.up("md")]: {
      marginLeft: 8,
      marginRight: "auto",
    },
  },
  icon: {
    width: "100%",
    height: "100%",
  },
}))

function useConsultUrl(order: PatientOrdersOrder, orderedTest: OrderedTest) {
  const consultUrl = useMemo(() => {
    if (order.status === ORDER_STATUS.DRAFT) {
      return
    }

    const practitioner = order.signing_practitioner
    const labCompany = orderedTest.lab_test.lab_company
    const labCompanyAccount =
      practitioner &&
      practitioner.lab_company_accounts.find((companyAccount) =>
        companyAccount.lab_companies
          .map((company) => company.id)
          .includes(labCompany.id)
      )

    const query = new URLSearchParams({
      "practitioner-first-name": practitioner && practitioner.user.first_name,
      "practitioner-last-name": practitioner && practitioner.user.last_name,
      "lab-company-name": labCompany.name,
      "lab-company-id": labCompany.id,
      "accession-id": orderedTest.accession_id,
      "practitioner-npi": practitioner && practitioner.npi_number,
      "practitioner-email": practitioner && practitioner.user.email,
      "request-not-from-signing-practitioner":
        practitioner && (practitioner.id !== order.practitioner.id).toString(),
    })

    if (labCompanyAccount && labCompanyAccount.account_id) {
      query.append("practitioner-account-number", labCompanyAccount.account_id)
    }

    if (practitioner && practitioner.clinic.phone_number) {
      query.append("practitioner-phone", practitioner.clinic.phone_number)
    }

    return `https://www.rupahealth.com/clinical-consultation?${query.toString()}`
  }, [order, orderedTest.lab_test.lab_company])

  return consultUrl
}

function legacyClinicConsultDisabledReason(
  order: PatientOrdersOrder,
  orderedTest: OrderedTest
) {
  if (!orderedTest.results) {
    return (
      <>
        Schedule a Clinical Consult <br />
        (Available When Results Are In)
      </>
    )
  } else if (
    isOrderedTestForCompany(orderedTest, LAB_COMPANY_KEY.BIOREFERENCE)
  ) {
    return "Clinical consults are not available for BioReference tests"
  }

  return ""
}

export default function PatientOrdersOrderedTestOrderActions({
  order,
  orderedTest,
  patient,
  markOrderedTestResultReviewed,
  acknowledgeUnreadResult,
  appliedFilters,
}: {
  order: PatientOrdersOrder
  orderedTest: OrderedTest
  patient: Patient
  markOrderedTestResultReviewed: Function
  acknowledgeUnreadResult: Function
  appliedFilters?: any
}) {
  const { lab_test, requisition, specimen_issue } = orderedTest
  const styles = useStyles()
  const dispatch = useDispatch()

  const [isEarlyClinicalConsultsEnabled] = useFeatureFlag(
    FeatureFlag.IsEarlyClinicalConsultsEnabled
  )

  const [instantRequisitionDisabledForOrder] = instantRequisitionDisabled(order)

  const canGenerateRequisition: boolean =
    orderedTest.instant_requisition &&
    LAB_COMPANIES_WE_GENERATE_REQUISITIONS_FOR_IOK_ONLY.includes(
      lab_test.lab_company.key
    )

  const useSpecimenIssueRequisition: boolean = Boolean(
    specimen_issue &&
      LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS.includes(
        lab_test.lab_company.key
      )
  )

  const hasRequisition: boolean = useSpecimenIssueRequisition
    ? !!specimen_issue?.recollection_requisition
    : Boolean(requisition) ||
      LAB_COMPANIES_THAT_HAVE_REQUISITIONS.includes(lab_test.lab_company.key) ||
      canGenerateRequisition

  const requisitionUrl =
    LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS.includes(
      lab_test.lab_company.key
    )
      ? specimen_issue?.recollection_requisition
      : requisition

  const isApprovedVendorPhysicianAuthorizationOrder: boolean =
    order.physician_authorization_status ===
    PhysicianAuthorizationApprovalStatus.APPROVED

  const isVendorPhysicianAuthorizationOrder: boolean =
    order.requires_vendor_physician_authorization

  const isRequisitionAvailable =
    hasRequisition &&
    !instantRequisitionDisabledForOrder &&
    (!isVendorPhysicianAuthorizationOrder ||
      isApprovedVendorPhysicianAuthorizationOrder)

  const [isLoadingRequisitions, setIsLoadingRequisitions] = useState(false)
  const consultUrl = useConsultUrl(order, orderedTest)

  const openConsult = useCallback(() => {
    trackPractitionerDashboardAction(
      PatientOrderEvent.SCHEDULE_CONSULT,
      order.practitioner.id
    )
    window.open(consultUrl, "_blank")
  }, [consultUrl, order.practitioner.id])

  const openRequisition = useCallback(async () => {
    trackPractitionerDashboardAction(
      PatientOrderEvent.DOWNLOAD_REQUISTION,
      order.practitioner.id
    )
    if (requisitionUrl) {
      window.open(requisitionUrl)
    } else if (hasRequisition) {
      setIsLoadingRequisitions(true)
      await dispatch(
        Actions.openLabCompanyRequisition(
          order.id,
          lab_test.lab_company.id,
          orderedTest.id
        )
      )
      setIsLoadingRequisitions(false)
    }
  }, [
    hasRequisition,
    requisitionUrl,
    lab_test,
    order.id,
    setIsLoadingRequisitions,
  ])

  const { clinicConsultDisabled, clinicConsultDisabledReason } = useMemo(() => {
    if (
      order.requires_physician_authorization ||
      order.requires_vendor_physician_authorization
    ) {
      return {
        clinicConsultDisabled: true,
        clinicConsultDisabledReason: `Clinical consults are not available for ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} ${getOrderTypeLabel(
          true
        )}s`,
      }
    }
    if (isEarlyClinicalConsultsEnabled) {
      const clinicalConsultAvailabilityDetails =
        getClinicalConsultAvailability(orderedTest)
      return {
        clinicConsultDisabled:
          !clinicalConsultAvailabilityDetails.isAvailable(),
        clinicConsultDisabledReason: (
          <>
            Schedule a Clinical Consult <br />
            {clinicalConsultAvailabilityDetails.unavailableReason}
          </>
        ),
      }
    } else {
      const clinicConsultDisabledReason = legacyClinicConsultDisabledReason(
        order,
        orderedTest
      )
      return {
        clinicConsultDisabled: clinicConsultDisabledReason !== "",
        clinicConsultDisabledReason: clinicConsultDisabledReason,
      }
    }
  }, [orderedTest, order])

  const clinicConsultTooltip = clinicConsultDisabled
    ? clinicConsultDisabledReason
    : "Schedule a Clinical Consult"

  return (
    <div className={styles.orderActionsWrapper}>
      {orderedTest.results ? (
        <PatientOrdersOrderedTestMarkReviewedAction
          order={order}
          orderedTest={orderedTest}
          markOrderedTestResultReviewed={markOrderedTestResultReviewed}
        />
      ) : (
        <NavyTooltip
          arrow
          placement="top"
          title={
            isRequisitionAvailable
              ? "View Requisition"
              : "No Digital Requisition Available"
          }
        >
          <div>
            <MuiButton
              onClick={openRequisition}
              disabled={!isRequisitionAvailable || isLoadingRequisitions}
              key="print-button"
              className={styles.actionButton}
            >
              {isLoadingRequisitions ? (
                <CircularProgress
                  size={15}
                  aria-label="Loading the requisition form"
                />
              ) : (
                <PrintBlue className={styles.icon} />
              )}
            </MuiButton>
          </div>
        </NavyTooltip>
      )}
      <NavyTooltip arrow placement="top" title={clinicConsultTooltip}>
        <div>
          <MuiButton
            onClick={openConsult}
            disabled={clinicConsultDisabled}
            key="calendar-button"
            className={styles.actionButton}
          >
            <CalendarBlue
              title="consult-calendar-icon"
              className={styles.icon}
            />
          </MuiButton>
        </div>
      </NavyTooltip>

      <PatientOrdersOrderedTestResultsAction
        order={order}
        orderedTest={orderedTest}
        patient={patient}
      />

      <PatientOrdersOrderedTestDownloadActions
        order={order}
        orderedTest={orderedTest}
        acknowledgeUnreadResult={acknowledgeUnreadResult}
        appliedFilters={appliedFilters}
      />
    </div>
  )
}

import { ReactComponent as PrintIcon } from "app/assets/images/print-blue.svg"
import Button from "app/components/design-system/Button"
import { LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS } from "app/constants"
import useQuery from "app/hooks/use-query"
import {
  PatientPortalLabCompany,
  PatientPortalLabTest,
  PatientPortalOrderedTest,
} from "app/patient-portal/types"
import { trackPatientPortalAction } from "app/services/segment.typed"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import makeAppStyles from "app/utils/makeAppStyles"
import { SpecimenIssueNormalized } from "types/specimen-issue"

const useStyles = makeAppStyles((theme) => ({
  button: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 140,
    },
  },
}))

export interface DownloadRequisitionButtonProps {
  orderedTest: PatientPortalOrderedTest
}

export default function DownloadRequisitionButton({
  orderedTest,
}: DownloadRequisitionButtonProps) {
  const classes = useStyles()
  const query = useQuery()
  const isRequisitionAvailable = Boolean(orderedTest.attributes.requisition)

  const labTest = useCachedResource<PatientPortalLabTest>(
    orderedTest.relationships.lab_test.data
  )
  const labCompany = useCachedResource<PatientPortalLabCompany>(
    labTest?.relationships.lab_company.data
  )
  const specimenIssue = useCachedResource<SpecimenIssueNormalized>(
    orderedTest.relationships.specimen_issue.data
  )

  const requisitionUrl =
    specimenIssue &&
    labCompany &&
    LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS.includes(
      labCompany?.attributes.key
    )
      ? specimenIssue?.attributes.recollection_requisition || undefined
      : orderedTest.attributes.requisition

  function handleTrackingOnClick() {
    trackPatientPortalAction(query.get("token"), "download_requisition")
  }

  if (!isRequisitionAvailable) {
    return null
  }

  return (
    <Button
      className={classes.button}
      color="secondary"
      href={requisitionUrl}
      onClick={handleTrackingOnClick}
      size="small"
      startIcon={<PrintIcon fill="currentColor" width="18" height="17" />}
      target="_blank"
      type="button"
    >
      Requisition
    </Button>
  )
}

import { useState } from "react"
import { useDispatch } from "react-redux"

import { Button } from "@rupahealth/design"

import StartOrderModal from "app/components/modals/StartOrderModal"
import { markTestToAddToNextOrder } from "app/main/checkout/store/actions"
import { trackEventWithProperties } from "app/services/segment.typed"
import { AnyLabTest } from "app/types"

import { useActivateKitModal } from "./ActivateKitModal"
import { useIOKInventory } from "./InventoryDataProvider"
import { IOKInventoryEvents, InventoryItem } from "./inventory-types"

interface Props {
  inventoryItem: InventoryItem
}

const ActivateKitModalButton: React.FC<Props> = ({ inventoryItem }) => {
  const modal = useActivateKitModal()
  const { practitioner } = useIOKInventory()
  const [startOrderModalOpen, setStartOrderModalOpen] = useState(false)
  const dispatch = useDispatch()
  const onLabTestSelect = (labTest: AnyLabTest) => {
    dispatch(markTestToAddToNextOrder(labTest, { instant_requisition: true }))
    modal.hide()
    setStartOrderModalOpen(true)
  }

  return (
    <>
      {startOrderModalOpen && (
        // conditionally render the Modal so it doesn't load things before it's needed
        <StartOrderModal
          open={startOrderModalOpen}
          onClose={() => setStartOrderModalOpen(false)}
          practitioner={practitioner}
          shouldHideCreatePatientButton={false}
          onSelectPatient={() =>
            trackEventWithProperties(IOKInventoryEvents.START_ORDER, {
              practitioner_id: practitioner.id,
              in_office_kit_product_id: inventoryItem.in_office_kit_product_id,
              in_office_kit_product_name:
                inventoryItem.in_office_kit_product_name,
            })
          }
        />
      )}
      <Button
        variant="primary"
        onClick={() => {
          trackEventWithProperties(IOKInventoryEvents.ACTIVATE_KIT, {
            practitioner_id: practitioner.id,
            in_office_kit_product_id: inventoryItem.in_office_kit_product_id,
            in_office_kit_product_name:
              inventoryItem.in_office_kit_product_name,
          })
          modal.show({
            inventoryItem,
            onLabTestSelect,
          })
        }}
        className="w-36 font-title text-[15px] shadow-sm"
      >
        <span className="inline-flex items-center pt-1">Activate Kit</span>
      </Button>
    </>
  )
}

export default ActivateKitModalButton

import { isFunction, matches } from "lodash"

import formatDateForHuman from "app/utils/format-date-for-human"

import { isDashboardProps } from "./types/dashboard-types"
import { isPatientOrdersProps } from "./types/patient-orders-types"
import { isPatientPortalProps } from "./types/patient-portal-types"
import {
  BaseStatusConfigurationProps,
  UseStatusComponentsProps,
} from "./types/types"

/**
 * The useSatatusComponents hook is used to generate the appropriate components to display test statuses.
 * It takes a list of configurations and a set of props used to generate the information to display.
 * (e.g. orderedTest/Kit, order, labCompany, etc.)
 * STATUS_CONFIGURATION_TYPES are used to ensure typescript knows which props are required for each context.
 * The configurations list can be imported from the respective `status-configurations` directory depending on the context.
 * Each context has its own set of configurations, mostly due to the different data structures used in each location – the logic within
 * should be nearly identical across contexts, with small differences between practitioner and patient facing contexts.
 *
 * The hook returns an object containing:
 * - `lastUpdatedAt`: A formatted date string or a JSX element based on the `lastUpdatedAt` property of the matched configuration.
 * - `progressPercentage`: A number or a function result based on the `progressPercentage` property of the matched configuration.
 * - `subTitle`: A string or a function result based on the `subTitle` property of the matched configuration.
 * - `title`: A string or a function result based on the `title` property of the matched configuration.
 *
 * If no configuration matches, the default is a 0 progress bar with no date, title, or subtitle.
 * There should be a matching configuration for all statuses.
 */
export default function useStatusComponents<
  T extends BaseStatusConfigurationProps
>(props: UseStatusComponentsProps<T>) {
  const { statusConfigurations, ...statusConfigurationProps } = props
  const config = statusConfigurations.find((config) => {
    const isMatch = isFunction(config.when) ? config.when : matches(config.when)

    if (isDashboardProps(statusConfigurationProps)) {
      return isMatch({
        kitStatus: statusConfigurationProps.kit.attributes.status,
        orderStatus: statusConfigurationProps.order.attributes.cached_status,
        labCompanyNotifiesSampleAtLab:
          statusConfigurationProps.labCompany?.attributes
            .notifies_sample_at_lab,
      })
    } else if (isPatientPortalProps(statusConfigurationProps)) {
      return isMatch({
        orderedTestStatus:
          statusConfigurationProps.orderedTest.attributes.status,
        orderStatus: statusConfigurationProps.order?.attributes.status,
        labCompanyNotifiesSampleAtLab:
          statusConfigurationProps.labCompany?.attributes
            .notifies_sample_at_lab,
      })
    } else if (isPatientOrdersProps(statusConfigurationProps)) {
      return isMatch({
        orderedTestStatus: statusConfigurationProps.orderedTest.status,
        orderStatus: statusConfigurationProps.order.status,
        labCompanyNotifiesSampleAtLab:
          statusConfigurationProps.labCompany.notifies_sample_at_lab,
      })
    } else {
      return false
    }
  })

  if (config) {
    let lastUpdatedAt = ""
    if (isDashboardProps(statusConfigurationProps)) {
      lastUpdatedAt = statusConfigurationProps.kit.attributes.last_updated_at
    } else if (isPatientPortalProps(statusConfigurationProps)) {
      lastUpdatedAt = statusConfigurationProps.orderedTest.attributes.updated_at
    } else if (isPatientOrdersProps(statusConfigurationProps)) {
      lastUpdatedAt = statusConfigurationProps.orderedTest.updated_at
    }

    return {
      lastUpdatedAt: config.lastUpdatedAt ? (
        isFunction(config.lastUpdatedAt) ? (
          config.lastUpdatedAt(props)
        ) : (
          config.lastUpdatedAt
        )
      ) : (
        <>{formatDateForHuman(lastUpdatedAt)}</>
      ),
      progressPercentage: isFunction(config.progressPercentage)
        ? config.progressPercentage(props)
        : config.progressPercentage,
      subTitle: isFunction(config.subTitle)
        ? config.subTitle(props)
        : config.subTitle,
      title: isFunction(config.title) ? config.title(props) : config.title,
    }
  }

  return {
    progressPercentage: 0,
  }
}

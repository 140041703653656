import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { ReactComponent as RecollectionIcon } from "app/assets/icons/specimen-issue-actions/specimen-issue-action-partial-recollection.svg"
import {
  IOKOrderStatusUnion,
  ORDER_STATUS,
  OrderStatusUnion,
  SPECIMEN_ISSUE_STATUS,
  SpecimenIssueStatusUnion,
} from "app/constants.typed"
import { getOrderStatusMetadata } from "app/utils"

import StatusPill from "./StatusPill"

interface Props {
  status: OrderStatusUnion | IOKOrderStatusUnion
  total_ordered_tests: number
  total_completed_ordered_tests: number
  specimen_issue_status?: SpecimenIssueStatusUnion | null
}

const OrderStatus: React.FC<Props> = ({
  status,
  total_ordered_tests,
  total_completed_ordered_tests,
  specimen_issue_status,
}) => {
  const metadata = getOrderStatusMetadata(status)

  const label = getLabel({
    status,
    total_completed_ordered_tests,
    total_ordered_tests,
    label: metadata.label,
  })

  return (
    <>
      <StatusPill
        style={{ backgroundColor: metadata.background }}
        labelStyle={{ color: metadata.text }}
      >
        {label}
      </StatusPill>
      {!!specimen_issue_status && (
        <div className="mt-[6px]">
          <SpecimenIssueStatusPill status={specimen_issue_status} />
        </div>
      )}
    </>
  )
}

function getLabel({
  status,
  total_ordered_tests,
  total_completed_ordered_tests,
  label,
}: Props & { label: string }): string {
  if (
    status === ORDER_STATUS.IN_PROGRESS &&
    Number.isInteger(total_ordered_tests) &&
    Number.isInteger(total_completed_ordered_tests) &&
    total_completed_ordered_tests >= 1
  ) {
    if (total_completed_ordered_tests === total_ordered_tests) {
      return "All Results In"
    }

    return `Results In (${total_completed_ordered_tests} of ${total_ordered_tests})`
  }

  return label
}

interface SpecimenIssueStatusPillProps {
  status: SpecimenIssueStatusUnion
}

const SpecimenIssueStatusPill: React.FC<SpecimenIssueStatusPillProps> = ({
  status,
}) => {
  switch (status) {
    case SPECIMEN_ISSUE_STATUS.ACTION_REQUIRED:
      return (
        <StatusPill className="bg-red-50" labelClass="text-destructive">
          <FontAwesomeIcon icon={faTriangleExclamation} />
          Action Required
        </StatusPill>
      )
    case SPECIMEN_ISSUE_STATUS.RECOLLECTION_IN_PROGRESS:
      return (
        <StatusPill className="bg-slate-100" labelClass="text-body">
          <RecollectionIcon fill="currentColor" />
          Recollection in Progress
        </StatusPill>
      )
    case SPECIMEN_ISSUE_STATUS.PROCESSING_ACTION: // We were showing a pill for this one but decided to remove it
    default:
      return <></>
  }
}

export default OrderStatus

import { PatientPortalOrder } from "app/patient-portal/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"

export default function usePatientPortalPatientOrders(patientId?: string) {
  const { data, isLoading } = useCollectionSWR<
    ResourceCollection<PatientPortalOrder>
  >(patientId ? `/patient_portal/patient/${patientId}/order/` : null, {
    include: ["kits.lab_company", "practitioner", "storefront"],
  })

  const orders = useCachedCollection<PatientPortalOrder>(data)

  return {
    orders,
    isLoading,
  }
}

import { Redirect, Switch } from "react-router-dom"

import PatientPortalBGSupport from "app/assets/images/patient-portal/gradient1.jpeg"
import Portal from "app/components/Portal"
import PatientPortalBackground from "app/patient-portal/PatientPortalBackground"
import { PatientPortalBloodLabDashboard } from "app/patient-portal/blood-lab-dashboard"
import PatientPortalBloodLabDashboardSnapshot from "app/patient-portal/blood-lab-dashboard/components/PatientPortalBloodLabDashboardSnapshot"
import PatientPortalUserDocuments from "app/patient-portal/order/components/PatientPortalUserDocuments"
import PatientPortalUserOrder from "app/patient-portal/order/components/PatientPortalUserOrder"
import PatientPortalUserSupport from "app/patient-portal/order/components/PatientPortalUserSupport"
import PatientPortalUserTrends from "app/patient-portal/order/components/PatientPortalUserTrends"
import PatientPortalUserOrders from "app/patient-portal/user-orders/PatientPortalUserOrders"
import PatientPortalUsers from "app/patient-portal/users/PatientPortalUsers"

import Route from "../Route"
import { PatientPortalUserPaths } from "../paths/patient-portal-user-paths"

export const PatientPortalUserRoutes = () => (
  <Switch>
    <Route exact path={PatientPortalUserPaths.ROOT}>
      <Redirect to={PatientPortalUserPaths.ACCOUNTS} />
    </Route>
    <Route exact path={PatientPortalUserPaths.ACCOUNTS}>
      <PatientPortalUsers />
    </Route>
    <Route exact path={PatientPortalUserPaths.ORDERS}>
      <PatientPortalUserOrders />
    </Route>
    <Route exact path={PatientPortalUserPaths.ORDER}>
      <PatientPortalUserOrder />
    </Route>
    <Route exact path={PatientPortalUserPaths.BLOOD_LABS}>
      <PatientPortalBloodLabDashboard />
    </Route>
    <Route exact path={PatientPortalUserPaths.BLOOD_LABS_SNAPSHOT}>
      <PatientPortalBloodLabDashboardSnapshot />
    </Route>
    <Route exact path={PatientPortalUserPaths.SUPPORT}>
      <Portal id="patient-portal-background">
        <PatientPortalBackground image={PatientPortalBGSupport} />
      </Portal>
      <PatientPortalUserSupport />
    </Route>
    <Route exact path={PatientPortalUserPaths.TRENDS}>
      <PatientPortalUserTrends />
    </Route>
    <Route exact path={PatientPortalUserPaths.DOCUMENTS}>
      <PatientPortalUserDocuments />
    </Route>
  </Switch>
)

import { withStyles } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"

import { navy } from "../theme"

export default withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: navy,
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(14),
    textAlign: "center",
    padding: 16,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    borderRadius: 8,
  },
  arrow: {
    color: "white",
  },
}))(Tooltip)

import { useState } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"
import moment from "moment"

import { colors, Grid, makeStyles } from "@material-ui/core"

import { API } from "app/api"
import { ReactComponent as CircleCashIcon } from "app/assets/images/circle-cash-icon.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import GenericConfirmationModal from "app/components/modals/GenericConfirmationModal"
import { showMessage } from "app/store/actions/fuse"
import { CustomFeeLineItem, CustomFeeLineItemStatus } from "app/types"
import { formatDollars, handleApiSuccess } from "app/utils"

import { updateOrder } from "./store/actions"

interface Props {
  orderId: string
  customFeeLineItem: CustomFeeLineItem
  patientFullName: string
}

const useStyles = makeStyles({
  contentContainerLeft: {
    display: "flex",
    alignItems: "center",
  },
  contentContainerRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  contentContainerRightItems: {
    marginRight: 48,
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  cashIconContainer: {
    padding: "7px 8.5px 1.5px 8.3px",
    background: "#DCFCE7",
    borderRadius: 8,
    display: "inline-block",
    marginRight: 11,
  },
  customFeeText: {
    display: "inline-block",
  },
  customFeeStatusContainer: {
    padding: "1px 8px",
    borderRadius: 49,
    textAlign: "center",
    fontSize: 13,
    fontWeight: 700,
    height: "fit-content",
  },
  customFeeStatusPending: {
    color: "#0369A1",
    background: "#E0F2FE",
  },
  customFeeStatusPaid: {
    color: colors.green[700],
    background: colors.green[100],
  },
  customFeeStatusCompleted: {
    color: colors.green[700],
    background: colors.green[100],
  },
  customFeeStatusRefunded: {
    color: colors.blueGrey[600],
    background: colors.blueGrey[50],
  },
  refundButton: {
    padding: "11px 19px 8.5px",
    // Mui disabled pointer events on disabled buttons which means we're
    // unable to use a Tooltip wrapper
    "&.MuiButtonBase-root.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
})

/**
 *
 */
export default function OrderCustomFee({
  orderId,
  customFeeLineItem,
  patientFullName,
}: Props) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const [showTooltip, setShowTooltip] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isRefundLoading, setIsRefundLoading] = useState(false)

  const CUSTOM_FEE_LINE_ITEM_STATUS_TEXT = {
    [CustomFeeLineItemStatus.PENDING]: "PENDING",
    [CustomFeeLineItemStatus.PAID]: "PAID OUT",
    [CustomFeeLineItemStatus.COMPLETED]: "COMPLETED",
    [CustomFeeLineItemStatus.REFUNDED]: "REFUNDED",
  }
  const CUSTOM_FEE_LINE_ITEM_STATUS_CLASS = {
    [CustomFeeLineItemStatus.PENDING]: classes.customFeeStatusPending,
    [CustomFeeLineItemStatus.PAID]: classes.customFeeStatusPaid,
    [CustomFeeLineItemStatus.COMPLETED]: classes.customFeeStatusCompleted,
    [CustomFeeLineItemStatus.REFUNDED]: classes.customFeeStatusRefunded,
  }

  let disabledRefundReason =
    "You'll only be able to refund this Custom Fee once it has been transferred to your account after all results are in for the order. To process refunds before then, please contact Rupa support."
  let pendingHoverText =
    "This custom fee will not be paid out to you until the Friday after this entire order is completed."
  let customFeeLineItemDate: string | undefined
  if (customFeeLineItem.status === CustomFeeLineItemStatus.PAID) {
    customFeeLineItemDate = customFeeLineItem.date_balance_transferred
    // Do not block refund
    disabledRefundReason = ""
    pendingHoverText = ""
  } else if (customFeeLineItem.status === CustomFeeLineItemStatus.COMPLETED) {
    disabledRefundReason =
      "You can only refund your patient before the Custom Fee has been paid out to your Stripe Account. To process refunds before then, please contact Rupa support."
    customFeeLineItemDate = customFeeLineItem.date_balance_paid_out
    pendingHoverText = ""
  } else if (customFeeLineItem.status === CustomFeeLineItemStatus.REFUNDED) {
    customFeeLineItemDate = customFeeLineItem.date_balance_refunded
    disabledRefundReason = "You have already refunded your patient."
    pendingHoverText = ""
  }

  const handleRefund = async () => {
    try {
      await API.CustomFeeLineItem.refund(orderId, customFeeLineItem.id)
      await dispatch(updateOrder(orderId))
      setIsRefundLoading(false)
      setShowConfirmationModal(false)
      dispatch(handleApiSuccess("Successfully refunded Custom Fee"))
    } catch (error) {
      dispatch(
        showMessage({
          message:
            "Unable to issue refund. Please contact Rupa Health support.",
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        })
      )
      setIsRefundLoading(false)
      setShowConfirmationModal(false)
    }
  }

  return (
    <>
      <Grid container className="border-t border-gray-300 pt-4 pb-6">
        <Grid item md={6} sm={12} className={classes.contentContainerLeft}>
          <div className={classes.cashIconContainer}>
            <CircleCashIcon width={17} viewBox="0 0 21 20" />
          </div>
          <BodyText
            weight="semibold"
            size="base"
            className={classes.customFeeText}
          >
            {customFeeLineItem.name}
          </BodyText>
        </Grid>
        <Grid item md={6} sm={12} className={classes.contentContainerRight}>
          {customFeeLineItemDate && (
            <div className={classes.contentContainerRightItems}>
              <BodyText weight="regular" size="base">
                {moment(customFeeLineItemDate).format("MMM D, YYYY")}
              </BodyText>
            </div>
          )}
          <div className={classes.contentContainerRightItems}>
            <BodyText weight="semibold" size="base">
              {formatDollars(customFeeLineItem.price / 100)}
            </BodyText>
          </div>
          <Tooltip
            title={pendingHoverText || ""}
            disableHoverListener={pendingHoverText === ""}
            placement="top"
            arrow
            interactive
          >
            <div
              className={clsx(
                classes.customFeeStatusContainer,
                CUSTOM_FEE_LINE_ITEM_STATUS_CLASS[customFeeLineItem.status],
                classes.contentContainerRightItems
              )}
            >
              {CUSTOM_FEE_LINE_ITEM_STATUS_TEXT[customFeeLineItem.status]}
            </div>
          </Tooltip>
          <div className={classes.contentContainerRightItems}>
            <Tooltip
              title={disabledRefundReason || ""}
              disableHoverListener={!disabledRefundReason}
              open={showTooltip}
              placement="top"
              arrow
              onOpen={() => setShowTooltip(true)}
              onClose={() => setShowTooltip(false)}
            >
              <span>
                <DesignSystemButton
                  color="secondary"
                  loading={isRefundLoading}
                  size="small"
                  onClick={() => {
                    setIsRefundLoading(true)
                    setShowConfirmationModal(true)
                  }}
                  disabled={
                    customFeeLineItem.status ===
                      CustomFeeLineItemStatus.PENDING ||
                    customFeeLineItem.status ===
                      CustomFeeLineItemStatus.REFUNDED ||
                    customFeeLineItem.status ===
                      CustomFeeLineItemStatus.COMPLETED
                  }
                  className={classes.refundButton}
                >
                  Refund
                </DesignSystemButton>
              </span>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <GenericConfirmationModal
        open={showConfirmationModal}
        data={{
          title: "Refund Custom Fee",
          text: `Are you sure you'd like to refund your custom fee to ${patientFullName}?`,
          buttonText: "Refund Fee",
        }}
        onClose={() => {
          setShowConfirmationModal(false)
          setIsRefundLoading(false)
        }}
        actionFn={handleRefund}
      />
    </>
  )
}

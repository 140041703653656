import * as React from "react"

import BodyText from "./components/design-system/BodyText"
import { LAB_COMPANY_KEY, ORDER_LINE_ITEM_TYPE } from "./constants"

export const getLabCompanyText = (
  labCompanyKey: string
): {
  columnText: string
  tooltipNode?: React.ReactNode
} => {
  switch (labCompanyKey) {
    case LAB_COMPANY_KEY.CYREX: {
      return {
        columnText: "Included in the cost of the test",
        tooltipNode: (
          <BodyText>
            Phlebotomy is included in the cost of the test, and is booked online
            through Cyrex. Pediatric draws have to be paid for and booked
            separately. You can also set a Preferred Phlebotomist in Rupa's
            Settings so we always send your patients this option.
          </BodyText>
        ),
      }
    }
    case LAB_COMPANY_KEY.BIOREFERENCE: {
      return {
        columnText: "$25 Scarlet Health® Mobile Phlebotomy",
        tooltipNode: (
          <BodyText>
            BioReference tests come with $25 mobile phlebotomy through Scarlet,
            which patients schedule through a sleek online experience. You
            cannot use a Preferred Phlebotomist with BioReference lab testing.
          </BodyText>
        ),
      }
    }
    case LAB_COMPANY_KEY.LABCORP: {
      return {
        columnText: "$7 phlebotomy fee paid directly to Rupa",
        tooltipNode: (
          <BodyText>
            Your patient will need to get a blood draw done at a{" "}
            <a
              target="blank"
              href="https://www.labcorp.com/labs-and-appointments-advanced-search"
            >
              Labcorp draw station
            </a>
            . We'll send them an order form and instructions to book the
            appointment.
          </BodyText>
        ),
      }
    }
    case LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS: {
      return {
        columnText: "$10 Draw at Labcorp, Paid on Rupa",
        tooltipNode: (
          <BodyText>
            Your patient will need to get a blood draw done at a{" "}
            <a
              target="blank"
              href="https://www.labcorp.com/labs-and-appointments-advanced-search"
            >
              Labcorp draw station
            </a>
            . We'll send them an order form and instructions to book the
            appointment.
          </BodyText>
        ),
      }
    }
    case LAB_COMPANY_KEY.LABSFIRST_QUEST: {
      return {
        columnText: "$10 Draw, Paid on Rupa",
        tooltipNode: (
          <BodyText>
            Your patient will need to get a blood draw done at a{" "}
            <a
              target="blank"
              href="https://www.questdiagnostics.com/locations/search"
            >
              Quest draw station
            </a>
            . We'll send them an order form and instructions to book the
            appointment.
          </BodyText>
        ),
      }
    }
    default: {
      return {
        columnText: "Pricing varies, paid directly to the phlebotomist",
        tooltipNode:
          "Rupa sends patients local phlebotomy options based on their zip code. We do not contract with phlebotomists ourselves. Phlebotomy is coordinated separately and paid directly to the phlebotomist. Rupa sends patients both in-person and mobile phlebotomy options near them. You can also set a Preferred Phlebotomist in Rupa's Settings so we always send your patients this option.",
      }
    }
  }
}

export const checkIfOrderNeedPhlebotomist = (order) => {
  let patientNeedsToFindPhlebotomist = false
  let testsHaveSpecificPhlebotomist = false

  for (const lineItem of order ? order.line_items : []) {
    if (lineItem.type !== ORDER_LINE_ITEM_TYPE.LAB_TEST) {
      continue
    }

    // Check if the patient has tests that need a phlebotomist
    const companyHasOwnPhleb =
      lineItem.ordered_test.lab_test.lab_company.only_in_house_phlebotomy
    const testRequiresPhleb =
      lineItem.ordered_test.lab_test.lab_test_types.some(
        (type) => type.phlebotomy_required
      )
    patientNeedsToFindPhlebotomist =
      patientNeedsToFindPhlebotomist ||
      (!companyHasOwnPhleb && testRequiresPhleb)
    testsHaveSpecificPhlebotomist =
      testsHaveSpecificPhlebotomist || companyHasOwnPhleb
  }
  return [patientNeedsToFindPhlebotomist, testsHaveSpecificPhlebotomist]
}

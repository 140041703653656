import { useParams } from "react-router-dom"

import PatientPortalOrderQuickLinks from "app/patient-portal/footer/PatientPortalOrderQuickLinks"
import {
  PatientPortalUser,
  PatientPortalPatient,
} from "app/patient-portal/types"
import useCachedResource from "app/swr/hooks/use-cached-resource"

import usePatientPortalUsers from "../../users/hooks/use-patient-portal-users"
import PatientPortalFAQs from "./PatientPortalFAQs"

const PatientPortalUserSupport = () => {
  const { userId } = useParams<{
    userId: string
  }>()

  usePatientPortalUsers()
  const user = useCachedResource<PatientPortalUser>({
    id: userId,
    type: "patient_portal_user",
  })

  // All patient faqs are the same, so we can just use the first patient's faqs
  const firstPatientId = Array.isArray(user?.relationships.patient.data)
    ? user?.relationships.patient.data?.[0]?.id
    : user?.relationships.patient?.data?.id

  const patient = useCachedResource<PatientPortalPatient>({
    id: firstPatientId ? firstPatientId : "",
    type: "patient_portal_patient",
  })

  return (
    <div className="flex flex-col gap-3 sm:gap-5">
      <PatientPortalOrderQuickLinks
        title="Get Support"
        messageSupportButtonColor="primary"
      />
      <PatientPortalFAQs faqs={patient?.attributes.faqs} />
    </div>
  )
}

export default PatientPortalUserSupport

import { useMemo } from "react"

import { uniqBy } from "lodash"

import { InRangeOption } from "app/main/patient-orders/results-over-time/types/types"
import { sortBloodReportResultValues } from "app/patient-portal/blood-lab-dashboard/utils"
import {
  PatientPortalBloodLabDashboardReportSnapshot,
  PatientPortalBloodReportResult,
  PatientPortalClinic,
  PatientPortalLabTest,
  PatientPortalOrder,
  PatientPortalOrderedTest,
  PatientPortalPractitioner,
} from "app/patient-portal/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ResourceCollection } from "app/swr/types"
import { Biomarker } from "types/biomarker"
import { BodySystem } from "types/body-system"
import { LabCompany } from "types/lab-company"
import { OrderedResult } from "types/ordered-result"

export default function useBloodLabDashboardsSnapshot(
  snapshotId: string,
  search?: string
) {
  const {
    data: snapshot,
    error: snapshotError,
    isLoading: isSnapshotLoading,
    isValidating: isSnapshotValidating,
  } = useResourceSWR<PatientPortalBloodLabDashboardReportSnapshot>(
    `/patient_portal_blood_lab_dashboard_report_snapshots/${snapshotId}/`,
    {
      include: [
        "patient",
        "ordered_result.kit.ordered_tests.lab_test.biomarkers.body_systems",
        "ordered_result.order.practitioner",
        "ordered_result.order.practitioner.clinic",
        "ordered_result.lab_company",
        "ordered_result.ordered_tests.lab_test.biomarkers",
      ],
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  )

  const {
    data: bloodReportResultIdentifiers,
    isLoading: isBloodReportResultsLoading,
    isValidating: isBloodReportResultsValidating,
    error: bloodReportResultsError,
  } = useCollectionSWR<ResourceCollection<PatientPortalBloodReportResult>>(
    `/patient_portal_blood_lab_dashboard_report_snapshots/${snapshotId}/results/`,
    {},
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  )

  const bloodReportResults =
    useCachedCollection<PatientPortalBloodReportResult>(
      bloodReportResultIdentifiers
    )

  const filteredBloodReportResults = bloodReportResults.filter(
    (bloodReportResult) => {
      return (
        bloodReportResult.attributes.biomarker_short_name
          .toLowerCase()
          .includes(search?.toLowerCase() ?? "") ||
        bloodReportResult.attributes.biomarker_long_name
          .toLowerCase()
          .includes(search?.toLowerCase() ?? "")
      )
    }
  )

  const orderedResult = useCachedResource<OrderedResult>(
    snapshot?.relationships.ordered_result.data
  )

  const labCompany = useCachedResource<LabCompany>(
    orderedResult?.relationships.lab_company.data
  )

  const order = useCachedResource<PatientPortalOrder>(
    orderedResult?.relationships.order.data
  )

  const practitioner = useCachedResource<PatientPortalPractitioner>(
    order?.relationships.practitioner.data
  )

  const clinic = useCachedResource<PatientPortalClinic>(
    practitioner?.relationships.clinic.data
  )

  const orderedTests = useCachedCollection<PatientPortalOrderedTest>(
    orderedResult?.relationships.ordered_tests.data
  )

  const labTests = useCachedCollection<PatientPortalLabTest>(
    orderedTests?.flatMap((orderedTest) => {
      return orderedTest.relationships.lab_test.data
    })
  )

  const allBiomarkers = useCachedCollection<Biomarker>(
    bloodReportResults?.map((bloodReportResult) => {
      return bloodReportResult.relationships.biomarker.data
    })
  )

  const biomarkers = useCachedCollection<Biomarker>(
    filteredBloodReportResults.map((bloodReportResult) => {
      return bloodReportResult.relationships.biomarker.data
    })
  )

  const missingBiomarkers = useCachedCollection<Biomarker>(
    labTests
      .flatMap((labTest) => {
        return labTest.relationships.biomarkers.data
      })
      .filter((biomarker) => {
        return !bloodReportResults.find((bloodReportResult) => {
          return (
            bloodReportResult.relationships.biomarker?.data.id === biomarker.id
          )
        })
      })
  )

  const uniqueBodySystemIdentifiers = uniqBy(
    biomarkers.flatMap(
      (biomarker) => biomarker?.relationships?.body_systems?.data
    ),
    "id"
  )
  const bodySystems = useCachedCollection<BodySystem>(
    uniqueBodySystemIdentifiers
  )

  const sortedBodySystems = useMemo(() => {
    return [...bodySystems].sort(
      (a, b) => (a.attributes.sort_order || 0) - (b.attributes.sort_order || 0)
    )
  }, [bodySystems])

  const getMatchingBloodReportResultByBiomarkerId = (
    biomarkerId?: string
  ): PatientPortalBloodReportResult | undefined => {
    return filteredBloodReportResults.find((bloodReportResult) => {
      return bloodReportResult.relationships.biomarker.data?.id === biomarkerId
    })
  }

  const filterByInRangeOption = (
    bloodReportResults: PatientPortalBloodReportResult[],
    inRangeOption?: InRangeOption
  ) => {
    if (!inRangeOption || inRangeOption === InRangeOption.ALL) {
      return bloodReportResults
    }

    return bloodReportResults.filter((bloodReportResult) => {
      if (!bloodReportResult?.attributes?.value) {
        return false
      }
      if (inRangeOption === InRangeOption.IN_RANGE) {
        return (
          !bloodReportResult.attributes.out_of_optimal_range &&
          !bloodReportResult.attributes.out_of_standard_range
        )
      } else if (inRangeOption === InRangeOption.OUTSIDE_OPTIMAL_RANGE) {
        return (
          bloodReportResult.attributes.out_of_optimal_range &&
          !bloodReportResult.attributes.out_of_standard_range
        )
      } else if (inRangeOption === InRangeOption.OUT_OF_RANGE) {
        return bloodReportResult.attributes.out_of_standard_range
      }
      return true
    })
  }

  const getBloodReportResultsByLabTest = (
    labTest: PatientPortalLabTest,
    filterBodySystemId?: string,
    inRangeOptionFilter?: InRangeOption
  ): PatientPortalBloodReportResult[] => {
    const bloodReportResults = labTest.relationships.biomarkers.data
      .map((biomarker) => {
        return getMatchingBloodReportResultByBiomarkerId(biomarker.id)
      })
      .filter((bloodReportResult) => {
        if (!bloodReportResult) {
          return false
        }

        if (filterBodySystemId && filterBodySystemId !== "all") {
          const filteredBodySystems = bodySystems.filter(
            (bodySystem) => filterBodySystemId === bodySystem.id
          )
          const biomarkerIds = filteredBodySystems.flatMap((bodySystem) =>
            bodySystem.relationships.biomarkers.data.map(
              (biomarker) => biomarker.id
            )
          )
          if (
            !biomarkerIds.includes(
              bloodReportResult.relationships.biomarker?.data?.id
            )
          ) {
            return false
          }
        }

        return bloodReportResult
      }) as PatientPortalBloodReportResult[]

    return filterByInRangeOption(
      bloodReportResults.sort((a, b) => sortBloodReportResultValues(a, b)),
      inRangeOptionFilter
    )
  }

  const usesClinicOptimalRange = bloodReportResults.some(
    (bloodReportResult) => bloodReportResult.attributes.is_clinic_optimal_range
  )

  return {
    snapshot,
    bloodReportResults: filteredBloodReportResults,
    allBiomarkers,
    orderedResult,
    practitioner,
    clinic,
    sortedBodySystems,
    missingBiomarkers,
    isSnapshotLoading,
    isBloodReportResultsLoading,
    isBloodReportResultsValidating,
    isSnapshotValidating,
    snapshotError,
    bloodReportResultsError,
    labCompany,
    labTests,
    usesClinicOptimalRange,
    getBloodReportResultsByLabTest,
    getMatchingBloodReportResultByBiomarkerId,
  }
}

import clsx from "clsx"

import Grid from "@material-ui/core/Grid"

import {
  getPrimaryStateOrderingIssue,
  getNonShippingStateOrderingIssues,
} from "app/dataServices/orderingRights"
import { colors, shadows, cancelRed, white, primaryColor } from "app/theme"
import { PatientPortalOrder, PatientPortalOrderLineItem } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  warningContainer: {
    border: `1px solid ${colors.blueGray[400]}`,
  },
  errorContainer: {
    border: `1px solid ${colors.red[200]}`,
  },
  defaultContainer: {
    border: `1px solid ${colors.blueGray[200]}`,
  },
  container: {
    borderRadius: 8,
    boxShadow: shadows.default,
    overflow: "hidden",
  },
  warning: {
    backgroundColor: colors.blueGray[100],
    color: colors.blueGray[800],
    padding: 15,
    fontSize: 14,
  },
  error: {
    color: cancelRed,
    backgroundColor: colors.red[50],
    padding: 15,
    fontSize: 14,
    fontWeight: 600,
  },
  editingBorder: {
    border: `1px solid ${primaryColor}`,
  },
  disabledEditingBorder: {
    border: `1px solid ${colors.blue[200]}`,
  },
  editingBackground: {
    backgroundColor: colors.lightBlue[50],
  },
  defaultBackground: {
    background: white,
  },
})

export function TestLineItemContainer({
  lineItem,
  isLabshopsOrder,
  isEditing,
  disabled,
  ariaLabel,
  children,
}: {
  lineItem: PatientPortalOrderLineItem
  isLabshopsOrder: PatientPortalOrder["is_labshops_order"]
  isEditing: boolean
  disabled: boolean
  ariaLabel: string
  children: React.ReactNode
}) {
  const classes = useStyles()

  const orderedTest = lineItem.ordered_test

  const getShippingStateOrderingIssue = () => {
    if (!orderedTest) {
      return { error: false, warning: false, message: null }
    }

    const { shippingStateError, primaryStateOrderingIssue } =
      getPrimaryStateOrderingIssue(
        orderedTest.ordering_rights_status.error_ordering_issues
      )
    if (!primaryStateOrderingIssue) {
      return { error: false, warning: false, message: null }
    }
    return {
      error: shippingStateError,
      warning: !shippingStateError,
      message: primaryStateOrderingIssue.message,
    }
  }

  // We want to prioritize the shipping state issue over other ordering issues
  // Waiver warning is an ordering issue but we only want to show it as a warning so we break it out here
  // It is an error because we still want it to block checkout
  const {
    error: shippingStateError,
    warning: shippingStateWarning,
    message: shippingStateMessage,
  } = getShippingStateOrderingIssue()

  const waiverWarning = !isLabshopsOrder && shippingStateWarning

  const shippingStateNotAllowedError = !isLabshopsOrder && shippingStateError

  // All other ordering issues are handled normally outside of shipping states
  const orderingIssueErrors = getNonShippingStateOrderingIssues(
    orderedTest?.ordering_rights_status.error_ordering_issues || []
  )

  // Prioritize state issue, then pick first other issue in list
  const orderingIssueError = shippingStateNotAllowedError
    ? shippingStateMessage
    : orderingIssueErrors.length > 0 &&
      orderedTest?.ordering_rights_status.error_ordering_issues[0].message

  // Prioritize state issue, then pick first other warning in list
  const orderingIssueWarning = waiverWarning
    ? shippingStateMessage
    : orderedTest?.ordering_rights_status.warning_ordering_issues?.length &&
      orderedTest?.ordering_rights_status.warning_ordering_issues?.length > 0 &&
      orderedTest?.ordering_rights_status.warning_ordering_issues[0].message

  return (
    <div
      className={clsx(
        "mb-4",
        classes.container,
        isEditing ? classes.editingBackground : classes.defaultBackground,
        Boolean(orderingIssueError)
          ? classes.errorContainer
          : Boolean(orderingIssueWarning)
          ? classes.warningContainer
          : isEditing
          ? disabled
            ? classes.disabledEditingBorder
            : classes.editingBorder
          : classes.defaultContainer
      )}
      aria-label={ariaLabel}
    >
      <Grid container direction={"column"}>
        <div className={disabled ? "opacity-50" : ""}>
          {Boolean(orderingIssueError) && (
            <Grid container item className={classes.error}>
              {orderingIssueError}
            </Grid>
          )}
          {!Boolean(orderingIssueError) && Boolean(orderingIssueWarning) && (
            <Grid container item className={classes.warning}>
              {orderingIssueWarning}
            </Grid>
          )}
        </div>
        {children}
      </Grid>
    </div>
  )
}

export function TestLineItemsContainer({
  lineItems,
  isLabshopsOrder,
  isEditing,
  children,
  disabled,
  ariaLabel,
}: {
  lineItems: PatientPortalOrderLineItem[]
  isLabshopsOrder: PatientPortalOrder["is_labshops_order"]
  isEditing: boolean
  ariaLabel: string
  disabled: boolean
  children: React.ReactNode
}) {
  // Find the first line item that has an ordering issue
  const lineItemWithOrderingIssue = lineItems.find(
    (lineItem) => !lineItem.ordered_test?.ordering_rights_status.allowed
  )
  const lineItem = lineItemWithOrderingIssue ?? lineItems[0]
  return (
    <TestLineItemContainer
      lineItem={lineItem}
      isLabshopsOrder={isLabshopsOrder}
      isEditing={isEditing}
      disabled={disabled}
      ariaLabel={ariaLabel}
    >
      {children}
    </TestLineItemContainer>
  )
}

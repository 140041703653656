import ResultsExpectedDate from "app/components/StatusComponents/ResultsExpectedDate"
import { LAB_COMPANY_ALERT_TYPES } from "app/constants"
import { ORDERED_TEST_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({ orderedTest }: StatusConfigurationProps) => {
  if (orderedTest.date_sample_received_at_lab) {
    return (
      <>{`Sample Arrived at Lab on ${formatDateForHuman(
        orderedTest.date_sample_received_at_lab
      )}`}</>
    )
  }

  return <>Sample Arrived at Lab</>
}

const SubTitle = ({ orderedTest, labCompany }: StatusConfigurationProps) => {
  const resultsDelayAlert = orderedTest.active_alerts?.find(
    (alert) => alert.type === LAB_COMPANY_ALERT_TYPES.RESULTS_DELAY
  )

  if (orderedTest.estimated_results_date) {
    return (
      <ResultsExpectedDate
        dateResultsExpectedFromLab={orderedTest.estimated_results_date}
        resultsDelayTitle={resultsDelayAlert?.title}
        resultsDelaySubtitle={resultsDelayAlert?.subtitle}
        resultsDelayReason={orderedTest.results_delayed_reason}
        labProvidesResultsEta={labCompany.provides_results_eta}
        labCompanyName={labCompany.short_name}
      />
    )
  }
  return (
    <>{`${labCompany.short_name} doesn't share estimates for when results will come in.`}</>
  )
}

const sampleAtLabStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.AT_LAB,
      labCompanyNotifiesSampleAtLab: true,
    },
    progressPercentage: PROGRESS_PERCENTAGES.AT_LAB,
    title: Title,
    subTitle: SubTitle,
  }

export default sampleAtLabStatusConfiguration

import { Patient } from "app/types"
import interleave from "app/utils/interleave"
import { ResultVisualizationNotification } from "types/result-visualization-notification"

import ResultVisualuzationNotificationItem from "./ResultVisualizationNotificationItem"

interface Props {
  patient: Patient
  notifications: ResultVisualizationNotification[]
  onAcknowledge: (notificationId: string) => void
  onDismiss: (notificationId: string) => void
}

const ResultVisualizationNotificationsList = ({
  notifications,
  patient,
  onAcknowledge,
  onDismiss,
}: Props) => {
  return (
    <div className="flex flex-col bg-white border border-slate-200 shadow rounded-xl py-3 px-[9px] gap-3">
      {interleave(
        notifications.map((notification, index) => (
          <ResultVisualuzationNotificationItem
            key={`notification-${index}`}
            patient={patient}
            notification={notification}
            onAcknowledge={() => onAcknowledge(notification.id)}
            onDismiss={() => onDismiss(notification.id)}
          />
        )),
        (_, key) => (
          <div className="w-full h-[1px] bg-slate-200" />
        )
      )}
    </div>
  )
}

export default ResultVisualizationNotificationsList

import { makeStyles } from "@material-ui/core/styles"

import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"

import WarningContainer from "./WarningContainer"

const useStyles = makeStyles((theme) => ({
  warning: {
    color: colors.amber[900],
  },
}))

export default function VibrantContainedWarning({
  isPractitionerPaying,
}: {
  isPractitionerPaying: boolean
}) {
  const classes = useStyles()

  return (
    <WarningContainer>
      <BodyText size="sm" className={classes.warning}>
        {isPractitionerPaying
          ? "You have tests from Vibrant America in this order. The last day for Vibrant America testing on Rupa is January 31st. Please share your shipping details before then or the order will be canceled."
          : "You have tests from Vibrant America in this order. The last day for Vibrant America testing on Rupa is January 31st. Please purchase your tests before then or the order will be canceled."}
      </BodyText>
    </WarningContainer>
  )
}

import { useCallback, useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import { FuseSplashScreen } from "@fuse"
import _ from "lodash"
import { useParams } from "react-router-dom"

import { Theme, useMediaQuery } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import { UserPaths } from "app/Routes"
import BundleBlueIcon from "app/assets/icons/bundle-blue.svg"
import BundlesBanner from "app/assets/icons/bundles/bundles-collection.svg"
import UpRightArrow from "app/assets/icons/up-right-arrow.svg"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import Panel from "app/components/Panel"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import BundleInfoModal from "app/components/modals/BundleInfoModal"
import BundleModal from "app/components/modals/BundleModal"
import ConfirmationModal from "app/components/modals/ConfirmationModal"
import LabTestModal from "app/components/modals/LabTestModal"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import useFacebookSDK from "app/integrations/hooks/use-facebook-sdk"
import BundleCard from "app/main/checkout/BundleCard"
import reducer from "app/main/checkout/store/reducers"
import Banner from "app/main/dashboard/Banner"
import { trackBundleVisitCustomSettings } from "app/services/segment"
import {
  addFavoriteTest,
  removeFavoriteTest,
  loadShareBundle,
} from "app/store/actions"
import * as OtherActions from "app/store/actions"
import withReducer from "app/store/withReducer"
import { colors, rupaTeal } from "app/theme"
import { LabTestBundleWithoutPractitioner, labTestLocation } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import SavedAndSharedBundles from "./Bundles/SavedAndSharedBundles"
import SideNav from "./SideNav"

const useBundlesStyles = makeAppStyles({
  wrapper: {
    background: colors.coolGray[50],
    border: `1px solid ${colors.coolGray[200]}`,
    minHeight: "100vh",
    padding: 24,
  },
  panel: {
    marginTop: 24,
  },
  designSystemButton: {
    marginTop: ({ isMobile }: { isMobile: boolean }) =>
      isMobile ? 10 : "auto",
    alignItems: "center",
    float: ({ isMobile }: { isMobile: boolean }) =>
      isMobile ? "left" : "right",
  },
  emptySection: {
    display: "flex",
    background: "white",
    justifyContent: "center",
    alignItems: "center",
    border: `2px dashed ${colors.blueGray[200]}`,
    padding: "32px 64px",
    marginTop: 24,
    borderRadius: 8,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 6,
  },
  linkText: {
    fontWeight: 600,
    marginTop: 3,
    display: "inline-block",
  },
  previewImage: {
    width: "50%",
    maxWidth: 700,
  },
  banner: {
    marginTop: 24,
    borderWidth: 0,
    padding: "0px",
  },
  bannerContent: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    background:
      "radial-gradient(100% 200% at -50% 110%, rgba(74, 168, 188, 0.48) 0%, rgba(197, 245, 255, 0) 100%)",
    borderRadius: 8,
  },
  bannerText: {
    margin: "auto 30px",
    padding: "12px 0",
  },
  upRightArrowIcon: {
    width: 10,
    marginLeft: 5,
    marginRight: 10,
  },
})

interface BundlesParams {
  shareBundleId: string
}

function Bundles() {
  useFacebookSDK()
  const dispatch = useDispatch()
  const { shareBundleId }: BundlesParams = useParams()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const classes = useBundlesStyles({ isMobile })

  const [useBundlesSharingV2] = useFeatureFlag(FeatureFlag.BundlesSharingV2)

  useEffect(() => {
    if (shareBundleId) {
      setShareBundleLoading(true)
      dispatch(
        loadShareBundle(shareBundleId, () => {
          setShareBundleModalIsOpen(true)
          setShareBundleLoading(false)
        })
      )
    }
  }, [shareBundleId])

  const shareBundle = useAppSelector(({ shareBundle }) => shareBundle)
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const practitionerBundles = useAppSelector(
    ({ practitionerBundles }) => practitionerBundles.results
  )

  useEffect(() => {
    if (practitioner.id) {
      trackBundleVisitCustomSettings(practitioner.id, practitioner.clinic?.id)
    }
  }, [practitioner.id])

  useEffect(() => {
    const bundleToRefresh = practitionerBundles.find(
      (bundle) => bundle.id === bundleOpenInInfoModal?.id
    )
    if (bundleOpenInInfoModal && bundleToRefresh) {
      setBundleOpenInInfoModal(bundleToRefresh)
    }
  }, [practitionerBundles])

  const [shareBundleLoading, setShareBundleLoading] = useState(false)

  const [bundleModalIsOpen, setBundleModalIsOpen] = useState(false)

  const [bundleInfoModalIsOpen, setBundleInfoModalIsOpen] = useState(false)
  const [bundleOpenInInfoModal, setBundleOpenInInfoModal] =
    useState<LabTestBundleWithoutPractitioner | null>(null)

  const [bundleConfirmationModalIsOpen, setBundleConfirmationModalIsOpen] =
    useState(false)

  const [bundleIsSubmitting, setBundleIsSubmitting] = useState(false)
  const [shareBundleIsAdding, setShareBundleIsAdding] = useState(false)
  const [shareBundleModalIsOpen, setShareBundleModalIsOpen] = useState(false)

  const [labTestModalIsOpen, setLabTestModalIsOpen] = useState(false)
  const [labTestOpenInModal, setLabTestOpenInModal] = useState(null)

  function handleToggleFavorite(lab_test_id, isFavorite) {
    if (isFavorite) {
      dispatch(removeFavoriteTest(practitioner, lab_test_id))
    } else {
      dispatch(addFavoriteTest(practitioner, lab_test_id))
    }
  }

  function closeBundleModal() {
    setBundleModalIsOpen(false)
    setBundleOpenInInfoModal(null)
  }

  function handleOpenBundleInfoModal(bundle) {
    setBundleOpenInInfoModal(bundle)
    setBundleInfoModalIsOpen(true)
  }

  function handleCloseBundleInfoModal(bundle) {
    setShareBundleModalIsOpen(false)
    setBundleOpenInInfoModal(null)
    setBundleInfoModalIsOpen(false)
  }

  function closeBundleInfoAndOpenEditBundleModal() {
    setBundleInfoModalIsOpen(false)
    setBundleModalIsOpen(true)
  }

  function closeBundleInfoAndDeleteModal() {
    setShareBundleModalIsOpen(false)
    setBundleConfirmationModalIsOpen(false)
    setBundleInfoModalIsOpen(false)
    setBundleOpenInInfoModal(null)
    setBundleIsSubmitting(false)
  }

  function handleDeleteBundle() {
    if (bundleOpenInInfoModal) {
      setBundleIsSubmitting(true)
      dispatch(
        OtherActions.deleteBundle(
          bundleOpenInInfoModal.id,
          closeBundleInfoAndDeleteModal
        )
      )
    }
  }

  function handleAddShareBundle() {
    setShareBundleIsAdding(true)
    if (!shareBundleIsAdding) {
      dispatch(
        OtherActions.addShareBundle(
          shareBundleId,
          closeBundleInfoAndDeleteModal
        )
      )
    }
  }

  const openModalWithLabTest = useCallback((labTest) => {
    setLabTestOpenInModal(labTest)
    setLabTestModalIsOpen(true)
  }, [])

  const showShareBundleModal =
    shareBundleModalIsOpen &&
    !_.isEmpty(shareBundleId) &&
    !_.isEmpty(shareBundle) &&
    !_.isEmpty(practitioner)

  // Show loader until the shared bundle is loaded
  if (shareBundleLoading) {
    return <FuseSplashScreen />
  }

  let bundlesComponent = (
    <div className={classes.emptySection}>
      <BodyText align="center">No Bundles Have Been Created.</BodyText>
    </div>
  )

  if (useBundlesSharingV2) {
    bundlesComponent = (
      <SavedAndSharedBundles
        practitioner={practitioner}
        isInfoCard
        bundles={practitionerBundles}
        openBundleModal={(bundle: LabTestBundleWithoutPractitioner | null) => {
          setBundleOpenInInfoModal(bundle)
          setBundleModalIsOpen(true)
        }}
        usePhysicianAuthorizationForOrderingAccess={
          practitioner.vendor_physician_authorization_enabled
        }
        location={null}
        orderedBundleIds={null}
        onAdd={() => {}}
        onRemove={() => {}}
      />
    )
  } else if (practitionerBundles.length) {
    bundlesComponent = (
      <Panel className={classes.panel}>
        <Grid container className="py-3 px-6 mt-0" spacing={2}>
          {practitionerBundles.map((bundle) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                // set the key as a combination of the bundle id and lab tests it includes so that this
                // component updates when the lab tests belonging to a bundle are updated
                key={`${bundle.id}-${bundle.lab_tests
                  ?.map((lab_test) => lab_test.id)
                  .join("-")}`}
                className="labTestCardGridItem"
              >
                <BundleCard
                  practitioner={practitioner}
                  isInfoCard
                  bundle={bundle}
                  openModal={() => handleOpenBundleInfoModal(bundle)}
                  location={labTestLocation.BUNDLES_SETTINGS}
                  orderedBundleIds={null}
                  onAdd={() => {}}
                  onRemove={() => {}}
                />
              </Grid>
            )
          })}
        </Grid>
      </Panel>
    )
  }

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },

            {
              label: "Custom Test Bundles",
            },
          ]}
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title="Custom Test Bundles"
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <div className={classes.wrapper}>
            {!useBundlesSharingV2 && (
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs={12} md={8}>
                  <div className={classes.titleWrapper}>
                    <BodyText weight="semibold" size="lg">
                      Your Bundles
                    </BodyText>
                  </div>
                  <BodyText>
                    Bundles let you save a group of lab tests for easy 1-click
                    ordering, right from your Favorites. These can be custom
                    blood panels or a mix of tests from different lab companies.{" "}
                  </BodyText>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DesignSystemButton
                    color="secondary"
                    startIcon={BundleBlueIcon}
                    onClick={() => setBundleModalIsOpen(true)}
                    isMobile={isMobile}
                    className={classes.designSystemButton}
                  >
                    Create a Custom Bundle
                  </DesignSystemButton>
                </Grid>
              </Grid>
            )}
            <Banner
              backgroundColor="#FFFFFF"
              borderColor={rupaTeal}
              className={classes.banner}
              style={{ marginTop: useBundlesSharingV2 ? 0 : 24 }}
            >
              <a
                className={classes.bannerContent}
                href="https://www.rupahealth.com/bundle-library"
                target="_blank"
                rel="noreferrer"
              >
                <div className={classes.bannerText}>
                  <BodyText>
                    Bundles let you save a group of lab tests for easy 1-click
                    ordering, right from your Favorites. These can be custom
                    blood panels or a mix of tests from different lab companies.
                  </BodyText>
                  <BodyText className={classes.linkText} color="primary">
                    Browse example bundles from our collection.
                    <img
                      className={classes.upRightArrowIcon}
                      src={UpRightArrow}
                      alt="up-right-arrow"
                    />
                  </BodyText>
                </div>
                {!isMobile && (
                  <img
                    className={classes.previewImage}
                    src={BundlesBanner}
                    alt=""
                  />
                )}
              </a>
            </Banner>

            {bundlesComponent}
          </div>
        </Grid>
      </Grid>
      {showShareBundleModal && (
        <BundleInfoModal
          open
          isSharedBundle
          practitioner={practitioner}
          onClose={handleCloseBundleInfoModal}
          handleAddBundle={() => {}}
          handleAdd={() => {}}
          handleRemove={() => {}}
          handleAddShareBundle={handleAddShareBundle}
          handleToggleFavorite={handleToggleFavorite}
          openConfirmationModal={() => setBundleConfirmationModalIsOpen(true)}
          openModalWithLabTest={openModalWithLabTest}
          openEditBundleModal={closeBundleInfoAndOpenEditBundleModal}
          selectedLabTests={{}}
          bundle={shareBundle}
          showTitleButton={false}
        />
      )}
      {!_.isEmpty(practitioner) && (
        <BundleModal
          open={bundleModalIsOpen}
          practitioner={practitioner}
          practitionerBundles={practitionerBundles}
          onClose={closeBundleModal}
          handleToggleLabTestFavorite={handleToggleFavorite}
          bundle={bundleOpenInInfoModal}
          usePhysicianAuthorizationForOrderingAccess={
            practitioner.vendor_physician_authorization_enabled
          }
          order={null}
          ecommerceOrderingPractitioner={null}
          location={labTestLocation.BUNDLES}
          storefrontId={null}
        />
      )}
      {!_.isEmpty(practitioner) && bundleOpenInInfoModal && (
        <BundleInfoModal
          open={bundleInfoModalIsOpen}
          practitioner={practitioner}
          onClose={handleCloseBundleInfoModal}
          handleAddBundle={() => {}}
          handleAdd={() => {}}
          handleAddShareBundle={() => {}}
          handleRemove={() => {}}
          handleToggleFavorite={handleToggleFavorite}
          openConfirmationModal={() => setBundleConfirmationModalIsOpen(true)}
          openModalWithLabTest={openModalWithLabTest}
          openEditBundleModal={closeBundleInfoAndOpenEditBundleModal}
          selectedLabTests={{}}
          bundle={bundleOpenInInfoModal}
          showTitleButton={false}
        />
      )}
      {!_.isEmpty(practitioner) && bundleOpenInInfoModal && (
        <ConfirmationModal
          open={bundleConfirmationModalIsOpen}
          message="This will delete this bundle from your account. This action cannot be reversed."
          backButtonTitle="Actually, Let’s Keep It"
          confirmButtonTitle="Delete Bundle"
          onClose={() => setBundleConfirmationModalIsOpen(false)}
          loading={bundleIsSubmitting}
          handleConfirm={handleDeleteBundle}
        />
      )}
      {practitioner && labTestOpenInModal && !bundleModalIsOpen && (
        <LabTestModal
          labTest={labTestOpenInModal}
          isOpen={labTestModalIsOpen}
          practitioner={practitioner}
          signingPractitioner={practitioner}
          onAddLabTest={() => {}}
          onRemoveLabTest={() => {}}
          onClose={() => setLabTestModalIsOpen(false)}
          location={labTestLocation.BUNDLES}
        />
      )}
    </>
  )
}

export default withReducer("orders", reducer)(Bundles)

import _ from "lodash"

import { PHLEBOTOMY_PROVIDERS } from "app/types"

export function updateProviderList(
  newProviderToSetName: string,
  currentProviderNames: string[]
) {
  if (newProviderToSetName === PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN) {
    return _.uniq(
      currentProviderNames
        .filter((provider) => provider !== PHLEBOTOMY_PROVIDERS.AXLE)
        .concat(PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN)
    )
  } else {
    return _.uniq(
      currentProviderNames
        .filter((provider) => provider !== PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN)
        .concat(PHLEBOTOMY_PROVIDERS.AXLE)
    )
  }
}

export function resetProviderList(currentProviderNames: string[]) {
  return _.uniq(
    currentProviderNames
      .filter((provider) => provider !== PHLEBOTOMY_PROVIDERS.AXLE)
      .filter((provider) => provider !== PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN)
  )
}

import { useCallback, useEffect, useRef, useState } from "react"

import { debounce } from "lodash"
import SplitPane from "react-split-pane"

import Grabber from "app/assets/images/results-over-time/grabber.svg"
import { colors } from "app/theme"

import { FAILED_PROCESSING_COPY } from "../../constants"
import ResultUploadPdfViewer from "../components/ResultUploadPdfViewer"
import ResultsUploadStatusBanner from "../components/ResultsUploadStatusBanner"
import UserResultFormContainer from "../components/UserResultFormContainer"

interface ResultsUploadMobileStackedEntryProps {
  userResultId: string | undefined
  showProcessingError?: boolean
  pdfFile?: File
  pdfFileUrl?: string
  onSnapshotCreate: () => void
}

function getWindowHeight() {
  const { innerHeight: height } = window
  return height
}

function getStaticHeightForStyles() {
  const height = getWindowHeight()
  return height - 50
}

const ResultsUploadMobileStackedEntry = ({
  userResultId,
  showProcessingError,
  pdfFile,
  pdfFileUrl,
  onSnapshotCreate,
}: ResultsUploadMobileStackedEntryProps) => {
  const [staticHeight, setStaticHeight] = useState(getStaticHeightForStyles())
  const [dynamicPdfContainerHeight, setDynamicPdfContainerHeight] = useState(
    getWindowHeight() - 400
  )

  const windowHeight = getWindowHeight()

  const pdfContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleResize() {
      setStaticHeight(getStaticHeightForStyles())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const handleSizeChange = useCallback(
    debounce((size) => {
      const pdfContainerHeight = windowHeight - size
      setDynamicPdfContainerHeight(pdfContainerHeight)
    }, 300),
    [windowHeight]
  )

  return (
    <div className={"relative w-full"} style={{ height: staticHeight }}>
      <SplitPane
        split="horizontal"
        defaultSize={400}
        resizerStyle={{
          background: colors.blueGray[300],
          backgroundImage: `url(${Grabber})`,
          backgroundPosition: "center",
          height: 11,
        }}
        onChange={handleSizeChange}
      >
        <div className="w-full overflow-y-scroll pb-5">
          {showProcessingError && (
            <div className="pb-5">
              <ResultsUploadStatusBanner
                containerClassName="bg-yellow-50 border-yellow-300 p-3"
                textColor="text-yellow-900"
                icon={null}
                title={null}
                text={FAILED_PROCESSING_COPY}
              />
            </div>
          )}
          {userResultId && (
            <UserResultFormContainer
              userResultId={userResultId}
              onSnapshotCreate={onSnapshotCreate}
              showSuccessStatusBanner={!showProcessingError}
            />
          )}
        </div>

        <div className="w-full" ref={pdfContainerRef}>
          <div className="relative mt-[10px]">
            <div
              className="absolute w-full overflow-y-scroll"
              style={{ height: dynamicPdfContainerHeight }}
            >
              <ResultUploadPdfViewer
                pdfFile={pdfFile}
                pdfFileUrl={pdfFileUrl}
              />
            </div>
          </div>
        </div>
      </SplitPane>
    </div>
  )
}

export default ResultsUploadMobileStackedEntry

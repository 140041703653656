import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import { PatientPortalBloodReportResult } from "app/patient-portal/types"
import { BloodReportResult } from "types/blood-report-result"

import { ResultsOverTimeResultData } from "../patient-orders/results-over-time/types/types"

export const getBiomarkerStatus = (
  bloodReportResult: BloodReportResult | PatientPortalBloodReportResult
): BiomarkerStatus | undefined => {
  return bloodReportResult.attributes.status
}

export const parseStringValueToFloat = (value?: string | null): number => {
  if (!value) return parseFloat("") // returning NaN if no value

  const regex = /[-+]?\d*\.?\d+/
  const matches = value.match(regex)

  return matches ? parseFloat(matches[0]) : parseFloat("") // returning NaN if no matches
}

export const getBiomarkerStatusResultsOverTime = (
  resultOverTimeResult: ResultsOverTimeResultData
): BiomarkerStatus | undefined => {
  return resultOverTimeResult.status
}

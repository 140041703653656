import { useCallback, useMemo, useState } from "react"

import { useParams } from "react-router-dom"

import { ButtonProps, cn } from "@rupahealth/design"

import RupaLoadingButton from "app/components/RupaLoadingButton"
import useAppSelector from "app/hooks/useAppSelector"
import { trackIOKOrderSuppliesClicked } from "app/services/segment"

import {
  StartOrderModal,
  StartOrderModalProps,
} from "./order-history/StartOrderModal"

// Button that opens the StartOrderModal
// Used on both tabs of the In-Office Kits page
interface Props
  extends Omit<StartOrderModalProps, "open" | "onClose" | "practitioner"> {
  children: React.ReactNode
  className?: string
  buttonProps?: ButtonProps
  getInitialValues?: () => Promise<StartOrderModalProps["initialValues"]>
}

const IOKStartOrderModalButton: React.FC<Props> = ({
  children,
  buttonProps: { variant, className, ...buttonProps } = {},
  initialValues,
  getInitialValues,
  ...modalProps
}) => {
  const { tab } = useParams<{ tab: string }>()
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const orderingDisabled = useMemo(() => {
    if (!practitioner) return true
    return (
      practitioner.has_full_verification_info === false &&
      practitioner.is_clinic_staff === false
    )
  }, [practitioner])

  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalInitialValues, setModalInitialValues] = useState(initialValues)

  const onModalOpen = useCallback(async () => {
    if (!initialValues && getInitialValues) {
      setLoading(true)
      const values = await getInitialValues()
      setModalInitialValues(values)
      setLoading(false)
    }
    trackIOKOrderSuppliesClicked(practitioner.id, tab)
    setModalOpen(true)
  }, [initialValues, getInitialValues])

  return (
    <>
      {modalOpen && (
        <StartOrderModal
          open={true}
          onClose={() => setModalOpen(false)}
          practitioner={practitioner}
          initialValues={modalInitialValues}
          {...modalProps}
        />
      )}
      <RupaLoadingButton
        variant={variant || "outline-primary"}
        loading={loading}
        disabled={orderingDisabled}
        onClick={onModalOpen}
        className={cn("font-title text-[15px] shadow-sm", className)}
        {...buttonProps}
      >
        <span className="whitespace-nowrap inline-flex items-center">
          {children}
        </span>
      </RupaLoadingButton>
    </>
  )
}

export default IOKStartOrderModalButton

import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import clsx from "clsx"
// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import * as authActions from "app/auth/store/actions"
import Button from "app/components/design-system/Button"
import { FormTextField } from "app/components/forms/fields"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  inputField: {
    "& .MuiInputBase-input": {
      fontSize: 16,
    },
  },
}))

function JWTLoginTab({ role, toggleForgotPasswordModal, initialEmail }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const login = useSelector(({ auth }) => auth.login)

  const [isFormValid, setIsFormValid] = useState(false)
  const formRef = useRef(null)

  useEffect(() => {
    if (login.error && (login.error.email || login.error.password)) {
      formRef.current.updateInputsWithError({
        ...login.error,
      })
      disableButton()
    }
  }, [login.error])

  function disableButton() {
    setIsFormValid(false)
  }

  function enableButton() {
    setIsFormValid(true)
  }

  function handleSubmit(model) {
    dispatch(authActions.submitLogin(model, role))
  }

  return (
    <div className="w-full">
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className="flex flex-col justify-center w-full"
      >
        <FormTextField
          className={clsx("mb-5 fs-exclude", classes.inputField)}
          name="email"
          label="Email Address"
          value={initialEmail}
          required
        />

        <FormTextField
          className={clsx("mb-5 fs-exclude", classes.inputField)}
          type="password"
          name="password"
          label="Password"
          value=""
          required
          secondaryElement={
            <Button
              color="text"
              onClick={toggleForgotPasswordModal}
              size="small"
              className="mb-1"
              style={{ fontSize: 14 }}
            >
              Reset Password
            </Button>
          }
        />

        <Button
          color="primary"
          aria-label="LOG IN"
          disabled={!isFormValid}
          value="legacy"
          type="submit"
        >
          Sign In
        </Button>
      </Formsy>
    </div>
  )
}

export default JWTLoginTab

import { Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import PhlebotomyIndicatorIcon from "app/assets/icons/blood.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { LABCORP_COMPANY_KEYS, QUEST_COMPANY_KEYS } from "app/constants"
import { colors } from "app/theme"

const useStyles = makeStyles({
  phlebotomyIcon: {
    marginLeft: 4,
    marginRight: 12,
    width: 22,
    height: 22,
    padding: 1,
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 5,
    flexShrink: 0,
  },
})

const getPhlebotomyInstructions = (labCompany) => {
  if (LABCORP_COMPANY_KEYS.includes(labCompany.key)) {
    return (
      <BodyText>
        Your patient will need to get a blood draw done at a{" "}
        <Link
          href="https://www.labcorp.com/labs-and-appointments"
          target="_blank"
        >
          Labcorp draw station
        </Link>
        . We'll send them an order form and instructions to book the appointment
      </BodyText>
    )
  }
  if (QUEST_COMPANY_KEYS.includes(labCompany.key)) {
    return (
      <BodyText>
        Your patient will need to get a blood draw done at a{" "}
        <Link
          href="https://www.questdiagnostics.com/locations/search"
          target="_blank"
        >
          Quest draw station
        </Link>
        . We'll send them an order form and instructions to book the appointment
      </BodyText>
    )
  }
  return (
    <BodyText>
      There is typically an additional cost involved. Your patient will pay this
      directly to the phlebotomist.{" "}
      <Link
        href={`https://www.rupahealth.com/phlebotomy?lab-company-id=${labCompany.id}`}
        target="_blank"
      >
        See how Rupa instructs your patients on their blood draws.
      </Link>
    </BodyText>
  )
}

export const PhlebotomyRequired = ({ labCompany }) => {
  const classes = useStyles()

  return (
    <Tooltip
      placement="bottom"
      arrow
      interactive
      title={getPhlebotomyInstructions(labCompany)}
    >
      <img
        className={classes.phlebotomyIcon}
        src={PhlebotomyIndicatorIcon}
        alt="phlebotomy-icon"
      />
    </Tooltip>
  )
}

import OrderStatus from "app/components/OrderStatus"
import { ORDER_STATUS } from "app/constants.typed"
import { StorefrontOrderApprovalStatus } from "app/main/ecommerce/constants"

import { TableCellProps } from "./types"

export default function StatusTableCell({ order }: TableCellProps) {
  let status = order.status

  // TODO: Remove these once we create statuses in the backend to reflect this
  if (
    order.storefront_order_approval?.status ===
    StorefrontOrderApprovalStatus.DENIED
  ) {
    status = ORDER_STATUS.DENIED_BY_LABSHOP
  } else if (
    order.storefront_order_approval?.status ===
    StorefrontOrderApprovalStatus.PENDING
  ) {
    status = ORDER_STATUS.PENDING_LABSHOP_APPROVAL
  }

  // TODO: Revamp order status in the backend to reflect the different possible ordering statuses for blood draw companies
  const orderOnlyBloodDrawTests = order.ordered_tests.every(
    (ot) => ot.lab_test.lab_company.only_in_house_phlebotomy
  )
  if (status === ORDER_STATUS.AWAITING_SHIPMENT && orderOnlyBloodDrawTests) {
    status = ORDER_STATUS.IN_PROGRESS
  }

  return (
    <OrderStatus
      status={status}
      total_completed_ordered_tests={order.total_completed_ordered_tests}
      total_ordered_tests={order.total_ordered_tests}
      specimen_issue_status={order.specimen_issue_status}
    />
  )
}

import EmptyDocumentsIcon from "app/assets/images/results-over-time/documents-empty.png"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import Card from "app/patient-portal/Card"

import PatientPortalUserEmptyState from "./PatientPortalUserEmptyState"

const PatientPortalUserDocuments = () => {
  const [patientPortalDocumentsEnabled] = useFeatureFlag(
    FeatureFlag.PatientPortalDocuments
  )

  if (!patientPortalDocumentsEnabled) {
    return (
      <Card className="h-full flex">
        <div className="justify-center items-center text-navy font-semibold">
          Whoops... this page is not currently available.
        </div>
      </Card>
    )
  }

  return (
    <Card className="h-full">
      <div className="text-navy text-lg font-semibold">Trends</div>
      <div className="h-[1px] w-full bg-slate-200"></div>
      <PatientPortalUserEmptyState
        mainText="No documents yet!"
        supplementalText="Your lab results will appear here."
        imageNode={
          <div className="w-[225px]">
            <img src={EmptyDocumentsIcon} alt="no trends" />
          </div>
        }
      />
    </Card>
  )
}

export default PatientPortalUserDocuments

import React from "react"

import { useHistory } from "react-router-dom"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { Dialog, FadeProps, Grow, IconButton } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import CloseIcon from "@material-ui/icons/Close"

import { UserPaths } from "app/Routes/paths"
import { ReactComponent as GreenCheck } from "app/assets/icons/green-check.svg"
import { ReactComponent as DownArrowIcon } from "app/assets/icons/rupa-blood-dashboards/down-arrow.svg"
import { ReactComponent as UpArrowIcon } from "app/assets/icons/rupa-blood-dashboards/up-arrow.svg"
import BodyText from "app/components/design-system/BodyText"
import useAppSelector from "app/hooks/useAppSelector"
import { ClinicSettings } from "app/main/settings/RupaBloodDashboards/constants"
import { colors, shadows, alertYellow } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

interface ResultsOverTimeLegendModalProps {
  onClose: () => void
}

const GrowTransition = React.forwardRef<unknown, FadeProps>(function Transition(
  props,
  ref
) {
  return <Grow ref={ref} {...props} timeout={500} />
})

const useStyles = makeAppStyles(() => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
    border: "4px solid white",
    boxShadow: shadows["2xl"],
    width: 700,
    transition: "all 500ms",
  },
}))

const ResultsOverTimeLegendModal = ({
  onClose,
}: ResultsOverTimeLegendModalProps) => {
  const classes = useStyles()
  const history = useHistory()

  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const clinic = practitioner?.clinic

  const optimalRangesOnTest = clinic?.clinic_settings.includes(
    ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
  )

  return (
    <>
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
        TransitionComponent={GrowTransition}
      >
        <MuiDialogTitle
          disableTypography
          className="flex justify-between flex-nowrap bg-white items-center py-1 px-6"
        >
          <BodyText
            weight="semibold"
            size="lg"
            className="font-title flex items-center"
          >
            Legend
          </BodyText>
          <IconButton
            aria-label="close"
            onClick={onClose}
            key="close-button"
            className="text-body relative top-[1px] right-0 mr-[-12px]"
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className="sm:p-[22.5px] bg-gray-100 flex flex-col p-3">
          <div className="flex flex-col items-start gap-[17px] self-stretch p-4 rounded-lg bg-white shadow-sm">
            <div className="font-open-sans text-body text-sm">
              Your clinic has optimal ranges{" "}
              <span className="font-bold">
                {optimalRangesOnTest ? "on" : "off"}
              </span>{" "}
              in{" "}
              <span
                className="underline cursor-pointer"
                onClick={() => {
                  onClose()
                  history.push(UserPaths.RUPA_BLOOD_DASHBOARDS)
                }}
              >
                Settings
              </span>
              .
            </div>
            <TypeSection
              title={
                optimalRangesOnTest
                  ? "Optimal (or Normal, if no Optimal Range is specified)"
                  : "Normal"
              }
              body={
                optimalRangesOnTest
                  ? "Within the ideal range for optimal health."
                  : "Within the lab’s standard range."
              }
              statusIndicators={<GreenCheck width={19} height={27} />}
              color={colors.emerald[100]}
            />
            {optimalRangesOnTest && (
              <TypeSection
                title="Above or Below Optimal"
                body="Within the lab’s standard normal range but outside the ideal level for optimal health."
                statusIndicators={
                  <>
                    <DownArrowIcon width={19} height={27} fill={alertYellow} />
                    <UpArrowIcon width={19} height={27} fill={alertYellow} />
                  </>
                }
                color={colors.yellow[100]}
              />
            )}
            <TypeSection
              title="Abnormally High or Low"
              body="Outside the lab’s standard range."
              statusIndicators={
                <>
                  <DownArrowIcon
                    width={19}
                    height={27}
                    fill={colors.red[500]}
                  />
                  <UpArrowIcon width={19} height={27} fill={colors.red[500]} />
                </>
              }
              color={colors.rose[100]}
            />
          </div>
        </MuiDialogContent>
      </Dialog>
    </>
  )
}

const TypeSection = ({
  title,
  body,
  statusIndicators,
  color,
}: {
  title: string
  body: string
  statusIndicators: React.ReactNode
  color?: string
}) => {
  return (
    <div className="flex flex-col items-start gap-2.5 self-stretch p-2.5 rounded-md border border-slate-200 bg-white">
      <div className="flex gap-4 items-center">
        <div
          className="flex-shrink-0 w-32 h-14 rounded-lg flex items-center justify-center"
          style={{ backgroundColor: color }}
        >
          {statusIndicators}
        </div>
        <div>
          <div className="font-open-sans font-semibold text-body text-sm">
            {title}
          </div>
          <div className="font-open-sans text-body text-sm">{body}</div>
        </div>
      </div>
    </div>
  )
}

const ResultsOverTimeLegendNiceModal =
  NiceModal.create<ResultsOverTimeLegendModalProps>(ResultsOverTimeLegendModal)

export default ResultsOverTimeLegendNiceModal

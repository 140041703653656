import { GuestPaths, UserPaths } from "."
import { Location } from "history"
import {
  Redirect,
  Route as ReactRouterRoute,
  RouteProps as ReactRouterRouteProps,
} from "react-router-dom"

import { isUserGuest, isUserPatient, isUserPractitioner } from "app/auth/util"
import useAppSelector from "app/hooks/useAppSelector"
import { checkUserHasPermission } from "app/permissions"
import { AuthUser, Role } from "app/types"
import { isNextPathAllowed } from "app/utils.js"

import { PatientPortalPatientPaths } from "./paths/patient-portal-paths"

interface RouteProps extends ReactRouterRouteProps {
  permissions?: Role[]
}

function getRedirectProps(
  user: AuthUser,
  isAuthorized: boolean,
  location: Location
) {
  /*
   * Determines whether we need to redirect the user based on their desired route and authorization.
   *
   * NOTE: `isAuthorized` is true any time the user's role allows them to view the current route. This
   * means its true even for logged out users viewing /login. That's why below we have
   * some conditions that handle the redirects differently depending on the role.
   */

  const params = new URLSearchParams(location.search)

  let redirectPath: string | null = null
  const next = params.get("next")
  const patientPortalToken = params.get("patient-portal-token")

  const isGuestUser = isUserGuest(user)

  // Handle redirecting to an authorized path if the user is not authorized for the current route.
  if (!isAuthorized) {
    if (isGuestUser) {
      redirectPath = GuestPaths.LOGIN
    } else if (isUserPatient(user) && !isUserPractitioner(user)) {
      redirectPath = PatientPortalPatientPaths.ROOT
    } else {
      redirectPath = UserPaths.ROOT
    }

    // If we redirect to the login page, save the current page in `?next` so the user is redirected
    // after a successful login.
    if (
      !next &&
      redirectPath === GuestPaths.LOGIN &&
      // We don't ever want to save these paths
      location.pathname !== UserPaths.ROOT &&
      location.pathname !== PatientPortalPatientPaths.ROOT &&
      location.pathname !== GuestPaths.LOGIN
    ) {
      params.append("next", location.pathname)
    }

    // if redirecting to login from patient portal, add role param so patient tab will be selected
    if (
      redirectPath === GuestPaths.LOGIN &&
      location.pathname.startsWith(PatientPortalPatientPaths.ROOT)
    ) {
      params.set("role", "patient")

      // if patient portal token is present, then direct them to the callback page
      // to determine if they need to sign up or login
      if (patientPortalToken) {
        redirectPath = GuestPaths.PATIENT_PORTAL_CALLBACK
      }
    }
  }

  // Handle a next query param - we only support it for logged in users
  if (next && !isGuestUser) {
    const isNextAllowed = isNextPathAllowed(next, user)

    // For OAuth authorization screens, the user is required to be logged in. If
    // they're not, they're redirected to the client. After they've logged in, we want to
    // redirect them back to the OAuth screen, but as we need to do a hard refresh to
    // ensure the backend handles the request, we need to manually do a window.location.href
    // instead of using browser-only navigation.
    if (next.startsWith("/oauth")) {
      params.delete("next")

      window.location.href = `${next}${params.toString() ? `&${params}` : ""}`
    }

    if (isNextAllowed) {
      redirectPath = next
    } else {
      // If it's not valid we set the redirect path to the current path so that
      // the next param is removed (as we'll return a <Redirect> to the current path
      // but without the search params).
      redirectPath = location.pathname
    }

    // Irrespective of whether it was valid, we remove it
    params.delete("next")
  }

  return {
    redirectPath,
    redirectParams: params,
  }
}

export default function Route({ children, permissions, ...rest }: RouteProps) {
  const user = useAppSelector(({ auth }) => auth.user)

  const isAuthorized = permissions
    ? checkUserHasPermission(user, permissions)
    : true

  return (
    <ReactRouterRoute
      {...rest}
      render={({ location }) => {
        // Determine whether we need to redirect
        const { redirectPath, redirectParams } = getRedirectProps(
          user,
          isAuthorized,
          location
        )

        return redirectPath ? (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: location },
              search: redirectParams.toString(),
            }}
          />
        ) : (
          children
        )
      }}
    />
  )
}
